import {
  Track1Icon,
  Track2Icon,
  Track3Icon,
} from "../components/Reservation/icons";

// export const API_URL = "http://localhost:3001";
 // export const API_URL = "http://localhost:3000";
// export const API_URL = "https://test.transconnector.eu";
export const API_URL = "https://api.transconnector.eu";
//  export const API_URL = "https://test.transconnector.eu";


export const TRANSPORTER_TYPES = {
  1: {
    label: "Up to 16.5m",
    Icon: Track1Icon,
  },
  2: {
    label: "Up to 19.5m",
    Icon: Track2Icon,
  },
  3: {
    label: "Other",
    Icon: Track3Icon,
  },
};
