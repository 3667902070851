import { ADD_FAVOURITE, REMOVE_FAVOURITE } from "../../actionTypes";
import axios from "axios";
import { API_URL } from "../../../constants";
import { getToken } from "../../../helpers/getToken";

export const addFavourite = (id, name, type) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    axios
      .post(
        `${API_URL}/favourites`,
        {
          favouriteId: id,
          name: name,
          type: type,
        },
        config
      )
      .then((res) => {
        dispatch({
          type: ADD_FAVOURITE,
          payload: res.data,
        });
      });
  };
};

export const removeFavourite = (id) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    axios.delete(`${API_URL}/favourites/${id}`, config).then((res) => {
      dispatch({
        type: REMOVE_FAVOURITE,
        id: id,
      });
    });
  };
};
