import styled from "styled-components";

export const Content = styled.div`
  padding: 20px 15px;
  line-height: 1.5;

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 15px;
  }
  
  h3 {
    margin: 0;
  }

  .icon {
    max-width: 1em;
    transition: 0.25s opacity,visibility ease;
  }
  .route-item__info {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 12px;
    color: #0F1540;
    .icon {
      color: #262A68;
    }
  }
  
  .about-route {
    color: #9A9A9D;
    p {
      margin-top: 0;
    }
  }
  
  ul {
    margin: 0;
    padding-left: 20px;
    font-size: 14px;

    li {
      transition: 0.25s all ease;
      color: #0F1540;
      cursor: pointer;
      outline: none;
      
      .item-wrap {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      
      .icon--info {
        visibility: hidden;
        opacity: 0;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &:focus {
        color: #e54601;
      }

      &:hover {
        color: #ef4900;
      }

      &:focus,
      &:hover {
        .icon--info {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .heading {
      margin-bottom: 24px;
    }
    .route-item__info {
      margin-bottom: 16px;
      
      &--about {
        margin-top: 8px;
      }
    }
  }
  @media (min-width: 1400px) {
    padding: 40px 50px;
  }
`;

export const ButtonBack = styled.button`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: #9A9A9D;
  font-size: 14px;
  background: transparent;
  outline: none;
  border: 0;
  &:focus {
    color: #3b3b3b;
  }
  &:hover {
    color: #5d5d5d;;
  }
  
  .icon {
    width: 8px;
    height: 19px;
  }

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;
