import React from "react";
import {
  InputWrapper,
  LabelWrapper,
  Label,
  Input,
  ErrorContainer,
  ErrorWrapper,
} from "../CreateForm/Styles";

const CheckboxGroup = (props) => {
  return (
    <InputWrapper>
      <LabelWrapper>
        {props.label && (
          <Label>
            <h3>{props.label}</h3>
          </Label>
        )}
        <ErrorContainer>
          <Input>{props.children}</Input>
          {props.error && (
            <ErrorWrapper>
              <span>{props.error.msg || "required"}</span>
            </ErrorWrapper>
          )}
        </ErrorContainer>
      </LabelWrapper>
    </InputWrapper>
  );
};

export default CheckboxGroup;
