import {bool, number, object, string} from "yup";
import 'yup-phone-lite';
export default object({
 email: string().email().required('Please provide your email address'),
 transportType: number(),
 company: string().required('Please provide company name'),
 contact_person: string().required('Please provide contact person'),
 phone: string().required('Please provide your phone number'),
 date: string(),
 from: string().required(),
 to: string().required(),
 privacy: bool().required().oneOf([true], "The terms and conditions must be accepted.")
})
