import React, { useState } from "react";
import { Link } from "react-router-dom";
import image from "../images/login.png";
import "../styles/Forgot/Forgot.css";
import logo from "../images/logo-white.svg";
import Header from "../components/FrontPage/Header/Header";
import axios from "axios";
import { API_URL } from "../constants";

const Forgot = () => {
  const [email, setEmail] = useState("");
  const [userError, setUserError] = useState(false);
  const [userErrorMessage, setUserErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  function validateEmail(email) {
    var re = /^\S+@\S+$/;
    return re.test(email);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    if (email?.length > 0) {
      if (!validateEmail(email)) {
        setUserErrorMessage("Entered Email address is invalid");
        setUserError(true);
        setLoader(false);
        return;
      }
    }
    if (email?.length > 0 || email?.length === 0) {
      axios
        .post(`${API_URL}/recoverUser`, {
          email: email,
        })
        .then(() => {
          setEmailSent(true);
          setLoader(false);
        })
        .catch((err) => {
          setEmailSent(true);
          setLoader(false);
        });
    }
  };

  return (
    <>
      <Header />
      <div className="Login">
        <div className="Login__Image">
          <img src={logo} alt="TransConnector" />
          <img src={image} alt="TransConnector" />
        </div>
        <div className="Login--inner">
          {!emailSent ? (
            <>
              <h2>
                Forgot your <br></br> password?
              </h2>
              <h4>Enter your email address</h4>
              <form onSubmit={handleSubmit}>
                <div className={`Login__Error animated ${userError}`}>
                  <p>{userErrorMessage}</p>
                </div>
                <div className="floating-label">
                  <input
                    type="text"
                    placeholder=" "
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label>Email address</label>
                </div>
                <div className="inline-label">
                  <Link to="/log-in">Back to Log in</Link>
                </div>
                <div className="Submit__Form">
                  <input
                    className={`${loader}`}
                    type="submit"
                    value="Reset Password"
                  />
                  <div className={`loader ${loader}`}></div>
                </div>
              </form>
              <div className="SignUp">
                <h5>Don't have an account?</h5>
                <Link to="/sign-up">Sign Up</Link>
              </div>
            </>
          ) : (
            <div className="ForgotSuccess">
              <p>Reset password link has been sent to {email}</p>
              <Link to="/log-in">Back to login</Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Forgot;
