import React, { useEffect, useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";

import Sheet from "../../PageWithList/Sheet/Sheet";
import SpotloadSheetHeader from "./SpotloadSheetHeader";
import SheetInner from "./SheetInner";
import { SheetClose } from "../../PageWithList/Sheet/Styles";
import LoginGateway from "../../Modals/AuthGatewayModals/LoginGateway";
import SubscribeGateway from "../../Modals/AuthGatewayModals/SubscribeGateway";
import {
  addFavourite,
  removeFavourite,
} from "../../../store/actions/dashboard/favourites";
import { sendUserMessage } from "../../../store/actions/misc";

const reducer = (state, action) => {
  if (action.type === "setSpotload") {
    return action.payload;
  } else {
    return state;
  }
};

const SpotloadSheet = (props) => {
  const { USER, setSheetID } = props;
  const [spotload, dispatch] = useReducer(reducer, props.spotload || null);
  const [isFavourited, setIsFavourited] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [loginGateway, setLoginGateway] = useState(false);
  const [expiredGateway, setExpiredGateway] = useState(false);
  const reduxDispatch = useDispatch();
  const USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.status
  );

  // Set subscription gateway when user is expired or cancelled
  useEffect(() => {
    if (USER_PLAN && (USER_PLAN === "Expired" || USER_PLAN === "Cancelled")) {
      setIsExpired(true);
    } else {
      setIsExpired(false);
    }
  }, [USER_PLAN]);

  // Set login and subscription gateway modals

  
  useEffect(() => {
    if (!USER && props.id) setLoginGateway(true);
    if (USER && isExpired && props.id) setExpiredGateway(true);
  }, [props.id, loginGateway, expiredGateway, isExpired, USER]);

  
  // Get the requested spotload from all spotloads
  useEffect(() => {
    if (props.spotloads) {
      dispatch({
        type: "setSpotload",
        payload: props.spotloads.find((spotload) => spotload._id === props.id),
      });
    }
    return () => dispatch({ type: "setSpotload", payload: null });
  }, [dispatch, props.spotloads, props.id]);

  // Check if the requested spotload is in favourites
  useEffect(() => {
    if (USER?.favourites) {
      const is = USER?.favourites.some((favourite) =>
        favourite.favouriteId === props.id ? true : false
      );
      if (is) {
        setIsFavourited(true);
      } else {
        setIsFavourited(false);
      }
    }
  }, [USER, props.id]);

  // Handle adding to favourites
  const addToFavourites = () => {
    if (!spotload) return;
    reduxDispatch(
      addFavourite(
        spotload._id,
        `${spotload.loading.locations[0].locationName} - ${spotload.unloading.locations[0].locationName}`,
        "Spotload"
      )
    );
  };

  // Handle removing from favourites
  const removeFromFavourites = () => {
    if (!spotload) return;
    reduxDispatch(removeFavourite(spotload._id));
  };

  const handleMessageSubmit = (value) => {
    if (value?.length > 0) {
      reduxDispatch(
        sendUserMessage(
          spotload.author._id,
          `From ${spotload.loading.locations[0].locationName} to ${spotload.unloading.locations[0].locationName}`,
          value,
          "Spotload",
          spotload._id
        )
      )
        .then((res) => setMessageSent(true))
        .catch((err) => console.log("err", err));
    }
  };
/*
  if (loginGateway)
    return (
      <LoginGateway
        onClose={() => {
          setSheetID(null);
          setLoginGateway(false);
        }}
        offerGateway
      />
    );
  if (expiredGateway)
    return (
      <SubscribeGateway
        onClose={() => {
          setSheetID(null);
          setExpiredGateway(false);
        }}
      />
    );
    */
  if (/*USER && !isExpired &&*/ spotload) {
    return (
      <>
        <Sheet size={props.size} denyActions={props.denyActions}>
          <SpotloadSheetHeader
            setSheetID={(id) => props.setSheetID(id)}
            authorId={spotload.authorId}
            isFavourited={isFavourited}
            spotload={spotload}
            addToFavourites={addToFavourites}
            removeFromFavourites={removeFromFavourites}
            denyActions={props.denyActions}
            size={props.size}
            loginGateway={loginGateway}
          />
          {spotload && (
            <SheetInner
              handleMessageSubmit={handleMessageSubmit}
              spotload={spotload}
              messageSent={messageSent}
              setMessageSent={setMessageSent}
              denyActions={props.denyActions}
              loginGateway={loginGateway}

            />
          )}
        </Sheet>
        {!props.denyActions && (
          <SheetClose onClick={() => props.setSheetID(null)} />
        )}
      </>
    );
  } else return null;
};

export default SpotloadSheet;
