import React from "react";
import Header from "../components/FrontPage/Header/Header";
import EmptyTruck from "../components/EmptyTruck/EmptyTruck";
import LeftDrawer from "../components/Dashboard/Drawers/LeftDrawer";
import { isBrowser } from "react-device-detect";
import Minimessages from "../components/Dashboard/Messages/Minimessages";

const EmptyTruckPage = ({ USER }) => {
  return (
    <>
      <Header />
      <EmptyTruck />
      {USER && isBrowser && <Minimessages />}
      {USER && <LeftDrawer small />}
    </>
  );
};

export default EmptyTruckPage;
