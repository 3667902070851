import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import Table from "../../NewTable/Table";
import TableHeader from "../../NewTable/TableHeader";
import { TableHeaderItem } from "../../NewTable/Styles";
import TableContent from "../../NewTable/TableContent";
import TableRow from "../../NewTable/TableRow";
import TableRowItem from "../../NewTable/TableRowItem";
import Status from "../../NewTable/Status";
import Options from "../../NewTable/Options";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";
import ConfirmationSuccess from "../../Modals/ConfirmationModal/ConfirmationSuccess";
import { removeOffer } from "../../../store/actions/dashboard/offers";
import { REMOVE_OFFER } from "../../../store/actionTypes";

const FindRoundtripTable = (props) => {

  const [optionsId, setOptionsId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [confirmationSuccess, setConfirmationSuccess] = useState(false);
  const [canEdit, setCanEdit] = useState(true)
  const dispatch = useDispatch();

  
  // Handle removing a roundtrip
  const removeRoundtrip = (id) => {
    dispatch(removeOffer(id, "FindRoundtrip")).then((res) => {
      dispatch({
        type: REMOVE_OFFER,
        payload: res.data,
        id: id,
        array: "findRoundtrips",
      });
      setConfirmationModal(null);
      setConfirmationSuccess(true);
    });
  };

  const handleConfirmationModal = (id, title) => {
    setConfirmationModal({ id, title });
  };

  const handleToggleOptions = (id) => {
    if (optionsId === id) {
      setOptionsId(null);
    } else {
      setOptionsId(id);
    }
  };

  const handleRedirect = (url, state) => {
    props.history.push(url, state);
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem flex="0.3">Truck type</TableHeaderItem>
          <TableHeaderItem flex="0.3">Countries</TableHeaderItem>
          <TableHeaderItem flex="0.5">Languages</TableHeaderItem>
          <TableHeaderItem flex="0.2">Published</TableHeaderItem>
          <TableHeaderItem flex="0.2">Status</TableHeaderItem>
          <TableHeaderItem flex="0.1"></TableHeaderItem>
        </TableHeader>
        <TableContent>
          {props.roundtrips?.map((roundtrip) => {
            const truckTypes = roundtrip?.trucks?.truckTypes
              .map((element) => {
                if (
                  element.value === "Refrigerator" &&
                  roundtrip?.trucks?.temperature
                ) {
                  return `${element.value} (${roundtrip?.trucks?.temperature}°C)`;
                }
                return element.value;
              })
              .join(", ")
              .replace(/_/g, " ");
            return (
              <TableRow
                key={roundtrip._id}

                onClick={() =>
                  handleRedirect("/find-roundtrip", {
                    sheet: roundtrip._id,
                  })
                }

              >
                 <TableRowItem
                  rowName="Truck type"
                  title={{
                    name: truckTypes,
                  }}
                  flex="0.3"
                />
                <TableRowItem
                  rowName="Roundtrip"
                  title={{
                    name: roundtrip.name,
                  }}
                  flex="0.3"
                >
                  <span>
                    {" "}
                    {roundtrip.countries
                      ?.map((country) => {
                        return country.label;
                      })
                      .join(", ")}
                  </span>
                </TableRowItem>
                <TableRowItem
                  rowName="Roundtrip"
                  title={{
                    name: roundtrip.name,
                  }}
                  flex="0.5"
                >
                  <span>
                    {" "}
                    {roundtrip.languages
                      ?.map((language) => {
                        return language.label;
                      })
                      .join(", ")}
                  </span>
                </TableRowItem>
              
                <TableRowItem
                  rowName="Published"
                  title={{
                    name: moment(roundtrip.createdAt).format("DD.MM.YY"),
                  }}
                  flex="0.2"
                />
                <TableRowItem rowName="Status" flex="0.2">
                  <Status
                    active={
                      roundtrip.inReview
                        ? false
                        : roundtrip.active
                          ? true
                          : false
                    }
                    inReview={roundtrip.inReview}
                    status={
                      roundtrip.inReview
                        ? "In review"
                        : roundtrip.active
                          ? "Active"
                          : "Inactive"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      if (canEdit) {
                        if (!roundtrip.inReview)
                          props.handleStatusChange(
                            roundtrip._id,
                            !roundtrip.active,
                            "FindRoundtrip",
                            "findRoundtrips"
                          );
                      }
                    }}
                  />
                </TableRowItem>
                <TableRowItem flex="0.1" overflow>
                  <Options
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleOptions(roundtrip._id);
                    }}
                    open={optionsId === roundtrip._id}
                    onBlur={() => setOptionsId(null)}
                  >
                    <ul>
                      <li

                        onClick={() => {
                          if (canEdit) {
                            handleRedirect(
                              `/dashboard/find-roundtrip/edit/${roundtrip._id}`
                            )
                          }
                        }
                        }
                      >
                        Edit
                      </li>
                      {/*
                      <li
                        onClick={() => {
                          if (canEdit) {
                            handleRedirect(
                              `/dashboard/find-roundtrip/duplicate/${roundtrip._id}`
                            )
                          }
                        }
                        }
                      >
                        Duplicate
                      </li>
                    */}
                      <li
                        onClick={() => {
                          if (canEdit) {

                            handleConfirmationModal(
                              roundtrip._id,
                              `Delete`
                            )
                          }
                        }}
                      >
                        Delete
                      </li>
                    </ul>
                  </Options>
                </TableRowItem>
              </TableRow>
            );
          })}
        </TableContent>
      </Table>
      {confirmationModal && (
        <ConfirmationModal
          onClose={() => setConfirmationModal(null)}
          title={confirmationModal.title}
          onConfirm={() => {
            removeRoundtrip(confirmationModal.id);
            setConfirmationModal(null);
          }}
        />
      )}
      {confirmationSuccess && (
        <ConfirmationSuccess onClose={() => setConfirmationSuccess(false)} />
      )}
    </>
  );
};

export default withRouter(FindRoundtripTable);
