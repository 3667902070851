import React, { useState, useEffect } from "react";

import Filter from "./Filter";
import Loader from "../UI/Loader";
import ScreenBlock from "../Screen/ScreenBlock";
import EmptyTrucksTable from "./EmptyTrucksTable";

const EmptyTrucks = (props) => {
  const [filter, setFilter] = useState(0);
  const [active, setActive] = useState(0);
  const [inActive, setInactive] = useState(0);
  const [emptyTrucks, setEmptytrucks] = useState([]);

  const { OFFERS } = props;

  // Handle setting empty trucks state
  useEffect(() => {
    if (
      OFFERS &&
      OFFERS[3]?.emptyTrucks?.length > 0 &&
      emptyTrucks?.length <= 0
    ) {
      setEmptytrucks(OFFERS[3]?.emptyTrucks);
    }
  }, [OFFERS, emptyTrucks]);

  // Reset filter when there are no empty trucks to show
  useEffect(() => {
    if (emptyTrucks?.length <= 0 && filter > 0) {
      setFilter(0);
      setEmptytrucks(OFFERS[3]?.emptyTrucks);
    }
  }, [emptyTrucks, filter, OFFERS]);

  // Handle tender filtering
  useEffect(() => {
    if (filter === 0 && emptyTrucks?.length >= 0) {
      setEmptytrucks(OFFERS[3]?.emptyTrucks);
    } else if (filter === 1) {
      setEmptytrucks(OFFERS[3]?.emptyTrucks.filter((e) => e.active));
    } else if (filter === 2) {
      setEmptytrucks(OFFERS[3]?.emptyTrucks.filter((e) => !e.active));
    }
  }, [filter, OFFERS]);

  // Get count of active and inactive tenders
  useEffect(() => {
    if (OFFERS && OFFERS[3]?.emptyTrucks) {
      setActive(0);
      setInactive(0);
      OFFERS[3].emptyTrucks.forEach((e) =>
        e.active
          ? setActive((prevCount) => prevCount + 1)
          : setInactive((prevCount) => prevCount + 1)
      );
    }
  }, [OFFERS]);

  return (
    <ScreenBlock title="My Empty Trucks">
      {emptyTrucks?.length > 0 && (
        <Filter
          all={props.OFFERS && props.OFFERS[3]?.emptyTrucks?.length}
          active={active}
          inActive={inActive}
          filter={filter}
          setFilter={setFilter}
          type="Empty truck"
          to="/dashboard/trucks/create"
          isExpired={props.isExpired}
          setExpiredModal={props.setExpiredModal}
        />
      )}
      {emptyTrucks?.length > 0 ? (
        <EmptyTrucksTable
          emptyTrucks={emptyTrucks}
          handleStatusChange={props.handleStatusChange}
          handleOfferRemove={props.handleOfferRemove}
        />
      ) : (
        <Loader />
      )}
    </ScreenBlock>
  );
};

export default EmptyTrucks;
