import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  removeCompanyUser,
  editCompanyUserPrivilege,
  inviteUser,
} from "../../../store/actions/dashboard/company";

import iUsers from "../../../assets/icons/users.svg";
import welcomeUsers from "../../../assets/welcome/users.svg";
import uuid from "uuid";
import Table from "../UI/Table";
import Loader from "../UI/Loader";
import Form from "../UI/Form";
import FormBlock from "../UI/FormBlock";
import Input from "../UI/Input";
import CreateButton from "../UI/CreateButton";
import { ADD_COMPANY_USER } from "../../../store/actionTypes";
import UserNotice from "../../UserNotice/UserNotice";
import LeftDrawer from "../Drawers/LeftDrawer";
import Screen from "../Screen/Screen";
import ScreenBlock from "../Screen/ScreenBlock";
import Navbar from "../Navbar";
import { isBrowser } from "react-device-detect";
import Minimessages from "../Messages/Minimessages";
import DashboardFooter from "../DashboardFooter";

const Users = (props) => {
  const [componentState, setComponentState] = useState({
    loadingSubmission: false,
    saved: false,
    showUserForm: false,
  });
  const [inputs, setInputs] = useState({
    userRole: "Employee",
  });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const { COMPANY } = props;

  // Handle input changes for inviting a user
  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  // Handle form submission for inviting a user
  const handleInviteUser = (e) => {
    e.preventDefault();
    setErrors({});
    setComponentState((prevState) => ({
      ...prevState,
      loadingSubmission: true,
      saved: false,
    }));
    dispatch(inviteUser(inputs))
      .then((res) => {
        dispatch({
          type: ADD_COMPANY_USER,
          payload: res.data,
        });
        setInputs({
          userRole: inputs.userRole,
        });
        setComponentState((prevState) => ({
          ...prevState,
          loadingSubmission: false,
          saved: true,
        }));
        setTimeout(() => {
          setComponentState((prevState) => ({ ...prevState, saved: false }));
        }, 2000);
      })
      .catch((err) => {
        setComponentState((prevState) => ({
          ...prevState,
          loadingSubmission: false,
          saved: false,
        }));
        const errors = err.response.data.errors;
        setErrors(errors);
      });
  };

  // Handle submission for removing a user

  const userRemoveHandler = (id) => {
    dispatch(removeCompanyUser(id));
  };

  // Handle input changes from editing a user

  let editInputs = {};
  const editInputChangeHandler = (e) => {
    editInputs = { ...editInputs, [e.target.name]: e.target.value };
  };

  // Handle submission for editing a user

  const editUserHandler = (e, id) => {
    e.preventDefault();
    if (Object.keys(editInputs)?.length >= 1) {
      dispatch(editCompanyUserPrivilege(id, editInputs.userRole));
    }
  };

  return (
    <Screen>
      <Navbar />
      <ScreenBlock title="Users" img={iUsers}>
        {/* Create table of users when there are users present */}
        {COMPANY?.users?.length > 0 ? (
          <Table
            head={[
              {
                key: uuid(),
                items: [
                  { name: "Name" },
                  { name: "Email" },
                  { name: "Role" },
                  { name: "Status" },
                  { name: "Options" },
                ],
              },
            ]}
            content={[
              {
                key: uuid(),
                items: COMPANY.users.map((user) => {
                  return {
                    key: user.id,
                    id: user.id,
                    item: [
                      {
                        mobileTitle: "Name",
                        name: `${user.firstName} ${user.lastName}`,
                      },
                      {
                        mobileTitle: "Email",
                        name: user.email,
                      },
                      {
                        mobileTitle: "Role",
                        name: user.privileges,
                        isEditable: true,
                        inputs: [
                          {
                            InputType: "radio",
                            type: "radio",
                            htmlFor: "Employee",
                            name: "userRole",
                            value: "Employee",
                            defaultChecked:
                              user.privileges === "Employee" ? true : false,
                            onChange: (e) => editInputChangeHandler(e),
                          },
                          {
                            InputType: "radio",
                            type: "radio",
                            htmlFor: "Admin",
                            name: "userRole",
                            value: "Admin",
                            defaultChecked:
                              user.privileges === "Admin" ? true : false,
                            onChange: (e) => editInputChangeHandler(e),
                          },
                        ],
                      },
                      {
                        mobileTitle: "Status",
                        togglerStatus: user.active ? "Active" : "Inactive",
                      },
                      {
                        options: [
                          {
                            key: uuid(),
                            items: [
                              {
                                name: "Edit",
                                editable: true,
                                onEdit: (e) => editUserHandler(e, user._id),
                              },
                              {
                                name: "Remove",
                                confirmationModal: true,
                                onConfirm: () => userRemoveHandler(user._id),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  };
                }),
              },
            ]}
          />
        ) : // !COMPANY?.users && <Loader />
        null}

        {/* Add create new user button when there already users present */}
        {COMPANY?.users?.length >= 1 && !componentState.showUserForm && (
          <CreateButton
            isButton
            title="Create new user"
            onClick={() =>
              setComponentState((prevState) => ({
                ...prevState,
                showUserForm: true,
              }))
            }
          />
        )}
        {/* Create new user when has clicked a button */}
        {componentState.showUserForm ? (
          <Form onSubmit={(e) => handleInviteUser(e)}>
            <FormBlock title="Create new user">
              <Input
                InputType="input"
                type="text"
                label="First name"
                placeholder="First name"
                name="firstName"
                onChange={handleInputChange}
                value={inputs.firstName || ""}
                required={true}
                className={errors.firstName ? "invalid" : ""}
                error={errors.firstName ? errors.firstName.msg : null}
              />
              <Input
                InputType="input"
                type="text"
                label="Last name"
                placeholder="Last name"
                name="lastName"
                onChange={handleInputChange}
                value={inputs.lastName || ""}
                required={true}
                className={errors.lastName ? "invalid" : ""}
                error={errors.lastName ? errors.lastName.msg : null}
              />
              <Input
                InputType="input"
                type="email"
                label="Email"
                placeholder="Email address"
                name="email"
                onChange={handleInputChange}
                value={inputs.email || ""}
                required={true}
                className={errors.email ? "invalid" : ""}
                error={errors.email ? errors.email.msg : null}
              />
              <div className="inline-input">
                <h5>Role</h5>
                <div className="inline-input">
                  <Input
                    InputType="radio"
                    type="radio"
                    htmlFor="Employee"
                    name="userRole"
                    value="Employee"
                    defaultChecked={true}
                    onChange={handleInputChange}
                  />
                  <Input
                    htmlFor="Admin"
                    InputType="radio"
                    type="radio"
                    name="userRole"
                    value="Admin"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="submit">
                {!componentState.loadingSubmission ? (
                  <input
                    type="submit"
                    value={componentState.saved ? "User added" : "Add user"}
                    disabled={componentState.saved ? true : false}
                    className={componentState.saved ? "success" : ""}
                  />
                ) : (
                  <Loader />
                )}
              </div>
            </FormBlock>
          </Form>
        ) : null}

        {/* Display Welcome mat when no tenders and roundtrips */}
        {COMPANY?.users?.length <= 0 && !componentState.showUserForm && (
          <UserNotice
            image={welcomeUsers}
            title="Create your own team on Transconnector"
            buttons={[
              {
                name: "Add user",
                isButton: true,
                onClick: () =>
                  setComponentState((prevState) => ({
                    ...prevState,
                    showUserForm: true,
                  })),
              },
            ]}
          />
        )}
      </ScreenBlock>
      {isBrowser && <Minimessages />}
      <LeftDrawer />
      <DashboardFooter />
    </Screen>
  );
};

export default Users;
