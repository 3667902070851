import React from "react";
import { FooterWrapper } from "./Styles";

const Footer = (props) => {
  return (
    <FooterWrapper fixed={props.fixed} centered={props.centered}>
      {props.children}
    </FooterWrapper>
  );
};

export default Footer;
