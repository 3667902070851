import React, { useState, useEffect } from "react";

import styled from "styled-components";

import uuid from "uuid";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/Dashboard/Subscription/Subscription.css";

import Table from "../UI/Table";
import {
  getCompanyVerificationDocuments,
  uploadCompanyVerificationDocuments,
} from "../../../store/actions/dashboard/verification";
import ScreenBlock from "../Screen/ScreenBlock";
const FileDownload = require("js-file-download");

// styled components
const FileInputWrapper = styled.div`
  display: flex;
  gap: 1rem;
  input[type="file"] {
    display: none;
  }
  label {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: #f5f5f5;
    border-radius: 0.5rem;
    cursor: pointer;
    &:hover {
      background-color: #e5e5e5;
    }
  }
  button {
    color: #fff;
    background: #00b27e;
    border: none;
    border-radius: 4px;
    padding: 0.5rem;
    transition: 0.25s all ease;
    &:hover {
      transition: 0.25s all ease;
      background: #07ce94;
      border-color: #07ce94;
    }
`;

const VerificationDocuments = ({ COMPANY }) => {
  const VERIFICATION_DOCUMENTS = useSelector(
    (state) => state.dashboard.verification?.documents
  );
  const dispatch = useDispatch();

  const [files, setFiles] = useState({
    EU_license: null,
    CMR_insurance: null,
    company_register_card: null,
    VAT_register: null,
  });
  const [fileNames, setFileNames] = useState({
    EU_license: VERIFICATION_DOCUMENTS?.filter(
      (document) => document.documentType === "EU_license"
    ).pop()?.fileName,
    CMR_insurance: VERIFICATION_DOCUMENTS?.filter(
      (document) => document.documentType === "CMR_insurance"
    ).pop()?.fileName,
    company_register_card: VERIFICATION_DOCUMENTS?.filter(
      (document) => document.documentType === "company_register_card"
    ).pop()?.fileName,
    VAT_register: VERIFICATION_DOCUMENTS?.filter(
      (document) => document.documentType === "VAT_register"
    ).pop()?.fileName,
  });
  const [fileSelected, setFileSelected] = useState({
    EU_license: false,
    CMR_insurance: false,
    company_register_card: false,
    VAT_register: false,
  });

  const requiredDocuments = (() => {
    switch (COMPANY?.companyType) {
      case "Haulier":
        return [
          "EU_license",
          "CMR_insurance",
          "company_register_card",
          "VAT_register",
        ];
      case "Forwarder":
        return ["CMR_insurance", "VAT_register"];
      case "Producer":
        return ["company_register_card", "VAT_register"];
      default:
        return [];
    }
  })();

  const documentTypes = {
    EU_license: "EU license",
    CMR_insurance: "CMR insurance",
    company_register_card: "Company register card",
    VAT_register: "VAT register",
  };

  const status = {
    in_review: "In review",
    verified: "Verified",
    rejected: "Rejected",
  };

  const documentItem = (documentType) => {
    const document = VERIFICATION_DOCUMENTS?.filter(
      (document) => document.documentType === documentType
    ).pop();
    return {
      key: uuid(),
      id: uuid(),
      item: [
        {
          mobileTitle: "Document",
          name: documentTypes[documentType],
        },
        {
          mobileTitle: "Status",
          status:
            status[
              document?.status
            ] ?? "Not uploaded",
        },
        {
          mobileTitle: "Filename",
          name: fileNames[documentType]
        },
        {
          mobileTitle: "Upload",
          component: () => (
            <div className="subscription__body__content__table__upload">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const formData = new FormData();
                  formData.append("file", files[documentType]);
                  dispatch(
                    uploadCompanyVerificationDocuments(formData, documentType, fileNames[documentType])
                    );
                    // disable upload button
                    setFileSelected({
                      ...fileSelected,
                      [documentType]: false,
                    });
                    // unset fileNames
                    setFileNames({
                      ...fileNames,
                      [documentType]: "",
                    });
                    // unset files
                    setFiles({
                      ...files,
                      [documentType]: null,
                    });
                }}
              >
                <FileInputWrapper>
                  <input
                    type="file"
                    name="file"
                    id={`file-${documentType}`}
                    className="inputfile"
                    onChange={(e) => {
                      const fileName = e.target.files[0].name;
                      setFileNames({
                        ...fileNames,
                        [documentType]: fileName,
                      });
                      setFileSelected({
                        ...fileSelected,
                        [documentType]: true,
                      });
                      setFiles({
                        ...files,
                        [documentType]: e.target.files[0],
                      });
                    }}
                  />
                  <label htmlFor={`file-${documentType}`}>
                    <span>Choose a file</span>
                  </label>
                  {fileSelected[documentType] && (
                    <button type="submit" className="button button--primary">
                      Upload
                    </button>
                  )}
                </FileInputWrapper>
              </form>
            </div>
          ),
        },
      ],
    };
  };

  // Get current verification documents
  useEffect(() => {
      dispatch(getCompanyVerificationDocuments());
  }, [dispatch]);

  // set fileNames after fetching documents
  useEffect(() => {
    setFileNames({
      EU_license: VERIFICATION_DOCUMENTS?.filter(
        (document) => document.documentType === "EU_license"
      ).pop()?.fileName,
      CMR_insurance: VERIFICATION_DOCUMENTS?.filter(
        (document) => document.documentType === "CMR_insurance"
      ).pop()?.fileName,
      company_register_card: VERIFICATION_DOCUMENTS?.filter(
        (document) => document.documentType === "company_register_card"
      ).pop()?.fileName,
      VAT_register: VERIFICATION_DOCUMENTS?.filter(
        (document) => document.documentType === "VAT_register"
      ).pop()?.fileName,
    });
  }, [VERIFICATION_DOCUMENTS]);

  return (
    <>
        <ScreenBlock>
          <div className="subscription">
            <div className="subscription__header">
              <h2>Verification Documents</h2>
            </div>
            <div className="subscription__body">
              <div className="subscription__body__content">
                <div className="subscription__body__content__table">
                  <Table
                    head={[
                      {
                        key: uuid(),
                        items: [
                          { name: "Document" },
                          { name: "Status" },
                          { name: "Filename" },
                          { name: "Upload" },
                        ],
                      },
                    ]}
                    content={[
                      {
                        key: uuid(),
                        items: requiredDocuments.map((documentType) =>
                          documentItem(documentType)
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </ScreenBlock>
    </>
  );
};

export default VerificationDocuments;
