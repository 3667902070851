import React, {useMemo} from "react";
import Input from "../../Register/RegisterInput";
import {useField} from "formik";

const FormikInput = ({
                      name,
                      placeholder,
                      label,
                      type = 'text',
                     }) => {
 const [field, meta] = useField(name);
 const isError = useMemo(() => !!(meta.touched && meta.error), [meta.error, meta.touched]);
 return (
   <Input
     classes={`flex-fill floating-label ${isError && "invalid"}`}
     label={label}
     placeholder={placeholder}
     name={field.name}
     type={type}
     value={field.value || ""}
     onChange={field.onChange}
     onBlur={field.onBlur}
     error={isError && meta.error}
   />
 );
}

export default FormikInput;
