import React from "react";
import logo from "../../images/logo-light.svg";
import Container from "../UI/Container";

import {
  Wrapper,
  Logo,
  Content,
  Inner,
  InlineItems,
  Item,
  Social,
  Info
} from "./Styles";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Inner>
          <Logo>
            <Link to="/">
              <img src={logo} alt="TransConnector" />
            </Link>
          </Logo>
          <Content>
            <InlineItems>
              <Item>
                <ul>
                  <li>
                    <Link to="/spotload">Load Exchange</Link>
                  </li>
                  <li>
                    <Link to="/roundtrip">Roundtrip</Link>
                  </li>
                  <li>
                    <Link to="/tender">Tender</Link>
                  </li>
                  <li>
                    <Link to="/trucks">Empty Trucks</Link>
                  </li>
                </ul>
              </Item>
              <Item>
                <ul>
                  <li>
                    <Link to="/instant-payment">Instant Payment</Link>
                  </li>
                  <li>
                    <Link to="/cmr">CMR Insurance</Link>
                  </li>
                  <li>
                    <Link to="/ferry-bookings">Ferry Booking</Link>
                  </li>
                  <li>
                    <Link to="/vat-refund">VAT Refund</Link>
                  </li>
                  <li>
                    <Link to="/eurowag-fuel">Eurowag Fuel</Link>
                  </li>
                </ul>
              </Item>
              <Item>
                <ul>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/pricing">Pricing</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/terms">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                  </ul>
              </Item>
            </InlineItems>
          </Content>
        </Inner>
        <Info>
            <Item>
                  <a href="mailto:info@transconnector.eu">
                    info@transconnector.eu
                  </a>
                    <h4>
                      Our customer service team is 24/7{" "}
                      <a href="mailto:support@transconnector.eu">
                        support@transconnector.eu
                      </a>
                    </h4>
            </Item>
              <Social>
                <a
                  href="https://www.linkedin.com/company/transconnector/"
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                  {linkedin}
                </a>
              </Social>
              </Info>
      </Container>
    </Wrapper>
  );
};

const linkedin = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31.5"
    height="31.5"
    viewBox="0 0 31.5 31.5"
  >
    <path
      d="M29.25,2.25H2.243A2.26,2.26,0,0,0,0,4.521V31.479A2.26,2.26,0,0,0,2.243,33.75H29.25a2.266,2.266,0,0,0,2.25-2.271V4.521A2.266,2.266,0,0,0,29.25,2.25Zm-19.73,27H4.852V14.217H9.527V29.25ZM7.186,12.164A2.707,2.707,0,1,1,9.893,9.457a2.708,2.708,0,0,1-2.707,2.707ZM27.021,29.25H22.352V21.938c0-1.744-.035-3.987-2.426-3.987-2.433,0-2.805,1.9-2.805,3.86V29.25H12.452V14.217h4.479V16.27h.063a4.917,4.917,0,0,1,4.423-2.426c4.725,0,5.6,3.115,5.6,7.165Z"
      transform="translate(0 -2.25)"
      fill="#2867B2"
    />
  </svg>
);

export default Footer;
