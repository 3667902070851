import React from "react";
import Modal from "../Modal";
import Header from "../Header";
import Footer from "../Footer";
import Buttons from "../../Buttons/Buttons";
import Button from "../../Buttons/Button";
import { Centered, ModalInnerWrapper } from "../Styles";
import welcomeWarning from "../../../assets/welcome/warning.svg";

const ConfirmationModal = (props) => {
  return (
    <Modal onClose={props.onClose} medium>
      <Header onClose={props.onClose} title={props.title} />
      <ModalInnerWrapper>
        <Centered>
          <img src={welcomeWarning} alt="" />
          <h3>Are you sure you want to perform this action?</h3>
          <h4>This action is permanent.</h4>
        </Centered>
        <Footer centered>
          <Buttons>
            <Button
              spacing="1"
              type="secondary"
              title="Cancel"
              onClick={props.onClose}
            />
            <Button
              spacing="1"
              type="danger"
              title="Confirm"
              onClick={props.onConfirm}
            />
          </Buttons>
        </Footer>
      </ModalInnerWrapper>
    </Modal>
  );
};

export default ConfirmationModal;
