import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { createSpotloadsFromCSV } from "../../../store/actions/app/spotload";
import styled, { css } from "styled-components";
import Modal from "../../Modals/Modal";
import { API_URL } from "../../../constants";
import Cookies from "universal-cookie";
import Kebab from "../../Dashboard/UI/Kebab";
// import SpotloadCreator from "../SpotloadCreator";
import SpotloadCreator from "../SpotloadCreator";
import customMoment from "moment";
import moment from "moment";
import {spinner} from "../../Dashboard/UI/Spinner";

const PreviewWrapper = styled.div`
  margin: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
`;

const TableHeader = styled.th`
  // border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  font-size: 0.75rem;
  color: #686a7b;
  font-weight: 400;
  border: none;
`;

const TableRow = styled.tr`
  border: ${(props) => (props.noBorder ? "none" : "1px solid #ddd")};
  border-inline: none;
  // &:nth-child(even) {
  //   background-color: #f2f2f2;
  // }
`;

const TableCell = styled.td`
  padding: 8px;
  white-space: pre-line;
`;

const Heading = styled.h2`
  font-size: 1.25rem;
  color: #262a68;
  line-height: 1.6;
  margin: 0 0 1rem;
  font-weight: 600;
`;

const Address = styled.div`
  font-weight: 600;
`;

const Date = styled.div`
  font-size: 0.75rem;
  color: #686a7b;
`;

const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
`;

const PublishButton = styled.button`
  color: #fff;
  background: #00b27e;
  border: none;
  border-radius: 4px;
  padding: 0.5rem;
  transition: 0.25s all ease;
  &:hover {
    transition: 0.25s all ease;
    background: #07ce94;
    border-color: #07ce94;
  }
`;

const BackButton = styled.button`
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  transition: 0.25s all ease;
  &:hover {
    transition: 0.25s all ease;
    background: #ccc;
    border-color: #ccc;
  }
`;

const KebabContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const KebabButton = styled.button`
  background: unset;
  border: none;
  padding: 0;
  cursor: pointer;
`;

// kebab menu pops up below the kebab button
const KebabMenu = styled.div`
  position: absolute;
  top: ${(props) => props.coords.top}px;
  left: ${(props) => props.coords.left}px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  z-index: 1;
  button {
    background: none;
    border: none;
    padding: 0.5rem;
    width: 100%;
    text-align: left;
    cursor: pointer;
    transition: 0.25s all ease;
    &:hover {
      transition: 0.25s all ease;
      background: #f4f4f4;
    }
  }
`;

const KebabEdit = styled.div``;

const ErrorWrapper = styled.div`
  ${(props) =>
    props.error &&
    css`
      border: 1px solid #ff0000;
      border-radius: 4px;
      padding: 0.25rem;
    `}
`;

const ErrorLabel = styled.div`
  color: #ff0000;
  font-size: 0.75rem;
  padding: 0.25rem;
  ${(props) => props.customStyle}
`;

const processData = (data) => {
  const customMoment = (date) => {
    if (date) {
      return moment(date, ["YYYY-MM-DD", "DD.MM.YYYY", "MM/DD/YYYY"]).format(
        "DD.MM.YYYY"
      );
    }
    return null;
  };
  return data.map((item) => ({
    Loading: {
      address: item.loading
        ? item.loading
        : `${item.loadingCountry} ${item.loadingCity ?? ""} ${
            item.loadingPostalCode
          }`,
      date: item.loadingDateEnd ? `Date: ${customMoment(item.loadingDateStart)} - ${customMoment(
        item.loadingDateEnd
      )}` : `Date: ${customMoment(item.loadingDateStart)}`,
    },
    Unloading: {
      address: item.unloading
        ? item.unloading
        : `${item.unloadingCountry} ${item.unloadingCity ?? ""} ${
            item.unloadingPostalCode
          }`,
      date: item.unloadingDateEnd ? `Date: ${customMoment(item.unloadingDateStart)} - ${customMoment(
        item.unloadingDateEnd
      )}` : `Date: ${customMoment(item.unloadingDateStart)}`,
    },
    "Truck type": {
      truckType: item.truckType.join(", "),
      typeOfLoad: item.typeOfLoad,
      // weight: `${item.weight}kg`,
      ...(item.weight && { weight: `${item.weight}kg` }),
      // loadingMeters: `LDM: ${item.loadingMeters}m`,
      ...(item.loadingMeters && {
        loadingMeters: `LDM: ${item.loadingMeters}m`,
      }),
    },
    // "Target price": `${item.targetPrice}€`,
    "Target price": item.targetPrice ? `${item.targetPrice}€` : "",
  }));
};

const Preview = ({ csvId, config, onClose, ...props }) => {
  const columns = ["Loading", "Unloading", "Truck type", "Target price"];
  const [data, setData] = useState(
    // give id to each item
    props.data.loads.map((item, index) => ({ ...item, id: index }))
  );
  const [filteredData, setFilteredData] = useState(
    processData(props.data.loads)
  );

  const [errors, setErrors] = useState(props.data.errors);
  const [notifyErrors, setNotifyErrors] = useState(false);

  const [loading, setLoading] = useState(false);

  const getError = (index, item, column) => {
    if (errors) {
      switch (column) {
        case "Loading":
          return errors[`[${index}].loading`]
            ? errors[`[${index}].loading`].msg
            : null;
        case "Unloading":
          return errors[`[${index}].unloading`]
            ? errors[`[${index}].unloading`].msg
            : null;
        case "Truck type":
          return errors[`[${index}].truckType[0]`]
            ? errors[`[${index}].truckType[0]`].msg
            : errors[`[${index}].truckType[1]`]
            ? errors[`[${index}].truckType[1]`].msg
            : null;
        case "Target price":
          return errors[`[${index}].targetPrice`]
            ? errors[`[${index}].targetPrice`].msg
            : null;
        default:
          return null;
      }
    }
  };

  useEffect(() => {
    const pd = processData(data);
    setFilteredData(pd);
  }, [data]);

  const dispatch = useDispatch();

  const [kebabOpen, setKebabOpen] = useState(filteredData.map(() => false));
  const [kebabEditOpen, setKebabEditOpen] = useState(
    filteredData.map(() => false)
  );

  const toggleKebab = (e, index) => {
    if (e) {
      setKebabCoords(getKebabCoords(e.target));
    }
    setKebabOpen(kebabOpen.map((open, i) => (i === index ? !open : open)));
  };

  const toggleKebabEdit = (index) => {
    setKebabEditOpen(
      kebabEditOpen.map((open, i) => (i === index ? !open : open))
    );
  };

  const kebabRef = useRef(null);
  const [kebabCoords, setKebabCoords] = useState({ top: 0, left: 0 });

  const getKebabCoords = (target) => {
    return {
      top: target.offsetTop + target.offsetHeight,
      left: target.offsetLeft,
    };
  };

  // const handlePublish = () => {
  //   // const payload = { id: csvId };
  //   const payload = data;
  //   console.log(payload);
  //   const cookies = new Cookies();

  //   fetch(`${API_URL}/spotloads/upload/publish`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${cookies.get("tc_token")}`,
  //     },
  //     body: JSON.stringify(payload),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data);
  //       // onClose();
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // };

  const handlePublish = async () => {
    if (errors) {
      if (Object.keys(errors).length > 0) {
        setNotifyErrors(true);
        return;
      }
    }

    const payload = {
      id: csvId,
      loads: data,
      randomiseAddress: config.randomiseAddress,
    };

    const cookies = new Cookies();
    
    // set loading state
    setLoading(true);

    try {
      await dispatch(createSpotloadsFromCSV(payload));
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    // reset state
    setLoading(false);
    setErrors({});
    setNotifyErrors(false);
    setData([]);
    setFilteredData([]);

    onClose();
  };

  const handleRowEdit = (index, edit) => {
    const newData = [...data];
    newData[index] = {
      loading: edit.loading0.value,
      loadingDateStart: edit.loadingDate.startDate,
      loadingDateEnd: edit.loadingDate.endDate,
      unloading: edit.unloading0.value,
      unloadingDateStart: edit.unloadingDate.startDate,
      unloadingDateEnd: edit.unloadingDate.endDate,
      truckType: edit.truckType.map((item) => item.label),
      typeOfLoad: edit.typeOfLoad,
      weight: edit.freight.weight,
      loadingMeters: edit.freight.loadingMeters,
      targetPrice: edit.targetPrice,
      typeOfCargo: edit.cargoType?.label,
      length: edit.freight.length,
      width: edit.freight.width,
      height: edit.freight.height,
      ADR: edit.specialRequests.ADR,
      euroPalletExchange: edit.specialRequests.euroPalletExchange,
      TIRCable: edit.specialRequests.TIRCable,
      liveTracking: edit.specialRequests.liveTracking,
      nonStackable: edit.specialRequests.nonStackable,
      wasteLoad: edit.specialRequests.wasteLoad,
      extraInfo: edit.extraInfo,
    };
    setData(newData);

    // reset errors for edited row
    const newErrors = { ...errors };
    delete newErrors[`[${index}].loading`];
    delete newErrors[`[${index}].unloading`];
    delete newErrors[`[${index}].loadingDateStart`];
    delete newErrors[`[${index}].loadingDateEnd`];
    delete newErrors[`[${index}].unloadingDateStart`];
    delete newErrors[`[${index}].unloadingDateEnd`];
    delete newErrors[`[${index}].typeOfLoad`];
    delete newErrors[`[${index}].truckType[0]`];
    delete newErrors[`[${index}].truckType[1]`];
    delete newErrors[`[${index}].targetPrice`];
    setErrors(newErrors);

    // reset notify errors
    setNotifyErrors(false);
  };

  const handleDeleteRow = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  
    // reset errors for deleted row and shift errors for subsequent rows
    const newErrors = { ...errors };
    Object.keys(newErrors).forEach((key) => {
      const match = key.match(/\[(\d+)\]/);
      if (match) {
        const errorIndex = parseInt(match[1]);
        if (errorIndex === index) {
          // delete error for deleted row
          delete newErrors[key];
        } else if (errorIndex > index) {
          // shift error index for subsequent rows
          const shiftedKey = key.replace(`[${errorIndex}]`, `[${errorIndex - 1}]`);
          newErrors[shiftedKey] = newErrors[key];
          delete newErrors[key];
        }
      }
    });
    setErrors(newErrors);
  
    // close kebab menu
    setKebabOpen(kebabOpen.map((open, i) => (i === index ? false : open)));
  };

  return (
    <Modal>
      <PreviewWrapper>
        <Heading>Preview</Heading>
        <Table>
          <thead>
            <TableRow noBorder>
              {columns.map((column) => (
                <TableHeader key={column}>{column}</TableHeader>
              ))}
            </TableRow>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <TableRow key={index}>
                {columns.map((column) => (
                  <TableCell key={column}>
                    <ErrorWrapper error={getError(index, item, column)}>
                      {column === "Loading" || column === "Unloading" ? (
                        <>
                          <Address>{item[column].address}</Address>
                          <Date>{item[column].date}</Date>
                        </>
                      ) : column === "Truck type" ? (
                        <>
                          <div>{item[column].truckType}</div>
                          <div>
                            {item[column].typeOfLoad} ·{" "}
                            {item[column].weight ?? ""} ·{" "}
                            {item[column].loadingMeters ?? ""}
                          </div>
                        </>
                      ) : (
                        <div>{item[column]}</div>
                      )}
                    </ErrorWrapper>
                    {getError(index, item, column) && (
                      <ErrorLabel>{getError(index, item, column)}</ErrorLabel>
                    )}
                  </TableCell>
                ))}
                <td>
                <KebabContainer ref={kebabRef}>
                  <KebabButton
                    onClick={(e) => toggleKebab(e, index)}
                    ref={kebabRef}
                  >
                    <Kebab />
                  </KebabButton>
                  {kebabOpen[index] && (
                    <KebabMenu coords={kebabCoords}>
                      <button onClick={(e) => toggleKebabEdit(index)}>
                        Edit
                      </button>
                      <button onClick={() => handleDeleteRow(index)}>
                        Delete
                      </button>
                    </KebabMenu>
                  )}
                </KebabContainer>
                {kebabEditOpen[index] && (
                  <KebabEdit>
                    <Modal>
                      <SpotloadCreator
                        inputs={data[index]}
                        showAllBlocks
                        fromUploader
                        editMode
                        onClose={(e) => (
                          toggleKebabEdit(index), toggleKebab(e, index)
                        )}
                        onEdit={(edit) => handleRowEdit(index, edit)}
                      />
                    </Modal>
                  </KebabEdit>
                )}
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
        <ButtonContainer>
          <BackButton onClick={props.goBack}>Back</BackButton>
          <PublishButton onClick={handlePublish}>
            {loading ? spinner : "Publish"}
          </PublishButton>
        </ButtonContainer>
        {notifyErrors && (
          <ErrorLabel customStyle={"text-align: right;"}>
            Please fix errors before publishing the spotloads
          </ErrorLabel>
        )}
      </PreviewWrapper>
    </Modal>
  );
};

export default Preview;
