import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import Table from '../../NewTable/Table';
import TableHeader from '../../NewTable/TableHeader';
import { TableHeaderItem } from '../../NewTable/Styles';
import TableContent from '../../NewTable/TableContent';
import TableRow from '../../NewTable/TableRow';
import TableRowItem from '../../NewTable/TableRowItem';
import Status from '../../NewTable/Status';
import Options from '../../NewTable/Options';
import ConfirmationModal from '../../Modals/ConfirmationModal/ConfirmationModal';
import ConfirmationSuccess from '../../Modals/ConfirmationModal/ConfirmationSuccess';
import { removeOffer } from '../../../store/actions/dashboard/offers';
import { REMOVE_OFFER } from '../../../store/actionTypes';
import formatLocationName from '../../../helpers/LocationName';

const TendersTable = (props) => {
  const [optionsId, setOptionsId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [confirmationSuccess, setConfirmationSuccess] = useState(false);
  const dispatch = useDispatch();

  const handleConfirmationModal = (id, title) => {
    setConfirmationModal({ id, title });
  };

  // Handle removing a tender
  const removeTender = (id) => {
    dispatch(removeOffer(id, 'Tender')).then((res) => {
      dispatch({
        type: REMOVE_OFFER,
        payload: res.data,
        id: id,
        array: 'tenders',
      });
      setConfirmationModal(null);
      setConfirmationSuccess(true);
    });
  };

  const handleToggleOptions = (id) => {
    if (optionsId === id) {
      setOptionsId(null);
    } else {
      setOptionsId(id);
    }
  };

  const handleRedirect = (url, state) => {
    props.history.push(url, state);
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem flex="0.5">Loading</TableHeaderItem>
          <TableHeaderItem flex="0.5">Unloading</TableHeaderItem>
          <TableHeaderItem flex="0.2">Offers</TableHeaderItem>
          <TableHeaderItem flex="0.4">Truck type</TableHeaderItem>
          <TableHeaderItem flex="0.2">Deadline</TableHeaderItem>
          <TableHeaderItem flex="0.2">Status</TableHeaderItem>
          <TableHeaderItem flex="0.1"></TableHeaderItem>
        </TableHeader>
        <TableContent>
          {props.tenders.map((tender) => {
            const truckTypes = tender?.truckType
              ?.map((element) => {
                if (element.value === 'Refrigerator' && tender.temperature) {
                  return `${element.value} (${tender.temperature}°C)`;
                }
                return element.value;
              })
              .join(', ')
              .replace(/_/g, ' ');
            return (
              <TableRow
                key={tender._id}
                onClick={() =>
                  handleRedirect('/tender', {
                    sheet: tender._id,
                  })
                }
              >
                <TableRowItem
                  rowName="Loading"
                  image={{
                    url: tender.loading[0].flagUrl,
                    alt: tender.loading[0].country,
                  }}
                  title={{
                    name: tender.loading
                      .map(
                        (l) =>
                          l.locationName && formatLocationName(l.locationName)
                      )
                      .join('; '),
                  }}
                  flex="0.5"
                />
                <TableRowItem
                  rowName="Unloading"
                  image={{
                    url: tender.unloading[0].flagUrl,
                    alt: tender.unloading[0].country,
                  }}
                  title={{
                    name: tender.unloading
                      .map(
                        (l) =>
                          l.locationName && formatLocationName(l.locationName)
                      )
                      .join('; '),
                  }}
                  flex="0.5"
                />
                <TableRowItem
                  rowName="Offers"
                  title={{
                    name: tender?.count?.toString(),
                  }}
                  flex="0.2"
                />
                <TableRowItem
                  rowName="Truck type"
                  title={{
                    name: truckTypes,
                  }}
                  flex="0.4"
                >
                  <span>
                    {`${tender.Freight.cargoType}`}{' '}
                    {tender.Freight.dimensions.weight &&
                      ` · ${tender.Freight.dimensions.weight}kg`}{' '}
                    {tender.Freight.dimensions.loadingMeters &&
                      ` · LDM: ${tender.Freight.dimensions.loadingMeters}m`}
                  </span>
                </TableRowItem>
                <TableRowItem
                  rowName="Deadline"
                  title={{
                    name: moment(tender.offerIsActive).format('DD.MM.YY'),
                  }}
                  flex="0.2"
                />
                <TableRowItem rowName="Status" flex="0.2">
                  <Status
                    active={tender.status}
                    status={tender.status ? 'Active' : 'Inactive'}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleStatusChange(
                        tender._id,
                        !tender.status,
                        'Tender',
                        'tenders'
                      );
                    }}
                  />
                </TableRowItem>
                <TableRowItem flex="0.1" overflow>
                  <Options
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleOptions(tender._id);
                    }}
                    open={optionsId === tender._id}
                    onBlur={() => setOptionsId(null)}
                  >
                    <ul>
                      <li
                        onClick={() =>
                          handleRedirect(`/dashboard/tender/edit/${tender._id}`)
                        }
                      >
                        Edit
                      </li>
                      <li
                        onClick={() =>
                          handleRedirect(
                            `/dashboard/tender/duplicate/${tender._id}`
                          )
                        }
                      >
                        Duplicate
                      </li>
                      <li
                        onClick={() =>
                          handleConfirmationModal(
                            tender._id,
                            `Delete: ${tender.name}`
                          )
                        }
                      >
                        Delete
                      </li>
                    </ul>
                  </Options>
                </TableRowItem>
              </TableRow>
            );
          })}
        </TableContent>
      </Table>
      {confirmationModal && (
        <ConfirmationModal
          onClose={() => setConfirmationModal(null)}
          title={confirmationModal.title}
          onConfirm={() => removeTender(confirmationModal.id)}
        />
      )}
      {confirmationSuccess && (
        <ConfirmationSuccess onClose={() => setConfirmationSuccess(false)} />
      )}
    </>
  );
};

export default withRouter(TendersTable);
