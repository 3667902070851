import React from "react";
import { CardBlockWrapper, CardBlockTitle } from "./Styles";

const CardBlock = (props) => {
  return (
    <CardBlockWrapper>
      {props.title && (
        <CardBlockTitle>
          <h2>{props.title}</h2>
        </CardBlockTitle>
      )}
      {props.children}
    </CardBlockWrapper>
  );
};

export default CardBlock;
