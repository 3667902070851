import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { NavLink, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../../images/logo-white.svg";
import { isMobile } from "react-device-detect";
import { ReactSVG } from "react-svg";
import "../../../styles/Dashboard/Drawers.css";
import ReactTooltip from "react-tooltip";

import {
  SET_USER,
  SET_COMPANY,
  SET_USER_SUBSCRIPTION,
  SET_DRAWER,
  SET_SPOTLOADS,
  SET_TENDERS,
  SET_ROUNDTRIPS,
  SET_EMPTYTRUCKS,
  SET_OFFERS,
  SET_QUOTATIONS,
} from "../../../store/actionTypes";

import iTender from "../../../assets/icons/drawer-tender.svg";
import iMobileTender from "../../../assets/icons/mobile-drawer-tender.svg";
import iMobileRoundtrip from "../../../assets/icons/mobile-drawer-roundtrip.svg";
import iMobileTrucks from "../../../assets/icons/left-drawer-trucks-orange.svg";
import iMessages from "../../../assets/icons/drawer-messages.svg";
import iQuotations from "../../../assets/icons/drawer-quotations.svg";
import iOffers from "../../../assets/icons/drawer-offers.svg";
import iFavourites from "../../../assets/icons/drawer-favourites.svg";
import iSubscription from "../../../assets/icons/drawer-subscription.svg";
import iUsers from "../../../assets/icons/drawer-users.svg";
import iAccount from "../../../assets/icons/drawer-account.svg";
import calendarIcon from "../../../assets/icons/calendar.svg";
import pointerIcon from "../../../assets/icons/pointer.svg";
import iEmpty from "../../../assets/icons/icon-empty.svg";
import iLogout from "../../../assets/icons/icon-logout.svg";
import iLeftTender from "../../../assets/icons/left-drawer-tender.svg";
import iLeftRoundtrip from "../../../assets/icons/left-drawer-roundtrip.svg";
import iLeftTruck from "../../../assets/icons/left-drawer-trucks.svg";
import iLeftSpotload from "../../../assets/icons/left-drawer-spotload.svg";

import logoIcon from "../../../assets/icons/logo-icon.svg";

import SubscribeGateway from "../../Modals/AuthGatewayModals/SubscribeGateway";
import io from "socket.io-client";
import { getUnread } from "../../../store/actions/misc";

import { API_URL } from "../../../constants";

const socket = io(`${API_URL}`, {
  transports: ["websocket"],
  secure: true,
});
const LeftDrawer = (props) => {
  // const [openDrawer, setOpenDrawer] = useState(false);
  const openDrawer = useSelector((state) => state.app.misc.drawer);
  const [letters, setLetters] = useState();
  const [subscribeGateway, setSubscribeGateway] = useState(false);
  const USER = useSelector((state) => state.dashboard.user?.data);
  const COMPANY = useSelector((state) => state.dashboard.company?.data);
  const UNREAD = useSelector((state) => state.app.misc?.unread);
  const QUOTES = useSelector((state) => state.app.misc?.quotes);
  //console.log(state.app.misc)
  /*   const USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.activeSubscription
  ); */
  // console.log(useSelector((state) => state.app.misc));
  const dispatch = useDispatch();
  // console.log(openDrawer);
  const setOpenDrawer = (val) => {
    dispatch({
      type: SET_DRAWER,
      payload: val,
    });
  };

  useEffect(() => {
    if (USER?.id) {
      socket.on(USER?.id, (res) => {
        dispatch(getUnread());
      });
    }
  }, [USER, dispatch]);

  useEffect(() => {
    // company letters
    if (COMPANY?.companyName) {
      const companyLetters = COMPANY.companyName.replace(/ /, "");
      let firstLetter = companyLetters.charAt(0);
      let secondLetter = companyLetters.charAt(1);
      setLetters(firstLetter + secondLetter);
    }
  }, [COMPANY]);

  const handleLogOut = () => {
    const cookies = new Cookies();
    cookies.remove("tc_token", { path: "/" });
    dispatch({
      type: SET_USER,
      payload: null,
    });
    dispatch({
      type: SET_COMPANY,
      payload: null,
    });
    dispatch({
      type: SET_USER_SUBSCRIPTION,
      payload: null,
    });
    dispatch({
      type: SET_SPOTLOADS,
      payload: null,
    });
    dispatch({
      type: SET_TENDERS,
      payload: null,
    });
    dispatch({
      type: SET_ROUNDTRIPS,
      payload: null,
    });
    dispatch({
      type: SET_EMPTYTRUCKS,
      payload: null,
    });
    dispatch({
      type: SET_OFFERS,
      payload: null,
    });
    dispatch({
      type: SET_QUOTATIONS,
      payload: null,
    });
    props.history.push("/");
  };

  //Listen to route changes

  return (
    <>
      <div className="Left_Drawer" id={props.small && "left-drawer-small"}>
        <div className="Left_Drawer--inner">
          {isMobile && (
            <div className="Left_Drawer--mobile-nav">
              <ul>
                <li>
                  <NavLink to="/spotload">
                    <ReactSVG src={iTender} />
                    Exchange
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/roundtrip">
                    <ReactSVG src={iMobileRoundtrip} />
                    Roundtrip
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/tender">
                    <ReactSVG src={iMobileTender} />
                    Tender
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/trucks">
                    <ReactSVG src={iMobileTrucks} />
                    Empty trucks
                  </NavLink>
                </li>
                {/* <li onClick={() => setOpenDrawer(!openDrawer)}>
                  <ReactSVG src={iMenu} />
                  Menu
                </li> */}
              </ul>
            </div>
          )}
          {!isMobile && (
            <>
              <div className="Drawer--logo">
                <NavLink to="/dashboard">
                  <img src={logo} alt="Transconnector" />
                  <img src={logoIcon} alt="Transconnector" />
                </NavLink>
              </div>
              <div className="Drawer--company">
                <NavLink to="/dashboard/my-company">
                  <div className="company-letters">{letters}</div>
                  My Company
                </NavLink>
              </div>
            </>
          )}
          <div
            className={`Drawer--items ${isMobile && openDrawer}`}
            onClick={() => setOpenDrawer(!openDrawer)}
          >
            <ul>
              {isMobile && (
                <li>
                  {" "}
                  <NavLink activeClassName="active" to="/dashboard/my-company">
                    <div className="company-letters">{letters}</div>My Company
                  </NavLink>
                </li>
              )}
              {!isMobile && (
                <div
                  style={{
                    marginBottom: "2rem",
                  }}
                >
                  <li data-tip data-for="loadExchange">
                    <NavLink activeClassName="active" to="/spotload">
                      <ReactSVG src={iLeftSpotload} />
                      Load Exchange
                    </NavLink>

                    <ReactTooltip
                      id="loadExchange"
                      place="right"
                      effect="solid"
                    >
                      Load Exchange
                    </ReactTooltip>
                  </li>
                  <li data-tip data-for="roundtrip">
                    <NavLink activeClassName="active" to="/roundtrip">
                      <ReactSVG src={iLeftRoundtrip} />
                      Roundtrip
                    </NavLink>
                    <ReactTooltip id="roundtrip" place="right" effect="solid">
                      Roundtrip
                    </ReactTooltip>
                  </li>
                  <li data-tip data-for="tender">
                    <NavLink activeClassName="active" to="/tender">
                      <ReactSVG src={iLeftTender} />
                      Tender
                    </NavLink>
                    <ReactTooltip id="tender" place="right" effect="solid">
                      Tender
                    </ReactTooltip>
                  </li>
                  <li data-tip data-for="emptyTrucks">
                    <NavLink activeClassName="active" to="/trucks">
                      <ReactSVG src={iLeftTruck} />
                      Empty trucks
                    </NavLink>
                    <ReactTooltip id="emptyTrucks" place="right" effect="solid">
                      Empty trucks
                    </ReactTooltip>
                  </li>
                </div>
              )}
              {/* {USER_PLAN ? ( */}
              <li data-tip data-for="messages">
                <NavLink activeClassName="active" to="/dashboard/messages">
                  <ReactSVG src={iMessages} />
                  {UNREAD > 0 && <span className="unread-count">{UNREAD}</span>}
                  Messages
                </NavLink>
                <ReactTooltip id="messages" place="right" effect="solid">
                  Messages
                </ReactTooltip>
              </li>
              {/* ) : (
                <li onClick={() => setSubscribeGateway(true)}>
                  <a href="#!">
                    <ReactSVG src={iMessages} />
                    {UNREAD > 0 && (
                      <span className="unread-count">{UNREAD}</span>
                    )}
                    Messages
                  </a>
                  <div className="link-tooltip">Messages</div>
                </li>
              )} */}
              <li data-tip data-for="quotations">
                <NavLink activeClassName="active" to="/dashboard/quotations">
                  <ReactSVG src={iQuotations} />
                  {QUOTES > 0 && <span className="unread-count">{QUOTES}</span>}
                  Quotations
                </NavLink>
                <ReactTooltip id="quotations" place="right" effect="solid">
                  Quotations
                </ReactTooltip>
              </li>
              <li data-tip data-for="myOffers">
                <NavLink activeClassName="active" to="/dashboard/my-offers">
                  <ReactSVG src={iOffers} />
                  My Offers
                </NavLink>
                <ReactTooltip id="myOffers" place="right" effect="solid">
                  My Offers
                </ReactTooltip>
              </li>
              <li data-tip data-for="favourites">
                <NavLink activeClassName="active" to="/dashboard/favourites">
                  <ReactSVG src={iFavourites} />
                  Favourites
                </NavLink>
                <ReactTooltip id="favourites" place="right" effect="solid">
                  Favourites
                </ReactTooltip>
              </li>
              {USER && USER.privileges === "Admin" && (
                <li data-tip data-for="subscription">
                  <NavLink
                    activeClassName="active"
                    to="/dashboard/subscription"
                  >
                    <ReactSVG src={iSubscription} />
                    Subscription
                  </NavLink>
                  <ReactTooltip id="subscription" place="right" effect="solid">
                    Subscription
                  </ReactTooltip>
                </li>
              )}
              {USER && USER.privileges === "Admin" && (
                <li data-tip data-for="users">
                  <NavLink activeClassName="active" to="/dashboard/users">
                    <ReactSVG src={iUsers} />
                    Users
                  </NavLink>
                  <ReactTooltip id="users" place="right" effect="solid">
                    Users
                  </ReactTooltip>
                </li>
              )}
              <li data-tip data-for="myAccount">
                <NavLink activeClassName="active" to="/dashboard/my-account">
                  <ReactSVG src={iAccount} />
                  My Account
                </NavLink>
                <ReactTooltip id="myAccount" place="right" effect="solid">
                  My Account
                </ReactTooltip>
              </li>
              {USER && USER.privileges === "Admin" && (
                <li data-tip data-for="ferryBookings">
                  <NavLink
                    activeClassName="active"
                    to="/dashboard/ferry-bookings"
                  >
                    <ReactSVG src={calendarIcon} />
                    Ferry bookings
                  </NavLink>
                  <ReactTooltip id="ferryBookings" place="right" effect="solid">
                    Ferry bookings
                  </ReactTooltip>
                </li>
              )}

              <div
                style={{
                  marginTop: "1.5rem",
                }}
              >
                <li data-tip data-for="logout">
                  <NavLink activeClassName="" to="/" onClick={handleLogOut}>
                    <ReactSVG src={iLogout} />
                    Log Out
                  </NavLink>
                  <ReactTooltip id="logout" place="right" effect="solid">
                    Log Out
                  </ReactTooltip>
                </li>
              </div>
              <div className="only-mobile">
                <hr />
                <li>
                  <NavLink activeClassName="active" to="/spotload">
                    <ReactSVG src={iTender} />
                    Exchange
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/tender">
                    <ReactSVG src={iMobileTender} />
                    Tender
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/roundtrip">
                    <ReactSVG src={iMobileRoundtrip} />
                    Roundtrip
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/trucks">
                    <ReactSVG src={iMobileTrucks} />
                    Empty trucks
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/pricing">
                    <ReactSVG src={iEmpty} />
                    Pricing
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/blog">
                    <ReactSVG src={iEmpty} />
                    Blog
                  </NavLink>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
      {isMobile && (
        <div
          className={`Left_Drawer-close ${isMobile && openDrawer}`}
          onClick={() => setOpenDrawer(!openDrawer)}
        ></div>
      )}
      {subscribeGateway && (
        <SubscribeGateway onClose={() => setSubscribeGateway(false)} />
      )}
    </>
  );
};

export default withRouter(LeftDrawer);
