import styled, { css } from 'styled-components';

export const NoticeWrapper = styled.div`
    background: #EDF6FD;
    border-radius: 10px;
    padding: 20px 30px;
    max-width: 70%;
    margin: auto;
    display: flex;
    align-items: center;
    h3 {
        margin: 0;
        margin-left: 40px;
        color: #262A68;
    }
    .action {
        margin-left: auto;
        width: 140px;
    }
    @media screen and (max-width: 992px) {
        flex-wrap: wrap;
        margin-top: 140px;
        img {
            display: none;
        }
        .action {
            margin: auto;
            margin-top: 10px;
        }
    }
    @media screen and (max-width: 568px) {
        max-width: 95%;
        text-align: center;
        h3 {
            margin-left: 0
        }
    }

`