import React, { useState } from "react";
import { Background, Static } from "./Styles";
import Container from "../../components/UI/Container";
import { sanitize } from "dompurify";
import { FAQList, FAQItem, FAQButton, FAQInfo } from "./Styles";

const FAQ = () => {
  const [dropdownId, setDropDownId] = useState(null);
  return (
    <Background background="#FBFDFF">
      <Static>
        <Container gutter="large">
          <h1>FAQ</h1>
          <FAQList>
            {faqs.map((faq) => {
              const { id, title, content } = faq;
              return (
                <FAQItem key={id} active={dropdownId === id ? 1 : null}>
                  <FAQButton
                    type="button"
                    onClick={() =>
                      dropdownId !== id
                        ? setDropDownId(id)
                        : setDropDownId(null)
                    }
                  >
                    {title}
                    <span>{dropdownId !== id ? down : up}</span>
                  </FAQButton>
                  <FAQInfo
                    dangerouslySetInnerHTML={{ __html: sanitize(content) }}
                  />
                </FAQItem>
              );
            })}
          </FAQList>
        </Container>
      </Static>
    </Background>
  );
};

const faqs = [
  {
    id: 1,
    title: "How do I register?",
    content: `<p>Click the log in button in the top left corner of the page,
    then select sign up. Fill out the required data about your
    company, select the desired plan and pay. You now have
    successfully registered your account.</p>`,
  },
  {
    id: 2,
    title: "How much does it cost to use TransConnector?",
    content: `<p>Transconnector has 2 different types of access. Free and Roundtrip Pro. You can see the plans from <a href="/pricing">here.</a></p>`,
  },
  {
    id: 3,
    title: "How to create a Tender offer?",
    content: `<p>Click the link "Tender" in the menu and find the "Create Tender"  button. Fill up needed fields and publish your offer.</p>`,
  },
  {
    id: 4,
    title: "How to create a Roundtrip offer?",
    content: `<p>Click the link "Roundtrip" in the menu and find the "Create Roundtrip" button. Fill up needed fields then your offer will be reviewed and published.</p>`,
  },
  {
    id: 5,
    title: "How to create a Spot Load offer?",
    content: `<p>Click the link "Load Exchange" in the menu and find the "Create Spot freight" button. Fill up needed fields and publish your offer.</p>`,
  },
  {
    id: 6,
    title: "I posted my roundtrip offer. When will I get offers?",
    content: `<p>After publishing your offer, it will be reviewed by page admin after approval it appears on "Roundtrip" page and other members of TransConnector can see it. You will receive a notification if your roundtrip offer is published. If other members find your offer attractive, they’ll contact you.</p>`,
  },
  {
    id: 7,
    title: "I posted my tender offer. When will I get offers?",
    content: `<p>After publishing your offer, it appears on "Tender" page and other members of TransConnector can see it. If they’ll find your offer attractive, they’ll contact you. You will receive a notification if your Tender gets a new quotation or message.</p>`,
  },
  {
    id: 8,
    title: "How can I cancel my subscription?",
    content: `<p>You can cancel your subscription any time under your account "My subscriptions" page by clicking "Cancel subscription" link. After cancellation your subscription will stay active until previous payment period ends.</p>`,
  },
  {
    id: 9,
    title: "How can I contact the customer service team?",
    content: `<p>You are welcome to contact us by e-mail: 
      <a href="mailto:support@transconnector.eu">support@transconnector.eu</a>
    </p>`,
  },
];

const down = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28.779"
    height="17.215"
    viewBox="0 0 28.779 17.215"
  >
    <path
      d="M-20382.77-18806.762l12.979,12.973,12.973-12.973"
      transform="translate(20384.184 18808.176)"
      fill="none"
      stroke="#ef4900"
      strokeWidth="4"
    />
  </svg>
);

const up = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28.779"
    height="17.215"
    viewBox="0 0 28.779 17.215"
  >
    <path
      d="M-20382.77-18806.762l12.979,12.973,12.973-12.973"
      transform="translate(-20355.404 -18790.961) rotate(180)"
      fill="none"
      stroke="#ef4900"
      strokeWidth="4"
    />
  </svg>
);

export default FAQ;
