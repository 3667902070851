import React, { useState } from "react";
import styled from "styled-components";
import Modal from "../../Modals/Modal";
import { useDropzone } from "react-dropzone";
import { withRouter } from "react-router-dom";
import Uploader from "./SpotloadUploaderStep1";
import FieldMapping from "./SpotloadUploaderStep2";
import Preview from "./SpotloadUploaderStep3";
import { useEffect } from "react";
import { API_URL } from "../../../constants";
import Cookies from "universal-cookie";

// Modal.setAppElement("#root"); // Set the root element for the modal

// const ModalWrapper = styled.div`
//   text-align: center;
// `;

// const ModalContent = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 20px;
// `;

const UploadButton = styled.label`
  padding: 10px 20px;
  background-color: #0074d9;
  color: white;
  cursor: pointer;
`;

const DropzoneArea = styled.div`
  border: 2px dashed #0074d9;
  padding: 20px;
  text-align: center;
  cursor: pointer;
`;

const testData = {
  "errors": {
    "[0].loading": {
        "value": "xdxdxdxd",
        "msg": "Valid loading location must be specified",
        "param": "[0].loading",
        "location": "body"
    }
},
  "loads": [
      {
          "loading": "xdxdxdxd",
          "unloading": "Pargi 6, Vasula alevik 60535",
          "loadingDateStart": "2023-01-01",
          "loadingDateEnd": "2023-01-02",
          "unloadingDateStart": "2023-01-05",
          "unloadingDateEnd": "2023-01-06",
          "truckType": [
              "Jumbo"
          ],
          "targetPrice": "100.50",
          "typeOfLoad": "FTL",
          "typeOfCargo": "General goods",
          "weight": "5000",
          "loadingMeters": "3",
          "length": "10",
          "width": "2",
          "height": "2",
          "ADR": true,
          "euroPalletExchange": true,
          "TIRCable": false,
          "liveTracking": true,
          "nonStackable": false,
          "wasteLoad": true,
          "extraInfo": "Some extra information"
      },
      {
          "loading": "Pargi 6, Vasula alevik 60535",
          "unloading": "Tartu",
          "loadingDateStart": "2023-02-01",
          "loadingDateEnd": "2023-02-02",
          "unloadingDateStart": "2023-02-05",
          "unloadingDateEnd": "2023-02-06",
          "truckType": [
              "Mega_truck"
          ],
          "targetPrice": "150.75",
          "typeOfLoad": "LTL",
          "typeOfCargo": "Wood",
          "weight": "3000",
          "loadingMeters": "2",
          "length": "8.5",
          "width": "2.5",
          "height": "10",
          "ADR": "false",
          "euroPalletexchange": "true",
          "TIRCable": "false",
          "liveTracking": "true",
          "nonStackable": "false",
          "wasteLoad": "true",
          "extraInfo": "Some more information"
      }
  ]
}

const SpotloadUploader = ({ onClose, onUpload, USER }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [headers, setHeaders] = useState([]);
  const [csvId, setCsvId] = useState(null);
  const [data, setData] = useState([]);
  // const [data, setData] = useState(testData);
  const [config, setConfig] = useState({});// {delimiter: ",", randomiseAddress: true}
  const [currentStep, setCurrentStep] = useState(1);

  const handleData = (data) => {
    setData(data.data);
    setConfig({ ...config, randomiseAddress: data.config.randomiseAddress });
  };

  useEffect(() => {
    // after we receive the uploaded file, we POST it to the server
    // we also send the delimiter
    if (uploadedFile) {
      const formData = new FormData();
      formData.append("csv", uploadedFile);
      const cookies = new Cookies();
      fetch(`${API_URL}/spotloads/upload?delimiter=${encodeURIComponent(config.delimiter)}`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${cookies.get("tc_token")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setHeaders(res.headers);
          setCsvId(res.id);
          //   onUpload(res);
          setUploadedFile(null);
          setCurrentStep(2);
        })
        .catch((err) => console.log(err));
    }
  }, [uploadedFile]);

  useEffect(() => {
    console.log("got data", data);
    if (data.loads) {
      setCurrentStep(3);
    }
  }, [data]);

  return (
    <>
      <Uploader onClose={onClose} onUpload={({ file, delimiter }) => {
        setUploadedFile(file);
        setConfig({ delimiter });
      }}></Uploader>
      {currentStep === 2 && (
        <FieldMapping
        USER={USER}
          headers={headers}
          config={config}
          csvId={csvId}
          onClose={onClose}
          onData={handleData}
        ></FieldMapping>
      )}
      {currentStep === 3 && (
        <Preview data={data} config={config} csvId={csvId} onClose={onClose} goBack={() => setCurrentStep(2)}></Preview>
      )}
    </>
  );
};

export default SpotloadUploader;
