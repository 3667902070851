import axios from "axios";
import { API_URL } from "../../constants";
import { SET_UNREAD } from "../actionTypes";
import { getToken } from "../../helpers/getToken";
import {
  SET_USER,
  SET_COMPANY,
  SET_USER_SUBSCRIPTION,
  SET_SPOTLOADS,
  SET_TENDERS,
  SET_EMPTYTRUCKS,
  SET_ROUNDTRIPS,
  SET_OFFERS,
  SET_QUOTATIONS,
  SET_UNREADQUOTATIONS
} from "../../store/actionTypes";
import Cookies from "universal-cookie";

export const getUnread = () => {

  return (dispatch) => {
    const token = getToken();

    axios
      .get(`${API_URL}/unreadCount`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const { unread, quotations } = res.data;
        if (res.data.success === false) {
          //LOG OUT THE USER
          const cookies = new Cookies();
          cookies.remove("tc_token", { path: "/" });
          dispatch({
            type: SET_USER,
            payload: null,
          });
          dispatch({
            type: SET_COMPANY,
            payload: null,
          });
          dispatch({
            type: SET_USER_SUBSCRIPTION,
            payload: null,
          });
          dispatch({
            type: SET_SPOTLOADS,
            payload: null,
          });
          dispatch({
            type: SET_TENDERS,
            payload: null,
          });
          dispatch({
            type: SET_ROUNDTRIPS,
            payload: null,
          });
          dispatch({
            type: SET_EMPTYTRUCKS,
            payload: null,
          });
          dispatch({
            type: SET_OFFERS,
            payload: null,
          });
          dispatch({
            type: SET_QUOTATIONS,
            payload: null,
          });
          return window.location = "/log-in";
        }
        else {
          dispatch({ type: SET_UNREAD, payload: unread });
          dispatch({ type: SET_UNREADQUOTATIONS, payload: quotations });

        }
      })
      .catch((err) => { });
  };
};

export const sendUserMessage = (
  id,
  title,
  content,
  type,
  post_originate,
  isQuotation
) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios({
      method: "POST",
      url: `${API_URL}/conversations`,
      data: {
        recieverId: id,
        title,
        content,
        type,
        post_originate,
        isQuotation,
      },
      ...config,
    });
  };
};
