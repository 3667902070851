import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import logo from '../../images/logo.svg';
import iMenu from '../../assets/icons/drawer-menu.svg';

import { Wrapper, Inner, Letters, MobileNav } from './Styles';
import Routes from './Routes';
import { ReactSVG } from 'react-svg';
import { SET_DRAWER } from '../../store/actionTypes';

const Dashboard = (props) => {
  const open = useSelector((state) => state.app.misc.drawer);
  const dispatch = useDispatch();

  const toggleDrawer = () => {
    dispatch({
      type: SET_DRAWER,
      payload: !open,
    });
  };
  /*   const [letters, setLetters] = useState();
  const COMPANY = useSelector((state) => state.dashboard.company?.data); */

  // Generate company letters
  /*   useEffect(() => {
    if (COMPANY && !letters) {
      // company letters
      if (COMPANY.companyName) {
        const companyLetters = COMPANY.companyName.replace(/ /, "");
        let firstLetter = companyLetters.charAt(0);
        let secondLetter = companyLetters.charAt(1);
        setLetters(firstLetter + secondLetter);
      }
    }
  }, [COMPANY, letters]); */


  return (
    <Wrapper>
      
      <MobileNav>
        <Link to="/dashboard">
          <img src={logo} alt="Transconnector" />
        </Link>
        <button type="button" onClick={toggleDrawer}>
          <ReactSVG src={iMenu} />
        </button>

        {/*         {COMPANY && COMPANY.companyName && (
          <Link to="/dashboard">
            <Letters>{letters}</Letters>
          </Link>
        )} */}
      </MobileNav>
      <Inner>
        <Routes />
      </Inner>
    </Wrapper>
  );
};

export default Dashboard;
