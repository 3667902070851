import React, { useState, useEffect } from "react";

import Filter from "./Filter";
import Loader from "../UI/Loader";
import ScreenBlock from "../Screen/ScreenBlock";
import SpotloadsTable from "./SpotloadsTable";


const Spotloads = (props) => {
  const [filter, setFilter] = useState(0);
  const [active, setActive] = useState(0);
  const [inActive, setInactive] = useState(0);
  const [spotloads, setSpotloads] = useState([]);

  const { OFFERS } = props;

  // Handle setting spotload state
  useEffect(() => {
    if (OFFERS && OFFERS[2]?.spotloads?.length > 0 && spotloads?.length <= 0) {
      setSpotloads(OFFERS[2]?.spotloads);
    }
  }, [OFFERS, spotloads]);

  // Reset filter when there are no spotloads to show
  useEffect(() => {
    if (spotloads?.length <= 0 && filter > 0) {
      setFilter(0);
      setSpotloads(OFFERS[2]?.spotloads);
    }
  }, [spotloads, filter, OFFERS]);

  // Handle tender filtering
  useEffect(() => {
    if (filter === 0 && spotloads?.length >= 0) {
      setSpotloads(OFFERS[2]?.spotloads);
    } else if (filter === 1) {
      setSpotloads(OFFERS[2]?.spotloads.filter((e) => e.active));
    } else if (filter === 2) {
      setSpotloads(OFFERS[2]?.spotloads.filter((e) => !e.active));
    }
  }, [filter, OFFERS]);

  // Get count of active and inactive tenders
  useEffect(() => {
    if (OFFERS && OFFERS[2]?.spotloads) {
      setActive(0);
      setInactive(0);
      OFFERS[2].spotloads.forEach((e) =>
        e.active
          ? setActive((prevCount) => prevCount + 1)
          : setInactive((prevCount) => prevCount + 1)
      );
    }
  }, [OFFERS]);

  return (
    <ScreenBlock title="My Spot freights">
      {spotloads?.length > 0 && (
        <Filter
          all={props.OFFERS && props.OFFERS[2]?.spotloads?.length}
          active={active}
          inActive={inActive}
          filter={filter}
          setFilter={setFilter}
          type="Freight"
          to="/dashboard/spotload/create"
          isExpired={props.isExpired}
          setExpiredModal={props.setExpiredModal}
        />
      )}
      {spotloads?.length > 0 ? (
        <SpotloadsTable
          spotloads={spotloads}
          handleStatusChange={props.handleStatusChange}
          handleOfferRemove={props.handleOfferRemove}
        />
      ) : (
        <Loader />
      )}
    </ScreenBlock>
  );
};

export default Spotloads;
