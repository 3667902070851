import styled from "styled-components";

export const Content = styled.div`
  padding: 20px 15px 30px;
  background: #E5E5E5;
  margin-top: 4rem;
  
  button {
    cursor: pointer;
    transition: 0.25s all ease;
  }

  h1,
  h2,
  h3,
  h5 {
    margin-top: 0;
    color: #262A68;
    font-weight: 700;
  }
  
  h1 {
    text-align: center;
    line-height: 1.4;
    margin: 0 auto 20px;
    font-size: 32px;

    span {
      color: #ef4900;
    }
  }
  h3,
  h2 {
    margin-bottom: 20px;
  }
  h5 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .spinner {
    width: 16px;
    animation: spin 1s linear infinite;

    circle {
      opacity: .25;
    }
    path {
      opacity: .75;
    }
  }

  .flex-fill {
    flex: 1 1 auto;
  }

  .icon {
    width: 1em;
    max-width: 100%;
    fill: currentColor;
  }

  @media (min-width: 992px) {
    margin-top: 0;
  }
  
  @media (min-width: 1200px) {
    padding-top: 40px;
    padding-bottom: 50px;
    h1 {
      font-size: 36px;
      margin-bottom: 40px;
    }
    
    h2 {
      margin-bottom: 30px;
    }
    
    h3 {
      font-size: 22px;
    }
  }
  @media (min-width: 1440px) {
    padding-top: 60px;
    padding-bottom: 80px;
    
    h1 {
      max-width: 1110px;
    }
  }
`;

export const Tabs = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 8px;
  max-width: 1270px;
  margin: 0 auto;
  display: flex;
  border-bottom: 1px solid #DEDEDE;
  
  & > :first-child {
    border-top-left-radius:  8px;
    border-top-right-radius:  8px;
  }
  
  & > :not(:last-child) {
    border-right-color: #DEDEDE;
  }

  > :last-child {
    border-top-right-radius:  8px;
  }

  @media (min-width: 768px) {
    & > :first-child {
      border-top-right-radius:  0;
    }
  }

  @media (min-width: 992px) {
    & > :last-child {
      border-top-right-radius:  0;
    }
  }
`;

export const Tab = styled.button`
  background: #fff;
  flex-wrap: wrap;
  font-weight: 700;
  outline: none;
  gap: 10px;
  text-transform: uppercase;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #262A68;
  padding: 10px;
  border: 1px solid transparent;
  font-size: 16px;
  
  &:focus {
    background: #0f1540;
    color: #fff;
  }

  &.active,
  &:hover {
    background: #262A68;
    color: #fff;
  }
  
  .icon {
    width: 34px;
    height: 34px;
    max-width: 100%;
  }

  @media (min-width: 1440px) {
    font-size: 20px;
    padding: 15px;
    gap: 18px;
    .icon {
      width: 60px;
      height: 60px;
    }
  }
`;


export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  
  .transport-info {
    background: #fff;
    border-radius: 8px;
  }
  
  .transport-select {
    grid-row: 2;
    display: flex;
    flex-direction: column;
  }

  .field-wrap {
    border-bottom-right-radius:  8px;
    border-bottom-left-radius:  8px;
  }

  @media (min-width: 992px) {
    grid-template-columns: .6fr .4fr;
    gap: 0;

    .transport-select {
      grid-row: unset;
    }
    
    .transport-info {
      border-left: 1px solid #DEDEDE;
      border-radius: 0 8px 8px 0;
    }
    
    .field-wrap {
      border-bottom-right-radius:  0;
    }
  }
`;
