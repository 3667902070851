import React, { useState } from "react";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { API_URL } from "../../constants";

import Modal from "../Modals/Modal";
import Header from "../Modals/Header";
import Footer from "../Modals/Footer";
import Button from "../Buttons/Button";

import { ModalInnerWrapper, Centered } from "../Modals/Styles";

const WithInvoice = ({ subId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();

  const handleClick = () => {
    setModalOpen(true);
    const cookies = new Cookies(),
      token = cookies.get("tc_token");
    axios({
      url: `${API_URL}/pay`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        subscriptionID: subId,
      },
    });
  };

  const handleRedirect = () => history.push("/dashboard");
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <span
        style={{
          color: "#262a68",
          lineHeight: 2,
        }}
      >
        Don’t want to pay with a credit card?
      </span>
      <InvoiceButton type="button" onClick={handleClick}>
        <strong
          style={{
            fontSize: "1rem",
          }}
        >
          Pay with invoice (bank transfer)
        </strong>
      </InvoiceButton>
      {modalOpen && (
        <MDL>
          <Modal onClose={handleRedirect} medium>
            <Header onClose={handleRedirect} noBorder title="Email sent." />
            <ModalInnerWrapper>
              <Centered>
                <h3>
                  Thank you for your order. <br />
                  Invoice has been sent to your e-mail.
                </h3>
                <h4>
                  Your subscription will be activated within 24h after payment.
                </h4>
              </Centered>
              <Footer centered>
                <Button
                  spacing
                  type="primary"
                  title="Got it"
                  onClick={handleRedirect}
                />
              </Footer>
            </ModalInnerWrapper>
          </Modal>
        </MDL>
      )}
    </div>
  );
};

const InvoiceButton = styled.button`
  background: none;
  font-family: "Inter";
  border: none;
  color: #000;
  cursor: pointer;
`;

const MDL = styled.div`
  button {
    border-radius: 8px;
    padding: 1rem 2rem;
    cursor: pointer;
    font-size: 18px;
  }
`;

export default WithInvoice;
