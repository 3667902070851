import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  background: #fbfdff;
  margin-left: auto;
  padding: 1rem;
  @media (min-width: 1200px) {
    max-width: 1111px;
    padding: 2rem 2rem 2rem 4rem;
  }
  @media (min-width: 1440px) {
    max-width: calc(1286px + 134px);
    padding: 3rem 3rem 3rem 6rem;
  }
  @media (min-width: 1740px) {
    max-width: calc(1556px + 134px);
    padding: 4rem 4rem 4rem 8rem;
  }
`;

export const Selector = styled.div`
  h3 {
    font-size: 18px;
    color: #262a68;
    margin-bottom: 2rem;
  }
  @media (min-width: 992px) {
    display: inline-flex;
    width: 100%;
    padding: 1rem;
    align-items: center;
    h3 {
      margin: 0;
      margin-right: 4rem;
    }
  }
`;
export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0 0.5rem;
  position: relative;
  button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Inter";
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    background: none;
    border: none;
    padding: 0.5rem;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 6px;
  }
  ${({ active }) =>
    active &&
    css`
      button {
        cursor: default;
        background: #262a68;
        color: #fff;
        font-weight: 600;
        box-shadow: 0 3px 15px rgba(118, 144, 213, 0.16);
      }
    `}
  @media (min-width: 992px) {
    padding: 0.5rem 1rem;
  }
`;

export const ActiveArrow = styled.div`
  position: absolute;
  top: -45%;
  left: 50%;
  transform: translate(-50%, -45%);
  animation: MoveUpDown 2s linear infinite;
  @keyframes MoveUpDown {
    0%,
    100% {
      bottom: 10px;
    }
    50% {
      bottom: 40px;
    }
  }
  @media (min-width: 992px) {
    top: -25%;
    transform: translate(-50%, -25%);
    @keyframes MoveUpDown {
      0%,
      100% {
        bottom: 0px;
      }
      50% {
        bottom: 50px;
      }
    }
  }
`;

export const SelectorGroup = styled.div`
  display: inline-flex;
  width: 100%;
  max-width: 550px;
  ${ButtonWrapper}:not(:last-child) {
    border-right: 1px solid #626595;
  }
  @media (max-width: 578px) {
    flex-wrap: wrap;
    ${ButtonWrapper} {
      width: 50%;
      &:nth-child(3),
      &:nth-child(4) {
        ${ActiveArrow} {
          top: 75%;
          transform: translate(-50%, 75%) rotate(180deg);
          animation: MoveDownUp 2s linear infinite;
          @keyframes MoveDownUp {
            0%,
            100% {
              top: 60%;
            }
            50% {
              top: 100%;
            }
          }
        }
      }
    }
  }
`;

export const OfferList = styled.div`
  max-width: 1111px;
  margin-top: 1.5rem;
`;
