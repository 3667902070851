import React from 'react';
import RadioGroup from '../CreateForm/RadioGroup';
import RadioInput from '../CreateForm/RadioInput';
import NumberGroup from '../CreateForm/NumberGroup';
import NumberInput from '../CreateForm/NumberInput';
import AdditionalRequirements from '../CreateForm/AdditionalRequirements';
import CheckboxGroup from '../CreateForm/CheckboxGroup';
import Checkbox from '../CreateForm/Checkbox';
import Textarea from '../CreateForm/Textarea';
import { Wrapper, InlineWrapper } from '../CreateForm/Styles';

const EmptyTruckCreatorStepTwo = (props) => {
  return (
    <Wrapper>
      <InlineWrapper>
        <RadioGroup label="Type of load" error={props.errors?.typeOfLoad}>
          <InlineWrapper>
            <RadioInput
              name="typeOfLoad"
              value="FTL"
              onInputChange={props.onInputChange}
              inputs={props.inputs}
            />
            <RadioInput
              name="typeOfLoad"
              value="LTL"
              onInputChange={props.onInputChange}
              inputs={props.inputs}
            />
          </InlineWrapper>
        </RadioGroup>
        {/*         <RadioGroup label="Type of load" error={props.errors?.typeOfLoad}>
          <InlineWrapper>
            <RadioInput
              name="typeOfLoad"
              value="FTL"
              onInputChange={props.onInputChange}
              inputs={props.inputs}
            />
            <RadioInput
              name="typeOfLoad"
              value="LTL"
              onInputChange={props.onInputChange}
              inputs={props.inputs}
            />
          </InlineWrapper>
        </RadioGroup> */}
      </InlineWrapper>
      <div style={{ maxWidth: '600px' }}>
        <NumberGroup>
          <InlineWrapper>
            <NumberInput
              label="Weight"
              min={0}
              name="weight"
              placeholder="Kg"
              inputs={props.inputs}
              value={props.inputs?.freight?.weight || ''}
              onInputChange={props.onInputChange}
              descendant="freight"
              error={props.errors?.weight}
            />
            <NumberInput
              label="Loading meters"
              min={0}
              name="loadingMeters"
              placeholder="Meter"
              inputs={props.inputs}
              value={props.inputs?.freight?.loadingMeters || ''}
              onInputChange={props.onInputChange}
              descendant="freight"
              error={props.errors?.loadingMeters}
            />
          </InlineWrapper>
          <AdditionalRequirements
            onInputChange={props.onInputChange}
            errors={props.errors}
            inputs={props.inputs}
            showVolume
          />
        </NumberGroup>
      </div>
      <CheckboxGroup
        label="Special features"
        error={props.errors?.specialRequests}
      >
        <Checkbox
          label="ADR"
          value="ADR"
          name="specialFeatures"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="specialFeatures"
        />
        <Checkbox
          label="Euro pallet exchange"
          value="euroPalletExchange"
          name="specialFeatures"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="specialFeatures"
        />
        <Checkbox
          label="TIR Cable"
          value="TIRCable"
          name="specialFeatures"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="specialFeatures"
        />
        <Checkbox
          label="Live tracking"
          value="liveTracking"
          name="specialFeatures"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="specialFeatures"
        />
        <Checkbox
          label="Waste license"
          value="wasteLoad"
          name="specialFeatures"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="specialFeatures"
        />
        <Checkbox
          label="Available for roundtrips"
          value="availableForRoundtrip"
          name="specialFeatures"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="specialFeatures"
        />
      </CheckboxGroup>
      <Textarea
        label="Extra info"
        name="extraInfo"
        inputs={props.inputs}
        onInputChange={props.onInputChange}
        error={props.errors?.extraInfo}
      />
    </Wrapper>
  );
};

export default EmptyTruckCreatorStepTwo;
