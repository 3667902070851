export const filterTruckTypes = (truckTypes) => {
  let returnTypes;
  truckTypes?.length > 0
    ? (returnTypes = truckTypes
        .map((el) => {
          return el.value;
        })
        .join(","))
    : (returnTypes = "");
  return returnTypes;
};

export const filterLocations = (
  loading,
  loadingRadius,
  unloading,
  unLoadingRadius
) => {
  return {
    loading: {
      value: loading?.value ? encodeURI(loading?.value) : "",
      radius: loadingRadius || 50,
    },
    unloading: {
      value: unloading?.value ? encodeURI(unloading?.value) : "",
      radius: unLoadingRadius || 50,
    },
  };
};
