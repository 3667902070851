import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0 1rem;
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  a {
    padding: 0.5rem 0;
    color: #a7acbd;
    font-size: 14px;
    text-decoration: none;
  }
  @media (min-width: 768px) {
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    padding-right: 1.5rem;
    flex-direction: row;
    a {
      padding: 0;
      padding-left: 1rem;
    }
  }
`;
