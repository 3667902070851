import React, { useEffect, useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";

import Sheet from "../../PageWithList/Sheet/Sheet";
import RoundtripSheetHeader from "./RoundtripSheetHeader";
import SheetInner from "./SheetInner";
import { SheetClose } from "../../PageWithList/Sheet/Styles";
import {
  addFavourite,
  removeFavourite,
} from "../../../store/actions/dashboard/favourites";
import { sendUserMessage } from "../../../store/actions/misc";

const reducer = (state, action) => {
  if (action.type === "setRoundtrip") {
    return action.payload;
  } else {
    return state;
  }
};

const FindRoundtripSheet = (props) => {
  const { USER, setSheetID } = props;
  const [roundtrip, dispatch] = useReducer(reducer, props.roundtrip || null);
  const [isFavourited, setIsFavourited] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [loginGateway, setLoginGateway] = useState(false);
  const [expiredGateway, setExpiredGateway] = useState(false);
  const USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.activeSubscription
  );
  const SUBSCRIPTION_NAME = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.subscriptionName
  );
  const reduxDispatch = useDispatch();

  // Set subscription gateway when user is expired or cancelled
  useEffect(() => {
    if (
      (typeof SUBSCRIPTION_NAME !== "undefined" &&
        SUBSCRIPTION_NAME === "Free trial") ||
      (typeof USER_PLAN !== "undefined" && !USER_PLAN)
    ) {
      setIsExpired(true);
    } else {
      setIsExpired(false);
    }
  }, [USER_PLAN, SUBSCRIPTION_NAME]);

  // Set login and subscription gateway modals
  
  useEffect(() => {
    if (!USER && props.id) setLoginGateway(true);
    if (USER && isExpired && props.id) setExpiredGateway(true);
  }, [props.id, loginGateway, expiredGateway, isExpired, USER]);

  // Get the requested roundtrip from all roundtrips
  useEffect(() => {
    if (props.roundtrips) {
      dispatch({
        type: "setRoundtrip",
        payload: props.roundtrips.find(
          (roundtrip) => roundtrip._id === props.id
        ),
      });
    }
    return () => dispatch({ type: "setRoundtrip", payload: null });
  }, [dispatch, props.roundtrips, props.id]);

  // Check if the requested tender is in favourites
  useEffect(() => {
    if (USER?.favourites) {
      USER.favourites.some((favourite) =>
        favourite.favouriteId === props.id
          ? setIsFavourited(true)
          : setIsFavourited(false)
      );
    }
  }, [USER, props.id]);

  // Handle adding to favourites -- NOT USED
  const addToFavourites = () => {
    if (!roundtrip) return;
    reduxDispatch(
      addFavourite(roundtrip._id, `${roundtrip.name}`, "FindRoundtrip")  
    );
  };

  // Handle removing from favourites
  const removeFromFavourites = () => {
    if (!roundtrip) return;
    reduxDispatch(removeFavourite(roundtrip._id));
  };

  const handleMessageSubmit = (value) => {
    if (value?.length > 0) {
      reduxDispatch(
        sendUserMessage(
          roundtrip.author._id,
          roundtrip.name,
          value,
          "FindRoundtrip",
          roundtrip._id
        ) //TODO dispatch
      )
        .then((res) => setMessageSent(true))
        .catch((err) => console.log("err", err));
    }
  };

  if (/*USER &&*/ roundtrip) {
    return (
      
      <>
        <Sheet size={props.size} denyActions={props.denyActions}>
          <RoundtripSheetHeader
            setSheetID={(id) => props.setSheetID(id)}
            company={roundtrip.company}
            isFavourited={isFavourited}
            roundtrip={roundtrip}
            addToFavourites={addToFavourites}
            removeFromFavourites={removeFromFavourites}
            denyActions={props.denyActions}
            size={props.size}
            isExpired={isExpired}
            loginGateway={loginGateway}
          />
          {roundtrip && (
            <SheetInner
              handleMessageSubmit={handleMessageSubmit}
              roundtrip={roundtrip}
              messageSent={messageSent}
              setMessageSent={setMessageSent}
              denyActions={props.denyActions}
              isExpired={isExpired}
              loginGateway={loginGateway}
            />
          )}
        </Sheet>
        {!props.denyActions && (
          <SheetClose onClick={() => props.setSheetID(null)} />
        )}
      </>
    );
  } else return null;
};

export default FindRoundtripSheet;
