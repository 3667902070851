import moment from "moment";
export const FormatTenderEditData = (data) => {
  const {
    loading,
    unloading,
    periodOfTransports,
    shipments,
    targetPrice,
    truckType,
    temperature,
    offerIsActive,
    Freight,
    Payment,
    additionalInformation,
  } = data;
  const loadingLocations = {};
  const unloadingLocations = {};
  loading.forEach((l, i) => {
    loadingLocations[`loading${i}`] = {
      value: l.locationName,
      label: l.locationName,
    };
  });
  unloading.forEach((l, i) => {
    unloadingLocations[`unloading${i}`] = {
      value: l.locationName,
      label: l.locationName,
    };
  });
  return {
    ...loadingLocations,
    ...unloadingLocations,
    periodOfTransports,
    noOfTrucks: shipments.numberOfShipments,
    period: {
      value: shipments.periodicity,
      label:
        shipments.periodicity !== "daily"
          ? shipments.periodicity.substring(
              0,
              shipments.periodicity?.length - 2
            )
          : "day",
    },
    targetPrice,
    truckType,
    temperature,
    offerIsActive: moment(offerIsActive),
    typeOfLoad: Freight.cargoType,
    freight: {
      height: Freight.dimensions?.height,
      length: Freight.dimensions?.lenght,
      volume: Freight.dimensions?.volume,
      weight: Freight.dimensions?.weight,
      width: Freight.dimensions?.width,
      loadingMeters: Freight.dimensions?.loadingMeters,
    },
    cargoType: {
      value: Freight.freight,
      label: Freight.freight.replace(/_/g, " "),
    },
    specialRequests: {
      ADR: Freight.specialRequests.ADR,
      euroPalletExchange: Freight.specialRequests.EuroPallet,
      liveTracking: Freight.specialRequests.LiveTracking,
      nonStackable: Freight.specialRequests.NonStackable,
      TIRCable: Freight.specialRequests.TIRCable,
      wasteLoad: Freight.specialRequests.wasteLoad,
    },
    otherReq: Freight.specialRequests.otherReq,
    ...Payment,
    addInfo: additionalInformation,
  };
};
