import {Link} from "react-router-dom";
import React, {useMemo} from "react";
import {useField} from "formik";
import {PrivacyField} from "./Styles";

const PrivacyInput = () => {
 const [field, meta, helpers] = useField({name: 'privacy', type: 'checkbox'});
 const isError = useMemo(() => !!(meta.touched && meta.error), [meta.error, meta.touched]);
 return (
   <PrivacyField>
    <label htmlFor="accept">
     <input
       type="checkbox"
       id="accept"
       name="accept"
       checked={field.checked}
       onChange={() => helpers.setValue(!field.checked)}
     />
     <span>
      I have read and agree with{" "}
      <span>
            <Link to="/terms" target="_blank">
              Terms & Conditions
            </Link>
          </span>{" "}
      and{" "}
      <span>
            <Link to="/privacy" target="_blank">
              Privacy Policy
            </Link>
          </span>
     </span>
    </label>
    {isError && (<div className="error-message">{meta.error}</div>)}
   </PrivacyField>
 );
}

PrivacyInput.propTypes = {};

export default PrivacyInput;
