import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { useDropzone } from "react-dropzone";

import iMyAccount from "../../../assets/icons/my-account.svg";
import Form from "../UI/Form";
import FormBlock from "../UI/FormBlock";
import Input from "../UI/Input";
import Loader from "../UI/Loader";
import { EDIT_COMPANY } from "../../../store/actionTypes";
import {
  editCompany,
  addCompanyLogo,
} from "../../../store/actions/dashboard/company";
import ScreenBlock from "../Screen/ScreenBlock";

const CompanyProfile = (props) => {
  const [componentState, setComponentState] = useState({
    avatarPreview: [],
    profilePictureErr: false,
    profilePictureLoading: false,
    loadingSubmission: false,
    saved: false,
  });
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const [descriptionChars, setDescriptionChars] = useState("0/255");
  const dispatch = useDispatch();
  const { COMPANY } = props;

  // ********** LOGIC FOR DROPZONE ********** //

  // ADD COMPANY LOGO
  const onDrop = useCallback(
    (acceptedFiles) => {
      const formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      setComponentState((prevState) => ({
        ...prevState,
        profilePictureErr: false,
        profilePictureLoading: true,
      }));
      dispatch(addCompanyLogo(formData))
        .then((res) => {
          // Set avatar preview on uploading image and loading to false
          setComponentState((prevState) => ({
            ...prevState,
            avatarPreview: res.data.logo,
            profilePictureLoading: false,
          }));
          // Add image to state upon uploading
          setInputs((prevState) => ({
            ...prevState,
            companyLogo: res.data.logo,
          }));
        })
        .catch((err) => {
          setComponentState((prevState) => ({
            ...prevState,
            profilePictureLoading: false,
            profilePictureErr: err.response.data.errors[0].title,
          }));
        });
    },
    [dispatch]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpg, image/jpeg, image/png",
    maxFiles: 1,
    maxSize: 2000000,
    onDrop,
  });

  // **************************************** //

  // Set default input values

  useEffect(() => {
    if (COMPANY) {
      Object.keys(COMPANY).forEach((key) => {
        if (!inputs.hasOwnProperty(key)) {
          setInputs((prevState) => ({
            ...prevState,
            [key]: COMPANY[key],
          }));
        }
      });
      if (COMPANY.logo) {
        setComponentState((prevState) => ({
          ...prevState,
          avatarPreview: COMPANY.logo,
        }));
      }
      if (COMPANY.description) {
        let length = COMPANY.description?.length;
        setDescriptionChars(length + "/255");
      }
      if (COMPANY.companyType) {
        setInputs((prevState) => ({
          ...prevState,
          companyType: {
            label: COMPANY.companyType,
            value: COMPANY.companyType,
          },
        }));
      }
      if (COMPANY.noOfEmployees) {
        setInputs((prevState) => ({
          ...prevState,
          noOfEmployees: {
            label: COMPANY.noOfEmployees,
            value: COMPANY.noOfEmployees,
          },
        }));
      }
    }
  }, [COMPANY]);

  // Handle input changes

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
    if (e.target.name === "description") {
      let length = e.target?.value?.length;
      if (length <= 255) {
        setDescriptionChars(e.target?.value?.length + "/255");
      }
    } else if (
      e.target.name === "companyType" ||
      e.target.name === "noOfEmployees"
    ) {
      setInputs({ ...inputs, [e.target.name]: e.target.value });
    } else {
      setInputs({ ...inputs, [e.target.name]: e.target.value });
    }
  };

  // Handle select dropdown changes

  const selectChangeHandler = (e, action) => {
    setInputs({
      ...inputs,
      [action.name]: {
        label: e.label,
        value: e.value,
      },
    });
  };

  // Handle editing company information

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});
    setComponentState((prevState) => ({
      ...prevState,
      saved: false,
      loadingSubmission: true,
    }));
    dispatch(editCompany(inputs))
      .then((res) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: res.data,
        });
        setComponentState((prevState) => ({
          ...prevState,
          loadingSubmission: false,
          saved: true,
        }));
        setTimeout(() => {
          setComponentState((prevState) => ({
            ...prevState,
            saved: false,
          }));
        }, 2000);
      })
      .catch((err) => {
        setComponentState((prevState) => ({
          ...prevState,
          loadingSubmission: false,
        }));
        const errors = err.response.data.errors;
        //props.onEditCompanyFailure(errors);
        setErrors(errors);
      });
  };

  // Show loader when company data is not ready to be shown

  if (!COMPANY) {
    return (
      <div className="Screen--block">
        <div className="Screen--block--inner">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <ScreenBlock title="Company Profile" img={iMyAccount}>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <FormBlock title="Company information">
          <Input
            InputType="input"
            type="text"
            label="Company name"
            placeholder="Company name"
            name="companyName"
            onChange={handleInputChange}
            value={inputs.companyName || ""}
            required
            className={(errors.companyName && "invalid") || ""}
            error={errors.companyName && errors.companyName.msg}
            disabled
          />
          <Input
            InputType="input"
            type="text"
            label="Address"
            placeholder="Address"
            name="address"
            onChange={handleInputChange}
            value={inputs.address || ""}
            required
            className={(errors.address && "invalid") || ""}
            error={errors.address && errors.address.msg}
          />
          <div className="textarea-counter">
            <Input
              InputType="textarea"
              type="textarea"
              label="Description"
              placeholder="Enter your company description"
              name="description"
              onChange={handleInputChange}
              value={inputs.description || ""}
              className={(errors.description && "invalid") || ""}
              error={errors.description && errors.description.msg}
            />
            <p>Characters left {descriptionChars}</p>
          </div>
          <div className="input-collection">
            <div className="input-wrapper">
              <h4>Company Logo</h4>
              {!componentState.profilePictureLoading ? (
                <div className="input">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>Click to edit</p>
                    <div className="avatar-preview">
                      <img src={componentState.avatarPreview} alt="" />
                    </div>
                  </div>
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </div>
          {componentState.profilePictureErr && (
            <div className="error">
              <p>{componentState.profilePictureErr}</p>
            </div>
          )}
        </FormBlock>
        <FormBlock title="Additional info">
          <div className="input-collection">
            <div className="input-wrapper">
              <h4>Company type</h4>
              <Select
                classNamePrefix="react-select"
                className={`dropdown ${
                  (errors.companyType && "invalid") || ""
                }`}
                placeholder="Select"
                options={companyTypes}
                name="companyType"
                onChange={selectChangeHandler}
                value={inputs.companyType || ""}
                required
              />
              {errors.companyType && (
                <div className="error">
                  <p>{errors.companyType.msg}</p>
                </div>
              )}
            </div>
          </div>
          <div className="input-collection">
            <div className="input-wrapper">
              <h4>Number of employees</h4>
              <Select
                classNamePrefix="react-select"
                className={`dropdown ${
                  (errors.noOfEmployees && "invalid") || ""
                }`}
                placeholder="Select"
                options={employeeSelect}
                name="noOfEmployees"
                onChange={selectChangeHandler}
                value={inputs.noOfEmployees || ""}
                required
              />
              {errors.noOfEmployees && (
                <div className="error">
                  <p>{errors.noOfEmployees.msg}</p>
                </div>
              )}
            </div>
          </div>

          <Input
            InputType="input"
            type="number"
            label="Year founded"
            placeholder="Founded"
            name="yearFounded"
            value={inputs.yearFounded || ""}
            onChange={handleInputChange}
            required
            className={(errors.yearFounded && "invalid") || ""}
            error={errors.yearFounded && errors.yearFounded.msg}
          />
        </FormBlock>
        <FormBlock title="Contact info">
          <Input
            InputType="input"
            type="text"
            label="Website"
            placeholder="Company website"
            name="website"
            value={inputs.website || ""}
            onChange={handleInputChange}
            className={(errors.website && "invalid") || ""}
            error={errors.website && errors.website.msg}
          />
          <Input
            InputType="input"
            type="email"
            label="Email"
            placeholder="Company Email"
            name="companyEmail"
            value={inputs.companyEmail || ""}
            onChange={handleInputChange}
            className={(errors.companyEmail && "invalid") || ""}
            error={errors.companyEmail && errors.companyEmail.msg}
          />
          <Input
            InputType="input"
            type="text"
            label="Phone"
            placeholder="Company phone"
            name="companyPhone"
            value={inputs.companyPhone || ""}
            onChange={handleInputChange}
            className={(errors.companyPhone && "invalid") || ""}
            error={errors.companyPhone && errors.companyPhone.msg}
          />
        </FormBlock>
        <div className="submit">
          {!componentState.loadingSubmission ? (
            <input
              type="submit"
              value={componentState.saved ? "Saved" : "Save"}
              disabled={componentState.saved ? true : false}
              className={(componentState.saved && "success") || ""}
            />
          ) : (
            <Loader />
          )}
        </div>
      </Form>
    </ScreenBlock>
  );
};

const companyTypes = [
  { value: "Haulier", label: "Haulier" },
  { value: "Forwarder", label: "Forwarder" },
  { value: "Producer", label: "Producer" },
];

const employeeSelect = [
  { value: "1-10", label: "1-10" },
  { value: "10-25", label: "10-25" },
  { value: "25-50", label: "25-50" },
  { value: "50-100", label: "50-100" },
  { value: "100+", label: "100+" },
];

export default CompanyProfile;
