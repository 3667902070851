import React from "react";
import { ModalWrapper, ModalInner, ModalClose } from "./Styles";

const Modal = (props) => {
  return (
    <>
      <ModalWrapper {...props}>
        <ModalInner>{props.children}</ModalInner>
      </ModalWrapper>
      <ModalClose onClick={props.onClose} />
    </>
  );
};

export default Modal;
