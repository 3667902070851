import styled from "styled-components";

export const Wrapper = styled.div`
  padding-top: 3rem;
  padding-bottom: 6rem;
  background: #050720;
  @media (min-width: 992px) {
    display: inline-flex;
    width: 100%;
  }
`;

export const Logo = styled.div`
  width: 100px;
  margin: 1.5rem 0;
  @media (min-width: 992px) {
    width: 175px;
    margin-right: 6rem;
  }
  @media (min-width: 1200px) {
    margin-right: 10rem;
  }
  @media (min-width: 1440px) {
    margin-right: 12rem;
  }
  @media (min-width: 1740px) {
    margin-right: 14rem;
  }
`;
export const Content = styled.div``;
export const Inner = styled.div`
  @media (min-width: 992px) {
    display: inline-flex;
    width: 100%;
  }
`;
export const Item = styled.div`
  h3 {
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      color: #fff;
      list-style-type: none;
      padding: 0.5rem 0;
      a {
        color: #c1c1c1;
        text-decoration: none;
      }
    }
  }
  h4,
  p,
  a {
    color: #fff;
    line-height: 1.5;
  }
  a {
    text-decoration: none;
  }
  h4 {
    font-size: 14px;
    font-weight: 500;
  }
  ol {
    margin: 0;
    padding: 0;
    li {
      padding: 0.5rem 0;
    }
  }
  span {
    display: flex;
    font-size: 14px;
    color: #6f6f6f;
    margin: 1rem 0;
    font-weight: 500;
  }
  @media (min-width: 992px) {
    span {
      margin-bottom: 2rem;
    }
  }
`;
export const InlineItems = styled.div`
  @media (min-width: 992px) {
    margin-bottom: 4rem;
    display: inline-flex;
    width: 100%;
    ${Item}:not(:last-child) {
      margin-right: 5rem;
    }
  }
  @media (min-width: 1200px) {
    ${Item}:not(:last-child) {
      margin-right: 7.5rem;
    }
  }
  @media (min-width: 1440px) {
    ${Item}:not(:last-child) {
      margin-right: 10rem;
    }
  }
`;
export const Social = styled.div`
  svg {
    margin-right: 1rem;
  }
`;
