
import React from "react";
import Input from "../../components/Register/RegisterInput";
import { isMobile } from "react-device-detect";
const RegisterStepOne = (props) => {
  return (
    <div
      className={`Registration__part--PartFirst ${props.pagination === 1 && "active"
        }`}
    >
      <h3>Step 1. Your account</h3>
      {isMobile && <p>Fill form with your personal info</p>}
      <Input
        classes={`floating-label ${props?.errors?.firstName && "invalid"}`}
        name="firstName"
        type="text"
        value={props.inputs?.firstName || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="First name"
        error={props.errors?.firstName && props.errors?.firstName?.msg}
      />
      <Input
        classes={`floating-label ${props.errors?.lastName && "invalid"}`}
        name="lastName"
        type="text"
        value={props.inputs?.lastName || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Last name"
        error={props.errors?.lastName && props.errors?.lastName?.msg}
      />
      <Input
        classes={`floating-label ${props.errors?.email && "invalid"}`}
        name="email"
        type="text"
        value={props.inputs?.email || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Personal email address"
        error={props.errors?.email && props.errors?.email?.msg}
      />
      <Input
        classes={`floating-label ${props.errors?.phone && "invalid"}`}
        name="phone"
        type="text"
        value={props.inputs?.phone || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Phone"
        error={props.errors?.phone && props.errors?.phone?.msg}
      />
      <div className="Password">
        <Input
          classes={`floating-label ${props.errors?.password && "invalid"}`}
          name="password"
          type="password"
          value={props.inputs?.password || ""}
          onChange={(e) => props.handleInputChange(e)}
          label="Password"
          error={props.errors?.password && props.errors?.password?.msg}
        />
        <Input
          classes={`floating-label ${props.errors?.repeatPassword && "invalid"
            }`}
          name="repeatPassword"
          type="password"
          value={props.inputs?.repeatPassword || ""}
          onChange={(e) => props.handleInputChange(e)}
          label="Repeat password"
          error={
            props.errors?.repeatPassword && props.errors?.repeatPassword?.msg
          }
        />
      </div>


      <div className="formSubmit form-next">
        <button
          type="button"
          className="next-step"
          onClick={props.handleNextStep}
        >
          Next step &gt;
        </button>
      </div>

    </div>
  );
}

export default RegisterStepOne;
