import React, { memo, useMemo } from "react";
import Select from "react-select";
import { useField } from "formik";
import Creatable, { useCreatable } from "react-select/creatable";

const FormikSelect = memo(({ name, placeholder, options = [] }) => {
  const [field, meta, helpers] = useField(name);
  const isError = useMemo(
    () => !!(meta.touched && meta.error),
    [meta.error, meta.touched]
  );

  const value = useMemo(() => {
    return (
      (options && options.find((option) => option.value === field.value)) || {
        label: field.value,
        value: field.value,
      }
    );
  }, [field.value, options]);

  return (
    <div className="dropdown-wrapper flex-fill">
      <Creatable
        classNamePrefix="react-select"
        className={`dropdown ${isError && "invalid"}`}
        placeholder={placeholder}
        isClearable
        maxMenuHeight={190}
        name={field.name}
        onChange={(props) => {
          helpers.setTouched(true);
          helpers.setValue(props?.value || "");
        }}
        options={options}
        value={value}
      />
      {isError && <div className="error-message">{meta.error}</div>}
    </div>
  );
});

export default FormikSelect;
