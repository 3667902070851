import React, { useState } from "react";
import { SheetInnerWrapper } from "../../PageWithList/Sheet/Styles";
import Details from "./Details";
import ActionButtons from "../../PageWithList/Sheet/ActionButtons";
import ActionButton from "../../PageWithList/Sheet/ActionButton";
import MessageModal from "../../Modals/MessageModal/MessageModal";
import MessageSent from "../../Modals/MessageModal/MessageSent";
import { isIOS } from "react-device-detect";
import LoginGateway from "../../Modals/AuthGatewayModals/LoginGateway";

const SheetInner = (props) => {
  const [modal, setModal] = useState(false);
  const { _id } = props.roundtrip;
  const [loginGateway, setLoginGateway] = useState(false);

  if (loginGateway)
    return (
      <LoginGateway
        onClose={() => {
          setLoginGateway(false);
        }}
        offerGateway
      />
    );
  if (!loginGateway) {
    return (
      <>
        <SheetInnerWrapper iOS={isIOS}>
          <Details
            icon={messageIcon}
            isExpired={props.loginGateway}
            iOS={isIOS && "1"}
            roundtrip={props.roundtrip}
            setModal={setModal}
            modal={modal}
            setLoginGateway={setLoginGateway}
            show
          />
        </SheetInnerWrapper>
        {!props.denyActions && (
          <>
            <ActionButtons iOS={isIOS && "1"}>
              <ActionButton
                icon={messageIcon}
                label="Send message"
                onClick={() => {
                  !props.loginGateway
                    ? setModal(!modal)
                    : setLoginGateway(true);
                }}
              />
            </ActionButtons>
            {modal && !props.messageSent && (
              <MessageModal
                handleMessageSubmit={props.handleMessageSubmit}
                id={_id}
                onClose={() => setModal(!modal)}
              />
            )}
            {props.messageSent && (
              <MessageSent
                onClose={() => {
                  props.setMessageSent(false);
                  setModal(false);
                }}
              />
            )}
          </>
        )}
      </>
    );
  }
};

const messageIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.812"
    height="15.852"
    viewBox="0 0 18.812 15.852"
  >
    <path
      d="M17.811,10.234a1.544,1.544,0,0,1-1.539,1.539H4.078L1,14.852V2.539A1.544,1.544,0,0,1,2.539,1H16.272a1.544,1.544,0,0,1,1.539,1.539Z"
      fill="none"
      stroke="#262a68"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default SheetInner;
