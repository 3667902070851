import React from "react";
import { SheetBlockWrapper, SheetBlockLabel } from "./Styles";
import { InstantPaymentContainer } from "../../PageWithList/List/Styles";

const SheetBlock = (props) => {
  return (
    <SheetBlockWrapper>
      {props.label && (
        <SheetBlockLabel>
          <h5>{props.label}</h5>
          <InstantPaymentContainer>{props.payment}</InstantPaymentContainer>
        </SheetBlockLabel>
      )}
      {props.children}
    </SheetBlockWrapper>
  );
};

export default SheetBlock;
