import React from 'react';
import Modal from '../Modal';
import Header from '../Header';
import { ModalInnerWrapper, Centered } from '../Styles';
import LargeButton from '../../CreateForm/LargeButton';

const VerificationNotice = (props) => {
  return (
    <Modal onClose={props.onClose} small>
      <Header onClose={props.onClose} />
      <ModalInnerWrapper>
        <Centered>
          <h3>
          You have not yet submitted your company documents for verification or they have not been reviewed yet. The review process should be completed within 1 working day.
          </h3>
          <LargeButton centered label={"Upload documents"} to={"/dashboard/my-account/verification"} />
        </Centered>
      </ModalInnerWrapper>
    </Modal>
  );
};

export default VerificationNotice;
