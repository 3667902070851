import { SET_SPOTLOADS } from "../../actionTypes";
import axios from "axios";
import { API_URL } from "../../../constants";
import { getToken } from "../../../helpers/getToken";

export const getSpotloads = () => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    axios.get(`${API_URL}/spotloads`, config).then((res) => {
      if (res.data && res.data?.length > 0) {
        dispatch({ type: SET_SPOTLOADS, payload: res.data });
      } else {
        // TODO: SET ERROR
      }
    });
  };
};

export const getSingleSpotload = (id) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios.get(`${API_URL}/mySpotload/${id}`, config);
  };
};

export const getFilterSpotloads = (params) => {
  const token = getToken();
  return () => {
    return axios.get(`${API_URL}/spotloads`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      params: params,
    });
  };
};

export const createSpotload = (method, data) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios({
      method: method,
      url: `${API_URL}/spotLoads-client`,
      data: data,
      ...config,
    });
  };
};

export const createSpotloadsFromCSV = (data) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return new Promise((resolve, reject) => {
      axios.post(`${API_URL}/spotloads/upload/publish`, data, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
};

export const validateSpotload = (data) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios.post(`${API_URL}/spotloadValidate`, data, config);
  };
};

export const validateEditSpotloadCSVRow = (data) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios.post(`${API_URL}/editLoadCSVValidate`, data, config);
  };
};
