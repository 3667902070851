import React, { useState, useEffect } from "react";
import {
  InputWrapper,
  LabelWrapper,
  Label,
  selectStyles,
  ErrorContainer,
  ErrorWrapper,
} from "../CreateForm/Styles";
import Select from "react-select";
import NumberInputNegative from "./NumberInputNegative";
import trucktypesJSON from "../../data/trucktypes.json";
const truckTypes = trucktypesJSON.types;

const TrucktypeSelect = (props) => {
  const [showTemp, setShowTemp] = useState(false);
  const selectHandler = (arr, name) => {
    props.onSelectChange(arr, name);
    if (arr && !props.noMulti) {
      arr.filter((e) => e.value === "Refrigerator")?.length > 0
        ? setShowTemp(true)
        : setShowTemp(false);
    }
    if (arr && props.noMulti) {
      if (arr.value === "Refrigerator") {
        setShowTemp(true);
      } else {
        setShowTemp(false);
      }
    }
  };

  // Check if pre-defined data includes a temperature, if, then show temp
  const { temperature } = props.inputs || {};
  useEffect(() => {
    temperature || temperature === 0 ? setShowTemp(true) : setShowTemp(false);
  }, [temperature]);

  return (
    <InputWrapper>
      <LabelWrapper>
        {props.label && (
          <Label>
            <h3>{props.label}</h3>
          </Label>
        )}
        <ErrorContainer>
          <Select
            placeholder={props.label || ""}
            options={truckTypes}
            isMulti={props.noMulti ? false : true}
            closeMenuOnSelect={props.noMulti ? true : false}
            isClearable={false}
            styles={selectStyles}
            classNamePrefix="styled"
            name={props.name}
            value={props.inputs?.[props.name] || null}
            onChange={(val, e) => selectHandler(val, props.name)}
            className={props.errors?.[props.name] && "invalid"}
          />
          {props.errors?.[props.name] && (
            <ErrorWrapper>
              <span>{props.errors?.[props.name].msg}</span>
            </ErrorWrapper>
          )}
        </ErrorContainer>
      </LabelWrapper>
      {showTemp && !props.noTemp && (
        <NumberInputNegative
          min={-270}
          step={1}
          value={props.inputs.temperature || ""}
          label="Temperature"
          placeholder="Temp °C"
          name="temperature"
          error={props.errors?.temperature}
          onInputChange={props.onInputChange}
        />
      )}
    </InputWrapper>
  );
};

export default TrucktypeSelect;
