import React from "react";
import SheetBlock from "../../PageWithList/Sheet/SheetBlock";
import {
  ShowWrapper,
  SheetItem,
  SheetItemInline,
  SheetItemIconsWrapper,
  SheetItemWithIcon,
} from "../../PageWithList/Sheet/Styles";
import { Link } from "react-router-dom";

import DetailsSpecialRequests from "../../PageWithList/Sheet/DetailsSpecialRequests";
import uuid from "uuid";


const Details = (props) => {
  const {
    author,
    company,
    loading,
    unloading,
    Freight,
    shipments,
    targetPrice,
    offerIsActive,
    truckType,
    Payment,
    periodOfTransports,
    temperature,
  } = props.tender;

  return (
    <ShowWrapper show={props.show}>
      <SheetItemIconsWrapper>
        {(author?.firstName || author?.lastName) && (
          <SheetItemWithIcon blur={props.loginGateway || props.nonVerifiedStatus}>
            <Link
              to={
                !props.loginGateway && !props.nonVerifiedStatus ? `/dashboard/company/${company?._id}` : "#!"
              }
            >
              {userIcon}
              {!props.loginGateway && !props.nonVerifiedStatus ? (
                <>
                  {author?.firstName} {author?.lastName}
                </>
              ) : (
                  <span>xxxxxxxxxxxxx</span>
                )}
            </Link>
          </SheetItemWithIcon>
        )}
        {author?.email && (
          <SheetItemWithIcon blur={props.loginGateway || props.nonVerifiedStatus}>
            <a href={!props.loginGateway && !props.nonVerifiedStatus ? `mailto:${author.email}` : "#!"}>
              {emailIcon}{" "}
              {!props.loginGateway && !props.nonVerifiedStatus ? (
                <>{author.email}</>
              ) : (
                  <span>xxxxxxxxxxxxxxxxxx</span>
                )}
            </a>
          </SheetItemWithIcon>
        )}
        {author?.phone && (
          <SheetItemWithIcon blur={props.loginGateway || props.nonVerifiedStatus}>
            <a href={!props.loginGateway && !props.nonVerifiedStatus ? `tel:${author.phone}` : "#!"}>
              {phoneIcon}{" "}
              {!props.loginGateway && !props.nonVerifiedStatus ? (
                <>{author.phone}</>
              ) : (
                  <span>xxxxxxxxxxxxxxxxxx</span>
                )}
            </a>
          </SheetItemWithIcon>
        )}
        {props.loginGateway ? <p style={{ "margin-top": "25px", 'text-align': "center" }}>In order to see contacts <span><Link to="/log-in">log in</Link> or <span><Link to="/sign-up">sign up</Link></span> for free</span></p> : null}
        {props.nonVerifiedStatus ? <p style={{ "margin-top": "25px", 'text-align': "center" }}>In order to see contacts <span><Link to="/dashboard/profile">verify your account</Link></span></p> : null}
      </SheetItemIconsWrapper>
      <SheetBlock label="Tender info">
        <SheetItem>
          <h3>Loading</h3>
          {loading &&
            loading.map((load) => {
              return <h4 key={uuid()}>{load.locationName}</h4>;
            })}
        </SheetItem>
        <SheetItem>
          <h3>Unloading</h3>
          {unloading &&
            unloading.map((unload) => {
              return <h4 key={uuid()}>{unload.locationName}</h4>;
            })}
        </SheetItem>
        <SheetItem>
          <h3>Truck type</h3>
          <h4>
            {truckType
              .map((element) => {
                if (element.value === "Refrigerator" && temperature) {
                  return `${element.value} (${temperature}°C)`;
                }
                return element.value;
              })
              .join(", ")
              .replace(/_/g, " ")}
          </h4>
        </SheetItem>
        <SheetItemInline>
          <SheetItem>
            <h3>Type of load</h3>
            <h4>{Freight.cargoType}</h4>
          </SheetItem>
          <SheetItem>
            <h3>No of trips</h3>
            <h4>
              {shipments.numberOfShipments} x{" "}
              {shipments.periodicity.toLowerCase()}
            </h4>
          </SheetItem>
          {targetPrice && targetPrice !== "0" && (
            <SheetItem>
              <h3>Target price</h3>
              <h4>{targetPrice}€</h4>
            </SheetItem>
          )}
        </SheetItemInline>
      </SheetBlock>
      <SheetBlock label="Details">
        <SheetItemInline inlineRow>
          {Freight.dimensions?.weight > 0 && (
            <SheetItem>
              <h3>Weight</h3>
              <h4>
                {Freight.dimensions.weight} kg
              </h4>
            </SheetItem>
          )}
          {Freight.dimensions?.loadingMeters > 0 && (
            <SheetItem>
              <h3>Loading meters</h3>
              <h4>{Freight.dimensions.loadingMeters} m</h4>
            </SheetItem>
          )}
          {Freight.dimensions?.length > 0 && (
            <SheetItem>
              <h3>Length</h3>
              <h4>{Freight.dimensions?.length} cm</h4>
            </SheetItem>
          )}
          {Freight.dimensions?.width > 0 && (
            <SheetItem>
              <h3>Width</h3>
              <h4>{Freight.dimensions.width} cm</h4>
            </SheetItem>
          )}
          {Freight.dimensions?.height > 0 && (
            <SheetItem>
              <h3>Height</h3>
              <h4>{Freight.dimensions.height} cm</h4>
            </SheetItem>
          )}
          {Freight.dimensions?.volume > 0 && (
            <SheetItem>
              <h3>Volume</h3>
              <h4>
                {Freight.dimensions.volume} m<sup>3</sup>
              </h4>
            </SheetItem>
          )}
        </SheetItemInline>
        {Freight.freight && (
          <SheetItem>
            <h3>Type of cargo</h3>
            <h4>{Freight.freight.replace(/_/g, " ")}</h4>
          </SheetItem>
        )}
        {periodOfTransports && (
          <SheetItem>
            <h3>Period of shipments</h3>
            <h4>
              {new Date(periodOfTransports.startDate).toLocaleDateString()} -{" "}
              {new Date(periodOfTransports.endDate).toLocaleDateString()}
            </h4>
          </SheetItem>
        )}
        {offerIsActive && (
          <SheetItem>
            <h3>Deadline to recieve offers</h3>
            <h4>{new Date(offerIsActive).toLocaleDateString()}</h4>
          </SheetItem>
        )}
        <DetailsSpecialRequests specialRequests={Freight.specialRequests} />
        {Freight.specialRequests.otherReq && (
          <SheetItem>
            <h3>Other requirements</h3>
            <h4>{Freight.specialRequests.otherReq}</h4>
          </SheetItem>
        )}
      </SheetBlock>
      {Payment.paymentTime !== 0 ||
        (Payment.paymentComments && (
          <SheetBlock label="Payment">
            {Payment.paymentTime !== 0 && (
              <SheetItem>
                <h3>Payment time</h3>
                <h4>{Payment.paymentTime}</h4>
              </SheetItem>
            )}
            {Payment.paymentComments && (
              <SheetItem>
                <h3>Payment comments</h3>
                <h4>{Payment.paymentComments}</h4>
              </SheetItem>
            )}
          </SheetBlock>
        ))}
    </ShowWrapper>
  );
};


const userIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="19"
    viewBox="0 0 17 19"
  >
    <g transform="translate(-5.5 -4)">
      <path
        d="M22,28.5v-2a4,4,0,0,0-4-4H10a4,4,0,0,0-4,4v2"
        transform="translate(0 -6)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M20,8.5a4,4,0,1,1-4-4A4,4,0,0,1,20,8.5Z"
        transform="translate(-2)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

const emailIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.406"
    height="13.062"
    viewBox="0 0 17.406 13.062"
  >
    <g transform="translate(-2.297 -5.5)">
      <path
        d="M4.6,6H17.4A1.56,1.56,0,0,1,19,7.508v9.046a1.56,1.56,0,0,1-1.6,1.508H4.6A1.56,1.56,0,0,1,3,16.554V7.508A1.56,1.56,0,0,1,4.6,6Z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M19,9l-8,6.428L3,9"
        transform="translate(0 -1.163)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

const phoneIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.2"
    height="21.286"
    viewBox="0 0 15.2 21.286"
  >
    <g transform="translate(-7.5 -2.5)">
      <path
        d="M9.529,3H19.671A2.029,2.029,0,0,1,21.7,5.029V21.257a2.029,2.029,0,0,1-2.029,2.029H9.529A2.029,2.029,0,0,1,7.5,21.257V5.029A2.029,2.029,0,0,1,9.529,3Z"
        transform="translate(0.5 0)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <line
        x2="7.381"
        transform="translate(11 20.015)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default Details;
