import React, { useRef, useEffect, useState } from 'react';
import styled from "styled-components";
import { Static, Background, CTA } from "./Styles";
import Container from "../../components/UI/Container";
import LinkButton from "../../components/Buttons/NewButton/LinkButton";
import img1 from "../../assets/icons/spotloads/image 4.png";
import img2 from "../../assets/icons/spotloads/image 5.png";
import img3 from "../../assets/icons/spotloads/image 6.png";
import docsvg from "../../assets/icons/spotloads/Frame 96.svg";
import mockCSV from "../../data/mock.csv";
import semiTruck from "../../assets/icons/spotloads/semi-truck-with-trailer.png";

const Wrapper = styled.div`
  p {
    color: #000;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  img.productMock {
    width: 80%;
    height: auto;
  }
`;

const HighlightArea = styled.div`
  background: #edf6fd;
  padding: 1rem;
  margin: 2rem 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 1rem;
  a {
    color: #0074d9;
    text-decoration: none;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  border: 1px solid #000;
  th,
  td {
    border: 1px solid #000;
    padding: 0.5rem;
  }
`;

const BannerContent = styled.div`
  display: flex;
  z-index: 1;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  width: fit-content;
  height: ${props => props.height}px;
  padding: 2rem;
  h3 {
    color: #fff;
    font-family: Inter;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  p {
    color: #fff;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

// overlay BannerContent over Banner image
const StyledBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-block: 3rem;
  width: 100%;
  height: auto;
  position: relative;
  img {
    position: absolute;
    border-radius: 8px;
  }
`;

const Banner = () => {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  const handleLoad = () => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight);
    }
  };

  return (
    <StyledBanner>
      <img ref={ref} src={semiTruck} alt="semiTruck" onLoad={handleLoad} />
            <BannerContent height={height}>
            <h3>Find new carriers effortlessly with bulk upload!</h3>
            <p>
              Our Loads Upload feature allows you to seamlessly post numerous
              offers on the exchange. Simply upload a .csv file with
              pre-existing details of your load or vehicle offer and find best
              carriers and price offers.
            </p>
            <LinkButton landingPage to="/sign-up" centered="mobile">
              Try it now!
            </LinkButton>
            </BannerContent>

    </StyledBanner>
  );
};

const LoadUpload = (props) => {
  return (
    <Wrapper>
      <Static>
        <Container gutter="medium">
          <h2>Loads Upload - Upload your loads via CSV or API</h2>
          <p>
            <p>
              With our new Loads Upload functionality, you can effortlessly
              publish multiple offers on our exchange platform without the
              hassle of repeatedly filling out load or vehicle forms.
            </p>
            <p>
              Our Loads Upload feature allows you to seamlessly post numerous
              offers on the exchange. Simply upload a .csv file with
              pre-existing details of your load or vehicle offer and find best
              carriers and price offers.
            </p>
          </p>
          <img className='productMock' src={img1} alt="img1" />
          <p>
            <p>
              A CSV file, or comma-separated values file, is a versatile tool
              for storing data in plain text. Leveraging this format can
              significantly expedite your workflow. Instead of manually
              inputting data or descriptions, you can effortlessly populate your
              listings with rich information.
            </p>

            <p>Why upload your loads via CSV?</p>

            <p>
              Our CSV Upload function simplifies the process, allowing you to
              quickly import and manage large volumes of data.
            </p>
          </p>
          <h3>Getting started is easy:</h3>
          <p>
            To initiate a product CSV import on TransConnector, follow these
            steps:
            <ol>
              <li>
                Sign in or register your company on TransConnector platform.
              </li>
              <li>Navigate to the Load Exchange page.</li>
              <li>
                Locate the Add Loads from CSV  button in the right  side of the
                page, situated next to the Create Sport freight Button 
              </li>
              <li>
                Then Browse or drag suitable csv file consisting your loads
              </li>
              <li>
                Map each column in your spreadsheet to the relevant icon and
                field in the mapping step. The auto-recognition feature will
                automatically match the column header to the fields in
                TransConnector.
                <img className='productMock' src={img2} alt="img2" />
              </li>
              <li>Once you are finished mapping, click “Next.”</li>
              <li>
                Click 'Preview” and check if everything is correct. In the next
                window, choose what to do if duplicates are found in your
                spreadsheet. If TransConnector detects a duplicate record in
                your spreadsheet or TransConnector data, it will consolidate
                this into one entry.
              </li>
              <li>
                After your import, you will see a confirmation page with an
                overview of the imported data. Depending on the selected CSV
                file, the import should be completed in less than 5 minutes,
                populating your store with relevant data.
              </li>
            </ol>
          </p>
          <p>
            Once the import process is finished, you will find the newly
            imported loads on your admin dashboard My offers
          </p>
          <img className='productMock' src={img3} alt="img3" />
          <p>
            With this freshly integrated data, you can now wait for the offers
            and quotations.
          </p>
          <h3>Importing data into TransConnector with spreadsheets (csv)</h3>
          <p>
            You can import your data to TransConnector from XLS, XLSX and CSV
            spreadsheet files, allowing you to add all your loads at the same
            time. Before getting started, check out our article on how your data
            is organized in TransConnector. It’s important to understand how the
            data you’re importing will fit into the TransConnector data
            structure.
          </p>
          <HighlightArea>
            <img width={"29px"} height={"37px"} src={docsvg} alt="docsvg" />
            <span>
              Download our sample spreadsheet{" "}
              <a href={mockCSV} download>
                here
              </a>
            </span>
          </HighlightArea>
          <h3>Some spreadsheet formatting tips:</h3>
          <p>
            <p>Some spreadsheet formatting tips:</p>
            <ul>
              <li>
                <strong>Remove formulas</strong> – Use a fresh spreadsheet
                without any formulas. If you have a spreadsheet that uses
                formulas or data-linking to generate the contents of a cell,
                copy the data from your spreadsheet into a fresh sheet without
                the formulas.
              </li>
              <li>
                <strong>One tab per spreadsheet</strong> – Your spreadsheet
                should only have one tab containing data. If your spreadsheet
                has more than one tab, copy and paste the tabs into individual
                files and import them one at a time.
              </li>
              <li>
                <strong>No special symbols</strong> – Your spreadsheet can’t
                have symbols for numeric or monetary fields. For example, a
                column for deal value should just include the number “1000” and
                not the symbol “$1000”.
              </li>
              <li>
                <strong>Spreadsheet size limit</strong> – There is no maximum
                limit on the number of spreadsheet columns, but the maximum file
                size is 50MB, with a limit of 50,000 rows per spreadsheet.
              </li>
            </ul>{" "}
          </p>
          <h3>Mandatory fields</h3>
          <p>
            When importing data to <b>TransConnector</b> from a spreadsheet, include
            the mandatory fields for each item. Each mandatory field needs a
            separate column in your spreadsheet mapped to the corresponding
            field in <b>TransConnector</b>. <b>Note:</b> If you don’t import your data with
            the mandatory fields, it will create no items, and fields will be
            empty. 
          </p>
          <Table>
            <thead>
              <tr>
                <th>To import</th>
                <th>You need these mandatory fields</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Loading date</td>
                <td>date</td>
              </tr>
              <tr>
                <td>Unloading date</td>
                <td>date</td>
              </tr>
              <tr>
                <td>Loading Address</td>
                <td>address</td>
              </tr>
              <tr>
                <td>Unloading Address</td>
                <td>address</td>
              </tr>
              <tr>
                <td>Load Type</td>
                <td>loadType</td>
              </tr>
              <tr>
                <td>Load Weight</td>
                <td>weight</td>
              </tr>
              <tr>
                <td>Truck type</td>
                <td>truckType</td>
              </tr>
              <tr>
                <td>Price</td>
                <td>number</td>
              </tr>
            </tbody>
          </Table>
          <Banner />
        </Container>
      </Static>
    </Wrapper>
  );
};

export default LoadUpload;
