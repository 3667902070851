import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  SHOW_MESSAGE_WINDOW,
  SET_CURRENT_MESSAGE_WINDOW,
} from '../../../store/actionTypes';
import MessageList from './MessageList';
import MessageWindow from './MessageWindow';

import '../../../styles/Dashboard/Messages/Messages.css';
import LeftDrawer from '../Drawers/LeftDrawer';

const Messages = (props) => {
  // If user gets redirected with a certain message window id set it to state
  /*   const USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.activeSubscription
  ); */
  const showMessage = props.location.state?.showMessage;
  const { onShowMessageWindow, onSetCurrentMessageWindow } = props;
  useEffect(() => {
    if (showMessage) {
      onShowMessageWindow(true);
      onSetCurrentMessageWindow(showMessage);
    }
  }, [showMessage, onShowMessageWindow, onSetCurrentMessageWindow]);

  const { history } = props;
  /*   useEffect(() => {
    if (USER_PLAN !== 'undefined' && !USER_PLAN) history.push('/dashboard');
  }, [USER_PLAN, history]); */
  return (
    <>
      <div className="Messages">
        <div className="Messages--inner">
          <MessageList />
          {props.SHOW_MESSAGE_WINDOW && <MessageWindow />}
        </div>
      </div>
      <LeftDrawer small />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    SHOW_MESSAGE_WINDOW: state.dashboard.message.showMessageWindow,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessageWindow: (payload) =>
      dispatch({ type: SHOW_MESSAGE_WINDOW, payload: payload }),
    onSetCurrentMessageWindow: (payload) =>
      dispatch({ type: SET_CURRENT_MESSAGE_WINDOW, payload: payload }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Messages)
);
