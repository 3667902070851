import React from "react";
import { ButtonsWrapper, ButtonsInnerWrapper } from "./Styles";

const Buttons = (props) => {
  return (
    <ButtonsWrapper
      desktopOnly={props.desktopOnly}
      right={props.right}
      maxWidth={props.maxWidth}
    >
      <ButtonsInnerWrapper centered={props.centered} column={props.column}>
        {props.children}
      </ButtonsInnerWrapper>
    </ButtonsWrapper>
  );
};

export default Buttons;
