import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import Cookies from "universal-cookie";
import "../../styles/Register/Register.css";
import Header from "../FrontPage/Header/Header";
import RegisterHelper from "./RegisterHelper";
import RegisterStepOne from "./RegisterStepOne";
import RegisterStepTwo from "./RegisterStepTwo";
import RegisterStepThree from "./RegisterStepThree";

import { withRouter } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constants";
import { useSelector } from "react-redux";
import { getUser } from "../../store/actions/dashboard/user";
import { getCompany } from "../../store/actions/dashboard/company";

const DebtCollectRegister = (props) => {
  const COMPANY = useSelector((state) => state.dashboard.company?.data);
  const [pagination, setPagination] = useState(1);
  const [inputs, setInputs] = useState(
    {
      companyName: COMPANY?.companyName,
      vatNumber: COMPANY?.vatNumber,
      country: COMPANY?.country,
      address: COMPANY?.address,
    } || {}
  );
  const [errors, setErrors] = useState({});
  const [thanks, setThanks] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!COMPANY) {
      dispatch(getCompany());
      setInputs(
        {
          companyName: COMPANY?.companyName,
          vatNumber: COMPANY?.vatNumber,
          country: COMPANY?.country,
          address: COMPANY?.address,
        } || {}
      );
    }
  }, [dispatch]);

  // handle input changes

  const handleInputChange = (e) => {
    console.log(e.target.name);
    console.log(e.target.value);
    if (e.target.name === "accept") {
      setInputs({ ...inputs, [e.target.name]: e.target.checked });
    } else {
      setInputs({ ...inputs, [e.target.name]: e.target.value });
    }
    setErrors({ ...errors, [e.target.name]: null });
  };

  // handle next step click
  const handleStep1to2 = () => {
    // all fields are required
    let newErrors = {};

    if (!inputs.companyName) {
      newErrors.companyName = { msg: "Company name is required" };
    }
    if (!inputs.vatNumber) {
      newErrors.vatNumber = { msg: "VAT number is required" };
    }
    if (!inputs.country) {
      newErrors.country = { msg: "Country is required" };
    }
    if (!inputs.address) {
      newErrors.address = { msg: "Address is required" };
    }
    if (!inputs.postalCode) {
      newErrors.postalCode = { msg: "Postal code is required" };
    }
    if (!inputs.city) {
      newErrors.city = { msg: "City is required" };
    }
    if (!inputs.contactPerson) {
      newErrors.contactPerson = { msg: "Contact person is required" };
    }
    if (!inputs.contactEmail) {
      newErrors.contactEmail = { msg: "Contact email is required" };
    }
    if (!inputs.contactPhone) {
      newErrors.contactPhone = { msg: "Contact phone is required" };
    }
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setPagination(2);
    
    // setInputs({
    //   ...inputs,
    //   debtorCompanyName: inputs.companyName,
    //   debtorVatNumber: inputs.vatNumber,
    //   debtorCountry: inputs.country,
    //   debtorAddress: inputs.address,
    //   debtorPostalCode: inputs.postalCode,
    //   debtorCity: inputs.city,
    //   debtorContactPerson: inputs.contactPerson,
    //   debtorContactEmail: inputs.contactEmail,
    //   debtorContactPhone: inputs.contactPhone,
    // });
  };

  const handleStep2to3 = () => {
    let newErrors = {};
    if (!inputs.debtorCompanyName) {
      newErrors.debtorCompanyName = { msg: "Company name is required" };
    }
    if (!inputs.debtorVatNumber) {
      newErrors.debtorVatNumber = { msg: "VAT number is required" };
    }
    if (!inputs.debtorCountry) {
      newErrors.debtorCountry = { msg: "Country is required" };
    }
    if (!inputs.debtorAddress) {
      newErrors.debtorAddress = { msg: "Address is required" };
    }
    if (!inputs.debtorPostalCode) {
      newErrors.debtorPostalCode = { msg: "Postal code is required" };
    }
    if (!inputs.debtorCity) {
      newErrors.debtorCity = { msg: "City is required" };
    }
    if (!inputs.debtorContactPerson) {
      newErrors.debtorContactPerson = { msg: "Contact person is required" };
    }
    if (!inputs.debtorContactEmail) {
      newErrors.debtorContactEmail = { msg: "Contact email is required" };
    }
    if (!inputs.debtorContactPhone) {
      newErrors.debtorContactPhone = { msg: "Contact phone is required" };
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setPagination(3);
  };

  // handle form submit

  const submitHandler = (e) => {
    console.log(inputs);
    e.preventDefault();

    let newErrors = {};
    if (!inputs.acceptTC) {
      newErrors.acceptTC = { msg: "Please accept terms" };
    }
    if (!inputs.acceptPP) {
      newErrors.acceptPP = { msg: "Please accept privacy policy" };
    }
    if (!inputs.amount) {
      newErrors.amount = { msg: "Amount is required" };
    }
    if (!inputs.dueDate) {
      newErrors.dueDate = { msg: "Due date is required" };
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
      
    axios
      .post(`${API_URL}/debtCollect`, inputs)
      .then((res) => {})
      .catch((err) => {
        const errors = err.response.data.errors;
        setErrors(errors);
      });
    setThanks(true);
  };

  return (
    <>
      <Header />
      <div className="Register">
        {!thanks && <RegisterHelper pagination={pagination} />}{" "}
        {!thanks && (
          <div className="Register__container">
            <div className="Register--inner">
              <form onSubmit={(e) => submitHandler(e)}>
                <RegisterStepOne
                  pagination={pagination}
                  inputs={inputs}
                  errors={errors}
                  handleInputChange={handleInputChange}
                  handleNextStep={handleStep1to2}
                  setErrors={setErrors}
                  setInputs={setInputs}
                />
                <RegisterStepTwo
                  pagination={pagination}
                  inputs={inputs}
                  errors={errors}
                  setPagination={setPagination}
                  handleInputChange={handleInputChange}
                  handleNextStep={handleStep2to3}
                  setErrors={setErrors}
                  setInputs={setInputs}
                />
                <RegisterStepThree
                  pagination={pagination}
                  inputs={inputs}
                  errors={errors}
                  setPagination={setPagination}
                  handleInputChange={handleInputChange}
                  setErrors={setErrors}
                  setInputs={setInputs}
                />
              </form>
            </div>
          </div>
        )}
        {thanks && (
          <div className="Register__thanks">
            <h1>Thank you for your trust</h1>
            <h2>Our debt collection specialist will contact You soon.</h2>
            <Link to="/dashboard">&lt; Back to Transconnector</Link>
          </div>
        )}
      </div>
    </>
  );
};

export default withRouter(DebtCollectRegister);
