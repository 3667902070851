import React from "react";
import Header from "../components/FrontPage/Header/Header";
import Footer from "../components/Footer2/Footer";
import LeftDrawer from "../components/Dashboard/Drawers/LeftDrawer";
import DebtCollect from "./static/DebtCollect";
import ScrollToTopOnMount from "../helpers/ScrollToTop";

const DebtCollectPage = ({ USER }) => {
  return (
    <>
      <ScrollToTopOnMount />
      <Header landingPage={true} />
      <DebtCollect />
      <Footer />
      {USER && <LeftDrawer small />}
    </>
  );
};

export default DebtCollectPage;
