import React from "react";
import Header from "../components/FrontPage/Header/Header";
import FindRoundtrip from "../components/FindRoundtrip/FindRoundtrip";
import LeftDrawer from "../components/Dashboard/Drawers/LeftDrawer";
import Minimessages from "../components/Dashboard/Messages/Minimessages";
import { isBrowser } from "react-device-detect";

const FindRoundtripPage = ({ USER }) => {
  return (
    <>
      <Header />
      <FindRoundtrip />
      {USER && isBrowser && <Minimessages />}
      {USER && <LeftDrawer small />}
    </>
  );
};

export default FindRoundtripPage;
