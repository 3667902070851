import React, { useState, useEffect } from "react";
import client from "braintree-web/client";
import hostedFields from "braintree-web/hosted-fields";
import axios from "axios";
import { threeDSecure } from "braintree-web";

import Form from "../UI/Form";
import Loader from "../UI/Loader";
import iClose from "../../../assets/icons/drawer-close-mark.svg";
import "../../../styles/Dashboard/Subscription/CardDetails.css";
import { API_URL } from "../../../constants";
import Cookies from "universal-cookie";

const cookies = new Cookies(),
  tcToken = cookies.get("tc_token");

const CardDetails = (props) => {
  const [errors, setErrors] = useState({});
  const [loadingSubmission, setLoadingSubmission] = useState(false);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://js.braintreegateway.com/web/3.56.0/js/three-d-secure.min.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const form = document.querySelector("#cardDetailsForm");
    const submit = document.querySelector("#submit");
    //GET CLIENT TOKEN FROM EXPRESS SERVER
    axios
      .get(`${API_URL}/clientToken`, {
        headers: {
          Authorization: `Bearer ${tcToken}`,
        },
      })
      .then(function (res) {
        const clientToken = res.data;
        client.create(
          {
            authorization: clientToken,
          },
          function (clientErr, clientInstance) {
            if (clientErr) {
              return;
            }
            //Create 3d Secure instance
            threeDSecure.create(
              {
                version: 2,
                client: clientInstance,
              },
              function (threeDSecureErr, threeDSecureInstance) {
                if (threeDSecureErr) {
                  return;
                }
                const threeDSecure = threeDSecureInstance;

                // Create a hostedFields component to initialize the form
                hostedFields.create(
                  {
                    client: clientInstance,
                    // Customize the Hosted Fields.
                    styles: {
                      input: {
                        "font-size": "14px",
                        "font-family": "Inter, sans-serif",
                      },
                      "input.invalid": {
                        color: "#e13505",
                      },
                      "input.valid": {
                        color: "#3ad59b",
                      },
                      "::-webkit-input-placeholder": {
                        color: "#b0b0b5",
                      },
                      ":-moz-placeholder": {
                        color: "#b0b0b5",
                      },
                      "::-moz-placeholder": {
                        color: "#b0b0b5",
                      },
                      ":-ms-input-placeholder": {
                        color: "#b0b0b5",
                      },
                      ":focus": {
                        transition: "0.25s all ease",
                        outline: "none",
                        "box-shadow": "0 0 0 2px #a0d1fa",
                      },
                    },
                    // Configure which fields in your card form will be generated by Hosted Fields instead
                    fields: {
                      number: {
                        selector: "#card-number",
                        placeholder: "Card Number",
                      },
                      cvv: {
                        selector: "#cvv",
                        placeholder: "CVV",
                      },
                      expirationDate: {
                        selector: "#expiration-date",
                        placeholder: "Expiration Date",
                      },
                    },
                  },
                  function (hostedFieldsErr, instance) {
                    if (hostedFieldsErr) {
                      console.error(hostedFieldsErr);
                      return;
                    }

                    // Once the fields are initialized enable the submit button
                    submit.removeAttribute("disabled");

                    // Initialize the form submit event
                    form.addEventListener("submit", function (event) {
                      event.preventDefault();
                      // When the user clicks on the 'Submit payment' button this code will send the
                      // encrypted payment information in a variable called a payment method nonce
                      instance.tokenize(function (tokenizeErr, payload) {
                        // setError(false);
                        if (tokenizeErr) {
                          console.error(tokenizeErr);
                          return;
                        }
                        //GET USERDATA
                        const threeDSecureParameters = {
                          nonce: payload.nonce,
                          onLookupComplete: function (data, next) {
                            next();
                          },
                        };
                        //3D Secure Card verification

                        threeDSecure.verifyCard(
                          threeDSecureParameters,
                          function (err, res) {
                            if (err) {
                              return;
                            }
                            if (res.liabilityShifted === false) {
                              return;
                            }
                            const threeDnonce = res.nonce;
                            setLoadingSubmission(true);
                            setErrors({});
                            setSaved(false);
                            axios
                              .post(
                                `${API_URL}/updateCard`,
                                {
                                  nonce: threeDnonce,
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer ${tcToken}`,
                                  },
                                }
                              )
                              .then((res) => {
                                setLoadingSubmission(false);
                                setSaved(true);
                              })
                              .catch((err) => {
                                const errors = err.response.data;
                                setLoadingSubmission(false);
                                setSaved(false);
                                setErrors(errors);
                              });
                          }
                        );
                      }, false);
                    });
                  }
                );
              }
            );
          }
        );
      });
  }, []);
  return (
    <>
      <div className={`Table_Modal ${props.modal ? "show" : "hide"}`}>
        <div className="Table_Modal--inner">
          <div className="Table_Modal--header">
            <h2>Card Details</h2>
            <div
              className="Table_Modal--inner-close"
              onClick={() => props.setModal(!props.modal)}
            >
              <img src={iClose} alt="Close" />
            </div>
          </div>
          <div className="Table_Modal--body">
            <Form method="post" id="cardDetailsForm">
              {errors && errors.message ? (
                <div className="error">
                  <p style={{ fontSize: 14, color: "#e13505" }}>
                    {errors.message}
                  </p>
                </div>
              ) : null}
              <div className="input-collection">
                <div className="input-wrapper">
                  <h4>Cardholder name</h4>
                  <div className="input">
                    <input
                      type="text"
                      placeholder="Cardholder name"
                      id="cardName"
                    />
                  </div>
                </div>
              </div>
              <div className="input-collection">
                <div className="input-wrapper">
                  <h4>Card Number</h4>
                  <div className="input">
                    <div id="card-number"></div>
                  </div>
                </div>
              </div>
              <div className="input-collection">
                <div className="input-wrapper">
                  <h4>CVV</h4>
                  <div className="input">
                    <div id="cvv"></div>
                  </div>
                </div>
              </div>
              <div className="input-collection">
                <div className="input-wrapper">
                  <h4>Expiration Date</h4>
                  <div className="input">
                    <div id="expiration-date"></div>
                  </div>
                </div>
              </div>
              <div id="checkout-message"></div>
              <div className="submit">
                {!loadingSubmission ? (
                  <input
                    id="submit"
                    type="submit"
                    value={!saved ? "Save" : "Saved"}
                    className={saved ? "success" : ""}
                  />
                ) : (
                  <Loader />
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div
        className={`Table_Modal--close ${props.modal ? "show" : "hide"}`}
        onClick={() => props.setModal(!props.modal)}
      ></div>
    </>
  );
};
export default CardDetails;
