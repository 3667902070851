import React from "react";
import { isMobile } from "react-device-detect";

const RegisterHelper = (props) => {
  return (
    <div className="Register__helper">
      <div className="Register--titles">
        <div className="Register--titles--inner">
          <h1>Fill out the Form</h1>
          {isMobile ? (
            <div className="mobile-step">
              <div className={`step-one`}>1</div>
              <div className={`step-two`}>2</div>
              <div className={`step-three`}>3</div>
            </div>
          ) : (
            <p>Our experienced collections team starts
            their work, getting you paid fast</p>
          )}
        </div>
      </div>
      <div className="Register__steps">
        <div className={`Register--step ${props.pagination === 1 && "active"}`}>
          <div className="Register--step--inner">
            <h2>Step 1. Your Company</h2>
            <p>Company & Personal Info</p>
          </div>
        </div>
        <div className={`Register--step ${props.pagination === 2 && "active"}`}>
          <div className="Register--step--inner">
            <h2>Step 2. Debtor</h2>
            <p>Debtor Company & Personal Info</p>
          </div>
        </div>
        <div className={`Register--step ${props.pagination === 3 && "active"}`}>
          <div className="Register--step--inner">
            <h2>Step 3. Debt</h2>
            <p>Debt Info</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterHelper;
