import React from "react";
import { Link } from "react-router-dom";
import {
  TableRowItemWrapper,
  TableRowItemName,
  TableRowItemInline,
  HTML,
} from "./Styles";

const TableRowItem = (props) => {
  return (
    <TableRowItemWrapper
      flex={props.flex}
      sameLine={props.mobileSameLine}
      wrap={props.html && "1"}
      onlyMobile={props.onlyMobile}
      onlyDesktop={props.onlyDesktop}
      overflow={props.overflow && "1"}
    >
      {props.rowName && <h2>{props.rowName}</h2>}
      {props.icon || props.image || props.title ? (
        <TableRowItemInline alignItems={props.alignItems} transform={props.transform}>
          {props.icon && props.icon}
          {props.image && (
            <img src={props.image.url} alt={props.image.alt || ""} />
          )}
          {props.title && (
            <>
              {props.title?.name && props.title.to && (
                <Link
                  to={props.title.to}
                  target={props.title.targetBlank ? "_blank" : null}
                >
                  <TableRowItemName>{props.title.name}</TableRowItemName>
                </Link>
              )}
              {props.title?.name && props.title.href && (
                <a
                  href={props.title.href}
                  target={props.title.targetBlank ? "_blank" : null}
                >
                  <TableRowItemName>{props.title.name}</TableRowItemName>
                </a>
              )}
              {props.title?.name &&
                !props.title?.strong &&
                !props.title.to &&
                !props.title.href && (
                  <TableRowItemName {...props.title}>
                    <h4>{props.title.name}</h4>
                  </TableRowItemName>
                )}
              {props.title?.name && props.title?.strong && !props.title.to && (
                <TableRowItemName {...props.title}>
                  <strong>{props.title.name}</strong>
                </TableRowItemName>
              )}
            </>
          )}
        </TableRowItemInline>
      ) : null
      }
      {
        props.html && (
          <HTML padding dangerouslySetInnerHTML={{ __html: props.html }}></HTML>
        )
      }
      { props.children}
    </TableRowItemWrapper >
  );
};

export default TableRowItem;
