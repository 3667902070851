import styled from "styled-components";

export const Wrap = styled.div`
  background: #fff;
  padding: 15px 20px;

  .select-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-bottom: 24px;

    .react-select__value-container {
      padding: 0 1rem;
    }
  }

  .field-group {
    margin-bottom: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  .floating-label {
    position: relative;

    input {
      display: flex;
      width: 100%;
      padding: 1.35rem 1rem 1rem;
      border-radius: 4px;
      background: #fff;
      border: 1px solid #DEDEDE;
      font-size: 1rem;
      color: #000;
      transition: 0.25s all ease;

      &:focus,
      &:not(:placeholder-shown) {
        ~ label {
          top: 0.3rem;
          left: 1rem;
          font-size: 12px;
          transition: 0.25s all ease;
          color: #9a9a9a;
        }
      }

      &:focus {
        transition: 0.25s all ease;
        outline: none;
        box-shadow: 0 0 0 2px #a0d1fa;
      }

      &:disabled {
        background: #e7e7e7;
      }
    }

    label {
      color: #9a9a9a;
      transition: 0.25s all ease;
      position: absolute;
      left: 1rem;
      top: 1.25rem;
      pointer-events: none;
    }

    .error-message {
      top: -10px;
    }

    &.invalid {
      input {
        border-color: #d40000;

        &:focus {
          box-shadow: 0 0 0 2px #d40000;
        }
      }
    }
  }

  .dropdown-wrapper {
    width: 100%;
    position: relative;

    .error-message {
      top: -10px;
    }
  }

  .dropdown {
    width: 100%;

    .react-select__control--is-focused {
      box-shadow: 0 0 0 2px #a0d1fa;
    }

    &.invalid {
      .react-select__control {
        border-color: #d40000;

        &.react-select__control--is-focused {
          box-shadow: 0 0 0 2px #d40000;
        }
      }
    }
  }

  .dropdown > div {
    border-radius: 4px;
    padding: 0.6rem 0;
    border: 1px solid #DEDEDE;
  }
  
  @media (min-width: 768px) {
    .select-wrap {
      flex-direction: row;
    }
  }

  @media (min-width: 1400px) {
    padding: 36px 50px 50px;
  }
`;

export const ButtonIcon = styled.button`
  display: inline-flex;
  border: none;
  box-shadow: none;
  flex: 0 0 30px;
  outline: none;
  color: #262A68;
  background: transparent;

  .icon {
    width: 18px;
    height: 22px;
  }

  &:focus {
    color: #0f1540;
  }

  &:hover {
    color: #3a40a5;
  }
  
  @media (max-width: 768px) {
    .icon {
      transform: rotate(90deg);
    }
  }
`;

export const Submit = styled.button`
  text-align: center;
  background: #00B27E;
  outline: none;
  border: 2px solid #00B27E;
  padding: 10px;
  border-radius: 4px;
  min-height: 40px;
  text-transform: uppercase;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: 600;

  .icon {
    width: 9px;
  }

  &:hover {
    background: transparent;
    color: #00B27E;
  }

  &:focus {
    background: transparent;
    color: #007253;
    border-color: #007253;
  }

  @media (min-width: 578px) {
    padding: 14px;
    min-height: 52px;
  }
`;

export const InfoButton = styled.span`
  color: #0F1540;
  border-radius: 4px;
  background: transparent;
  border: 1px solid #0F1540;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 6px;
  width: 40px;
  height: 40px;

  @media (min-width: 578px) {
    width: 52px;
    height: 52px;
  }
`

export const FormActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
