import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TenderListHeader from "./TenderListHeader";
import TenderListItem from "./TenderListItem";
import Loader from "../../Dashboard/UI/Loader";
import UserNotice from "../../UserNotice/UserNotice";
import welcomeNoResults from "../../../assets/welcome/noResults.svg";
import {
  filterTruckTypes,
  filterLocations,
} from "../../../helpers/ListFiltering";
import { getFilterTenders } from "../../../store/actions/app/tender";
import ReviewModal from "../../Modals/ReviewModal/ReviewModal";
import AddReview from "../../Modals/ReviewModal/AddReview";
import { TextButton } from "../../Buttons/NewButton/Styles";

const TenderList = React.memo((props) => {
  const [tenders, setTenders] = useState([]);
  const [hasSetAllTenders, setHasSetAllTenders] = useState(false);
  const { USER, COMPANY } = props;
  const [isExpired, setIsExpired] = useState(false);
  const [reviewModal, setReviewModal] = useState(null);
  const [addReviewModal, setAddReviewModal] = useState(null);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const dispatch = useDispatch();
  const USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.status
  );

  // Tender list filtering
  const {
    loading,
    loadingradius,
    unloading,
    unloadingradius,
    truckType,
    typeOfLoad,
  } = props.filters;
  const { setAllTenders } = props;

  const fetchTenders = (newSkip = skip, reset = false) => {
    let didCancel = false;
    const truckTypes = filterTruckTypes(truckType);
    const params = filterLocations(
      loading,
      loadingradius,
      unloading,
      unloadingradius
    );
  
    dispatch(
      getFilterTenders({
        loadinglocation: params.loading.value,
        loadingradius: params.loading.radius,
        unloadinglocation: params.unloading.value,
        unloadingradius: params.unloading.radius,
        trucktype: truckTypes,
        typeofload: typeOfLoad,
        limit: limit,
        skip: reset ? 0 : newSkip,
      })
    ).then((res) => {
      if (!didCancel) {
        res.data?.length > 0
          ? props.setTenderCount(res.data?.length)
          : props.setTenderCount(0);
  
        if (res.data.success === false || res.data?.length <= 0) {
          setTenders(null);
        } else {
          // const result = res.data.filter((e) => e.status).reverse();
          const result = res.data;
          if (reset) {
            setTenders(result);
          } else {
            setTenders((prevTenders) => [...prevTenders, ...result]);
          }
          setHasMore(result.length === limit);
          const hasNoMore = result.length < limit;
          if (!hasSetAllTenders && !hasNoMore) {
            setAllTenders([...tenders, ...result]);
          }
          if (hasNoMore) {
            setAllTenders([...tenders, ...result]);
            setHasSetAllTenders(true);
          }
        }
      }
    });
    return () => (didCancel = true);
  };

  useEffect(() => {
    if (props.filters) {
      fetchTenders(0, true);
      setSkip(0);
    }
  }, [
    props.filters,
    loading,
    loadingradius,
    unloading,
    unloadingradius,
    truckType,
    typeOfLoad,
  ]);

  const loadMore = () => {
    const newSkip = skip + limit;
    setSkip(newSkip);
    fetchTenders(newSkip);
  };

  // Set subscription barrier
  useEffect(() => {
    if (USER_PLAN && (USER_PLAN === "Expired" || USER_PLAN === "Cancelled"))
      setIsExpired(true);
  }, [USER_PLAN]);

  // Add Sheet ID to localstorage to display viewed tenders
  useEffect(() => {
    if (props.sheetID && USER && !isExpired) {
      const viewedTenders = localStorage.hasOwnProperty("viewedTenders")
        ? JSON.parse(localStorage.getItem("viewedTenders"))
        : [];
      if (!viewedTenders.includes(props.sheetID)) {
        viewedTenders.push(props.sheetID);
        localStorage.setItem("viewedTenders", JSON.stringify(viewedTenders));
      }
    }
  }, [props.sheetID, USER, isExpired]);

  return (
    <TenderListHeader USER={USER} COMPANY={COMPANY}>
      {tenders?.length > 0
        ? tenders?.map((tender) => {
            return (
              <TenderListItem
                key={tender._id}
                tender={tender}
                sheetID={props.sheetID === tender._id ? props.sheetID : null}
                setSheetID={props.setSheetID}
                USER={USER}
                COMPANY={COMPANY}
                setReviewModal={setReviewModal}
                viewed={
                  localStorage.hasOwnProperty("viewedTenders") &&
                  localStorage.getItem("viewedTenders").includes(tender._id)
                }
              />
            );
          })
        : tenders && <Loader />}
      {!tenders && (
        <UserNotice
          image={welcomeNoResults}
          title="Unfortunately no tenders were found. Try again with different filters or clear all filters with the button below."
          buttons={[
            {
              name: "Clear filters",
              isButton: true,
              onClick: props.clearFilters,
            },
          ]}
        />
      )}
      {hasMore && (
        <div style={{ textAlign: "center", marginTop: "1rem", width: "100%" }}>
        <TextButton onClick={loadMore}>Load more</TextButton>
        </div>
      )}
      {reviewModal && (
        <ReviewModal
          showHeader
          fetchReviews
          id={reviewModal}
          onClose={() => setReviewModal(null)}
          onClick={() => {
            setAddReviewModal(reviewModal);
            setReviewModal(null);
          }}
        />
      )}
      {!reviewModal && addReviewModal && (
        <AddReview
          name={reviewModal?.company?.companyName}
          id={addReviewModal}
          onClose={() => setAddReviewModal(false)}
        />
      )}
    </TenderListHeader>
  );
});

export default TenderList;