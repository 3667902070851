import styled, { css } from "styled-components";

export const Static = styled.div`
  padding: 7rem 0;
  p {
    font-size: 16px;
    line-height: 2;
    width: 80%;
    margin-bottom: 4.5%;
    @media (max-width: 992px) {
      width: 100%;
      margin-bottom: 2rem;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  h1 {
    font-size: 22px;
    color: #000000;
    line-height: 1.4;
  }
  h3, h2 {
    color: #272A64;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  h5 {
    color: #7a7a7a;
    font-size: 18px;
    line-height: 1.4;
    font-weight: 400;
    margin: 0;
    margin-bottom: 1rem;
  }
  @media (min-width: 992px) {
    padding-left: 1rem;
    padding-right: 1rem;
    h1 {
      font-size: 28px;
      max-width: 75%;
    }
    h5 {
      margin-bottom: 2rem;
    }
  }
  @media (min-width: 1200px) {
    padding-top: 4rem;
    padding-bottom: 10rem;
    h1 {
      font-size: 36px;
      max-width: 60%;
    }
  }
`;
export const Background = styled.div`
  background: ${({ background }) => background && background};
  padding: ${({ padding }) => padding && padding};
`;
export const AboutList = styled.div`
  h2 {
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    margin-top: 0;
  }
  h3 {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
  }
`;
export const AboutListContent = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;
  p {
    color: #000000;
    line-height: 1.5;
    margin: 1rem 0;
    max-width: 630px;
  }
  @media (min-width: 992px) {
    p {
      font-size: 16px;
      margin: 0;
      margin-bottom: 2rem;
    }
  }
  @media (min-width: 1200px) {
    p {
      margin-bottom: 3rem;
    }
  }
`;
export const AboutListItem = styled.div`
  margin: 1.5rem 0;
  &:nth-child(2),
  &:nth-child(3) {
    ${AboutListContent} {
      border-bottom: 1px solid #707070;
    }
  }
  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
  }
`;
export const AboutListTitle = styled.div`
  padding: 1rem 2rem;
  background: #ffffff;
  font-size: 18px;
  color: #ef4900;
  font-weight: 600;
  border-radius: 6px;
  box-shadow: 0 3px 15px rgba(102, 156, 210, 0.19);
  width: max-content;
  height: min-content;
  display: flex;
  margin: 2rem auto;
  justify-content: center;
  @media (min-width: 992px) {
    width: 212px;
    min-width: 212px;
    max-width: 212px;
    margin-right: 2rem;
    font-size: 20px;
    margin-left: 0;
  }
  @media (min-width: 1200px) {
    margin-right: 4rem;
    width: 241px;
    min-width: 241px;
    max-width: 241px;
    font-size: 24px;
  }
  @media (min-width: 1440px) {
    margin-right: 5rem;
  }
`;
export const CTA = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem 0;
  h4 {
    font-size: 1rem;
    color: #262a68;
    text-align: center;
    font-weight: 400;
    line-height: 1.4;
  }
  h3 {
    color: #363636;
    font-size: 14px;
    text-align: center;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 1.5rem;
  }
  @media (min-width: 992px) {
    h4 {
      font-size: 20px;
      margin-bottom: 0.5rem;
    }
    h3 {
      font-size: 18px;
      margin-bottom: 3rem;
    }
  }
  @media (min-width: 1200px) {
    h4 {
      font-size: 28px;
    }
    h3 {
      font-size: 20px;
    }
  }
`;

export const FAQList = styled.section``;
export const FAQInfo = styled.div`
  max-height: 0;
  overflow: hidden;
  p {
    margin: 0;
    line-height: 1.6;
    font-size: 14px;
    color: #000000;
    margin: 1rem 2rem;
  }
  a {
    color: #ef4900;
  }
  @media (min-width: 992px) {
    p {
      max-width: 80%;
      line-height: 1.7;
      margin: 2rem;
      margin-top: 1rem;
    }
  }
`;
export const FAQItem = styled.div`
  background: #ffffff;
  box-shadow: 0 3px 12px rgba(159, 184, 220, 0.16);
  border-radius: 8px;
  margin-bottom: 2rem;
  ${({ active }) =>
    active &&
    css`
      ${FAQInfo} {
        max-height: 9999px;
      }
    `}
`;
export const FAQButton = styled.button`
  background: none;
  border: none;
  width: 100%;
  font-family: "Inter";
  font-weight: 700;
  color: #262a68;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  text-align: left;
  padding: 2rem 1.5rem;
`;

export const TermsList = styled.section`
  h3 {
    color: #262a68;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 2rem 0;
  }
  ul {
    margin: 1.5rem 0;
    padding: 0;
    li {
      padding: 0.5rem 0;
      list-style-type: disc;
      line-height: 1.5;
      color: #000000;
      font-size: 14px;
    }
  }
  a,
  p {
    color: #000000;
  }
  p {
    line-height: 1.5;
    font-size: 14px;
  }
  @media (min-width: 992px) {
    h3 {
      font-size: 24px;
    }
    ul {
      li {
        line-height: 1.6;
      }
    }
    p {
      line-height: 1.6;
    }
  }
`;

export const PrivacyList = styled.section`
  h3 {
    margin: 2rem 0;
    color: #262a68;
    font-size: 18px;
  }
  h4 {
    margin: 1.5rem 0;
    font-size: 16px;
    color: #262a68;
  }
  p {
    line-height: 1.6;
    font-size: 14px;
  }
  ul {
    margin: 1rem 0;
    padding: 0;
    li {
      font-size: 14px;
      list-style-type: none;
      padding: 0.5rem 0;
    }
  }
`;

export const FreeTrialList = styled.div`
  max-width: 400px;
  margin-bottom: 3rem;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      display: flex;
      justify-content: space-between;
      font-weight: 700;
      padding: 1rem 0;
      border-bottom: 1px solid rgba(147, 150, 170, 0.18);
      &:first-child {
        color: #686a7d;
      }
    }
  }
`;
export const FreeTrialContent = styled.div`
  margin-bottom: 2.5rem;
  a {
    color: #000;
  }
  p {
    line-height: 1.5;
    margin: 1.5rem 0;
  }
`;

export const MoreInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  margin: 4rem auto;
  h3 {
    color: #262a68;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 700;
    font-size: 24px;
  }
  input {
    padding: 1.5rem;
    border: 1px solid #686a7d;
    border-radius: 3px;
    width: 100%;
    background: #fff;
    font-family: "Inter";
    font-size: 16px;
    &::placeholder {
      color: #686a7d;
    }
    &[type="submit"] {
      background: #00ca8f;
      color: #fff;
      padding: 1rem;
      max-width: 135px;
      margin: 1.5rem auto;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
`;

export const HowDoesItWork = styled.div`
  margin: 1.5rem 0 4rem 0;
  h3 {
    color: #262a68;
    display: flex;
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 24px;
  }
`;

export const HowDoesItWorkList = styled.ol`
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;
export const HowDoesItWorkListItem = styled.li`
  padding: 1rem 0 1rem 1rem;
  line-height: 1.4;
  font-size: 16px;
  color: #000;
  font-family: "Inter";
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: -0.5rem;
    top: 1.5rem;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #00ca8f;
  }
`;

//factoring page

export const Factoring = styled.div`
  padding: 7rem 1rem;
  h1 {
    font-size: 24px;
    color: #000000;
    line-height: 1.4;
    max-width: 80%;
  }
  h2 {
    font-size: 20px;
    color: #000000;
    line-height: 1.4;
    max-width: 100%;
  }
  h3 {
    font-size: 18px;
    color: #000000;
    line-height: 1.4;
    max-width: 100%;
  }
  h5 {
    color: #7a7a7a;
    font-size: 18px;
    line-height: 1.4;
    font-weight: 400;
    margin: 0;
    margin-bottom: 1rem;
  }

  @media (min-width: 992px) {
    padding-left: 1rem;
    padding-right: 1rem;
    h1 {
      font-size: 28px;
      max-width: 75%;
    }
    h5 {
      margin-bottom: 2rem;
    }
  }
  @media (min-width: 1200px) {
    padding-top: 4rem;
    padding-bottom: 10rem;
    h1 {
      font-size: 36px;
      max-width: 80%;
    }
    h2 {
      font-size: 24px;
      max-width: 70%;
    }
  }
`;

export const LogoSection = styled.div`
  height: 6rem;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
export const Logo = styled.img`
  height: 5rem;
  &:nth-child(2) {
    height: 7rem;
  }
  &:nth-child(1) {
    display: none;
    @media (min-width: 992px) {
      display: flex;
    }
  }
  @media (max-width: 992px) {
    height: 6rem;
    margin-top: 1.5rem;
  }
`;

export const TitleSection = styled.div`
  margin-top: 4rem;
  margin-bottom: 3rem;
  width: 100%;
`;

export const JITpay = styled.div`
  @media (min-width: 992px) {
    color: #00aa13;
    margin-bottom: 5rem;
    .line {
      background: #ee7f4b;
      height: 0.15rem;
      width: 60%;
      transform: translateX(-110px);
    }
  }
  color: #000000;
`;

export const InfoSection = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-top: 1rem;
  @media (max-width: 992px) {
    flex-direction: column;
    margin-bottom: 2rem;
    margin-left: 0;
  }
  li {
    font-size: 18px;
    margin-top: 2rem;
  }
`;
export const CostListContent = styled.div`
  margin: 0 auto;
  padding-bottom: 1rem;
  width: 100%;
  p {
    color: #000000;
    line-height: 1.5;
    margin: 1rem 0;
    max-width: 630px;
  }
  @media (min-width: 992px) {
    margin: 1rem 0;
    padding-bottom: 1rem;
    width: 60%;
    p {
      font-size: 16px;
      margin: 0;
      margin-bottom: 1rem;
    }
  }
  @media (min-width: 1200px) {
    p {
      margin-bottom: 1.5rem;
    }
  }
`;
export const CostListItem = styled.div`
  margin-top: 1.5rem 0;
  .bordered {
    border-bottom: 1px solid #707070;
  }

  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
  }
`;
export const ButtonDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 14rem;
  height: 4rem;
  border-radius: 0.5rem;
  border: none;
  background: #00aa13;
  color: white;
  font-size: 22px;
  font-family: "Inter";
  font-weight: 600;
`;

//VAT refund page

export const Refund = styled.div`
  padding: 6rem 1rem 3rem;
  @media (max-width: 992px) {
    padding: 6rem, 1rem;
  }
`;

export const TitleContent = styled.div`
  p {
    font-size: 18px;
    line-height: 2;
    color: gray;
    width: 80%;
    margin-bottom: 7.5%;
    @media (max-width: 992px) {
      width: 100%;
      margin-bottom: 3rem;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  h1 {
    font-size: 36px;
    color: #ef4900;
    line-height: 1.4;
    max-width: 80%;
    span {
      color: navy;
    }
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }
`;

export const BackgroundDiv = styled.div`
  position: absolute;
  width: 100vw;
  background: #fbfdff;
  /* background: teal; */
  height: 35rem;
  z-index: -1;
  top: 27.5rem;
  @media (max-width: 992px) {
    background: #fbfdff;
    /* background: teal; */
    height: 100rem;
    top: 27.5rem;
  }
`;
export const Bullet = styled.span`
  @media (min-width: 992px) {
    display: none;
  }
`;
export const SuccessToast = styled.div`
  margin-top: 2rem;
  width: 120%;
  padding: 25px;
  padding-bottom: 120px;
  height: 65px;
  /* background: linear-gradient(#00b27e, white 99%); */

  border: 1px solid #00b27e;
  border-radius: 5px;
  h3 {
    text-align: center;
    margin-bottom: 1rem;
    color: black;
    font-family: inter;
    font-weight: normal;
  }
`;
// export const InsuranceLogo = styled.img`
//   width: 480px;
//   opacity: 0.3;
//   position: absolute;
//   right: 10%;
//   top: 50%;
// `;
export const InsuranceLogo = styled.div`
  width: 320px;
  margin-top: 20%;
  margin-right: 40%;
  z-index: -1;
  @media (max-width: 992px) {
    display: none;
  }
`;
export const InsuranceMobileLogo = styled.div`
  position: static;
  width: 70%;
  margin: 0rem auto 0;
  @media (min-width: 992px) {
    display: none;
  }
`;
export const MainPara = styled.p`
  line-height: 1.5rem;
  text-align: justify;
  @media (min-width: 992px) {
    width: 80%;
    margin: 0rem;
  }
`;

export const ListSection = styled.section`
  margin-top: 7.5rem;
  @media (max-width: 992px) {
    width: 100%;
    padding: 0;
    margin-top: 4rem;
  }
  ul {
    @media (max-width: 992px) {
      width: 100%;
      padding: 0;
    }
  }
  li {
    font-size: 16px;
    font-weight: normal;
    padding: 1rem;
    line-height: 1.5rem;
    @media (max-width: 992px) {
      font-weight: normal;
      list-style: none;
    }
  }
`;

export const EurowagLogo = styled.span`
  position: absolute;
  width: 12%;
  right: 15vw;

  @media (max-width: 992px) {
    display: none;
  }
`;
export const EurowagMobileLogo = styled.div`
  position: static;
  width: 70%;
  margin: 0rem auto 0;
  @media (min-width: 992px) {
    display: none;
  }
`;
export const ArrowDiv = styled.div`
  position: absolute;
  z-index: -1;
  opacity: 20%;
  transform: rotate(180deg);
  top: 32.5rem;
  right: 17.5rem;
  @media (max-width: 992px) {
    display: none;
  }
`;
export const ArrowDiv2 = styled.div`
  position: absolute;
  opacity: 20%;
  left: 27.5rem;
  z-index: -1;
  top: 60rem;
  @media (max-width: 992px) {
    display: flex;
    top: 26rem;
    left: 2rem;
    opacity: 20%;
    height: 50px;
    width: 90px;
  }
`;
export const VATbutton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 14rem;
  height: 3.5rem;
  border-radius: 0.5rem;
  border: none;
  background: #00b27e;
  color: white;
  font-size: 22px;
  font-family: "Inter";
  margin-bottom: 2rem;
  :hover {
    cursor: pointer;
  }
  :disabled {
    background: #86868643;
  }
  @media (max-width: 992px) {
    margin-bottom: 7rem;
  }
`;

//Eurowag Fuel page

export const EurowagPage = styled.div`
  padding: 6rem 1rem;
  margin: 0 auto;
  @media (max-width: 769px) {
    max-width: 100vw;
  }
`;

export const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListContainer = styled.div`
  width: 80%;
  ul {
    padding: 1rem 1rem;
    font-family: "Inter";
    li {
      margin-bottom: 1.5rem;
    }
  }
`;
export const CardContainer = styled.div`
  position: absolute;
  z-index: -3;
  right: 100px;
  /* bottom: -2.5rem; */
  width: 30%;
  @media (max-width: 992px) {
    display: none;
  }
`;
export const MobileCardContainer = styled.div`
  position: static;
  width: 100%;
  margin: 0rem auto 0;
  @media (min-width: 992px) {
    display: none;
  }
`;
//DesktopTable
export const DesktopTableContainer = styled.div`
  margin: 4rem auto 0;
  padding: 2rem 3rem;
  width: 90%;
  background-color: #fbfdff;
  border-radius: 20px;
  thead {
    font-weight: medium;
    th {
      padding: 2rem;
      border-left: 1px solid rgba(104, 106, 125, 0.2);
      color: #686a7d;
    }
  }
  th {
    padding: 2rem 0;
    font-weight: normal;
    text-align: left;
    width: 25%;
    color: #262a68;
  }
  td {
    padding: 2rem 2rem;
    border-left: 1px solid rgba(104, 106, 125, 0.2);
  }
  @media (max-width: 769px) {
    display: none;
  }
`;

//MobileTable

export const MobileTableContainer = styled.div`
  margin: 0rem auto 0rem;
  padding: 1rem 1.5rem;
  width: 100%;
  table {
    background-color: #fbfdff;
    border-radius: 20px;
    margin-bottom: 2rem;
  }
  thead {
    font-weight: medium;
    th {
      padding: 2rem;
      text-align: center;
      color: #686a7d;
    }
  }
  th {
    padding: 2rem 1rem;
    font-weight: normal;
    text-align: left;
    width: 50%;
    color: #262a68;
  }
  td {
    width: 50%;
    padding: 2rem 0rem;
  }
  @media (min-width: 769px) {
    display: none;
  }
`;
export const ArrowContainer = styled.div`
  position: absolute;
  display: flex;
  z-index: -1;
  img {
    opacity: 0.18;
  }
`;

export const Img = styled.img`
  @media (max-width: 769px) {
    display: none;
  }
`;

/// form
export const FormContainer = styled.div`
  width: 80%;
  margin: 3.5rem auto;
  @media (max-width: 769px) {
    width: 100%;
    margin-top: 5rem;
  }
`;

export const FormWrapperInner = styled.div`
  background: #fff;
  max-width: 600px;
  margin: 0 auto;
  @media (min-width: 768px) {
    padding-bottom: 0;
    border-radius: 5px;
    border: 1px solid #eee;
    padding: 2rem;
    padding-top: 4rem;
    box-shadow: 0px 3px 27px 0px rgba(151, 180, 203, 0.16);
  }
`;
export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  margin: 0 auto;
  padding: 0rem 3rem;

  @media (max-width: 769px) {
    width: 100%;
    padding: 0rem 1rem;
  }
  input,
  select,
  textarea {
    font-size: 100%;
  }

  input {
    height: 40px;
    border: 1px solid #9aabc2;
    border-radius: 5px;
    padding-left: 10px;
    font-size: 16;
    ::placeholder {
      color: #686a7d;
      opacity: 0.7;
    }
    @media (max-width: 769px) {
      height: 35px;
      min-width: 140px;
      max-width: 140px;
    }
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  label {
    @media (max-width: 769px) {
      font-size: 16px;
    }
    padding-right: 20px;
  }
`;

export const SelectContainer = styled.div`
  max-width: 209px;
  width: 100%;
  @media (max-width: 769px) {
    height: 35px;
    min-width: 140px;
    max-width: 140px;
  }
`;

export const selectStyles = {
  container: (provided) => ({
    ...provided,
    width: "auto",

    "&.invalid": {
      ".styled__control": {
        border: "1px solid red",
      },
    },
    "@media (max-width: 350px)": {
      minWidth: "100%",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    paddingLeft: "0.2rem",
    border: "1px solid #9aabc2",
    borderRadius: 6,
    boxShadow: state.isFocused ? "0 0 0 2px #a0d1fa" : "none",
    maxHeight: "40px",
    width: "100%",
    "&:hover": {
      borderColor: "#9aabc2",
    },
    "@media (min-width: 1200px)": {
      marginBottom: "0",
      maxWidth: 250,
    },
  }),
  input: (provided) => ({
    ...provided,
    fontSize: "16px",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "16px",
    fontFamily: "Inter",
    opacity: "0.7",
    color: "#686a7d",
  }),

  valueContainer: (provided) => ({
    ...provided,
    height: "40px",
    padding: "0px",
    paddingLeft: "8px",
  }),

  dropdownIndicator: (provided) => ({
    ...provided,

    svg: {
      fill: "#686a7d",
    },
  }),

  singleValue: (provided) => ({
    ...provided,
    color: "#0C0C0C",
    fontSize: "16px",
    fontFamily: "Inter",
    "@media (max-width: 768px)": {
      paddingLeft: "8px",
    },
    "@media (min-width: 768px)": {
      marginTop: "4px",
    },
  }),
};
