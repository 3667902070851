import React from 'react';
import { Link } from 'react-router-dom';
import Container from '../UI/Container';
import {
  Static,
  FreeTrialList,
  FreeTrialContent,
} from '../../pages/static/Styles';
import LinkButton from '../../components/Buttons/NewButton/LinkButton';

const FreeTrial = () => {
  return (
    <Container gutter="large">
      <Static>
        <h1>About Free Trial</h1>
        <FreeTrialList>
          <ul>
            <li>
              Feature <span>Limit</span>
            </li>
            <li>
              Sent messages <span>10</span>
            </li>
            <li>
              Tenders <span>5</span>
            </li>
          </ul>
        </FreeTrialList>
        <FreeTrialContent>
          <p>
            During the free trial period you can send out total of 10 different
            messages.
          </p>
          <p>Within free trial period you can also create 5 tender offers.</p>
          <p>
            To start your free trial, simply go to{' '}
            <Link to="/sign-up">Sign Up.</Link> You won't be charged during your
            free trial and no credit card is needed when registering. We'll
            remind you when your free trial is about to end.
          </p>
          <p>
            If you choose to continue using Transconnector after your trial
            ends, you can find a suitable plan <Link to="/pricing"></Link>
            here. You can also contact directly with our sales team at{' '}
            <a href="mailto:info@transconnector.eu">info@transconnector.eu</a>
          </p>
        </FreeTrialContent>
        <LinkButton to="/sign-up">Start your free trial</LinkButton>
      </Static>
    </Container>
  );
};

export default FreeTrial;
