import React, {memo} from "react";
import {Tab, Tabs} from "./Styles";
import {BridgeIcon, ShipIcon, TunnelIcon} from "./icons";

const tabs = [
 {name: 'ferries', icon: ShipIcon},
 {name: 'bridges', icon: BridgeIcon},
 {name: 'tunnels', icon: TunnelIcon},
]

const ReservationTabs = memo(({setFieldValue, activeTab, setFieldTouched}) => {
 const onSelectTab = (val) => () => {
  setFieldValue('bookingType', val);
  setFieldValue('from', '');
  setFieldValue('to', '');
  setFieldTouched('from', false);
  setFieldTouched('to', false);
 }
 return (
   <Tabs>
    {tabs.map(({name, icon: Icon}) => (
      <Tab type="button" onClick={onSelectTab(name)} key={name} className={`${activeTab === name ? 'active' : ''} flex-fill`}>
       <Icon className="icon"/>
       {name}
      </Tab>
    ))}
   </Tabs>
 );
})

export default ReservationTabs;
