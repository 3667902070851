import React from 'react';
import { Link } from 'react-router-dom';

import { Post, ContentWrapper, Arrow } from './Styles';

const BlogPost = (props) => {
  return (
    <Post>
      <Link to={props.to}>
        <img src={props.img} alt="" />
        <ContentWrapper>
          <h3>{props.title}</h3>
          <p>{props.content}</p>
          <span>{props.date}</span>
          <Arrow>{arrow}</Arrow>
        </ContentWrapper>
      </Link>
    </Post>
  );
};

const arrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.837"
    height="18.359"
    viewBox="0 0 18.837 18.359"
  >
    <path
      d="M8.009,3.877l.933-.933a1,1,0,0,1,1.425,0l8.173,8.169a1,1,0,0,1,0,1.425l-8.173,8.173a1,1,0,0,1-1.425,0l-.933-.933a1.01,1.01,0,0,1,.017-1.442l5.066-4.826H1.009A1.007,1.007,0,0,1,0,12.5V11.154a1.007,1.007,0,0,1,1.009-1.009H13.092L8.026,5.319A1,1,0,0,1,8.009,3.877Z"
      transform="translate(0 -2.647)"
      fill="#fff"
    />
  </svg>
);

export default BlogPost;
