import styled, { css } from "styled-components";

export const ShowWrapper = styled.div`
  ${(props) =>
    !props.show &&
    css`
      display: none;
    `}
`;

export const SheetInner = styled.div``;

export const SheetHeaderImagePlaceholder = styled.div`
  width: 35px;
  height: 35px;
  min-width: 35px;
  margin-right: 1rem;
  border-radius: 50%;
  background: #eee;
`;

export const SheetHeaderNamePlaceholder = styled.div`
  height: 20px;
  width: 100%;
  border-radius: 8px;
  background: #eee;
`;

export const SheetHeader = styled.div`
  /*  
position: fixed;
  top: 0;
  left: 0;
  */
  height: 60px;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  display: inline-flex;
  align-items: center;
  background: #fff;
  z-index: 1;
  a {
    display: inline-flex;
    width: 100%;
    align-items: center;
    text-decoration: none;
    height: 100%;
    max-width: calc(100% - 120px);
  }
  img {
    width: 35px;
    height: 35px;
    margin-right: 1rem;
    border-radius: 50%;
    object-fit: contain;
  }
  h3 {
    margin: 0;
    font-size: 14px;
    color: #050720;
    font-weight: 700;
    width: calc(100% - 60px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (min-width: 1200px) {
    left: unset;
    right: 0;
    max-width: 400px;
  }
  ${(props) =>
    props.size === "small" &&
    css`
      @media (min-width: 1200px) {
        max-width: 320px;
        width: 320px;
        a {
          padding-left: 1rem;
        }
        ${SheetHeaderImagePlaceholder} {
          margin-left: 1rem;
        }
      }
    `}
`;

export const SheetWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 40;
  @media (min-width: 1200px) {
    top: 0;
    right: 0;
    bottom: unset;
    left: unset;
    max-width: 400px;
    box-shadow: 0 -10px 30px 0 rgba(154, 171, 194, 0.5);
  }
  ${(props) =>
    props.size === "small" &&
    css`
      @media (min-width: 1200px) {
        width: 320px;
        max-width: 320px;
        box-shadow: 0px -10px 15px 0px rgba(154, 171, 194, 0.3);
        ${SheetHeader} {
          a {
            max-width: calc(100% - 60px);
          }
        }
      }
    `}
  ${(props) =>
    props.denyActions &&
    css`
      ${SheetInnerWrapper} {
        height: 100vh;
      }
    `}
`;

export const BackWrapper = styled.div`
  padding: 1rem;
  height: 60px;
  width: 60px;
  min-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.desktopHide &&
    css`
      @media (min-width: 1200px) {
        display: none;
      }
    `}
`;

export const Back = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  svg {
    width: 20px;
    height: 20px;
  }
  &:hover {
    cursor: pointer;
    &:before {
      transform: translate(-50%, -50%) scale(1);
      transition: 0.25s all ease;
    }
  }
  &:before {
    content: "";
    position: absolute;
    width: 35px;
    height: 35px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 0.25s all ease;
    border-radius: calc(35px / 2);
    background: #f4f4f4;
    z-index: -1;
  }
`;

export const FavouriteWrapper = styled.div`
  width: 60px;
  min-width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: lightgoldenrodyellow;
`;

export const FavouriteTooltip = styled.div`
  display: none;
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
  h6 {
    margin: 0;
    font-size: 12px;
    white-space: nowrap;
    padding: 0.5rem;
    color: #fff;
    font-weight: 400;
  }
  &:before {
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #3b3b3b;
    opacity: 0.9;
    border-radius: 3px;
  }
  &:after {
    content: "";
    position: absolute;
    right: -7.5px;
    top: 8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7.5px 0 7.5px 7.5px;
    border-color: transparent transparent transparent #3b3b3b;
    opacity: 0.9;
  }
`;

export const Favourite = styled.div`
  &:hover {
    cursor: pointer;
  }
  svg {
    width: 24px;
    height: 24px;
  }
  @media (min-width: 1200px) {
    &:hover {
      transform: scale(1.15);
      + ${FavouriteTooltip} {
        display: block;
      }
    }
  }
  ${(props) =>
    props.isFavourited &&
    css`
      svg path {
        fill: #ffbc1a;
      }
    `}
`;

export const SheetBlockWrapper = styled.div`
  padding: 1rem;
`;

export const SheetInnerWrapper = styled.div`
  /*padding-top: 60px; */
  height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;
  position: relative;
  &::-webkit-scrollbar-track {
    background-color: #fbfbfc;
  }
  &::-webkit-scrollbar {
    background-color: #eaeaea;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c5c6d3;
    border: 4px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }
  ${SheetBlockWrapper}:not(:last-of-type) {
    margin-bottom: 1rem;
    border-bottom: 1px solid #889dbd;
  }
  ${(props) =>
    props.iOS &&
    css`
      padding-bottom: 120px;
    `}
`;

export const SheetItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1.5rem 0;
  h3 {
    padding-left: 0.25rem;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  h4 {
    color: #050720;
    margin: 0;
    padding: 0.5rem 0;
    padding-left: 1rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
  }
`;
export const SheetItemInline = styled.div`
  display: inline-flex;
  width: 100%;
  > ${SheetItem} {
    h3,
    h4 {
      text-align: center;
      padding: 0;
    }
  }
  ${(props) =>
    props.inlineRow &&
    css`
      flex-wrap: wrap;
      > ${SheetItem} {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 50%;
        padding-left: 0.25rem;
        h3 {
          margin: 0;
          margin-right: 1rem;
        }
      }
    `}
`;

export const SheetTabsWrapper = styled.div`
  position: absolute;
  top: calc(0.5rem);
  right: 1rem;
  svg {
    margin-right: 0.5rem;
  }
  button {
    min-width: 101.44px;
    min-height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Inter";
    color: #262a68;
    border: 1px solid #262a68;
    border-radius: 4px;
    background: none;
    padding: 0.5rem 1rem;
    font-weight: 600;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #a0d1fa;
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

export const SheetBlockLabel = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h5 {
    margin: 0;
    color: #4785ff;
    font-size: 14px;
  }
`;

export const MapWrapper = styled.div`
  #mapContainer {
    height: 300px;
    margin-top: 3rem;
  }
  @media (max-width: 350px) {
    #mapContainer {
      height: 250px;
    }
  }
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 300px;
  background: #fff;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(60px + 81px);
  left: 0;
  @media (max-width: 350px) {
    height: 250px;
  }
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  position: fixed;
  bottom: 0;
  left: 0;

  background: #fff;
  padding: 0.5rem 1rem;
  width: 100%;
  border-top: 1px solid #eeeeee;
  justify-content: space-between;
  button {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: none;
    background: none;
    font-weight: 600;
    font-size: 14px;
    height: 40px;
    padding: 0.75rem 1rem;
    font-family: "Inter";
    white-space: nowrap;
    transition: 0.25s all ease;
    svg {
      margin-right: 1rem;
    }
    &:first-child {
      margin-right: 1rem;
      border: 1px solid #262a68;
      color: #262a68;
    }
    &:last-child {
      background: #00b27e;
      color: #fff;
      border: none;
      svg path {
        stroke: #fff; f
      }
    }
    &:focus {
      transition: 0.25s all ease;
      outline: none;
      box-shadow: 0 0 0 2px #a0d1fa;
    }
    &:hover {
      cursor: pointer;
    }
  }
  @media (max-width: 350px) {
    button {
      padding: 0;
    }
  }
  @media (min-width: 1200px) {
    left: unset;
    right: 0;
    max-width: 400px;
  }
  ${(props) =>
    props.isRelative &&
    css`
      position: relative;
      border: none;
    `}
  ${(props) =>
    props.iOS &&
    css`
      height: 60px;
    `}
`;

export const ActionButtonsInnerWrapper = styled.div`
  display: inline-flex;
  width: 100%;
`;

export const SheetClose = styled.div`
  @media (min-width: 1200px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    z-index: 12;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const SheetItemIconsWrapper = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #889dbd;
`;
export const SheetItemWithIcon = styled.div`
  margin: 0.5rem 0;
  color: #050720;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  display: inline-flex;
  width: 100%;
  align-items: center;
  a {
    color: #050720;
    font-size: 14px;
    font-family: "Inter";
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
  }
  svg {
    width: 17px;
    margin-right: 1rem;
    height: 19px;
  }
  span {
    ${({ blur }) => blur && `filter: blur(3px);`}
  }
`;

export const HTMLField = styled.div`
  font-size: 14px;
  line-height: 1.5;
  padding-left: 1rem;
  font-weight: 500;
  p {
    color: #050720;
    margin: 0;
    padding: 0.5rem 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
  }
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }
`;
