import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../Modal";
import Header from "../Header";
import NumberInput from "../../CreateForm/NumberInput";
import PeriodSelect from "../../CreateForm/PeriodSelect";
import MessageBox from "../MessageBox";
import Footer from "../Footer";
import LargeButton from "../../CreateForm/LargeButton";
import { ModalInnerWrapper, Centered } from "../Styles";
import { InputInlineWrapper } from "./Styles";
import {
  InputWrapper,
  LabelWrapper,
  Label,
  ErrorContainer,
  ErrorWrapper,
} from "../../CreateForm/Styles";
import { SET_QUOTATIONS } from "../../../store/actionTypes";
import { sendQuotation } from "../../../store/actions/dashboard/quotation";

const SendQuoteModal = (props) => {
  const [inputs, setInputs] = useState(props.inputs || {});
  const [errors, setErrors] = useState({});
  const [quoteSent, setQuoteSent] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (value, type) => {
    setInputs((prevState) => ({ ...prevState, [type]: value }));
    if (errors[type]) {
      setErrors((prevState) => ({ ...prevState, [type]: null }));
    }
  };

  const handleQuoteSubmit = () => {
    dispatch(
      sendQuotation(
        !props.updateQuote ? "POST" : "PUT",
        props.id,
        props.updateQuote ? props.id : null,
        inputs?.price,
        inputs?.noOfTrucks,
        inputs?.period,
        inputs?.comment
      )
    )
      .then((res) => {
        props.onSetQuotations(res.data);
        setQuoteSent(true);
      })
      .catch((err) => {
        const errors = err.response.data.errors;
        setErrors(errors);
      });
  };

  if (quoteSent) {
    return (
      <Modal onClose={props.onClose} small>
        <Header onClose={props.onClose} />
        <ModalInnerWrapper>
          <Centered>
            {icon}
            <h1>{props.successMessage || "Quote sent successfully"}</h1>
          </Centered>
        </ModalInnerWrapper>
      </Modal>
    );
  }
  return (
    <Modal onClose={props.onClose} medium>
      <Header title={props.title || "Send Quote"} onClose={props.onClose} />
      <ModalInnerWrapper>
        <InputInlineWrapper>
          <NumberInput
            min={0}
            step={1}
            label="Price (EUR)"
            placeholder="Price"
            name="price"
            inputs={inputs}
            value={inputs?.price || ""}
            onInputChange={handleInputChange}
            error={errors?.price}
          />
        </InputInlineWrapper>
        <InputInlineWrapper>
          <NumberInput
            min={0}
            step={1}
            label="Trucks"
            placeholder="Trucks"
            name="noOfTrucks"
            inputs={inputs}
            value={inputs?.noOfTrucks || ""}
            onInputChange={handleInputChange}
            error={errors?.noOfTrucks}
          />
          <span>per</span>
          <PeriodSelect
            placeholder="Period"
            name="period"
            inputs={inputs}
            onSelectChange={handleInputChange}
            error={errors?.period}
          />
        </InputInlineWrapper>
        <InputWrapper>
          <LabelWrapper>
            <Label>
              <h3>Comment</h3>
            </Label>
            <ErrorContainer maxWidth>
              <MessageBox
                value={inputs?.comment}
                onInputChange={(e) => handleInputChange(e, "comment")}
                error={errors?.comment}
                disableImageUpload
              />
              {errors?.comment && (
                <ErrorWrapper>
                  <span>{errors.comment?.msg}</span>
                </ErrorWrapper>
              )}
            </ErrorContainer>
          </LabelWrapper>
        </InputWrapper>
      </ModalInnerWrapper>
      <Footer fixed="mobile">
        <LargeButton
          label={props.submitLabel || "Send Quote"}
          onClick={handleQuoteSubmit}
        />
      </Footer>
    </Modal>
  );
};

const icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="156"
    height="156"
    viewBox="0 0 156 156"
  >
    <g transform="translate(-870 -269)">
      <circle
        cx="78"
        cy="78"
        r="78"
        transform="translate(870 269)"
        fill="#fbfbfb"
      />
      <g transform="translate(7 45)">
        <g
          transform="translate(903 264)"
          fill="none"
          stroke="#00B27E"
          strokeWidth="4"
        >
          <circle cx="38" cy="38" r="38" stroke="none" />
          <circle cx="38" cy="38" r="36" fill="none" />
        </g>
        <path
          d="M13083.955-13612.08l10.612,10.611,20.426-20.426"
          transform="translate(-12158.68 13913.853)"
          fill="none"
          stroke="#00B27E"
          strokeLinecap="round"
          strokeWidth="6"
        />
      </g>
    </g>
  </svg>
);

export const mapDispatchToProps = (dispatch) => {
  return {
    onSetQuotations: (payload) =>
      dispatch({ type: SET_QUOTATIONS, payload: payload }),
  };
};

export default connect(null, mapDispatchToProps)(SendQuoteModal);
