import React, { useState } from "react";
import { SingleDateWrapper } from "../CreateForm/Styles";
import {
  InputWrapper,
  LabelWrapper,
  Label,
  ErrorContainer,
  ErrorWrapper,
} from "../CreateForm/Styles";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { isMobile } from "react-device-detect";

const SingleDateSelect = (props) => {
  const [focused, setFocused] = useState(false);
  return (
    <InputWrapper>
      <LabelWrapper>
        {props.label && (
          <Label>
            <h3>{props.label}</h3>
          </Label>
        )}
        <SingleDateWrapper error={props.error}>
          <ErrorContainer>
            <SingleDatePicker
              date={props.inputs?.[props.type] || null}
              onDateChange={(date) =>
                props.onInputChange(date ? moment(date) : date, props.type)
              }
              focused={focused}
              displayFormat="DD.MM.YYYY"
              placeholder={props.placeholder}
              numberOfMonths={1}
              customInputIcon={dateIcon}
              onFocusChange={({ focused }) => setFocused(focused)}
              id={props.id}
              firstDayOfWeek={1}
              disabled={props.disabled}
              isOutsideRange={props.isOutsideRange}
              withPortal={isMobile ? true : false}
            />
            {props.error && (
              <ErrorWrapper>
                <span>{props.error.msg}</span>
              </ErrorWrapper>
            )}
          </ErrorContainer>
        </SingleDateWrapper>
      </LabelWrapper>
    </InputWrapper>
  );
};

const dateIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.975"
    height="17.572"
    viewBox="0 0 15.975 17.572"
  >
    <g transform="translate(-2 -1)">
      <path
        d="M15.578,18.975H4.4a2.4,2.4,0,0,1-2.4-2.4V5.4A2.4,2.4,0,0,1,4.4,3H15.578a2.4,2.4,0,0,1,2.4,2.4V16.578A2.4,2.4,0,0,1,15.578,18.975ZM4.4,4.6a.8.8,0,0,0-.8.8V16.578a.8.8,0,0,0,.8.8H15.578a.8.8,0,0,0,.8-.8V5.4a.8.8,0,0,0-.8-.8Z"
        transform="translate(0 -0.403)"
        fill="#262a68"
      />
      <path
        d="M15.8,5.792a.8.8,0,0,1-.8-.8V1.8a.8.8,0,0,1,1.6,0V4.994A.8.8,0,0,1,15.8,5.792Z"
        transform="translate(-2.617)"
        fill="#262a68"
      />
      <path
        d="M7.8,5.792a.8.8,0,0,1-.8-.8V1.8a.8.8,0,1,1,1.6,0V4.994A.8.8,0,0,1,7.8,5.792Z"
        transform="translate(-1.006)"
        fill="#262a68"
      />
      <path
        d="M17.176,10.6H2.8A.8.8,0,0,1,2.8,9H17.176a.8.8,0,1,1,0,1.6Z"
        transform="translate(0 -1.61)"
        fill="#262a68"
      />
    </g>
  </svg>
);

export default SingleDateSelect;
