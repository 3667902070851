import React, { useState } from "react";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Link, withRouter } from "react-router-dom";
import image from "../../images/login.png";
import "../../styles/Login/Login.css";
import logo from "../../images/logo-white.svg";
import Header from "../FrontPage/Header/Header";
import axios from "axios";
import { API_URL } from "../../constants";
import { CreateToast } from "../../helpers/CreateToast";
import { getUser } from "../../store/actions/dashboard/user";
import { getCompany } from "../../store/actions/dashboard/company";

const Login = (props) => {
  const { USER, COMPANY } = props;
  const [componentState, setComponentState] = useState({
    error: false,
    loader: false,
  });
  const [inputs, setInputs] = useState({});
  const dispatch = useDispatch();

  const inputChangeHandler = (value, name) => {
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setComponentState((prevState) => ({
      ...prevState,
      loader: true,
      error: false,
    }));
    axios
      .post(`${API_URL}/auth`, inputs)
      .then((res) => {
        const token = res.data;
        // Set cookie
        const cookies = new Cookies();
        cookies.set("tc_token", token, {
          path: "/",
          expires: inputs?.remember
            ? moment().add(14, "days").toDate()
            : moment().add(1, "days").toDate(),
        });
        setComponentState((prevState) => ({ ...prevState, loader: false }));
        if (token && !USER) {
          dispatch(getUser(token));
          if (!COMPANY) {
            dispatch(getCompany(token));
          }
        }
        setTimeout(() => {
          props.history.push("/spotload");
        }, 200);
      })
      .catch((err) => {
        if (err) {
          CreateToast({
            message: "⚠️ Review your form fields.",
            type: "warning",
          });
          setComponentState((prevState) => ({
            ...prevState,
            loader: false,
            error: true,
          }));
        }
      });
  };

  return (
    <>
      <Header />
      <div className="Login">
        <div className="Login__Image">
          <img src={logo} alt="TransConnector" />
          <img src={image} alt="TransConnector" />
        </div>
        <div className="Login--inner">
          <h2>Welcome back!</h2>
          <h4>Log in to your account</h4>
          <form onSubmit={handleSubmit}>
            <div className={`Login__Error animated ${componentState.error}`}>
              <p>Invalid Email address or password.</p>
            </div>
            <div className="floating-label">
              <input
                type="text"
                placeholder=" "
                value={inputs.username || ""}
                name="username"
                onChange={(e) => inputChangeHandler(e.target.value, "username")}
              />
              <label>Email address</label>
            </div>
            <div className="floating-label">
              <input
                type="password"
                placeholder=" "
                value={inputs.password || ""}
                name="password"
                onChange={(e) => inputChangeHandler(e.target.value, "password")}
              />
              <label>Password</label>
            </div>
            <div className="inline-label">
              <label htmlFor="remember">
                <input
                  type="checkbox"
                  id="remember"
                  checked={inputs.remember || false}
                  onChange={(e) =>
                    inputChangeHandler(e.target.checked, "remember")
                  }
                />
                Remember me
              </label>
              <Link to="/forgot">Forgot password?</Link>
            </div>
            <div className="Submit__Form">
              <input
                className={`${componentState.loader}`}
                type="submit"
                value="Login"
              />
              <div className={`loader ${componentState.loader}`}></div>
            </div>
          </form>
          <div className="SignUp">
            <h5>Don't have an account?</h5>
            <Link to="/sign-up">Sign Up</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Login);
