import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Filter from '../Roundtrip/RoundtripFilter/Filter';
import Modal from '../Modals/Modal';
import ModalHeader from '../Modals/Header';
import { withRouter } from 'react-router-dom';
import RoundtripList from './FindRoundtripList/FindRoundtripList';
import FindRoundtripSheet from './FindRountripSheet/FindRoundtripSheet';
import Container from '../UI/Container';
import DashboardFooter from '../Dashboard/DashboardFooter';
import ListContainer from '../UI/ListContainer';

const FindRoundtrip = (props) => {
  const USER = useSelector((state) => state.dashboard.user?.data);
  const [filters, setFilters] = useState({});
  const [filterFixed, setFilterFixed] = useState(false);
  const [sheetID, setSheetID] = useState(
    props.location?.state?.sheet || props.match?.params?.id || null
  );
  const [allRoundtrips, setAllRoundtrips] = useState([]);
  const [showRouteModal, setShowRouteModal] = useState(false);

  // If user has requested a roundtrip via route, if it doesn't exist, display a modal
  useEffect(() => {
    const routeSheet = props.location?.state?.sheet || props.match?.params?.id;
    if (routeSheet && allRoundtrips?.length > 0) {
      if (!allRoundtrips.find((e) => e._id === routeSheet)) {
        setShowRouteModal(true);
      }
    }
  }, [props.location, props.match, allRoundtrips]);

  const handleModalClose = () => {
    setShowRouteModal(false);
    setSheetID(null);
  };

  const handleSheetChange = useCallback((id) => {
    setSheetID(id);
  }, []);

  useEffect(() => {
    if (window.innerWidth >= 992) {
      const scrollEvt = document.addEventListener('scroll', function () {
        window.pageYOffset >= 134
          ? setFilterFixed(true)
          : setFilterFixed(false);
      });
      return () => document.removeEventListener('scroll', scrollEvt);
    }
  }, []);
  return (
    <>
      <Filter fixed={filterFixed} filters={filters} setFilters={setFilters} />
      <ListContainer offset={USER ? 1 : null}>
        <RoundtripList
          filters={filters}
          sheetID={sheetID}
          setSheetID={handleSheetChange}
          setAllRoundtrips={setAllRoundtrips}
          clearFilters={() => setFilters({})}
          USER={USER}
        />
        {allRoundtrips?.length > 0 && <DashboardFooter />}
      </ListContainer>
      {allRoundtrips && sheetID && (
        <FindRoundtripSheet
          roundtrips={allRoundtrips}
          id={sheetID}
          setSheetID={handleSheetChange}
          USER={USER}
        />
      )}
      {showRouteModal && (
        <Modal onClose={handleModalClose} small>
          <ModalHeader title="Roundtrip not found" onClose={handleModalClose} />
          <h4>The roundtrip you requested was not found.</h4>
          <h5>Here are some reasons to why:</h5>
          <ul>
            <li>
              The roundtrip you requested was removed, is currently inactive or
              in review.
            </li>
            <li>The link you accessed was broken or missing.</li>
          </ul>
        </Modal>
      )}
    </>
  );
};

export default withRouter(FindRoundtrip);
