import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import dompurify from "dompurify";
import moment from "moment";
import Cookies from "universal-cookie";
import { connect } from "react-redux";

import iSeen from "../../../assets/icons/seen.svg";
import iSend from "../../../assets/icons/send.svg";

// wysiwyg
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import FroalaEditorComponent from "react-froala-wysiwyg";
import { API_URL } from "../../../constants";

const MinimessagesBox = (props) => {
  const [message, setMessage] = useState("");
  const [s3Hash, setS3Hash] = useState();
  const [height, setHeight] = useState(false);
  const heightRef = useRef(null);
  const sanitizer = dompurify.sanitize;

  // GET S3 HASH
  useEffect(() => {
    axios.get(`${API_URL}/s3hash`).then((res) => setS3Hash(res.data));
  }, []);

  // Set the viewport height of the message box
  useEffect(() => {
    if (height === 0) {
      setHeight(60);
    }
  }, [height]);

  useEffect(() => {
    setHeight(heightRef.current.clientHeight);
  }, [message]);

  // Handle sending the message
  const sendMessage = () => {
    if (message?.length > 0) {
      const cookies = new Cookies(),
        token = cookies.get("tc_token");
      axios.post(
        `${API_URL}/msg`,
        {
          conversationId: props.conversation.conversationId,
          message: sanitizer(message, {
            ALLOWED_ATTR: ["target", "href", "src"],
          }),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage("");
    }
  };

  return (
    <div
      className={`Current_Message_Box ${
        props.minimizedConversations.includes(
          props.ACTIVE_CONVERSATIONS.conversationId
        )
          ? "minimize"
          : ""
      }`}
    >
      <div
        className="Current_Message_Box--inner"
        style={{
          height: `calc((300px - ${height}px))`,
        }}
      >
        <div className="Conversation">
          {props.conversation.messages?.map((c) => {
            return (
              <div
                key={c.message._id}
                className="sent-message"
                data-start={c.wrapStart ? "true" : null}
                data-end={c.wrapEnd ? "true" : null}
              >
                <div
                  className={c.type}
                  data-created={moment(c.message.createdAt)
                    .startOf("minute")
                    .fromNow()}
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(c.message.content, {
                      ALLOWED_ATTR: ["target", "href", "src"],
                    }),
                  }}
                ></div>
                {c.wrapEnd && c.type === "reciever" ? (
                  <img
                    src={props.conversation?.reciever?.recieverAvatar}
                    className="reciever-avatar"
                    alt=""
                  />
                ) : null}
                {c.seen && <img src={iSeen} alt="Seen" className="seen" />}
              </div>
            );
          })}
        </div>
      </div>
      <div className="Send_Message">
        <div className="Send_Message--inner" ref={heightRef}>
          {s3Hash && (
            <FroalaEditorComponent
              tag="textarea"
              model={message}
              config={{
                placeholderText: "Type your message...",
                charCounterCount: false,
                buttons: ["insertImage", "insertFile"],
                toolbarButtons: {
                  moreRich: {
                    buttons: ["insertImage", "insertFile"],
                    buttonsVisible: 2,
                    imageAllowedTypes: ["jpeg", "jpg", "png"],
                  },
                },
                autofocus: true,
                imageAllowedTypes: ["jpeg", "jpg", "png"],
                toolbarSticky: false,
                wordPasteKeepFormatting: true,
                wordPasteModal: false,
                attribution: false,
                quickInsertEnabled: false,
                enter: FroalaEditorComponent.ENTER_BR,
                zIndex: 90,
                heightMin: 48,
                imageUploadToS3: s3Hash,
                fileUploadToS3: s3Hash,
                imageMaxSize: 5 * 1024 * 1024, // 5MB
                key:
                  "hWA2C-7F2C2B1D5C2D2vD-8B-7lc1A-16sD-13C-11A-9qvbxjA2F-11ng1G3I3A2B6D6B3D3E4B2G1==",
                events: {
                  focus: function () {
                    //setMessageBoxHasFocus(true);
                  },
                  blur: function () {
                    //setMessageBoxHasFocus(false);
                  },
                },
              }}
              onModelChange={(e) => setMessage(e)}
            />
          )}
          <div className="Send_Icon" onClick={sendMessage}>
            <img src={iSend} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    USER_DATA: state.dashboard.user.data,
    ACTIVE_CONVERSATIONS: state.dashboard.message.activeMiniConversations,
  };
};

export default connect(mapStateToProps)(MinimessagesBox);
