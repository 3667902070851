import React, { useState } from "react";
import iArrowDown from "../../assets/icons/arrow-down.svg";
import "../../styles/Dashboard/Messages/MessagesList.css";
import { FilterWrapper, FilterBox, FilterOptions } from "./Styles";

const DropdownFilter = (props) => {
  const [filters] = useState(props.filters);
  //const [filterOptions, setFilterOptions] = useState(false);
  const [currentOptionFilter, setCurrentOptionFilter] = useState(
    props.filters[0]
  );

  return (
    <>
      {filters && (
        <FilterWrapper show={props.show}>
          <FilterBox onClick={() => props.setShow(!props.show)}>
            <h3>{currentOptionFilter}</h3>
            <img src={iArrowDown} alt="" />
          </FilterBox>
          <FilterOptions onClick={() => props.setShow(!props.show)}>
            <ul>
              {filters.map((filter) => {
                return (
                  <li
                    key={filter}
                    onClick={() => {
                      setCurrentOptionFilter(filter);
                      if (props.setSelectedFilter) {
                        props.setSelectedFilter(filter);
                      }
                    }}
                  >
                    {filter}
                  </li>
                );
              })}
            </ul>
          </FilterOptions>
        </FilterWrapper>
      )}
    </>
  );
};

export default DropdownFilter;
