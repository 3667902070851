import React from "react";

// three vertical dots
const Kebab = (props) => {
    return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <circle cx="12" cy="5" r="2" fill="#262A68"/>
    <circle cx="12" cy="12" r="2" fill="#262A68"/>
    <circle cx="12" cy="19" r="2" fill="#262A68"/>
        </svg>
    );
}

export default Kebab;