import React from "react";

import "../../../styles/Dashboard/UI/Form.css";

const Form = props => {
  return (
    <div className="Form">
      <div className="Form--inner">
        <form onSubmit={props.onSubmit} {...props}>
          {props.children}
        </form>
      </div>
    </div>
  );
};

export default Form;
