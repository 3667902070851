import React, { useState } from "react";
import styled from "styled-components";
import { Static } from "./Styles";
import Container from "../../components/UI/Container";
import stacking_img from "../../../src/images/stacking.png";
import paid_img from "../../../src/images/PAID.png";
import debtmock1 from "../../../src/images/debtmock1.png";
import debtmock2 from "../../../src/images/debtmock2.png";
import { Link } from "react-router-dom";

// 5 sections

const Section1 = styled.div`
  display: flex;
  flex-direction: row;
  h1 {
    color: #040730;
    font-family: Inter;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 80%;
  }
  p {
    color: #000;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 1.25rem */
    max-width: 60%;
  }
  p.deal {
    color: #0E114A;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .images {
    transform: translate(0, 6rem);
    scale: 0.7;
    display: flex;
    flex-direction: row;
    
    right: 15%;
    position: relative;
    img {
      height: fit-content;
      max-width: 100%;
    }
    #stacking {
      right: 10%;
      position: relative;
    }
    #paid {
      bottom: -30%;
      position: relative;
      z-index: 1;
    }
  }
  @media (max-width: 1200px) {
    .btn {
      margin-bottom: 8rem;
    }
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      margin-top: 2rem;
    }
    p {
      max-width: 100%;
    }
    .btn {
      margin-bottom: 2rem;
    }
    .images {
      flex-direction: column;
      transform: translate(0, 0);
      scale: 0.9;
      right: 0;
      img {
        width: 60%;
        height: auto;
      }
      #stacking {
        transform: translate(0, 0);
        align-self: end;
        right: 0;
      }
      #paid {
        align-self: start;
        top: 20%;
        position: absolute;
      }
    }
  }
`;
const Section2 = styled.div`
  margin-top: 0rem;
  position: relative;
  z-index: -1;

  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // align-items: center;
  background-color: #f4f8ff;
  box-shadow: 0 0 0 100vmax #f4f8ff;
  clip-path: inset(0 -100vmax);

  h1 {
    margin-top: 4rem;
    color: #31347d;
    font-family: Inter;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 2.5rem */
  }
  li {
    color: #000;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
  }
  .points {
    margin-bottom: 6rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ul {
      padding: 0;
      display: flex;
      flex-direction: column;
    }
    li {
      max-width: 60%;
      margin-block: 1rem;
    }
    #offset {
      transform: translate(0, 7rem);
    }
  }
  @media (max-width: 768px) {
    margin-top: 0;
    h1 {
      margin-top: 10rem;
      color: #31347d;
      font-family: Inter;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 125%; /* 1.875rem */
    }
    .points {
      flex-direction: column;
      margin-bottom: 2rem;
      ul {
        max-width: 100%;
      }
      li {
        max-width: 100%;
      }
      #offset {
        transform: translate(0, 0);
      }
    }
  }
`;
const Section3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  h1 {
    color: #000;
    font-family: Inter;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 2.5rem */
    margin-block: 2rem;
  }
  h4 {
    color: #31347d;
    font-family: Inter;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 1.875rem */
    margin: 0;
  }
  h3 {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
  h2 {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
  p {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
const Deal = styled.div`
color: #0E114A;
text-align: center;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;

display: inline-flex;
margin-top: 1rem;
margin-bottom: 2rem;
width: 13.75rem;
transform: rotate(-6.977deg);
padding: 1rem;
justify-content: center;
align-items: center;
gap: 0.625rem;

border-radius: 0.25rem;
border: 2px dashed #31347D;
background: #FFF;
box-shadow: 0px 8px 28.7px 0px rgba(66, 90, 126, 0.25);
`;
const Section4 = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  // align-items: center;
  background-color: #f4f8ff;
  box-shadow: 0 0 0 100vmax #f4f8ff;
  clip-path: inset(0 -100vmax);
  h1 {
    margin-top: 4rem;
    color: #31347d;
    font-family: Inter;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 2.5rem */
  }
  p {
    color: #000;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 1.25rem */
  }
  .images {
    // transform: translate(0, 4rem);
    display: flex;
    flex-direction: row;
    // overflow: visible;
    img {
      max-width: 100%;
      height: fit-content;
    }
    #debtmock1 {
      // scale: 0.6;
      // max-width: 100%;
      // height: 100%;
      // transform: translate(-19rem, 0);
      width: 50%;
      top: 45%;
      right: 10%;
      position: relative;
    }
    #debtmock2 {
      z-index: 1;
      width: 60%;
      top: 30%;
      position: relative;
    }
  }
  .content {
    margin-bottom: 4rem;
  }
  @media (max-width: 1360px) {
    .images {
      transform: translate(0, 0);
      #debtmock1 {
        // transform: translate(5rem, 5rem);
      }
      #debtmock2 {
        // transform: translate(0, 0);
      }
    }
    @media (max-width: 1200px) {
      .images {
        #debtmock1 {
          top: 60%;
        }
        #debtmock2 {
          top: 50%;
        }
      }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      margin-top: 2rem;
    }
    p {
      max-width: 100%;
    }
    .content {
      margin-bottom: 0rem;
    }
    .images {
      margin-top: 2rem;
      // overflow: hidden;
      // transform: translate(0, 0);
      img {
        // max-width: 100%;
        height: auto;
      }
      #debtmock1 {
        position: absolute;
        width: 55%;
        top: 15%;
        right: 0%;
      }
      #debtmock2 {
        width: 60%;
        z-index: 1;
      }
    }
  }
`;
const Section5 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  p {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 1.5625rem */
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0px 4px 32.6px -4px rgba(66, 90, 126, 0.25);
  padding: 2rem;
`;

const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  margin-top: 6rem;
`;

const CardHero = styled.div``;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

const Button = styled.div`
  max-width: max-content;
  display: inline-flex;
  padding: 0.625rem 1.25rem;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.3125rem;
  background: rgb(79, 175, 130);
  transition: all 0.3s ease 0s;
  :hover {
    background: rgb(94, 203, 152);
  }

  color: #FFF;
text-align: center;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: normal;

  @media (max-width: 768px) {
    max-width: -webkit-fill-available;
    width: -webkit-fill-available;
  }
`;

const DebtCollect = (props) => {
  return (
    // <Wrapper>
    <Static>
      <Container gutter="medium">
        <Section1>
          <div>
            <h1>
              TdC Solutions -<br /> Transport Debt Collection{" "}
            </h1>
            <p>
              We specialize in <b>International Transport debt collection</b>,
              ensuring your relationships remain intact for future
              opportunities. Our approach is not only effective but fair, aiming
              to foster a transparent market environment by identifying and
              warning against fraudulent entities.
            </p>
            <p className="deal">No returned money = No cost for collection</p>
            <Link to="/debt-collect-sign-up">
              <Button className="btn">Order Debt Collection</Button>
            </Link>
          </div>
          <div className="images">
            <img id="paid" src={paid_img} alt="paid" />
            <img id="stacking" src={stacking_img} alt="stacking" />
          </div>
        </Section1>
        <Section2>
          <h1>Why Choose Us?</h1>
          <div className="points">
            <ul>
              <li>
                <b>Exclusive Access</b>: Benefit from our comprehensive
                blacklist and a vast network of over 160,000 transport and
                logistics contacts across Europe.
              </li>
              <li>
                <b>Community Insight</b>: Leverage our reach within 300+
                transport and logistics-related Facebook groups and forums.
              </li>
              <li>
                <b>Transparent Tracking</b>: Stay informed with our online debt
                collection reports.
              </li>
            </ul>
            <ul id="offset">
              <li>
                <b>Legal Expertise</b>: Rely on our extensive network of
                European lawyers.
              </li>
              <li>
                <b>Competitive Pricing</b>: Enjoy our reasonable rates, with
                fees contingent on successful debt recovery.
              </li>
            </ul>
          </div>
        </Section2>
        <Section3>
          <h1>Pricing Structure:</h1>
          <CardRow>
            <Card>
              <CardHero>
                <h2>8%</h2>
                <h3>of the recovered amount</h3>
              </CardHero>
              <CardContent>
                <p>up to 30 days after the payment term</p>
              </CardContent>
            </Card>
            <Card>
              <CardHero>
                <h2>10%</h2>
                <h3>of the recovered amount</h3>
              </CardHero>
              <CardContent>
                <p>between 31 and 60 days after the payment term</p>
              </CardContent>
            </Card>
            <Card>
              <CardHero>
                <h2>12%</h2>
                <h3>of the recovered amount</h3>
              </CardHero>
              <CardContent>
                <p>starting from 61 days after the payment term</p>
              </CardContent>
            </Card>
          </CardRow>
          <Deal>No returned money = No cost for collection!</Deal>
          <p>Success fee minimum 150€.
Invoiced only upon successful
debt recovery.</p>
        </Section3>
        <Section4>
          <div className="content">
            <h1>Get Started:</h1>
            <p>
              Simply fill out our form, and our experienced team will swiftly
              work on recovering your dues.
              <p>
                We require details about your company, the debtor, and the debt.
                Our commitment to privacy and fair practices ensures your data
                is handled with the utmost care.
              </p>
            </p>
            <Link to="/debt-collect-sign-up">
              <Button>Order Debt Collection</Button>
            </Link>
          </div>
          <div className="images">
            <img id="debtmock2" src={debtmock2} alt="debtmock2" />
            <img id="debtmock1" src={debtmock1} alt="debtmock1" />
          </div>
        </Section4>
        <Section5>
          <p>
            "We're dedicated to fast, effective debt collection across Europe.
            There's no agency that works harder to get you paid!"
          </p>
        </Section5>
      </Container>
    </Static>
    // </Wrapper>
  );
};

export default DebtCollect;
