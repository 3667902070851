import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import Table from '../../NewTable/Table';
import TableHeader from '../../NewTable/TableHeader';
import { TableHeaderItem } from '../../NewTable/Styles';
import TableContent from '../../NewTable/TableContent';
import TableRow from '../../NewTable/TableRow';
import TableRowItem from '../../NewTable/TableRowItem';
import Status from '../../NewTable/Status';
import Options from '../../NewTable/Options';
import ConfirmationModal from '../../Modals/ConfirmationModal/ConfirmationModal';
import ConfirmationSuccess from '../../Modals/ConfirmationModal/ConfirmationSuccess';
import { REMOVE_OFFER } from '../../../store/actionTypes';
import { removeOffer } from '../../../store/actions/dashboard/offers';
import formatLocationName from '../../../helpers/LocationName';

const SpotloadsTable = (props) => {
  const [optionsId, setOptionsId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [confirmationSuccess, setConfirmationSuccess] = useState(false);
  const dispatch = useDispatch();

  const handleConfirmationModal = (id, title) => {
    setConfirmationModal({ id, title });
  };

  const deleteSpotload = (id) => {
    dispatch(removeOffer(id, 'Spotload')).then((res) => {
      dispatch({
        type: REMOVE_OFFER,
        payload: res.data,
        id: id,
        array: 'spotloads',
      });
      setConfirmationModal(null);
      setConfirmationSuccess(true);
    });
  };

  const handleToggleOptions = (id) => {
    if (optionsId === id) {
      setOptionsId(null);
    } else {
      setOptionsId(id);
    }
  };

  const handleRedirect = (url, state) => {
    props.history.push(url, state);
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem flex="0.5">Loading</TableHeaderItem>
          <TableHeaderItem flex="0.5">Unloading</TableHeaderItem>
          <TableHeaderItem flex="0.4">Truck type</TableHeaderItem>
          <TableHeaderItem flex="0.2">Price</TableHeaderItem>
          <TableHeaderItem flex="0.2">Published</TableHeaderItem>
          <TableHeaderItem flex="0.2">Status</TableHeaderItem>
          <TableHeaderItem flex="0.1"></TableHeaderItem>
        </TableHeader>
        <TableContent>
          {props.spotloads.map((spotload) => {
            const loading = spotload.loading.locations[0];
            const unloading = spotload.unloading.locations[0];
            const truckTypes = spotload.truckType
              .map((element) => {
                if (element.value === 'Refrigerator' && spotload.temperature) {
                  return `${element.value} (${spotload.temperature}°C)`;
                }
                return element.value;
              })
              .join(', ')
              .replace(/_/g, ' ');
            return (
              <TableRow
                key={spotload._id}
                onClick={() =>
                  handleRedirect('/spotload', {
                    sheet: spotload._id,
                  })
                }
              >
                <TableRowItem
                  rowName="Loading"
                  image={{
                    url: loading.flagUrl,
                    alt: loading.country,
                  }}
                  title={{
                    name: spotload.loading.locations
                      .map(
                        (l) =>
                          l.locationName && formatLocationName(l.locationName)
                      )
                      .join('; '),
                  }}
                  flex="0.5"
                />
                <TableRowItem
                  rowName="Unloading"
                  image={{
                    url: unloading.flagUrl,
                    alt: unloading.country,
                  }}
                  title={{
                    name: spotload.unloading.locations
                      .map(
                        (l) =>
                          l.locationName && formatLocationName(l.locationName)
                      )
                      .join('; '),
                  }}
                  flex="0.5"
                />
                <TableRowItem
                  rowName="Truck type"
                  title={{
                    name: truckTypes,
                  }}
                  flex="0.4"
                >
                  <span>
                    {`${spotload.freight.typeOfLoad}`}{' '}
                    {spotload.freight.weight &&
                      ` · ${spotload.freight.weight}kg`}{' '}
                    {spotload.freight.loadingMeters &&
                      ` · LDM: ${spotload.freight.loadingMeters}m`}
                  </span>
                </TableRowItem>
                <TableRowItem
                  rowName="Price"
                  title={{
                    name: spotload.targetPrice
                      ? `${spotload.targetPrice} €`
                      : 'N/A',
                  }}
                  flex="0.2"
                />
                <TableRowItem
                  rowName="Published"
                  title={{
                    name: moment(spotload.createdAt).format('DD.MM.YY'),
                  }}
                  flex="0.2"
                />
                <TableRowItem rowName="Status" flex="0.2">
                  <Status
                    active={spotload.active}
                    status={spotload.active ? 'Active' : 'Inactive'}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.handleStatusChange(
                        spotload._id,
                        !spotload.active,
                        'Spotload',
                        'spotloads'
                      );
                    }}
                  />
                </TableRowItem>
                <TableRowItem flex="0.1" overflow>
                  <Options
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleOptions(spotload._id);
                    }}
                    open={optionsId === spotload._id}
                    onBlur={() => setOptionsId(null)}
                  >
                    <ul>
                      <li
                        onClick={() =>
                          handleRedirect(
                            `/dashboard/spotload/edit/${spotload._id}`
                          )
                        }
                      >
                        Edit
                      </li>
                      <li
                        onClick={() =>
                          handleRedirect(
                            `/dashboard/spotload/duplicate/${spotload._id}`
                          )
                        }
                      >
                        Duplicate
                      </li>
                      <li
                        onClick={() =>
                          handleConfirmationModal(
                            spotload._id,
                            `Delete: ${loading.locationName} - ${unloading.locationName}`
                          )
                        }
                      >
                        Delete
                      </li>
                    </ul>
                  </Options>
                </TableRowItem>
              </TableRow>
            );
          })}
        </TableContent>
      </Table>
      {confirmationModal && (
        <ConfirmationModal
          onClose={() => setConfirmationModal(null)}
          title={confirmationModal.title}
          onConfirm={() => deleteSpotload(confirmationModal.id)}
        />
      )}
      {confirmationSuccess && (
        <ConfirmationSuccess onClose={() => setConfirmationSuccess(false)} />
      )}
    </>
  );
};

export default withRouter(SpotloadsTable);
