import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../../constants";
import { sanitize } from "dompurify";

import { SingleBlog, SingleBlogContent, LatestBlogPosts } from "./Styles";
import Container from "../../UI/Container";
import { Link } from "react-router-dom";
import { Static } from "../../../pages/static/Styles";

const Single = ({ post }) => {
  const { _id, title, content } = post;
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    if (!posts)
      axios
        .get(`${API_URL}/cms/blog`)
        .then((res) => setPosts(res.data?.slice(0, 3)));
  }, [posts]);

  return (
    <>
      <Container gutter="large">
        <Static>
          <SingleBlog>
            <h1>{title}</h1>
            <SingleBlogContent
              dangerouslySetInnerHTML={{ __html: sanitize(content) }}
            />
            {posts && posts?.length > 0 && (
              <LatestBlogPosts>
                <h3>Latest Blog posts</h3>
                <ul>
                  {posts.map((post) => {
                    const { title } = post;
                    if (post._id === _id) return null;
                    return (
                      <li key={post._id}>
                        <Link to={`/blog/${post._id}`}>{title}</Link>
                      </li>
                    );
                  })}
                  <li></li>
                </ul>
              </LatestBlogPosts>
            )}
          </SingleBlog>
        </Static>
      </Container>
    </>
  );
};

export default Single;
