import React from "react";

import Hero from "../components/FrontPage/Hero";
import Hero2 from "../components/FrontPage/Hero2";
import Offers from "../components/FrontPage/Offers/Offers";
import Benefits from "../components/FrontPage/Benefits/Benefits";
import Testimonials from "../components/FrontPage/Testimonials2/Testimonials";
import Blog from "../components/FrontPage/Blog";
// import Footer from "../components/Footer/Footer";
import Footer from "../components/Footer2/Footer";
import Header from "../components/FrontPage/Header/Header";
import Accompaniments from "../components/FrontPage/Accompaniments2";
import GetStarted from "../components/FrontPage/GetStarted";
import LinkButton from "../components/Buttons/NewButton/LinkButton";

const Home2 = ({ USER }) => {
  return (
    <div className="Home">
      <Header landingPage={true} />
      {/* <Hero USER={USER} /> */}
      <Hero2 USER={USER} />
      <Accompaniments USER={USER} />
      {/* <Benefits /> */}
      <Testimonials />
      {!USER && (
          <GetStarted />
        )}
      <Footer />
    </div>
  );
};

export default Home2;
