import React from "react";
import Pricing from "../components/Pricing/FreeTrial";
import Header from "../components/FrontPage/Header/Header";
import Footer from "../components/Footer/Footer";
import LeftDrawer from "../components/Dashboard/Drawers/LeftDrawer";

const FreeTrialPage = ({ USER }) => {
  return (
    <>
      <Header />
      <Pricing />
      <Footer />
      {USER && <LeftDrawer small />}
    </>
  );
};

export default FreeTrialPage;
