import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 0 1rem;
  ${({ offset }) =>
    offset &&
    css`
      @media (min-width: 992px) {
        margin-left: 84px;
      }
    `}
  @media (min-width: 2400px) {
    max-width: calc(100% - 172px);
    margin-left: auto;
    margin-right: auto;
  }
`;
