import React from "react";
import { withRouter } from "react-router-dom";

import Table from "../../NewTable/Table";
import TableHeader from "../../NewTable/TableHeader";
import { TableHeaderItem } from "../../NewTable/Styles";
import TableContent from "../../NewTable/TableContent";
import TableRow from "../../NewTable/TableRow";
import TableRowItem from "../../NewTable/TableRowItem";
import formatLocationName from "../../../helpers/LocationName"

const SpotloadOffers = (props) => {
  const handleRedirect = (url, state) => {
    props.history.push(url, state);
  };
  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem flex="0.5">Loading</TableHeaderItem>
          <TableHeaderItem flex="0.5">Unloading</TableHeaderItem>
          <TableHeaderItem flex="0.4">Truck type</TableHeaderItem>
          <TableHeaderItem flex="0.2">Price</TableHeaderItem>
        </TableHeader>
        <TableContent>
          {props.spotloads.map((spotload) => {

            const loading = spotload.loading.locations[0];
            const unloading = spotload.unloading.locations[0];
            const truckTypes = spotload.truckType
              .map((element) => {
                if (element.value === "Refrigerator" && spotload.temperature) {
                  return `${element.value} (${spotload.temperature}°C)`;
                }
                return element.value;
              })
              .join(", ")
              .replace(/_/g, " ");
            return (
              <TableRow
                key={spotload._id}
                onClick={() =>
                  handleRedirect("/spotload", {
                    sheet: spotload._id,
                  })
                }
              >
                <TableRowItem
                  rowName="Loading"
                  image={{
                    url: loading.flagUrl,
                    alt: loading.country,
                  }}
                  title={{
                    name: formatLocationName(loading.locationName),
                  }}
                  flex="0.5"
                />
                <TableRowItem
                  rowName="Unloading"
                  image={{
                    url: unloading.flagUrl,
                    alt: unloading.country,
                  }}
                  title={{
                    name: formatLocationName(unloading.locationName),
                  }}
                  flex="0.5"
                />
                <TableRowItem
                  rowName="Truck type"
                  title={{
                    name: truckTypes,
                  }}
                  flex="0.4"
                >
                  <span>
                    {`${spotload.freight.typeOfLoad}`}{" "}
                    {spotload.freight.weight &&
                      ` · ${spotload.freight.weight}t`}{" "}
                    {spotload.freight.loadingMeters &&
                      ` · LDM: ${spotload.freight.loadingMeters}m`}
                  </span>
                </TableRowItem>
                <TableRowItem
                  rowName="Price"
                  title={{
                    name: spotload.targetPrice
                      ? `${spotload.targetPrice} €`
                      : "N/A",
                  }}
                  flex="0.2"
                />
              </TableRow>
            );
          })}
        </TableContent>
      </Table>
    </>
  );
};

export default withRouter(SpotloadOffers);
