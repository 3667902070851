import React, { useState } from "react";
import AsyncLocationInput from "../CreateForm/AsyncLocationInput";
import DateSelect from "../CreateForm/DateSelect";
import TrucktypeSelect from "../CreateForm/TrucktypeSelect";
import NumberInput from "../CreateForm/NumberInput";
import { InlineWrapper, Wrapper } from "../CreateForm/Styles";

const SpotloadCreatorStepOne = (props) => {
  const [selectedUnloadingStartDate, setSelectedUnloadingStartDate] = useState(
    null
  );

  const handleOutsideRange = (date) =>
    !selectedUnloadingStartDate
      ? date.isBefore(props.inputs?.loadingDate?.startDate - 1)
      : date.isBefore(props.inputs?.loadingDate?.endDate);

  return (
    <Wrapper>
      <InlineWrapper>
        <AsyncLocationInput
          label="Loading"
          type="loading"
          onSelectChange={props.onSelectChange}
          handleInputDelete={props.handleInputDelete}
          errors={props.errors}
          inputs={props.inputs}
          maxInputCount={10}
        />
        <DateSelect
          label="Loading date"
          type="loadingDate"
          onInputChange={props.onInputChange}
          error={props.errors?.loadingDate}
          inputs={props.inputs}
        />
      </InlineWrapper>
      <InlineWrapper>
        <AsyncLocationInput
          label="Unloading"
          type="unloading"
          onSelectChange={props.onSelectChange}
          handleInputDelete={props.handleInputDelete}
          errors={props.errors}
          inputs={props.inputs}
          maxInputCount={10}
        />
        <DateSelect
          label="Unloading date"
          type="unloadingDate"
          onInputChange={props.onInputChange}
          error={props.errors?.unloadingDate}
          inputs={props.inputs}
          minDate={props.inputs?.loadingDate?.endDate}
          disabled={!props.inputs?.loadingDate?.endDate}
          isOutsideRange={(d) => handleOutsideRange(d)}
          setSelectedUnloadingStartDate={setSelectedUnloadingStartDate}
        />
      </InlineWrapper>
      <TrucktypeSelect
        onSelectChange={props.onSelectChange}
        onInputChange={props.onInputChange}
        inputs={props.inputs}
        errors={props.errors}
        label="Truck type"
        name="truckType"
      />
      <NumberInput
        label="Target price (EUR)"
        min={0}
        name="targetPrice"
        placeholder="Price"
        inputs={props.inputs}
        value={props.inputs?.targetPrice || ""}
        onInputChange={props.onInputChange}
        error={props.errors?.targetPrice}
      />
    </Wrapper>
  );
};

export default SpotloadCreatorStepOne;
