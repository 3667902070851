import React from "react";

import { Wrapper, List } from "./Styles";
import { Inner } from "../Benefits/Styles";
import { isMobileOnly } from "react-device-detect";
import Item from "./Item";

import cargowell from "../../../images/testimonials/cargowell.svg";
import transway from "../../../images/testimonials/transway.png";
import seifert from "../../../images/testimonials/seifert.png";
import bws from "../../../images/testimonials/bws.svg";
import Container from "../../UI/Container";

const Testimonials = () => {
  return (
    <Wrapper>
      <Container gutter="large">
        <Inner>
          <h3>Feedback</h3>
        </Inner>
      </Container>
      <List>
        <Item
          representative="Karl Gumpold"
          company="Cargowell spedition GmbH"
          img={cargowell}
        >
          Your system is great. We really found somebody and where able to find
          new contacts – one of them already started to work with us and with
          two others we are talking about a cooperation.
        </Item>
        <Item representative="Marko Laur" company="TransWay OÜ" img={transway}>
          It is pretty easy to create tenders and roundtrips. Easy to connect
          with hauliers. We see lots of offers between Scandinavia and Baltic
          countries which is important for us.
        </Item>
        {!isMobileOnly && (
          <>
            <Item
              representative="Axel Kretzschmar"
              company="Seifert Logistics GmbH"
              img={seifert}
            >
              The platform is a very good idea to explicitly contact
              subcontractors looking for a long term cooperation.
            </Item>
            <Item
              representative="Anders Bro Maibom"
              company="Blue Water Shipping"
              img={bws}
            >
              TransConnector is a good tool to get in touch with new suppliers.
              I have had many suppliers contacing me and we are in dialog
              regarding a future corboration on diffenrent jobs. You can easily
              matchup prices in the current market.
            </Item>
          </>
        )}
      </List>
    </Wrapper>
  );
};

export default Testimonials;
