import React from "react";
import { sanitize } from "dompurify";
import SheetBlock from "../../PageWithList/Sheet/SheetBlock";
import {
  ShowWrapper,
  SheetItem,

  HTMLField,
} from "../../PageWithList/Sheet/Styles";
import DetailsSpecialRequests from "../../PageWithList/Sheet/DetailsSpecialRequests";
import { useHistory } from "react-router-dom";

const Details = (props) => {
  const {

    trucks,
    countries,
    requirements,
    additionalInformation,
    languages,

  } = props.roundtrip;

  const history = useHistory();

  return (
    <ShowWrapper show={props.show}>
      <SheetBlock
        label="Request info"
      >
        {countries && (
          <SheetItem>
            <h3>Countries</h3>
            <h4>
              {countries
                ?.map((country) => {
                  return country?.label.replace(/_/g, " ");
                })
                .join(", ")}
            </h4>
          </SheetItem>
        )}
        {trucks?.truckTypes && (
          <SheetItem>
            <h3>Truck type</h3>
            <h4>
              {trucks?.truckTypes
                .map((element) => {
                  if (element.value === "Refrigerator" && trucks?.temperature) {
                    return `${element.value} (${trucks?.temperature}°C)`;
                  }
                  return element.value;
                })
                .join(", ")
                .replace(/_/g, " ")}
            </h4>
            
            {trucks?.additionalInfo && (
              <>
              <h3>Additional Truck info</h3>
              <HTMLField
                dangerouslySetInnerHTML={{
                  __html: sanitize(trucks?.additionalInfo),
                }}
              />
              </>
            )}
          </SheetItem>
        )}
        {requirements && (
          <>
            <DetailsSpecialRequests
              title="Availability"
              specialRequests={requirements}
            />
            {requirements?.otherRequirements && (
              <HTMLField
                dangerouslySetInnerHTML={{
                  __html: sanitize(requirements?.otherRequirements),
                }}
              ></HTMLField>
            )}
          </>
        )}
      </SheetBlock>
    
      <SheetBlock label="Additional info">
        {additionalInformation && (
          <SheetItem>
            <HTMLField
              dangerouslySetInnerHTML={{
                __html: sanitize(additionalInformation),
              }}
            ></HTMLField>
          </SheetItem>
        )}
        {languages && (
          <SheetItem>
            <h3>Languages</h3>
            <h4>
              {languages
                ?.map((language) => {
                  return language.label;
                })
                .join(", ")}
            </h4>
          </SheetItem>
        )}
      </SheetBlock>
    </ShowWrapper>
  );
};

const userIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="19"
    viewBox="0 0 17 19"
  >
    <g transform="translate(-5.5 -4)">
      <path
        d="M22,28.5v-2a4,4,0,0,0-4-4H10a4,4,0,0,0-4,4v2"
        transform="translate(0 -6)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M20,8.5a4,4,0,1,1-4-4A4,4,0,0,1,20,8.5Z"
        transform="translate(-2)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

const emailIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.406"
    height="13.062"
    viewBox="0 0 17.406 13.062"
  >
    <g transform="translate(-2.297 -5.5)">
      <path
        d="M4.6,6H17.4A1.56,1.56,0,0,1,19,7.508v9.046a1.56,1.56,0,0,1-1.6,1.508H4.6A1.56,1.56,0,0,1,3,16.554V7.508A1.56,1.56,0,0,1,4.6,6Z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M19,9l-8,6.428L3,9"
        transform="translate(0 -1.163)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

const phoneIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.2"
    height="21.286"
    viewBox="0 0 15.2 21.286"
  >
    <g transform="translate(-7.5 -2.5)">
      <path
        d="M9.529,3H19.671A2.029,2.029,0,0,1,21.7,5.029V21.257a2.029,2.029,0,0,1-2.029,2.029H9.529A2.029,2.029,0,0,1,7.5,21.257V5.029A2.029,2.029,0,0,1,9.529,3Z"
        transform="translate(0.5 0)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <line
        x2="7.381"
        transform="translate(11 20.015)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default Details;
