import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Table from '../../NewTable/Table';
import Loader from '../UI/Loader';
import CardBlock from '../UI/Card/CardBlock';
import Card from '../UI/Card/Card';
import { CardInline, CardTitle } from '../UI/Card/Styles';

import '../../../styles/Dashboard/SingleCompany.css';
import { getSingleCompany } from '../../../store/actions/dashboard/company';
import Screen from '../Screen/Screen';
import Navbar from '../Navbar';
import ScreenBlock from '../Screen/ScreenBlock';
import LeftDrawer from '../Drawers/LeftDrawer';
import TableHeader from '../../NewTable/TableHeader';
import { TableHeaderItem } from '../../NewTable/Styles';
import TableRow from '../../NewTable/TableRow';
import TableRowItem from '../../NewTable/TableRowItem';
import { isBrowser } from 'react-device-detect';
import Minimessages from '../Messages/Minimessages';
import ReviewModalContent from '../../Modals/ReviewModal/ReviewModalContent';
import AddReview from '../../Modals/ReviewModal/AddReview';
import TenderOffers from '../../FrontPage/Offers/TenderOffers';
import RoundtripOffers from '../../FrontPage/Offers/RoundtripOffers';
import SpotloadOffers from '../../FrontPage/Offers/SpotloadOffers';
import EmptyTruckOffers from '../../FrontPage/Offers/EmptyTruckOffers';
import CreateButton from '../UI/CreateButton';
import {
  CompanyRating,
  ThumbsUp,
  ThumbsDown,
} from '../../PageWithList/List/Styles';

const SingleCompanyProfile = (props) => {
  const [companyId, setCompanyId] = useState(props.match.params.id);
  const [companyData, setCompanyData] = useState([]);
  const [allReviews, setAllReviews] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [addReviewModal, setAddReviewModal] = useState(false);
  const [positiveCount, setPositiveCount] = useState(0);
  const [negativeCount, setNegativeCount] = useState(0);
  const dispatch = useDispatch();
  const scrollRef = useRef(null);

  useEffect(() => {
    if (companyId) {
      dispatch(getSingleCompany(companyId))
        .then((res) => {
          setCompanyData(res.data);
          if (res.data?.reviews) {
            setReviews(res.data?.reviews);
            setAllReviews(res.data?.reviews);
            setPositiveCount(
              res.data?.reviews.filter((r) => r.review === 1)?.length
            );
            setNegativeCount(
              res.data?.reviews.filter((r) => r.review === 0)?.length
            );
          }
        })
        .catch(() => {
          setCompanyId(null);
        });
    }
  }, [companyId]);

  const handleAddReview = (review) => {
    setReviews((prevState) => [review, ...prevState]);
    setAllReviews((prevState) => [review, ...prevState]);
  };

  const handleReviewSort = (sort) => {
    if (sort === 'All reviews') {
      setReviews(allReviews);
    } else if (sort === 'Positive reviews') {
      const sorted = reviews.sort((r) =>
        r.review === 1 ? -1 : r.review === 0 ? 1 : 0
      );
      setReviews(sorted);
    } else if (sort === 'Negative reviews') {
      const sorted = reviews.sort((r) =>
        r.review === 0 ? -1 : r.review === 1 ? 1 : 0
      );
      setReviews(sorted);
    }
  };

  if (!companyId) {
    return <>{props.history.goBack()}</>;
  }

  if (companyData?.length <= 0) {
    return (
      <Screen>
        <Navbar />
        <ScreenBlock>
          <Loader />
        </ScreenBlock>
      </Screen>
    );
  }

  return (
    <Screen>
      <Navbar />
      <div className='Single_Company'>
        <div className='Single_Company--wrapper'>
          <div className='Single_Company--header'>
            <div
              style={{
                width: '100%',
              }}
            >
              <img src={companyData.logo} alt='' />
              <h3>{companyData.companyName}</h3>
            </div>
            <div className='s'>
              <CompanyRating paddingNone>
                <ThumbsUp>
                  {thumbsUp} (<span>{positiveCount}</span>)
                </ThumbsUp>
                <ThumbsDown>
                  {thumbsDown} (<span>{negativeCount}</span>)
                </ThumbsDown>
              </CompanyRating>
              <CreateButton
                title='Add rating'
                isButton
                onClick={() =>
                  window.scrollTo({
                    left: 0,
                    top: scrollRef?.current.offsetTop,
                    behavior: 'smooth',
                  })
                }
              />
            </div>
          </div>
          <div className='Single_Company--content'>
            <div className='Single_Company--content--block'>
              <div className='item'>
                <h4>Company type:</h4>
                <h5>{companyData.companyType}</h5>
              </div>
              <div className='item'>
                <h4>VAT Number:</h4>
                <h5>{companyData.vatNumber}</h5>
              </div>
              <div className='item'>
                <h4>Address:</h4>
                <h5>{companyData.address}</h5>
              </div>
              {companyData.website && (
                <div className='item'>
                  <h4>Website</h4>
                  <h5>{companyData.website}</h5>
                </div>
              )}
              {companyData.yearFounded && (
                <div className='item'>
                  <h4>Founded</h4>
                  <h5>{companyData.yearFounded}</h5>
                </div>
              )}
              <div className='item'>
                <h4>Number of employees</h4>
                <h5>{companyData.noOfEmployees}</h5>
              </div>
            </div>
            <div className='Single_Company--content--block'>
              {companyData.description && <p>{companyData.description}</p>}
            </div>
          </div>
        </div>
      </div>
      <CardInline>
        {companyData?.fleet?.length > 0 && (
          <CardBlock>
            <Card title='Fleet'>
              <Table>
                <TableHeader>
                  <TableHeaderItem flex='0.8'>Truck type</TableHeaderItem>
                  <TableHeaderItem flex='0.2'>Trucks</TableHeaderItem>
                </TableHeader>
                {companyData?.fleet?.map((truck) => {
                  return (
                    <TableRow key={truck._id}>
                      <TableRowItem
                        rowName='Truck type'
                        title={{
                          name: truck.truckType?.replace(/_/gi, ' '),
                        }}
                        flex='0.8'
                      />
                      <TableRowItem
                        rowName='Trucks'
                        title={{
                          name: truck.count,
                        }}
                        flex='0.2'
                      />
                    </TableRow>
                  );
                })}
              </Table>
            </Card>
          </CardBlock>
        )}
        {companyData?.documents?.length > 0 && (
          <CardBlock>
            <Card title='Documents'>
              <Table>
                <TableHeader>
                  <TableHeaderItem flex='1'>File</TableHeaderItem>
                </TableHeader>
                {companyData?.documents?.map((document) => {
                  return (
                    <TableRow key={document._id}>
                      <TableRowItem
                        rowName='File'
                        title={{
                          name: document.name,
                          href: document.url,
                          targetBlank: true,
                        }}
                        flex='1'
                      />
                    </TableRow>
                  );
                })}
              </Table>
            </Card>
          </CardBlock>
        )}
      </CardInline>
      {companyData &&
        companyData?.offers &&
        (companyData?.offers?.roundtrips?.length > 0 ||
          companyData?.offers?.tenders?.length > 0 ||
          companyData?.offers?.spotLoads?.length > 0 ||
          companyData?.offers?.emptyTrucks?.length > 0) && (
          <CardBlock>
            <Card title='Active offers'>
              {companyData?.offers?.spotLoads?.length > 0 && (
                <>
                  <CardTitle>Spot freights</CardTitle>
                  <SpotloadOffers spotloads={companyData?.offers?.spotLoads} />
                </>
              )}
              {companyData?.offers?.roundtrips?.length > 0 && (
                <>
                  <CardTitle>Roundtrips</CardTitle>
                  <RoundtripOffers
                    roundtrips={companyData?.offers?.roundtrips}
                  />
                </>
              )}
              {companyData?.offers?.tenders?.length > 0 && (
                <>
                  <CardTitle>Tenders</CardTitle>
                  <TenderOffers tenders={companyData?.offers?.tenders} />
                </>
              )}
              {companyData?.offers?.emptyTrucks?.length > 0 && (
                <>
                  <CardTitle>Empty trucks</CardTitle>
                  <EmptyTruckOffers
                    emptytrucks={companyData?.offers?.emptyTrucks}
                  />
                </>
              )}
            </Card>
          </CardBlock>
        )}
      {reviews && (
        <div ref={scrollRef}>
          <CardBlock>
            <Card
              positiveCount={positiveCount}
              negativeCount={negativeCount}
              title={`Reviews (${reviews?.length})`}
              button='Add Review'
              onClick={() => setAddReviewModal(true)}
            >
              <ReviewModalContent sort={handleReviewSort} reviews={reviews} />
            </Card>
          </CardBlock>
        </div>
      )}
      {isBrowser && <Minimessages />}
      {addReviewModal && (
        <AddReview
          name={companyData?.companyName}
          id={companyId}
          onClose={() => setAddReviewModal(false)}
          addReview={handleAddReview}
        />
      )}
      <LeftDrawer />
    </Screen>
  );
};

const thumbsUp = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14.8'
    height='14.8'
    viewBox='0 0 14.8 14.8'
  >
    <path
      d='M3.006,6.475H.694A.694.694,0,0,0,0,7.169v6.938a.694.694,0,0,0,.694.694H3.006a.694.694,0,0,0,.694-.694V7.169A.694.694,0,0,0,3.006,6.475ZM1.85,13.644a.694.694,0,1,1,.694-.694A.694.694,0,0,1,1.85,13.644ZM11.1,2.354c0,1.226-.751,1.914-.962,2.733h2.94A1.727,1.727,0,0,1,14.8,6.767a2.093,2.093,0,0,1-.562,1.422l0,0a2.415,2.415,0,0,1-.269,2.3,2.286,2.286,0,0,1-.474,2.161,1.535,1.535,0,0,1-.178,1.29c-.59.848-2.052.86-3.289.86H9.944a8.3,8.3,0,0,1-3.456-.917,4.549,4.549,0,0,0-1.522-.468.347.347,0,0,1-.341-.347V6.889a.346.346,0,0,1,.1-.246C5.873,5.511,6.365,4.313,7.3,3.373a3.5,3.5,0,0,0,.734-1.7C8.166,1.136,8.435,0,9.019,0,9.713,0,11.1.231,11.1,2.354Z'
      fill='#3fbc61'
    />
  </svg>
);

const thumbsDown = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14.8'
    height='14.8'
    viewBox='0 0 14.8 14.8'
  >
    <path
      d='M3.006,8.325H.694A.694.694,0,0,1,0,7.631V.694A.694.694,0,0,1,.694,0H3.006A.694.694,0,0,1,3.7.694V7.631A.694.694,0,0,1,3.006,8.325ZM1.85,1.156a.694.694,0,1,0,.694.694A.694.694,0,0,0,1.85,1.156ZM11.1,12.445c0-1.226-.751-1.914-.962-2.733h2.94A1.727,1.727,0,0,0,14.8,8.033a2.093,2.093,0,0,0-.562-1.422l0,0a2.415,2.415,0,0,0-.269-2.3,2.286,2.286,0,0,0-.474-2.161A1.534,1.534,0,0,0,13.315.86C12.725.012,11.262,0,10.026,0H9.944A8.3,8.3,0,0,0,6.488.917a4.549,4.549,0,0,1-1.522.468.347.347,0,0,0-.341.347V7.911a.346.346,0,0,0,.1.246c1.145,1.131,1.637,2.329,2.576,3.27a3.5,3.5,0,0,1,.734,1.7c.129.535.4,1.671.981,1.671C9.713,14.8,11.1,14.569,11.1,12.445Z'
      fill='#ca2e2e'
    />
  </svg>
);

export default withRouter(SingleCompanyProfile);
