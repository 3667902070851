import React from "react";

const Input = (props) => {
  return (
    <div className="input-collection">
      <div className="input-wrapper">
        {props.label ? <h4>{props.label}</h4> : null}
        <div className="input">
          {props.InputType === "textarea" ? (
            <textarea
              type={props.type}
              name={props.name}
              value={props.value}
              placeholder={props.placeholder}
              onChange={props.onChange}
              required={props.required}
              id={props.id}
              disabled={props.disabled}
              className={props.className}
            >
              {props.defaultValue ? props.defaultValue : null}
            </textarea>
          ) : null}
          {props.InputType === "input" ? (
            <input
              type={props.type}
              name={props.name}
              value={!props.defaultValue ? props.value : undefined}
              placeholder={props.placeholder}
              onChange={props.onChange}
              id={props.id}
              required={props.required}
              disabled={props.disabled}
              className={props.className}
              defaultValue={props.defaultValue}
              min={props.min}
              max={props.max}
              minLength={props.minLength}
              maxLength={props.maxLength}
              pattern={props.pattern}
              autoComplete={props.autocomplete}
            />
          ) : null}
          {props.InputType === "checkbox" ? (
            <label htmlFor={props.name}>
              <input
                type={props.type}
                name={props.name}
                id={props.name}
                value={props.value}
                placeholder={props.placeholder}
                onChange={props.onChange}
                required={props.required}
                disabled={props.disabled}
                className={props.className}
                defaultChecked={props.defaultChecked}
              />
              {props.name}
            </label>
          ) : null}
          {props.InputType === "radio" ? (
            <label htmlFor={props.htmlFor}>
              <input
                type={props.type}
                name={props.name}
                id={props.htmlFor}
                value={props.value}
                placeholder={props.placeholder}
                onChange={props.onChange}
                required={props.required}
                disabled={props.disabled}
                className={props.className}
                defaultChecked={props.defaultChecked}
              />
              {props.htmlFor}
            </label>
          ) : null}
          {props.error ? (
            <div className="error">
              <p>{props.error}</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Input;
