import React from "react";
import Container from "../../components/UI/Container";
import { Static, TermsList } from "./Styles";
import { Link } from "react-router-dom";

const s = `<h2>
I PRIVACY POLICY
</h2>
<h4>
Introduction
</h4>
<p>
The protection of personal data is treated as an important aspect in the operation of TdC Solutions OÜ (hereinafter: “TdC”). TdC provides debt recovery services, which involve the processing of personal data of both TdC clients as well as their debtors and contractors. Therefore, TdC has created this Privacy Policy to implement its obligations under the law, including arising from the provisions of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data (hereinafter referred to as the GDPR). The policy primarily contains information that, according to the regulations, needs to be provided by the personal data controller to the person whose data he processes. For this reason, in the Policy you will find information about the legal basis for the processing of personal data, how it is collected and used, as well as about the related rights of data subjects. TdC uses the provided personal data for the purposes set out in this document or for other purposes that are indicated each time at the time of their transfer to TdC. When collecting and using your personal data, TdC is diligent to maintain transparency regarding the basis and manner of processing personal data.
</p>
<h4>
Explanation of terms
</h4>
<p>
Whenever this Privacy Policy refers to:
</p>
<ul>

<li>personal data – it means any information about an identifiable natural person, including a natural person conducting business activity,

<li>controller – it means an entity that sets the purposes and methods of processing personal data,

<li>processing entity (processor) – it means an entity that processes personal data on behalf of the controller,

<li>data subject – it means a natural person identifiable on the basis of personal data made available to the controller,

<li>data processing – it means any activity on personal data, regardless of whether it is performed in an automated manner or not, e.g. collecting, storing, recording, organizing, modifying, viewing, using, sharing, limiting, deleting or destroying,

<li>recipient – it means a natural or legal person, public authorities, entities or other entities to which personal data is disclosed.
</li>
</ul>
<h4>
When does this Privacy Policy apply?
</h4>
<p>
This Privacy Policy applies only in cases where TdC is the controller of your personal data and processes it. This applies both to the case in which TdC processes personal data that TdC has obtained directly from the data subject, as well as the case in which TdC has obtained personal data from other sources. In both cases, TdC fulfills its reporting obligations set out in art. 13 and art. 14 GDPR in accordance with these provisions.
</p>
<p>
This policy does not apply to the situation when TdC processes your data only as a processing entity (processor) – i.e., for example, in the scope of data of debtors entrusted to TdC for processing by their creditors, who are TdC customers, for the purpose of providing debt recovery services by TdC to creditors (in these situations creditors are personal data controllers).
</p>
<p>
As a personal data processor, TdC informs that it acts as a personal data controller, i.e. as an entity deciding on the purpose and method of data processing, in relation to:<br>1) personal data of natural persons in the scope of performance of the agreement concluded with them (Agreement for the provision of debt recovery services),<br>2) personal data of natural persons from whom TdC purchased the debt as a result of the assignment of the debt,<br>3) data of natural persons who have consented to the processing of their data.
</p>
<p>
On the other hand, in the scope of personal data of debtors (as part of the debt collection service) and contractors, TdC acts as a processing entity (processor). You remain the controller of this personal data. The rules for entrusting data processing are set out in the General Terms and Conditions for Providing Debt Recovery Services available on our website (regarding data entrusted by you to TdC in connection with the ordering of debt recovery services).
</p>
<p>
Below we indicate TdC data as the controller of personal data:
</p>
<p>
TdC Solutions OÜ, with its registered office in Kanarbiku 2, Soinaste küla, Tartumaa.
</p>
<p>
The purposes and legal grounds for processing personal data and the periods of their storage by TdC
</p>
<p>
Your personal data may be processed by TdC:
</p>
<ul>

<li>in order to carry out preparations for concluding with you a debt recovery agreement or an assignment of debt agreement (including consideration of a debt sale offer submitted by you) – pursuant to art. 6 par. 1 lit. b) of the GDPR, i.e. when it is necessary to take action at your request before the conclusion of the agreement (data is stored by TdC until the end of these activities or until the conclusion of the agreement),

<li>in order for TdC to perform the agreement concluded with you (including, i.a., agreement regarding debt recovery) – pursuant to art. 6 par. 1 lit. b) of the GDPR, i.e. when it is necessary to perform the agreement concluded with you (data is stored by TdC for the duration of the agreement, settlements after its termination, and then the limitation period of any claims arising from its title),

<li>in order to possibly determine and pursue claims or defend itself against them – pursuant to art. 6 par. 1 lit. f) of the GDPR, i.e. when the legal basis for processing is the legitimate interest of TdC in protecting its rights (the data is stored by TdC until the limitation of claims of TdC arising from the agreement – the limitation period is determined by law, in particular the Civil Code),

<li>for analytical and statistical purposes – pursuant to art. 6 par. 1 lit. f) of the GDPR, i.e. when the legal basis for processing is the legitimate interest of TdC – improving the quality of services and adapting them to the needs of customers (data is stored by TdC for a period of 3 years from the date of termination of the agreement or from the event causing such processing),

<li>for direct marketing purposes – pursuant to art. 6 par. 1 lit. f) of the GDPR, i.e. when the legal basis for processing is TdC’s legitimate interest consisting in the controller’s right to process personal data for direct marketing purposes in motif 47 of the GDPR (data is stored by TdC until the data subject expresses effective objection).

<li>for other types of marketing – pursuant to art. 6 par. 1 lit. a) of the GDPR, i.e. based on your consent (data is stored by TdC until the data subject withdraws his consent for further processing of his data for this purpose),

<li>in order to fulfil the obligations provided for by law (including, for example, accounting, tax) – pursuant to art. 6 par. 1 lit. c) of the GDPR. (data are processed by TdC for the period required by the provisions of the law, i.e. Enforcing on the data controller storage of accounts until the time of expiry of tax liability).
</li>
</ul>
<p>
Your personal data are processed by TdC, among others, in relation to servicing the concluded agreements for the time necessary to realize services and for the time in which pursuing claims related to the provided services is possible. Your data may be stored by TdC also for the purposes of preventing abuses and frauds, for statistical and archiving purposes for the period of 3 years from the date of agreement expiry or from an event causing the need of such processing. Should a consent constitute the basis of data processing, such data shall be used by TdC until the time of its withdrawal. For the period of 5 years counted from the date of withdrawal of such a consent for the processing of data, TdC may however store data in a limited scope for the purpose of confirming compliance of the processing of such data with the provisions of GRDP, unless TdC, as data controller, based on other legal bases is obliged to process your data for a longer period of time. At the same time, for the purpose of settlements, TdC shall store data for the period in which TdC is obliged to maintain data or documents that contain such data in order to document execution of its legal obligations, including enabling inspections of their execution by public authorities.
</p>
<h4>
Voluntariness of submission of personal data
</h4>
<p>
Submission of personal data in the above stated purposes is of voluntary nature, however, their non-submission may result in failure to conclude agreement between you and TdC (i.e. In case when it is necessary to fill out the form by entering your personal data).
</p>
<p>
Rights of person whose data are processed by TdC as personal data controller
</p>
<p>
In case when TdC processes your personal data as their controller, you are entitled to:<br>– access your data and obtain their copies,<br>– adjust (correct) your data,<br>– remove data, limit their processing,
</p>
<p>
– submit an objection against their processing (including profiling),<br>– transfer data,<br>– submit complaints to the supervisory organ,<br>– withdraw consent for the processing of personal data should the processing of such data occur pursuant to the granted consent (Article 6, sec. 1 letter f of GRDP)
</p>
<h4>
Information on recipients and categories of personal data recipients
</h4>
<p>
Your personal data may be disclosed:
</p>
<ul>

<li>authorized personnel or contractors of TdC, including entities providing selected services towards TdC (accounting services, marketing, IT services, entities servicing electronic payments – in such scope as it is necessary for the provision of these services),

<li>business partners of TdC (entities supporting our services).
</li>
</ul>
<h4>
Automated making of decisions and profiling
</h4>
<p>
In the scope necessary for conclusion or conduct of agreement with TdC, your personal data may be processed in an automated manner, which may be related to an automated making of decisions, including profiling, which may evoke legal effects towards you or impact your situation in another significant manner. Data submitted by you in forms available on the website <a href="http://tdc-solutions.eu">tdc-solutions.eu</a>, as well as data concerning the so far history of cooperation between TdC and the client, should we have such data at disposal, including data regarding the history of debt-recovery orders carried out at the order and/or towards you may be subjected to an automated analysis, further to generally accessible data originating from the state registers, data from credit information agencies and debt exchange base maintained by TdC. Within the process of assessing the possibilities of submitting an offer of conclusion of agreement, statistical models are applied, as a result of which your creditworthiness and credibility towards entering into commitments with TdC. In case of revealing through this lack or insufficient creditworthiness or credibility to enter into a given commitment, TdC will refuse to conclude an agreement and provision of service to you.
</p>
<h4>
Declaration concerning Privacy Policy
</h4>
<p>
The hereby Privacy Policy has come into force on 1/02/2024. TdC reserves the right to amend the hereby Privacy Policy should this turn out to be necessary as a result of introducing modifications in our policy, management, right or jurisdiction. Any amendments shall be binding from the date of their publication on the website <a href="http://tdc-solutions.eu">tdc-solutions.eu</a> along with an indication of the date of their entry into force.
</p>
<h4>
Contact data
</h4>
<p>
Should you have any questions or concerns regarding the Privacy Policy, as well as should you wish to avail of the rights of recipient of data which TdC processes as controller (that is the right to access one’s data or obtain their copy, adjust/correct, remove data, limit data processing, submit an objection towards data processing, including reproduction, transfer of data, withdrawal of consent for personal data processing) please contact us through email at <a href="mailto:info@tdc-solutions.eu">info@tdc-solutions.eu</a>.
</p>
<h2>
II. COOKIES POLICY
</h2>
<h4>
Privacy Statement
</h4>
<p>
The hereby appendix regulates the policy applied by TdC Solutions OÜ with its seat in Kanarbiku 2, Soinaste küla, Tartumaa, Estonia, its subcontractors and related entities, in the scope of protection of personal data of Users and information which might constitute secrecy of Users in the enterprise.
</p>
<p>
The hereby policy refers to the services offered by the Controller on the following the website <a href="http://tdc-solutions.eu">tdc-solutions.eu</a> and on the forms belonging to <a href="http://tdc-solutions.eu">tdc-solutions.eu</a>.
</p>
<h4>
Cookies files
</h4>
<p>
The Controller applies the so-called “cookie” files in order to track User visits and save their preferences, such as language and log in data. Since it is possible to switch off cookie files in the internet browser, one may be prevented from availing of all products and services.
</p>
<h4>
Data in Google Analytics
</h4>
<p>
Traffic on the websites of the Controller is monitored by the analytical system Google Analytics, the objective of which is to gather data on the manner of using the websites and theire popularity. Such data include, among others, used browsers or IP addresses. These shall never be disclosed to any third parties.
</p>
<h4>
Storing data
</h4>
<p>
In order to avail of the services and products offered by the Controller, one must register an at the website <a href="http://tdc-solutions.eu">tdc-solutions.eu</a> or contact via contact form at the website <a href="http://tdc-solutions.eu">tdc-solutions.eu</a> as well as data gathered passively such as IP address, resolution, location and type of browser.
</p>
<h4>
Use of data
</h4>
<p>
Contact information will be used solely for the purpose:
</p>
<ul>

<li>necessary for the execution of agreement concluded between the User and the Controller

<li>granting answers to the asked questions/complaint,

<li>enabling establishing contact between Users for the purpose of negotiating/concluding agreement/order,

<li>to send our information brochure concerning changes in the software, Terms and Conditions etc.

<li>passing of information concerning the product, should the user be interested in same.
</li>
</ul>
<p>
Storing of personal data may also be used by the Controller for the purpose of sending to the User, pursuant to their consent expressed in accordance with the provisions of the Terms and Conditions, information regarding current and future products and services. The user may however unsubscribe from obtaining such information, by sending a message to the address <a href="mailto:info@tdc-solutions.eu">info@tdc-solutions.eu</a> (so-called withdrawal of consent for obtaining commercial information). Having withdrawn consent for obtaining commercial information these may continue to be sent to the user as information bulletin, concerning changes in the software, Terms and Conditions etc.
</p>
<h4>
Disclosure of data to third parties
</h4>
<p>
We reserve the right to disclose personal data of users to our related entities and subsidiaries of the Controller as well as to subcontractors in the scope necessary for their execution of agreements for service provision. The Controller maintains due diligence upon transferring data and applies such means and protections that prevent access to data by unauthorized persons (among others, SSL, connection encryption).
</p>
<p>
Without user consent personal data will not be disclosed to any third parties, with the exception of the circumstances in which the handover occurs as a result of legally justified demand of an authorized entity (among others: law enforcement body or justice authority).
</p>
<h4>
Access to data
</h4>
<p>
The user is entitled to view, correct or object to the use of his or her personal data. Upon obtaining a written demand and a document confirming identity of a User, the Controller corrects or removes personal data of that User from the database. The Controller will make all justified efforts in order to fulfil the demands of users concerning removal of personal data, unless these data must be retained on account of the law in place or in line with the law for business purposes. 
</p>
<h4>
Safety
</h4>
<p>
In order to ensure safety, the Controller:
</p>
<ul>

<li>Applies SSL encryption,

<li>Methods of storage, collection and processing of information, including physical means of safety are controlled in order to protect against an unauthorized access to the system,

<li>Access to personal data is granted solely to employees, contractors and representatives who must be authorized to such access in order to process them for the needs of the Controller. Furthermore, pursuant to the agreement they are obliged to maintain strict secrecy and in case of failure to fulfil these obligations they may be subjected to consequences, including termination of cooperation.
</li>
</ul>
<h4>
Declaration concerning Cookie Policy
</h4>
<p>
The hereby Cookie Policy has come into force on 01/02/2024. The Controller reserves the right to amend the hereby Cookie Policy should this turn out to be necessary as a result of introducing modifications in our policy, management, right or jurisdiction. Amendments shall become valid on the date of their publication on the website <a href="http://tdc-solutions.eu/en/privacy_policy">tdc-solutions.eu/en/privacy_policy</a> together with the date of their introduction.
</p>
<h4>
Contact info
</h4>
<p>
If you have questions or comments regarding cookies policy, please contact us by e-mail: <a href="mailto:info@tdc-solutions.eu">info@tdc-solutions.eu</a>.
</p>
<p>
TdC Solutions - Transport Debt Collection<br>Kanarbiku 2, Soinaste küla, 61709 Tartumaa, Estonia
</p>
<p>
Phone: +372 55 514 514, <a href="mailto:info@tdc-solutions.eu">info@tdc-solutions.eu</a>, <a href="http://www.tdc-solutions.eu">www.tdc-solutions.eu</a>
</p>
`

const o = {
  __html: s
}

const Terms = () => {
  return (
    <Container gutter="large">
      <Static>
        <h1>TdC Solutions privacy policy and personal data processing</h1>
        <TermsList>
          <div dangerouslySetInnerHTML={o} />
        </TermsList>
        </Static>
    </Container>
  );
};

export default Terms;