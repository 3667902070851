import { SET_ROUNDTRIPS } from "../../actionTypes";
import axios from "axios";
import { API_URL } from "../../../constants";
import { getToken } from "../../../helpers/getToken";

export const getRoundtrips = () => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    axios
      .get(`${API_URL}/roundtrip`, config)
      .then((res) => {
        dispatch({
          type: SET_ROUNDTRIPS,
          payload: res.data?.roundtrips,
        });
      })
      .catch((err) => {
        // TODO: SET ERROR
      });
  };
};

export const getFilterFindRoundtrip = (params) =>{
  const token = getToken();
  return () => {
    return axios.get(`${API_URL}/find-roundtrip`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      
      params: {
        ...params,
      },
      
    });
  };
}

export const getFilterRoundtrip = (params) => {
  const token = getToken();
  return () => {
    return axios.get(`${API_URL}/roundtrip`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      params: {
        ...params,
      },
    });
  };
};

export const getSingleRoundtrip = (id) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios.get(`${API_URL}/roundtrip/${id}`, config);
  };
};

export const getSingleFindRoundtrip = (id) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios.get(`${API_URL}/find-roundtrip/${id}`, config);
  };
};

export const validateRoundtrip = (url, data) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios.post(url, data, config);
  };
};

export const createRoundtrip = (method, url, data) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return () => {
    return axios({
      method: method,
      url: url,
      data: data,
      ...config,
    });
  };
};
