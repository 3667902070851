import React, { useState } from "react";
import { DateWrapper } from "./Styles";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { isMobile } from "react-device-detect";

const DateFilter = (props) => {
  const [focused, setFocused] = useState(null);
  return (
    <DateWrapper>
      <DateRangePicker
        startDate={props.filters?.[props.type]?.startDate || null}
        startDateId={`${props.type}Start`}
        startDatePlaceholderText={props.placeholderStart}
        endDate={props.filters?.[props.type]?.endDate || null}
        endDateId={`${props.type}End`}
        endDatePlaceholderText={props.placeholderEnd}
        onDatesChange={({ startDate, endDate }) => {
          props.onInputChange({ startDate, endDate }, props.type);
        }}
        readOnly={props.readOnly}
        showClearDates={props.clearable}
        displayFormat="DD.MM.YYYY"
        minimumNights={0}
        focusedInput={focused}
        onFocusChange={(focused) => setFocused(focused)}
        withPortal={isMobile ? true : false}
        numberOfMonths={1}
        firstDayOfWeek={1}
      />
    </DateWrapper>
  );
};

export default DateFilter;
