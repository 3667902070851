import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const CreateButton = (props) => {
  return (
    <ButtonWrapper centered={props.centered} white={props.white}>
      {props.to && !props.isButton && !props.directLink && (
        <Link to={props.to}>
          {props.title} {!props.noIcon && add}
        </Link>
      )}
      {props.to && !props.isButton && props.directLink && (
        <a href={props.to}>
          {props.title} {!props.noIcon && add}
        </a>
      )}
      {!props.to && props.isButton && !props.directLink && (
        <button type="button" onClick={props.onClick}>
          {props.title} {!props.noIcon && add}
        </button>
      )}
    </ButtonWrapper>
  );
};

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
  a,
  button {
    color: #fff;
    background: #4785ff;
    padding: 0.25rem 0.25rem 0.25rem 1rem;
    font-family: "Inter";
    font-weight: 600;
    font-size: 13px;
    text-decoration: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    transition: 0.25s all ease;
    white-space: nowrap;
    svg {
      margin-left: 0.5rem;
    }
    &:hover {
      transition: 0.25s all ease;
      cursor: pointer;
      background: #5991ff;
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #a0d1fa;
    }
  }
  button {
    border: none;
  }
  @media (min-width: 578px) {
    width: auto;
    a {
      width: max-content;
      white-space: nowrap;
    }
  }
  ${(props) =>
    props.centered &&
    css`
      width: 100%;
      justify-content: center;
    `}
  ${(props) =>
    props.white &&
    css`
      a,
      button {
        padding: 16px;
        height: 37px;
        color: #262a68;
        background: #fff;
        border: 1px solid #262a68;
        &:hover {
          background: #f1f5f9;
        }
      }
    `}
`;

const add = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="29"
    viewBox="0 0 32 29"
  >
    <g transform="translate(-9473 19430)">
      <rect
        width="32"
        height="29"
        rx="4"
        transform="translate(9473 -19430)"
        fill="#97baff"
      />
      <path
        d="M-1.051-1.016h2.1V-5.25H5.285v-2.1H1.051v-4.227h-2.1v4.227H-5.277v2.1h4.227Z"
        transform="translate(9489 -19409)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default CreateButton;
