import React, { useState } from "react";
import styled from "styled-components";
import Modal from "../../Modals/Modal";
import { useDropzone } from "react-dropzone";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

// Modal.setAppElement("#root"); // Set the root element for the modal

// const ModalWrapper = styled.div`
//   text-align: center;
// `;

// const ModalContent = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 20px;
// `;

const UploadButton = styled.button`
  color: #fff;
  background: #00b27e;
  font-family: "Inter var", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  align-self: center;
  border: none;
  border-radius: 4px;
  width: fit-content;
  padding: 0.5rem 1rem;
  margin: 1.5rem 0 0;
  transition: 0.25s all ease;
  &:hover {
    transition: 0.25s all ease;
    background: #07ce94;
    border-color: #07ce94;
  }
`;

const DropzoneArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  background: #f9fafd;
  color: #9da9c0;
  border: 1px dashed #9da9c0;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.85rem;
  right: 0;
  width: 35px;
  height: 35px;
  border: none;
  background: transparent;
  color: #d9d9d9;
  font-size: 2rem;
  font-weight: 600;
  transition: 0.25s all ease;
  &:hover {
    transition: 0.25s all ease;
    color: #262a68;
  }

  @media (min-width: 992px) {
    right: 1rem;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Heading = styled.h2`
  font-size: 1.25rem;
  color: #262a68;
  line-height: 1.6;
  margin: 0 0 1rem;
  font-weight: 600;
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 0;
  a {
    color: #0074d9;
    text-decoration: none;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const DelimiterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  gap: 20px;
`;

const DelimiterLabel = styled.label`
  width: 120px;
`;

const DelimiterSelect = styled.select`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: fit-content;
`;

const Uploader = ({ onClose, onUpload }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [wrongFile, setWrongFile] = useState(false);
  const [delimiter, setDelimiter] = useState(",");

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    // if file is not csv, setWrongFile(true)
    if (file.type !== "text/csv") {
      setWrongFile(true);
      return;
    }
    setWrongFile(false);
    setUploadedFile(file);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleUpload = () => {
    if (uploadedFile) {
      onUpload({ file: uploadedFile, delimiter });
      setUploadedFile(null);
    }
    // onClose();
  };

  const handleDelimiterChange = (e) => {
    setDelimiter(e.target.value);
  };

  return (
    <Modal small customPadding="1.5rem">
      <Header>
        <Heading>Add Loads from CSV</Heading>
        <CloseButton onClick={onClose}>&times;</CloseButton>
      </Header>
      {/* <UploadButton>
        <input {...getInputProps()} />
        Browse File
      </UploadButton> */}
      {uploadedFile ? (
        <p>File selected: {uploadedFile.name}</p>
      ) : (
        <DropzoneArea {...getRootProps()}>
          <input {...getInputProps()} />
          Browse or drag and drop CSV file
        </DropzoneArea>
      )}
      <DelimiterWrapper>
        <DelimiterLabel htmlFor="delimiter">Data delimiter:</DelimiterLabel>
        <DelimiterSelect
          id="delimiter"
          value={delimiter}
          onChange={handleDelimiterChange}
        >
          <option value=",">,</option>
          <option value=";">;</option>
          <option value=".">.</option>
          <option value=":">:</option>
          <option value="-">-</option>
          <option value="_">_</option>
          <option value="|">|</option>
        </DelimiterSelect>
      </DelimiterWrapper>

      {wrongFile ? (
        <p style={{ color: "red" }}>
          Please upload a CSV file. Other file types are not supported.
        </p>
      ) : (
        uploadedFile && (
          <UploadButton onClick={handleUpload}>Upload</UploadButton>
        )
      )}
      <LinkWrapper>
        <Link to={"/loadupload"}>Read More About CSV Upload</Link>
      </LinkWrapper>
    </Modal>
  );
};

export default Uploader;
