import React from "react";
import NumberInput from "../../CreateForm/NumberInput";
import Textarea from "../../CreateForm/Textarea";
import { Wrapper } from "../../CreateForm/Styles";

const TenderCreatorStepThree = (props) => {
  return (
    <Wrapper>
      <NumberInput
        label="Payment time"
        name="paymentTime"
        placeholder="Days"
        min={0}
        inputs={props.inputs}
        value={props.inputs?.paymentTime || ""}
        onInputChange={props.onInputChange}
        error={props.errors?.paymentTime}
      />
      <Textarea
        label="Comments"
        name="paymentComments"
        placeholder="Payment comments"
        inputs={props.inputs}
        onInputChange={props.onInputChange}
        error={props.errors?.extraInfo}
      />
      <Textarea
        label="Additional information"
        name="addInfo"
        placeholder="Additional information about tender"
        inputs={props.inputs}
        onInputChange={props.onInputChange}
        error={props.errors?.addInfo}
      />
    </Wrapper>
  );
};

export default TenderCreatorStepThree;
