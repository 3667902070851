import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import IntroImage from "../../../images/hero2.png";
import Container from "../../UI/Container";
import LinkButton from "../../Buttons/NewButton/LinkButton";
import dripLogo from "../../../images/Ebene_1.svg";

import { Content, TextContent, HeroImage } from "./Styles";
import { Gap } from "../../FrontPage/Accompaniments2/Styles";

const PartnersSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
  gap: 2rem;
  span {
    color: #262a68;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  .partners {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: fit-content;
    max-width: 300px;
    img {
      width: 100%;
      max-width: 100px;
    }
  }
  @media (min-width: 1200px) {
    .partners {
      justify-content: flex-start;
      }
    }
  }
`;

const Hero2 = ({ USER }) => {
  return (
    <Container gutter="medium">
      <Content>
        <TextContent>
          <h1>Seamlessly connecting shippers with carriers</h1>
          <p>
            Get immediate access to hundreds of thousands of loads and approved
            contractors. Discover & collaborate with reputable companies
            throughout Europe
          </p>
          {!USER && (
            <>
              <LinkButton landingPage to="/sign-up" centered="mobile">
                Get Started!
              </LinkButton>
              <Gap height="2rem" />
            </>
          )}
          <PartnersSection>
            <span>Our Partners:</span>
            <div className="partners">
              <a
                href="https://drip-log.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={dripLogo} alt="" />
              </a>
            </div>
          </PartnersSection>
        </TextContent>
        <HeroImage>
          <img src={IntroImage} alt="" />
        </HeroImage>
      </Content>
    </Container>
  );
};

export default Hero2;
