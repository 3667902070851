import React from "react";
import { Link } from "react-router-dom";
import {
  CardWrapper,
  CardHeader,
  CardContent,
  IconWrapper,
  Icon,
} from "./Styles";
import CreateButton from "../CreateButton";
import {
  CompanyRating,
  ThumbsUp,
  ThumbsDown,
} from "../../../PageWithList/List/Styles";

const Card = (props) => {
  return (
    <CardWrapper>
      {props.title && !props.to && !props.button && (
        <CardHeader>
          <h3>{props.title}</h3>
        </CardHeader>
      )}
      {props.title && props.to && props.privileges === "Admin" && (
        <CardHeader>
          <Link to={props.to}>
            {props.title}
            <IconWrapper>
              <span>Edit</span>
              <Icon>{pen}</Icon>
            </IconWrapper>
          </Link>
        </CardHeader>
      )}
      {props.title && !props.to && props.button && (
        <CardHeader>
          <h3>
            {props.title}{" "}
            {props.positiveCount && props.negativeCount && (
              <CompanyRating paddingNone paddingLeft posAbs>
                <ThumbsUp>
                  {thumbsUp} (<span>{props.positiveCount}</span>)
                </ThumbsUp>
                <ThumbsDown>
                  {thumbsDown} (<span>{props.negativeCount}</span>)
                </ThumbsDown>
              </CompanyRating>
            )}
          </h3>
          <IconWrapper nobg>
            <CreateButton
              isButton
              title={props.button}
              onClick={props.onClick}
            />
          </IconWrapper>
        </CardHeader>
      )}
      <CardContent>{props.children}</CardContent>
    </CardWrapper>
  );
};

const pen = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.002"
    height="12.003"
    viewBox="0 0 12.002 12.003"
  >
    <path
      d="M3,12.5V15H5.5l7.374-7.374-2.5-2.5ZM14.807,5.693a.664.664,0,0,0,0-.94l-1.56-1.56a.664.664,0,0,0-.94,0l-1.22,1.22,2.5,2.5,1.22-1.22Z"
      transform="translate(-3 -2.997)"
      fill="#6F6F6F"
    />
  </svg>
);

const thumbsUp = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.8"
    height="14.8"
    viewBox="0 0 14.8 14.8"
  >
    <path
      d="M3.006,6.475H.694A.694.694,0,0,0,0,7.169v6.938a.694.694,0,0,0,.694.694H3.006a.694.694,0,0,0,.694-.694V7.169A.694.694,0,0,0,3.006,6.475ZM1.85,13.644a.694.694,0,1,1,.694-.694A.694.694,0,0,1,1.85,13.644ZM11.1,2.354c0,1.226-.751,1.914-.962,2.733h2.94A1.727,1.727,0,0,1,14.8,6.767a2.093,2.093,0,0,1-.562,1.422l0,0a2.415,2.415,0,0,1-.269,2.3,2.286,2.286,0,0,1-.474,2.161,1.535,1.535,0,0,1-.178,1.29c-.59.848-2.052.86-3.289.86H9.944a8.3,8.3,0,0,1-3.456-.917,4.549,4.549,0,0,0-1.522-.468.347.347,0,0,1-.341-.347V6.889a.346.346,0,0,1,.1-.246C5.873,5.511,6.365,4.313,7.3,3.373a3.5,3.5,0,0,0,.734-1.7C8.166,1.136,8.435,0,9.019,0,9.713,0,11.1.231,11.1,2.354Z"
      fill="#3fbc61"
    />
  </svg>
);

const thumbsDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.8"
    height="14.8"
    viewBox="0 0 14.8 14.8"
  >
    <path
      d="M3.006,8.325H.694A.694.694,0,0,1,0,7.631V.694A.694.694,0,0,1,.694,0H3.006A.694.694,0,0,1,3.7.694V7.631A.694.694,0,0,1,3.006,8.325ZM1.85,1.156a.694.694,0,1,0,.694.694A.694.694,0,0,0,1.85,1.156ZM11.1,12.445c0-1.226-.751-1.914-.962-2.733h2.94A1.727,1.727,0,0,0,14.8,8.033a2.093,2.093,0,0,0-.562-1.422l0,0a2.415,2.415,0,0,0-.269-2.3,2.286,2.286,0,0,0-.474-2.161A1.534,1.534,0,0,0,13.315.86C12.725.012,11.262,0,10.026,0H9.944A8.3,8.3,0,0,0,6.488.917a4.549,4.549,0,0,1-1.522.468.347.347,0,0,0-.341.347V7.911a.346.346,0,0,0,.1.246c1.145,1.131,1.637,2.329,2.576,3.27a3.5,3.5,0,0,1,.734,1.7c.129.535.4,1.671.981,1.671C9.713,14.8,11.1,14.569,11.1,12.445Z"
      fill="#ca2e2e"
    />
  </svg>
);

export default Card;
