import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Modal from "../Modal";
import Header from "../Header";
import { ModalInnerWrapper } from "../Styles";
import {
  QuotationInnerHeader,
  QuotationInnerHeaderWrapper,
  TenderInfo,
  RightArrow,
  HeaderSubInfo,
  QuotationTableHeaderWrapper,
} from "./Styles";
import { BackWrapper, Back } from "../../PageWithList/Sheet/Styles";

import Table from "../../NewTable/Table";
import TableHeader from "../../NewTable/TableHeader";
import { TableHeaderItem } from "../../NewTable/Styles";
import TableRow from "../../NewTable/TableRow";
import TableRowItem from "../../NewTable/TableRowItem";

import Buttons from "../../Buttons/Buttons";
import Button from "../../Buttons/Button";

import { isBrowser } from "react-device-detect";
import moment from "moment";
import TableContent from "../../NewTable/TableContent";
import { SET_QUOTATIONS } from "../../../store/actionTypes";
import {
  sortQuotations,
  setQuotationStatus,
} from "../../../store/actions/dashboard/quotation";
import { sendUserMessage } from "../../../store/actions/misc";

import { DeclineWrapper } from "../../NewTable/Styles";
import Textarea from "../../CreateForm/Textarea";

const QuotationModal = (props) => {
  const {
    tender,
    offers,
    count,
  } = props.CURRENT_QUOTATIONS.recievedQuotations.find(
    (quotation) => quotation._id === props.id
  );
  const [sorting, setSorting] = useState(null);
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState(null)
  const [quoteDeclined, setQuoteDeclined] = useState(false)

  const handleSorting = (type) => {
    if (isBrowser)
      setSorting((prevState) => ({
        sortBy: type,
        order:
          prevState?.sortBy === type
            ? prevState.order === "DESC"
              ? "ASC"
              : "DESC"
            : "DESC",
      }));
  };

  const { onSetQuotations } = props;
  useEffect(() => {
    if (sorting) {
      dispatch(sortQuotations(sorting.sortBy, sorting.order))
        .then((res) => {
          onSetQuotations(res.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [sorting, onSetQuotations, dispatch]);

  const handleAction = (
    id,
    tenderId,
    status,
    recieverId,
    price,
    trucks,
    period
  ) => {
    if (status === 2 && !inputs?.declineReason) {
      setQuoteDeclined({
        id,
        tenderId,
        status,
        recieverId,
        price,
        trucks,
        period,
      })
    }
    else {

      dispatch(setQuotationStatus(id, status, inputs?.declineReason ? inputs.declineReason : undefined))
        .then((res) => {
          props.onSetQuotations(res.data);
          setQuoteDeclined(false)
          setInputs(null)
          sendMessage(recieverId, tenderId, status, { price, trucks, period });

        })
        .catch((err) => console.log("err", err));

    }
  };

  const handleInputChange = (e) => {
    setInputs({ ...inputs, "declineReason": e });
  };

  const sendMessage = (recieverId, originate, status, { price, trucks, period }) => {
    let OFFER_MESSAGE = null;
    if (status === 1) {
      OFFER_MESSAGE = `<p>Your offer to <strong>${tender.name}</strong> was marked as interested.</p><p><br></p><p>In order to get load and contract details, continue your conversation here or in emails.</p>`;
    }
    else {
      OFFER_MESSAGE = `<p>Your offer to <strong>${tender.name}</strong> was declined.</p><p><br></p>`;
    }
    dispatch(
      sendUserMessage(
        recieverId,
        tender.name,
        OFFER_MESSAGE,
        "Tender",
        originate,
        true
      )
    );
  };

  return (
    <Modal onClose={props.onClose} fullscreenMobile>
      <Header noBorder onClose={props.onClose} />
      <ModalInnerWrapper>
        <QuotationInnerHeaderWrapper>
          <QuotationInnerHeader>
            <TenderInfo>
              <BackWrapper desktopHide onClick={props.onClose}>
                <Back>{back}</Back>
              </BackWrapper>
              <h4>Tender</h4>
              <img
                src={tender.loading[0].flagUrl}
                alt={tender.loading[0].country}
              />
              <p>{tender.loading[0].locationName}</p>
              <RightArrow>{rightArrow}</RightArrow>
              <img
                src={tender.unloading[0].flagUrl}
                alt={tender.unloading[0].country}
              />
              <p>{tender.unloading[0].locationName}</p>
            </TenderInfo>
            <HeaderSubInfo>
              <div>
                <h4>Deadline</h4>
                <h5>{moment(tender.offerIsActive).format("DD.MM.YY")}</h5>
              </div>
              <div>
                <h4>Offers</h4>
                <h5>{count}</h5>
              </div>
            </HeaderSubInfo>
          </QuotationInnerHeader>
        </QuotationInnerHeaderWrapper>
        <QuotationTableHeaderWrapper>
          <h2>Recieved Quotations</h2>
        </QuotationTableHeaderWrapper>
        <Table>
          <TableHeader>
            <TableHeaderItem
              sortable={offers?.length > 1}
              flex="0.2"
              onClick={() => offers?.length > 1 && handleSorting("1")}
            >
              Date {offers?.length > 1 && sortBy}
            </TableHeaderItem>
            <TableHeaderItem flex="0.3">Company</TableHeaderItem>
            <TableHeaderItem
              sortable={offers?.length > 1}
              flex="0.2"
              onClick={() => offers?.length > 1 && handleSorting("3")}
            >
              Price {offers?.length > 1 && sortBy}
            </TableHeaderItem>
            <TableHeaderItem flex="0.2">Trucks</TableHeaderItem>
            <TableHeaderItem flex="0.3">Comment</TableHeaderItem>
            <TableHeaderItem flex="0.5"></TableHeaderItem>
          </TableHeader>
          <TableContent>
            {offers.map((offer) => {
              return (
                <>
                  <TableRow key={offer._id}>
                    <TableRowItem
                      overflow
                      rowName="Date"
                      title={{
                        name: moment(offer.createdAt).format("DD.MM.YY"),
                      }}
                      flex="0.2"
                    />
                    <TableRowItem
                      rowName="Company"
                      alignItems
                      title={{
                        name: offer.author,
                        to: `/dashboard/company/${offer.offerAuthorCompany}`,
                        targetBlank: true,
                      }}
                      flex="0.3"
                    />
                    <TableRowItem
                      rowName="Price"
                      title={{
                        name: `${offer.price} €`,
                      }}
                      flex="0.2"
                    />
                    <TableRowItem
                      rowName="Trucks"
                      title={{
                        name: `${offer.noOfTrucks} x ${offer.period}`,
                      }}
                      flex="0.2"
                    />
                    <TableRowItem
                      alignItems
                      rowName="Comment"
                      html={offer.comment}
                      flex="0.3"
                    />
                    {offer.status === 0 && (
                      <TableRowItem flex="0.5">
                        <Buttons centered column>
                          <Button
                            type="primary"
                            title="Interested"
                            onClick={() =>
                              handleAction(
                                offer._id,
                                offer.tenderId,
                                1,
                                offer.offerAuthor,
                                offer.price,
                                offer.noOfTrucks,
                                offer.period
                              )
                            }
                          />
                          {!quoteDeclined && (quoteDeclined.id !== offer._id) && (
                            <Button
                              type="alert"
                              title="Decline"
                              onClick={() =>
                                handleAction(
                                  offer._id,
                                  offer.tenderId,
                                  2,
                                  offer.offerAuthor,
                                  offer.price,
                                  offer.noOfTrucks,
                                  offer.period
                                )
                              }
                            />
                          )}
                        </Buttons>
                      </TableRowItem>
                    )}
                    {offer.status === 1 && (
                      <TableRowItem
                        transform="true"
                        icon={checkmark}
                        title={{
                          name: "Interested",
                        }}
                        flex="0.5"
                      >
                        <Buttons>
                          <Button
                            type="secondary"
                            title="Open chat"
                            onClick={() => {
                              props.history.push("/dashboard/messages", {
                                showMessage: offer?.conversationId,
                              });
                            }}
                          />
                        </Buttons>
                      </TableRowItem>
                    )}
                    {offer.status === 2 && (
                      <TableRowItem
                        transform="true"
                        icon={declined}
                        alignItems
                        title={{
                          name: "Declined",
                        }}
                        flex="0.5"
                      />
                    )}
                    {quoteDeclined && quoteDeclined.id == offer._id && (
                      <DeclineWrapper>
                        <Textarea
                          name="declineReason"
                          label="Reason for decline"
                          inputs={inputs}
                          onInputChange={(e) => handleInputChange(e)}
                        ></Textarea>
                        <Buttons centered column>
                          <Button
                            type="alert"
                            title="Decline"
                            onClick={() =>
                              handleAction(
                                offer._id,
                                offer.tenderId,
                                2,
                                offer.offerAuthor,
                                offer.price,
                                offer.noOfTrucks,
                                offer.period
                              )
                            }
                          />
                        </Buttons>
                      </DeclineWrapper>
                    )}
                  </TableRow>

                </>
              );
            })}
          </TableContent>
        </Table>
      </ModalInnerWrapper>
    </Modal>
  );
};

const sortBy = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 6 11"
  >
    <path d="M3,0,6,4H0Z" fill="#6b6e81" />
    <path
      d="M3,0,6,4H0Z"
      transform="translate(6 11) rotate(180)"
      fill="#6b6e81"
    />
  </svg>
);

const rightArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="15"
    viewBox="0 0 17.702 11.765"
  >
    <g transform="translate(0 0.707)">
      <path
        d="M614.285,633.5h16.456"
        transform="translate(-614.285 -628.313)"
        fill="none"
        stroke="#e5e5e5"
        strokeWidth="2"
      />
      <path
        d="M628.76,623l5.361,5.361"
        transform="translate(-617.145 -623)"
        fill="none"
        stroke="#e5e5e5"
        strokeWidth="2"
      />
      <path
        d="M628.76,638.147l5.358-5.715"
        transform="translate(-617.145 -627.772)"
        fill="none"
        stroke="#e5e5e5"
        strokeWidth="2"
      />
    </g>
  </svg>
);
const back = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.361"
    height="16.672"
    viewBox="0 0 19.361 16.672"
  >
    <g transform="translate(-25.139 -16.283)">
      <g>
        <path
          d="M20664.182-22224.01l-7.629,7.629,7.629,7.629"
          transform="translate(-20630 22241)"
          fill="none"
          stroke="#aeaeae"
          strokeWidth="2"
        />
        <path
          d="M0,0H17"
          transform="translate(27.5 24.5)"
          fill="none"
          stroke="#aeaeae"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);

const checkmark = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.6"
    height="13.4"
    viewBox="0 0 17.6 13.4"
  >
    <path
      d="M9,16.2,4.8,12,3.4,13.4,9,19,21,7,19.6,5.6Z"
      transform="translate(-3.4 -5.6)"
      fill="#00b27e"
    />
  </svg>
);

const declined = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <path
      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
      transform="translate(-5 -5)"
      fill="#ce1e1e"
    />
  </svg>
);

export const mapStateToProps = (state) => {
  return {
    CURRENT_QUOTATIONS: state.dashboard.quotations.data,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSetQuotations: (payload) =>
      dispatch({ type: SET_QUOTATIONS, payload: payload }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(QuotationModal));
