import {
  SET_COMPANY_VERIFICATION_DOCUMENTS
} from "../../actionTypes";

const initialState = {
  documents: [],
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_VERIFICATION_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;