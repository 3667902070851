import React from "react";
import SheetBlock from "../../PageWithList/Sheet/SheetBlock";
import {
  ShowWrapper,
  SheetItem,
  SheetItemInline,
  SheetItemIconsWrapper,
  SheetItemWithIcon,
} from "../../PageWithList/Sheet/Styles";
import { Link } from "react-router-dom";
import uuid from "uuid";
import DetailsSpecialRequests from "../../PageWithList/Sheet/DetailsSpecialRequests";
import moment from "moment";
import {
  ListButton,
  InstantPaymentContainer,
} from "../../PageWithList/List/Styles";

const Details = (props) => {
  const {
    author,
    loading,
    unloading,
    truckType,
    temperature,
    freight,
    targetPrice,
    extraInfo,
    company,
    specialRequests,
  } = props.spotload;

  return (
    <ShowWrapper show={props.show}>
      <SheetItemIconsWrapper>
        {(author?.firstName || author?.lastName) && (
          <SheetItemWithIcon blur={props.loginGateway}>
            <Link
              to={
                !props.loginGateway
                  ? `/dashboard/company/${company?._id}`
                  : "#!"
              }
            >
              {userIcon}
              {!props.loginGateway ? (
                <>
                  {author?.firstName} {author?.lastName}
                </>
              ) : (
                <span>xxxxxxxxxxxxx</span>
              )}
            </Link>
          </SheetItemWithIcon>
        )}
        {author?.email && (
          <SheetItemWithIcon blur={props.loginGateway}>
            <a href={!props.loginGateway ? `mailto:${author.email}` : "#!"}>
              {emailIcon}{" "}
              {!props.loginGateway ? (
                <>{author.email}</>
              ) : (
                <span>xxxxxxxxxxxxxxxxxx</span>
              )}
            </a>
          </SheetItemWithIcon>
        )}
        {author?.phone && (
          <SheetItemWithIcon blur={props.loginGateway}>
            <a href={!props.loginGateway ? `tel:${author.phone}` : "#!"}>
              {phoneIcon}{" "}
              {!props.loginGateway ? (
                <>{author.phone}</>
              ) : (
                <span>xxxxxxxxxx</span>
              )}
            </a>
          </SheetItemWithIcon>
        )}
        {props.loginGateway ? (
          <p style={{ "margin-top": "25px", "text-align": "center" }}>
            In order to see contacts{" "}
            <span>
              <Link to="/log-in">log in</Link> or{" "}
              <span>
                <Link to="/sign-up">sign up</Link>
              </span>{" "}
              for free
            </span>
          </p>
        ) : null}
      </SheetItemIconsWrapper>
      <SheetBlock
        label="Spot freight info"
        payment={
          <Link to="/instant-payment" style={{ textDecoration: "none" }}>
            <ListButton>Instant Payment</ListButton>
          </Link>
        }
      >
        <SheetItem>
          <h3>
            Loading - {moment(loading.date.start).format("DD.MM.YY")} to{" "}
            {moment(loading.date.end).format("DD.MM.YY")}
          </h3>
          {loading.locations &&
            loading.locations.map((load) => {
              return <h4 key={uuid()}>{load.locationName}</h4>;
            })}
        </SheetItem>
        <SheetItem>
          <h3>
            Unloading - {moment(unloading?.date?.start).format("DD.MM.YY")} to{" "}
            {moment(unloading?.date?.end).format("DD.MM.YY")}
          </h3>
          {unloading.locations &&
            unloading.locations.map((unload) => {
              return <h4 key={uuid()}>{unload.locationName}</h4>;
            })}
        </SheetItem>
        <SheetItem>
          <h3>Truck type</h3>
          <h4>
            {truckType
              .map((element) => {
                if (element.value === "Refrigerator" && temperature) {
                  return `${element.value} (${temperature}°C)`;
                }
                return element.value;
              })
              .join(", ")
              .replace(/_/g, " ")}
          </h4>
        </SheetItem>
        <SheetItemInline>
          <SheetItem>
            <h3>Type of load</h3>
            <h4>{freight.typeOfLoad}</h4>
          </SheetItem>
          {freight.typeOfCargo && (
            <SheetItem>
              <h3>Type of cargo</h3>
              <h4>{freight.typeOfCargo.label}</h4>
            </SheetItem>
          )}
          {targetPrice && targetPrice > 0 ? (
            <SheetItem>
              <h3>Target price</h3>
              <h4>{targetPrice}€</h4>
            </SheetItem>
          ) : null}
        </SheetItemInline>
      </SheetBlock>
      <SheetBlock label="Cargo info">
        <SheetItemInline inlineRow>
          {freight?.weight > 0 && (
            <SheetItem>
              <h3>Weight</h3>
              <h4>{freight.weight} kg</h4>
            </SheetItem>
          )}
          {freight?.loadingMeters > 0 && (
            <SheetItem>
              <h3>Loading meters</h3>
              <h4>{freight.loadingMeters} m</h4>
            </SheetItem>
          )}
          {freight?.length > 0 && (
            <SheetItem>
              <h3>Length</h3>
              <h4>{freight?.length} cm</h4>
            </SheetItem>
          )}
          {freight.width > 0 && (
            <SheetItem>
              <h3>Width</h3>
              <h4>{freight.width} cm</h4>
            </SheetItem>
          )}
          {freight.height > 0 && (
            <SheetItem>
              <h3>Height</h3>
              <h4>{freight.height} cm</h4>
            </SheetItem>
          )}
        </SheetItemInline>
        <DetailsSpecialRequests specialRequests={specialRequests} />
      </SheetBlock>
      {extraInfo && (
        <SheetBlock label="Extra info">
          <SheetItem>
            <h4>{extraInfo}</h4>
          </SheetItem>
        </SheetBlock>
      )}
    </ShowWrapper>
  );
};

const userIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="19"
    viewBox="0 0 17 19"
  >
    <g transform="translate(-5.5 -4)">
      <path
        d="M22,28.5v-2a4,4,0,0,0-4-4H10a4,4,0,0,0-4,4v2"
        transform="translate(0 -6)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M20,8.5a4,4,0,1,1-4-4A4,4,0,0,1,20,8.5Z"
        transform="translate(-2)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

const emailIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.406"
    height="13.062"
    viewBox="0 0 17.406 13.062"
  >
    <g transform="translate(-2.297 -5.5)">
      <path
        d="M4.6,6H17.4A1.56,1.56,0,0,1,19,7.508v9.046a1.56,1.56,0,0,1-1.6,1.508H4.6A1.56,1.56,0,0,1,3,16.554V7.508A1.56,1.56,0,0,1,4.6,6Z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        d="M19,9l-8,6.428L3,9"
        transform="translate(0 -1.163)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

const phoneIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.2"
    height="21.286"
    viewBox="0 0 15.2 21.286"
  >
    <g transform="translate(-7.5 -2.5)">
      <path
        d="M9.529,3H19.671A2.029,2.029,0,0,1,21.7,5.029V21.257a2.029,2.029,0,0,1-2.029,2.029H9.529A2.029,2.029,0,0,1,7.5,21.257V5.029A2.029,2.029,0,0,1,9.529,3Z"
        transform="translate(0.5 0)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <line
        x2="7.381"
        transform="translate(11 20.015)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default Details;
