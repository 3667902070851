import React from "react";
import Header from "../components/FrontPage/Header/Header";
import Spotload from "../components/Spotload/Spotload";
import LeftDrawer from "../components/Dashboard/Drawers/LeftDrawer";
import { isBrowser } from "react-device-detect";
import Minimessages from "../components/Dashboard/Messages/Minimessages";

const SpotloadPage = ({ USER }) => {
  return (
    <>
      <Header />
      <Spotload />
      {USER && isBrowser && <Minimessages />}
      {USER && <LeftDrawer small />}
    </>
  );
};

export default SpotloadPage;
