import React from "react";
import { ButtonWrapper } from "./Styles";

const Button = (props) => {
  return (
    <ButtonWrapper
      fileExport={props.fileExport}
      small={props.small}
      type={props.type}
      onClick={props.onClick}
      spacing={props.spacing}
    >
      {props.icon && props.icon}
      <button type="button">{props.title}</button>
    </ButtonWrapper>
  );
};

export default Button;
