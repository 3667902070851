import React from "react";
import { MobileTableContainer } from "../Styles";
import Container from "../../../components/UI/Container";
import Checkmark from "../../../assets/icons/checkmarkicon.svg";

const MobileTable = () => {
  return (
    <MobileTableContainer>
      <table cellSpacing={0} cellPadding={0}>
        <thead>
          <tr>
            <th colSpan={2}>ONCE card</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Designed for</th>
            <td>Transport Company (CRT)</td>
          </tr>
          <tr>
            <th>Payment conditions</th>
            <td>Post paid</td>
          </tr>
          <tr>
            <th>
              Invoice <br />
              (tax document)
            </th>
            <td>
              <img src={Checkmark} alt="Checkmark icon" />
            </td>
          </tr>
          <tr>
            <th>Fuel station coverage</th>
            <td>15 000+ filling stations Across Europe</td>
          </tr>
          <tr>
            <th>Toll Coverage</th>
            <td>26 countries</td>
          </tr>
          <tr>
            <th>VAT Refund</th>
            <td>
              <img src={Checkmark} alt="Checkmark icon" />
            </td>
          </tr>
          <tr>
            <th>Excise duty refund</th>
            <td>
              <img src={Checkmark} alt="Checkmark icon" />
            </td>
          </tr>
          <tr>
            <th>Net Invoicing</th>
            <td>
              <img src={Checkmark} alt="Checkmark icon" />
            </td>
          </tr>
        </tbody>
      </table>
      <table cellSpacing={0} cellPadding={0}>
        <thead>
          <tr>
            <th colSpan={2}>EASY card</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Designed for</th>
            <td>Transport Company (CRT)</td>
          </tr>
          <tr>
            <th>Payment conditions</th>
            <td>Pre paid</td>
          </tr>
          <tr>
            <th>
              Invoice <br />
              (tax document)
            </th>
            <td>
              <img src={Checkmark} alt="Checkmark icon" />
            </td>
          </tr>
          <tr>
            <th>Fuel station coverage</th>
            <td>15 000+ filling stations Across Europe</td>
          </tr>
          <tr>
            <th>Toll Coverage</th>
            <td>26 countries</td>
          </tr>
          <tr>
            <th>VAT Refund</th>
            <td>
              <img src={Checkmark} alt="Checkmark icon" />
            </td>
          </tr>
          <tr>
            <th>Excise duty refund</th>
            <td>
              <img src={Checkmark} alt="Checkmark icon" />
            </td>
          </tr>
          <tr>
            <th>Net Invoicing</th>
            <td>
              <img src={Checkmark} alt="Checkmark icon" />
            </td>
          </tr>
        </tbody>
      </table>
      <table cellSpacing={0} cellPadding={0}>
        <thead>
          <tr>
            <th colSpan={2}>VECTOR card</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Designed for</th>
            <td>Transport Company (CRT)</td>
          </tr>
          <tr>
            <th>Payment conditions</th>
            <td>Post paid</td>
          </tr>
          <tr>
            <th>
              Invoice <br />
              (tax document)
            </th>
            <td>
              <img src={Checkmark} alt="Checkmark icon" />
            </td>
          </tr>
          <tr>
            <th>Fuel station coverage</th>
            <td>15 000+ filling stations Across Europe</td>
          </tr>
          <tr>
            <th>Toll Coverage</th>
            <td>26 countries</td>
          </tr>
          <tr>
            <th>VAT Refund</th>
            <td>
              <img src={Checkmark} alt="Checkmark icon" />
            </td>
          </tr>
          <tr>
            <th>Excise duty refund</th>
            <td>
              <img src={Checkmark} alt="Checkmark icon" />
            </td>
          </tr>
          <tr>
            <th>Net Invoicing</th>
            <td>
              <img src={Checkmark} alt="Checkmark icon" />
            </td>
          </tr>
        </tbody>
      </table>
    </MobileTableContainer>
  );
};

export default MobileTable;
