import React from "react";
import {
  Background,
  CostListItem,
  CostListContent,
  Logo,
  TitleSection,
  InfoSection,
  LogoSection,
  Factoring,
  JITpay,
  ButtonDiv,
  FormContainer,
  FormWrapperInner,
  InputContainer,
  VATbutton,
  SelectContainer,
  selectStyles,
} from "./Styles";
import jitpayLogo from "../../../src/images/JITpay.svg";
import logo from "../../../src/images/logo.svg";
import Container from "../../components/UI/Container";
import { useSelector } from "react-redux";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { useState, useRef } from "react";
import Select from "react-select";
import allCountries from "../../data/allCountries.json";
import allLanguages from "../../data/allLanguages.json";

const countries = allCountries.countries;
const languages = allLanguages.languages;

const FactoringPage = () => {
  const [country, setCountry] = useState("");
  const [companyName, setCompanyName] = useState();
  const [regNumber, setRegNumber] = useState();
  const [vatNumber, setVatNumber] = useState();
  const [contactPerson, setContactPerson] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [lang, setLang] = useState("");

  const reRef = useRef(<ReCAPTCHA />);

  const postAdminData = async (e) => {
    e.preventDefault();
    const token = await reRef.current.executeAsync();
    reRef.current.reset();
    let languages = lang.join(", ");
    axios
      .post(
        "https://api.transconnector.eu/jitpaydata",
        {
          data: {
            country: country,
            company: companyName,
            contactPerson: contactPerson,
            regNumber: regNumber,
            vatNumber: vatNumber,
            phone: phone,
            email: email,
            token,
            languages: languages,
          },
        },
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          window.location.href =
            "https://www.jitpay.eu/en/jitpay-registration/";
        }
      });
  };

  const err = () => {
    alert("please select country before submitting");
  };
  return (
    <Factoring>
      <Container gutter="large">
        <LogoSection>
          <Logo src={logo} alt="transconnector Logo" />
          <Logo src={jitpayLogo} alt="JITpay Logo" />
        </LogoSection>
      </Container>
      <Container gutter="medium">
        <TitleSection>
          <h1>
            TransConnector in cooperation with <br />
            <JITpay>
              JITpay™ LOGISTIK-FACTORING
              <div className="line"></div>
            </JITpay>
          </h1>
        </TitleSection>
      </Container>
      <Container gutter="medium">
        <h2>
          By using the customised JITpay™ factoring solutions the waiting for
          incoming payments from your customers is over.
        </h2>
        <InfoSection>
          <ul>
            <li>
              JITpay™ buys your open claims and pre-finances up to 100% of the
              invoice amount.
            </li>
            <li>
              JITpay™ logistics-factoring can be used individually as required -
              without minimum turnover or obligations.
            </li>
            <li>
              Through JITpay™ digital processes, your invoices will be paid in
              full within 24 hours of submission.
            </li>
          </ul>
        </InfoSection>
      </Container>
      <Background background="#FBFDFF" padding="2rem 0">
        <Container gutter="medium">
          <h2>What does it cost?</h2>
          <InfoSection>
            <CostListItem>
              <CostListContent className="bordered">
                <h3>
                  JITpay™ charges only one fee. No hidden costs, no nasty
                  surprises:
                </h3>
                <h5>If the payment term agreed in the freight order is</h5>
                <p>
                  → 30 days, the JITpay™ fee is 1,99% of the invoiced amount.
                </p>
                <p>
                  → 45 days, the JITpay™ fee is 2,49% of the invoiced amount.
                </p>
                <p>
                  → 60 days, the JITpay™ fee is 2,99% of the invoiced amount.
                </p>
              </CostListContent>
            </CostListItem>
          </InfoSection>
          <h2> Example:</h2>
          <InfoSection>
            <CostListItem>
              <CostListContent>
                <h3>This means for an order value of € 500 you pay JITpay™:</h3>
                <p>30 days the JITpay™ fee is € 9,95</p>
                <p>45 days the JITpay™ fee is € 12,45</p>
                <p>60 days the JITpay™ fee is € 14,95</p>
              </CostListContent>
            </CostListItem>
          </InfoSection>
        </Container>
      </Background>

      <FormContainer style={{ width: "100%" }}>
        <FormWrapperInner style={{ maxWidth: "650px" }}>
          <form onSubmit={postAdminData}>
            <InputContainer>
              {/* <h3>Help us contact you: </h3> */}
            </InputContainer>
            <InputContainer>
              <label htmlFor="country">Country: </label>
              <SelectContainer>
                <Select
                  name="country"
                  options={countries}
                  styles={selectStyles}
                  required
                  onSubmit={country === "" ? err : ""}
                  onChange={(choice) => {
                    setCountry(choice.shorthand);
                  }}
                />
              </SelectContainer>
            </InputContainer>
            <br />
            <InputContainer>
              <label htmlFor="company_name">Company Name: </label>
              <input
                type="text"
                value={companyName}
                required
                placeholder="Company Name"
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
              />
            </InputContainer>
            <br />
            <InputContainer>
              <label htmlFor="companyno">Company Registration Number: </label>
              <input
                type="text"
                value={regNumber}
                required
                placeholder="Registration number"
                onChange={(e) => {
                  setRegNumber(e.target.value);
                }}
              />
            </InputContainer>
            <br />
            <InputContainer>
              <label htmlFor="company_vat">Company VAT Number: </label>
              <input
                type="text"
                value={vatNumber}
                required
                placeholder="VAT"
                onChange={(e) => {
                  setVatNumber(e.target.value);
                }}
              />
            </InputContainer>
            <br />

            <InputContainer>
              <label htmlFor="contact">Name of Contact Person: </label>
              <input
                type="text"
                value={contactPerson}
                required
                placeholder="Contact Person"
                onChange={(e) => setContactPerson(e.target.value)}
              />
            </InputContainer>
            <br />

            <InputContainer>
              <label htmlFor="phone">Phone Number: </label>
              <input
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    "Please enter a valid phone number"
                  )
                }
                min="10000"
                name="phone"
                type="number"
                value={phone}
                required
                placeholder="Phone number"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
            </InputContainer>
            <br />

            <InputContainer>
              <label htmlFor="email">E-mail: </label>
              <input
                type="email"
                value={email}
                required
                placeholder="email@email.com"
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputContainer>
            <br />
            <InputContainer>
              <label htmlFor="lang">Preferred Languages: </label>
              <br />
              <SelectContainer>
                <Select
                  name="languages"
                  options={languages}
                  styles={selectStyles}
                  required
                  isMulti
                  onSubmit={lang === "" ? err : ""}
                  onChange={(choice) => {
                    setLang(choice ? choice.map((i) => i.value) : []);
                  }}
                />
              </SelectContainer>
            </InputContainer>
            <ButtonDiv>
              <ReCAPTCHA
                sitekey="6LenyGkiAAAAAHIokUL5B2k6qb3y4fGmPitExsFg"
                size="invisible"
                ref={reRef}
              />
            </ButtonDiv>

            <ButtonDiv style={{ marginTop: "4rem" }}>
              <VATbutton type="submit">Register Now</VATbutton>
            </ButtonDiv>
          </form>
        </FormWrapperInner>
      </FormContainer>
    </Factoring>
  );
};

export default FactoringPage;
