import { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { API_URL } from '../constants';
import PageTitleNotification from './PageTitleNotification';
import { CreateToast } from './CreateToast';

const NotificationWrapper = ({ children }) => {
  const [activePageTitle, setActivePageTitle] = useState(null);

  useEffect(() => {
    if (activePageTitle) {
      PageTitleNotification.on(activePageTitle);
    }
  }, [activePageTitle]);

  useEffect(() => {
    if (!('Notification' in window)) {
      // This browser does not support desktop notifications
      return;
    } else {
      // Notifications are supported - ask for permissions
      if (Notification.permission !== 'granted') {
        Notification.requestPermission();
        return;
      }
      if (Notification.permission === 'granted') {
        const socket = io(`${API_URL}`, {
          transports: ['websocket'],
          secure: true,
        });
        socket.on('transconnector', (res) => {
          // Reset the active page title so it could be loaded again on a new notification
          setActivePageTitle(null);
          const { icon, image, title, content, pageTitle, url } = res;
          // Create a toast
          CreateToast({
            message: content,
            type: 'info',
            autoClose: 10000,
            onClick: () => (url ? window.open(url, '_blank') : null),
          });
          // Create a notification via the Notification API
          const notification = new Notification(title, {
            body: content,
            icon,
            image,
            dir: 'ltr',
            lang: 'en',
            vibrate: true,
          });
          // Add an event click handler for the notification if url is set
          if (url) {
            notification.onclick = function (e) {
              e.preventDefault();
              window.open(url, '_blank');
            };
          }
          // Set the document title if pageTitle is set
          pageTitle ? setActivePageTitle(pageTitle) : setActivePageTitle(null);
        });
        // TODO: Register a service worker so users get notified even when they are not on the page
        /*       navigator.serviceWorker.register("serviceWorker.js");
      Notification.requestPermission(function (result) {
        if (result === "granted") {
          navigator.serviceWorker.ready.then(function (registration) {
            registration.showNotification("Notification with ServiceWorker");
          });
        }
      }); */
        return () => {
          socket.destroy();
        };
      }
    }
  }, []);
  return children;
};

export default NotificationWrapper;
