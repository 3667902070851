import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 4rem;
  h2 {
    color: #363636;
    font-size: 2rem;
    margin-bottom: 4rem;
  }
  @media (min-width: 992px) {
    margin-bottom: 6rem;
  }
`;
export const Arrow = styled.div`
  position: absolute;
  bottom: -1.5rem;
  right: 1rem;
  display: none;
  pointer-events: none;
`;

export const Post = styled.div`
  box-shadow: 0px 3px 10px 0px rgba(0, 119, 255, 0.1);
  margin-bottom: 2rem;
  position: relative;
  a {
    display: flex;
    flex-direction: column;
    position: relative;
    text-decoration: none;
    &:hover {
      ${Arrow} {
        display: block;
      }
    }
  }
  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  &:before {
    content: '';
    position: absolute;
    pointer-events: none;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
`;

export const ContentWrapper = styled.div`
  position: absolute;
  bottom: 2.25rem;
  z-index: 2;
  width: 100%;
  h3 {
    padding: 1rem;
    padding-bottom: 0.5rem;
    color: #fff;
    margin: 0;
    text-transform: uppercase;
    font-size: 14px;
  }
  p {
    padding: 1rem;
    color: #fff;
    max-height: 75px;
    min-height: 75px;
    overflow: hidden;
    margin: 0;
    line-height: 1.5;
    font-size: 12px;
  }
  span {
    position: absolute;
    padding-top: 0.5rem;
    padding-left: 1rem;
    text-align: right;
    color: #b5b5b5;
    font-size: 12px;
  }
  @media (min-width: 992px) {
    h3 {
      font-size: 1rem;
    }
    p {
      font-size: 13px;
    }
  }
  @media (min-width: 1200px) {
    h3 {
      font-size: 19px;
    }
  }
`;
export const List = styled.div`
  @media (min-width: 992px) {
    display: inline-flex;
    width: 100%;
    flex-wrap: wrap;
    ${Post} {
      flex: 1 0 calc(50% - 2rem);
      &:nth-child(odd) {
        margin-right: 2rem;
      }
    }
  }
`;

export const BlogPageList = styled.div`
  margin: 7rem 0;
  @media (min-width: 992px) {
    display: inline-flex;
    width: 100%;
    flex-wrap: wrap;
    ${Post} {
      max-width: 387px;
      flex: 1 0 calc(50% - 2rem);
      &:nth-child(odd) {
        margin-right: 2rem;
      }
    }
  }
  @media (min-width: 1200px) {
    ${Post} {
      flex: 1 0 calc(33.3% - 2rem);
      max-width: 313.34px;
      &:nth-child(odd) {
        margin-right: 0;
      }
      &:not(:nth-child(3n)) {
        margin-right: 2rem;
      }
    }
  }
  @media (min-width: 1440px) {
    ${Post} {
      flex: 1 0 calc(21% - 2rem);
      max-width: 289.5px;
      &:not(:nth-child(3n)) {
        margin-right: 0;
      }
      &:not(:nth-child(4n)) {
        margin-right: 2rem;
      }
    }
  }
  @media (min-width: 1740px) {
    ${Post} {
      flex: 1 0 calc(20.4% - 2rem);
      max-width: 279.2px;
      &:not(:nth-child(4n)) {
        margin-right: 0;
      }
      &:not(:nth-child(5n)) {
        margin-right: 2rem;
      }
    }
  }
`;
export const SingleBlog = styled.div`
  position: relative;
`;
export const SingleBlogContent = styled.div`
  p,
  li {
    color: #000000;
    font-size: 16px;
    line-height: 1.5;
  }
  p {
    margin: 1rem 0;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    padding: 0.5rem 0;
  }
  img {
    max-width: 100%;
    height: auto;
  }
`;
export const LatestBlogPosts = styled.div`
  display: none;
  max-width: 200px;
  @media (min-width: 1440px) {
    display: block;
    position: absolute;
    top: 0;
    left: -32.5%;
    h3 {
      color: #262a68;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 2rem;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style-type: none;
        a {
          display: flex;
          color: #000000;
          padding: 0.5rem 0;
          line-height: 1.5;
        }
      }
    }
  }
  @media (min-width: 1740px) {
    left: -22.5%;
  }
`;
