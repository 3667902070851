import React, { useState, useEffect } from "react";
import SheetTab from "../../PageWithList/Sheet/SheetTab";
import { SheetInnerWrapper } from "../../PageWithList/Sheet/Styles";
import Details from "./Details";
import Map from "../../PageWithList/Sheet/Map";
import ActionButtons from "../../PageWithList/Sheet/ActionButtons";
import ActionButton from "../../PageWithList/Sheet/ActionButton";
import MessageModal from "../../Modals/MessageModal/MessageModal";
import MessageSent from "../../Modals/MessageModal/MessageSent";
import { isIOS } from "react-device-detect";
import LoginGateway from "../../Modals/AuthGatewayModals/LoginGateway";

const SheetInner = (props) => {
  const [detailsTab, setDetailsTab] = useState(true);
  const [hasRequestedMap, setHasRequestedMap] = useState(false);
  const [modal, setModal] = useState(false);
  const { _id, loading, unloading } = props.spotload;

  useEffect(() => {
    if (!detailsTab) setHasRequestedMap(true);
  }, [detailsTab]);
  const [loginGateway, setLoginGateway] = useState(false);

  if (loginGateway)
    return (
      <LoginGateway
        onClose={() => {
          setLoginGateway(false);
        }}
        offerGateway
      />
    );
  if (!loginGateway) {
    return (
      <>
        <SheetInnerWrapper iOS={isIOS}>
          <SheetTab detailsTab={detailsTab} setDetailsTab={setDetailsTab} />
          <Details spotload={props.spotload} loginGateway={props.loginGateway} show={detailsTab ? true : false} />
          <Map
            loading={loading.locations}
            unloading={unloading.locations}
            hasRequestedMap={hasRequestedMap}
            setHasRequestedMap={setHasRequestedMap}
            show={!detailsTab ? true : false}
          />
        </SheetInnerWrapper>
        {!props.denyActions && (
          <>
            <ActionButtons iOS={isIOS && "1"}>
              <ActionButton
                icon={messageIcon}
                label="Send message"
                onClick={() => {
                  !props.loginGateway ?
                    setModal(!modal) :
                    setLoginGateway(true)
                }}
              />
            </ActionButtons>
            {modal && !props.messageSent && (
              <MessageModal
                handleMessageSubmit={props.handleMessageSubmit}
                id={_id}
                onClose={() => setModal(!modal)}
              />
            )}
            {props.messageSent && (
              <MessageSent
                onClose={() => {
                  props.setMessageSent(false);
                  setModal(false);
                }}
              />
            )}
          </>
        )}
      </>
    );
  }
};

const messageIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.812"
    height="15.852"
    viewBox="0 0 18.812 15.852"
  >
    <path
      d="M17.811,10.234a1.544,1.544,0,0,1-1.539,1.539H4.078L1,14.852V2.539A1.544,1.544,0,0,1,2.539,1H16.272a1.544,1.544,0,0,1,1.539,1.539Z"
      fill="none"
      stroke="#262a68"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default SheetInner;
