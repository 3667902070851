import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";

import Home1 from "../../pages/Home1";
import Home2 from "../../pages/Home2";
import MainScreen from "./Main/Main";
/* import Pricing from "../../pages/Pricing";
import Checkout from "../Checkout/Checkout"; */
import MyCompany from "./MyCompany/MyCompany";
import Messages from "./Messages/Messages";
import Quotations from "./Quotations/Quotations";
import MyOffers from "./MyOffers";
import Favourites from "./Favourites/Favourites";
import Subscription from "./Subscription/Subscription";
import Users from "./Users/Users";
import MyAccount from "./MyAccount/";
import SingleCompanyProfile from "./Main/SingleCompanyProfile";
/* import Tender from "../../pages/Tender"; */
import TenderCreator from "../NewTender/TenderCreator/TenderCreator";
/* import Roundtrip from "../../pages/Roundtrip"; */
import RoundtripCreator from "../Roundtrip/RoundtripCreator/RoundtripCreator";
import FindCreator from "../FindRoundtrip/FindCreator/FindCreator";
/* import Spotload from "../../pages/Spotload"; */
import SpotloadCreator from "../Spotload/SpotloadCreator";

/* import EmptyTruck from "../../pages/EmptyTruck"; */
import EmptyTruckCreator from "../EmptyTruck/EmptyTruckCreator";
import ReservationDashboard from "../Reservation/ReservationDashboard";

import VerificationNotice from "../Modals/AuthGatewayModals/VerificationNotice";

/* import Blog from "../../pages/Blog";
import About from "../../pages/About";
import FreeTrial from "../../pages/FreeTrial";
import Terms from "../../pages/Terms";
import Privacy from "../../pages/Privacy"; */

const Routes = (props) => {
  const USER = useSelector((state) => state.dashboard.user?.data);
  const COMPANY = useSelector((state) => state.dashboard.company?.data);

  const history = useHistory();

  return (
    <Switch>
      {/* <Route exact path="/" component={Home1} /> */}
      <Route exact path="/" component={Home2} />

      <Route exact path="/dashboard" component={MainScreen} />
      {/*       <Route path="/blog/:id?" component={() => <Blog USER={USER} />} />
      <Route path="/pricing" render={() => <Pricing USER={USER} />} />
      <Route path="/about" render={() => <About USER={USER} />} />
      <Route path="/free-trial" render={() => <FreeTrial USER={USER} />} />
      <Route path="/terms" render={() => <Terms USER={USER} />} />
      <Route path="/privacy" render={() => <Privacy USER={USER} />} />
      <Route path="/checkout" component={Checkout} /> */}
      <Route path="/dashboard/my-company" component={MyCompany} />
      <Route
        path="/dashboard/messages"
        component={
          COMPANY?.verified
            ? () => <Messages />
            : () => (
                <VerificationNotice
                  onClose={() => history.push("/dashboard")}
                />
              )
        }
      />
      <Route path="/dashboard/quotations" component={
          COMPANY?.verified
            ? () => <Quotations />
            : () => (
                <VerificationNotice
                  onClose={() => history.push("/dashboard")}
                />
              )
        } />
      <Route path="/dashboard/my-offers" component={
          COMPANY?.verified
            ? () => <MyOffers />
            : () => (
                <VerificationNotice
                  onClose={() => history.push("/dashboard")}
                />
              )
        } />
      <Route
        path="/dashboard/favourites"
        render={() => <Favourites USER={USER} />}
      />
      {USER && USER.privileges === "Admin" && (
        <Route path="/dashboard/subscription" component={Subscription} />
      )}
      {USER && USER.privileges === "Admin" && (
        <Route
          path="/dashboard/users"
          render={() => <Users COMPANY={COMPANY} />}
        />
      )}
      <Route
        path="/dashboard/my-account"
        render={() => <MyAccount USER={USER} COMPANY={COMPANY} />}
      />
      <Route path="/dashboard/company/:id" component={SingleCompanyProfile} />

      <Route
        exact
        path="/dashboard/tender/create"
        component={
          COMPANY?.verified
            ? () => <TenderCreator />
            : () => (
                <VerificationNotice
                  onClose={() => history.push("/dashboard")}
                />
              )
        }
      ></Route>
      <Route
        exact
        path="/dashboard/tender/edit/:id"
        component={
          COMPANY?.verified
            ? (props) => (
                <TenderCreator showAllBlocks editMode {...props} />
              )
            : () => (
                <VerificationNotice
                  onClose={() => history.push("/dashboard")}
                />
              )
        }
      />
      <Route
        exact
        path="/dashboard/tender/duplicate/:id"
        component={
          COMPANY?.verified
            ? (props) => (
                <TenderCreator showAllBlocks duplicateMode {...props} />
              )
            : () => (
                <VerificationNotice
                  onClose={() => history.push("/dashboard")}
                />
              )
        }
      />
      {/*       <Route path="/tender/:id?" render={() => <Tender USER={USER} />} /> */}

      <Route
        exact
        path="/dashboard/roundtrip/create"
        component={
          COMPANY?.verified
            ? () => <RoundtripCreator />
            : () => (
                <VerificationNotice
                  onClose={() => history.push("/dashboard")}
                />
              )
        }
      ></Route>

      <Route
        exact
        path="/dashboard/roundtrip/edit/:id"
        component={
          COMPANY?.verified
            ? (props) => (
                <RoundtripCreator showAllBlocks editMode {...props} />
              )
            : () => (
                <VerificationNotice
                  onClose={() => history.push("/dashboard")}
                />
              )
        }
      />

      <Route
        exact
        path="/dashboard/find-roundtrip/edit/:id"
        component={
          COMPANY?.verified
            ? (props) => (
                <FindCreator showAllBlocks editMode {...props} />
              )
            : () => (
                <VerificationNotice
                  onClose={() => history.push("/dashboard")}
                />
              )
        }
      />
      <Route
        exact
        path="/dashboard/find-roundtrip/create"
        component={
          COMPANY?.verified
            ? () => <FindCreator />
            : () => (
                <VerificationNotice
                  onClose={() => history.push("/dashboard")}
                />
              )
        }
      ></Route>
      <Route
        exact
        path="/dashboard/roundtrip/duplicate/:id"
        component={
          COMPANY?.verified
            ? (props) => (
                <RoundtripCreator showAllBlocks duplicateMode {...props} />
              )
            : () => (
                <VerificationNotice
                  onClose={() => history.push("/dashboard")}
                />
              )
        }
      />
      {/*       <Route path="/roundtrip/:id?" render={() => <Roundtrip USER={USER} />} /> */}

      <Route
        exact
        path="/dashboard/spotload/create"
        component={
          COMPANY?.verified
            ? () => <SpotloadCreator />
            : () => (
                <VerificationNotice
                  onClose={() => history.push("/dashboard")}
                />
              )
        }
      ></Route>
      {/* <Route
        exact
        path="/dashboard/spotload/upload"
        component={() => <SpotloadUploader />}
      ></Route> */}
      <Route
        exact
        path="/dashboard/spotload/edit/:id"
        component={
          COMPANY?.verified
            ? (props) => (
                <SpotloadCreator showAllBlocks editMode {...props} />
              )
            : () => (
                <VerificationNotice
                  onClose={() => history.push("/dashboard")}
                />
              )
        }
      ></Route>
      <Route
        exact
        path="/dashboard/spotload/duplicate/:id"
        component={
          COMPANY?.verified
            ? (props) => (
                <SpotloadCreator showAllBlocks duplicateMode {...props} />
              )
            : () => (
                <VerificationNotice
                  onClose={() => history.push("/dashboard")}
                />
              )
        }
      ></Route>
      {/*       <Route
        path="/spotload/:id?"
        render={() => <Spotload USER={USER} />}
      ></Route> */}

      <Route
        exact
        path="/dashboard/trucks/create"
        component={
          COMPANY?.verified
            ? () => <EmptyTruckCreator />
            : () => (
                <VerificationNotice
                  onClose={() => history.push("/dashboard")}
                />
              )
        }
      ></Route>
      <Route
        exact
        path="/dashboard/trucks/edit/:id"
        component={
          COMPANY?.verified
            ? (props) => (
                <EmptyTruckCreator showAllBlocks editMode {...props} />
              )
            : () => (
                <VerificationNotice
                  onClose={() => history.push("/dashboard")}
                />
              )
        }
      ></Route>
      <Route
        exact
        path="/dashboard/trucks/duplicate/:id"
        component={
          COMPANY?.verified
            ? (props) => (
                <EmptyTruckCreator showAllBlocks duplicateMode {...props} />
              )
            : () => (
                <VerificationNotice
                  onClose={() => history.push("/dashboard")}
                />
              )
        }
      ></Route>
      {/*       <Route
        path="/trucks/:id?"
        render={() => <EmptyTruck USER={USER} />}
      ></Route> */}
      {USER && USER.privileges === "Admin" && (
        <Route
          exact
          path="/dashboard/ferry-bookings"
          component={ReservationDashboard}
        />
      )}
    </Switch>
  );
};

export default Routes;
