import React from "react";
import {
  InputWrapper,
  LabelWrapper,
  Label,
  RadioWrapper,
  ErrorContainer,
  ErrorWrapper,
} from "../CreateForm/Styles";

const RadioGroup = (props) => {
  return (
    <InputWrapper>
      <LabelWrapper>
        {props.label && (
          <Label>
            <h3>{props.label}</h3>
          </Label>
        )}
        <ErrorContainer maxWidth={props.maxWidth}>
          <RadioWrapper>{props.children}</RadioWrapper>
          {props.error && (
            <ErrorWrapper>
              <span>{props.error.msg}</span>
            </ErrorWrapper>
          )}
        </ErrorContainer>
      </LabelWrapper>
    </InputWrapper>
  );
};

export default RadioGroup;
