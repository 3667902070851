import moment from 'moment';
import trucktypesJSON from "../data/trucktypes.json";
import cargotypesJSON from "../data/cargotypes.json";

export const FormatSpotloadEditData = (data) => {
  const formats = ['YYYY-MM-DD', 'DD.MM.YYYY', 'MM/DD/YYYY'];
  // if data.loading is string, it comes from CSV upload so we branch here
  if (typeof data.loading === "string") {
    const loadingLocations = {};
    const unloadingLocations = {};
    //TODO should we allow semi-colon as delimiter for multi-location spotloads?
    data.loading.split(";").forEach((l, i) => {
      loadingLocations[`loading${i}`] = {
        value: l,
        label: l,
      };
    });
    data.unloading.split(";").forEach((l, i) => {
      unloadingLocations[`unloading${i}`] = {
        value: l,
        label: l,
      };
    });
    return {
      ...loadingLocations,
      ...unloadingLocations,
      loadingDate: {
        startDate: moment(data.loadingDateStart, formats) ?? null,
        endDate: moment(data.loadingDateEnd, formats) ?? null,
      },
      unloadingDate: {
        startDate: moment(data.unloadingDateStart, formats) ?? null,
        endDate: moment(data.unloadingDateEnd, formats) ?? null,
      },
      truckType: trucktypesJSON.types.filter((t) => t.value === data.truckType.find((tt) => tt === t.value)),
      temperature: data.temperature,
      typeOfLoad: data.typeOfLoad,
      freight: {
        weight: data.weight,
        loadingMeters: data.loadingMeters,
        height: data.height,
        length: data.length,
        width: data.width,
      },
      specialRequests: {
        ADR: data.ADR,
        euroPalletExchange: data.euroPalletExchange,
        TIRCable: data.TIRCable,
        liveTracking: data.liveTracking,
        nonStackable: data.nonStackable,
        wasteLoad: data.wasteLoad,
      },
      cargoType: (() => 
      {
        const ct = Object.entries(cargotypesJSON).find((t) => t[1] === data.typeOfCargo);
        return ct ? {value: ct[0], label: ct[1]} : null;
      })(),
      extraInfo: data.extraInfo,
      targetPrice: data.targetPrice,
    };

  }
  else if (typeof data.loadingCountry === "string") {
    const loadingLocations = {};
    const unloadingLocations = {};
    //TODO should we allow semi-colon as delimiter for multi-location spotloads?
      loadingLocations[`loading0`] = {
        value: `${data.loadingPostalCode}, ${data.loadingCountry}`,
        label: `${data.loadingPostalCode}, ${data.loadingCountry}`,
      };
      unloadingLocations[`unloading0`] = {
        value: `${data.unloadingPostalCode}, ${data.unloadingCountry}`,
        label: `${data.unloadingPostalCode}, ${data.unloadingCountry}`,
      };
    return {
      ...loadingLocations,
      ...unloadingLocations,
      loadingDate: {
        startDate: moment(data.loadingDateStart, formats) ?? null,
        endDate: moment(data.loadingDateEnd, formats) ?? null,
      },
      unloadingDate: {
        startDate: moment(data.unloadingDateStart, formats) ?? null,
        endDate: moment(data.unloadingDateEnd, formats) ?? null,
      },
      truckType: trucktypesJSON.types.filter((t) => t.value === data.truckType.find((tt) => tt === t.value)),
      temperature: data.temperature,
      typeOfLoad: data.typeOfLoad,
      freight: {
        weight: data.weight,
        loadingMeters: data.loadingMeters,
        height: data.height,
        length: data.length,
        width: data.width,
      },
      specialRequests: {
        ADR: data.ADR,
        euroPalletExchange: data.euroPalletExchange,
        TIRCable: data.TIRCable,
        liveTracking: data.liveTracking,
        nonStackable: data.nonStackable,
        wasteLoad: data.wasteLoad,
      },
      cargoType: (() => 
      {
        const ct = Object.entries(cargotypesJSON).find((t) => t[1] === data.typeOfCargo);
        return ct ? {value: ct[0], label: ct[1]} : null;
      })(),
      extraInfo: data.extraInfo,
      targetPrice: data.targetPrice,
    };
  }
  else {
  const {
    loading,
    unloading,
    truckType,
    temperature,
    freight,
    specialRequests,
    extraInfo,
    targetPrice,
  } = data;
  const loadingLocations = {};
  const unloadingLocations = {};
  loading.locations.forEach((l, i) => {
    loadingLocations[`loading${i}`] = {
      value: l.locationName,
      label: l.locationName,
    };
  });
  unloading.locations.forEach((l, i) => {
    unloadingLocations[`unloading${i}`] = {
      value: l.locationName,
      label: l.locationName,
    };
  });
  return {
    ...loadingLocations,
    ...unloadingLocations,
    loadingDate: {
      startDate: loading.date.start,
      endDate: loading.date.end,
    },
    unloadingDate: {
      startDate: moment(unloading?.date?.start),
      endDate: moment(unloading?.date?.end),
    },
    truckType,
    temperature,
    typeOfLoad: freight.typeOfLoad,
    freight: {
      weight: freight?.weight,
      loadingMeters: freight?.loadingMeters,
      height: freight?.height,
      length: freight?.length,
      width: freight?.width,
    },
    specialRequests,
    cargoType: freight.typeOfCargo,
    extraInfo,
    targetPrice,
  }}
}

