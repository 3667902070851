import React, { useEffect } from "react";
import Select from "react-select";
import Input from "../Register/RegisterInput";
import euCountries from "../../data/EUcountries.json";
import countries from "../../data/countries.json";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import axios from "axios";
import { isMobile } from "react-device-detect";
import { API_URL } from "../../constants";

const RegisterStepOne = (props) => {
  const COMPANY = useSelector((state) => state.dashboard.company?.data);
  const { vatNumber } = props.inputs;
  useEffect(() => {
    if (vatNumber && vatNumber?.length >= 2) {
      if (vatNumber.substr(0, 2).match(/[0-9]/i)) {
        props.setErrors((prevState) => ({
          ...prevState,
          vatNumber: {
            msg: "Enter a valid VAT number",
          },
        }));
      } else {
        props.setErrors((prevState) => ({
          ...prevState,
          vatNumber: null,
        }));
      }
      let countryCode = vatNumber.substr(0, 2).toUpperCase();
      if (vatNumber?.length > 7) {
        for (var i = 0; i < europeanCountries?.length; i++) {
          if (europeanCountries[i].code === countryCode) {
            //Wait until VAT code valid minimum lenght then run api
            if (vatNumber?.length > 9) {
              axios({
                method: "GET",
                url: `${API_URL}/vatCheck`,
                params: {
                  vatid: vatNumber.toString().replace(/\s/g, ""),
                },
              }).then((res) => {
                if (res.data.validation.valid) {
                  if (res.data.validation.company_name !== "---") {
                    props.setInputs((prevState) => ({
                      ...prevState,
                      companyName: res.data.validation.company_name,
                    }));
                    props.setErrors((prevState) => ({
                      ...prevState,
                      vatNumber: null,
                    }));
                  }
                  for (var i = 0; i < europeanCountries?.length; i++) {
                    if (europeanCountries[i].code === res.data.country_code) {
                      const newCountry = europeanCountries[i].country;
                      props.setInputs((prevState) => ({
                        ...prevState,
                        companyName:
                          res.data.validation.company_name !== "---"
                            ? res.data.validation.company_name
                            : prevState.companyName,
                        country: newCountry,
                      }));
                    }
                  }
                } else {
                  props.setErrors((prevState) => ({
                    ...prevState,
                    vatNumber: {
                      msg: "Enter a valid VAT number",
                    },
                  }));
                }
              });
            }
          }
        }
      }
    }
  }, [vatNumber]);

  // handle select changes

  const handleSelectChange = (e, name) => {
    props.setInputs({ ...props.inputs, [name]: e.value });
    props.setErrors({ ...props.errors, [name]: null });
  };

  return (
    <div
      className={`Registration__part--PartFirst ${props.pagination === 1 && "active"
        }`}
    >
      <h3>1. Your Company:</h3>
      {isMobile && <p>Fill form with your personal info</p>}
      <Input
        required
        classes={`floating-label ${props.errors?.companyName && "invalid"}`}
        name="companyName"
        type="text"
        value={props.inputs?.companyName || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Company Name"
        error={props.errors?.companyName && props.errors?.companyName?.msg}
      />
      <Input
        classes={`floating-label ${props.errors?.vatNumber && "invalid"}`}
        name="vatNumber"
        type="text"
        value={props.inputs?.vatNumber || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="VAT number (for example: DE123456789)"
        error={props.errors?.vatNumber && props.errors?.vatNumber?.msg}
      />
      <Input
        classes={`floating-label ${props.errors?.address && "invalid"}`}
        name="address"
        type="text"
        value={props.inputs?.address || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Address"
        error={props.errors?.address && props.errors.address.msg}
      />
            <Input
        classes={`floating-label ${props.errors?.postalCode && "invalid"}`}
        name="postalCode"
        type="text"
        value={props.inputs?.postalCode || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Postal Code"
        error={props.errors?.postalCode && props.errors.postalCode.msg}
      />
          <Input
            classes={`floating-label ${props.errors?.city && "invalid"}`}
            name="city"
            type="text"
            value={props.inputs?.city || ""}
            onChange={(e) => props.handleInputChange(e)}
            label="City"
            error={props.errors?.city && props.errors.city.msg}
          />
                <div className="dropdown-wrapper">
          <Select
            classNamePrefix="react-select"
            className={`dropdown ${props?.errors?.country && "invalid"}`}
            placeholder="Country"
            options={countrySelect}
            name="country"
            value={
              props.inputs?.country && {
                value: props.inputs?.country,
                label: props.inputs?.country,
              }
            }
            onChange={(e) => handleSelectChange(e, "country")}
          />
          {props.errors?.country ? (
            <div className="error-message">{props.errors?.country?.msg}</div>
          ) : null}
                </div>
        <Input
        classes={`floating-label ${props.errors?.contactPerson && "invalid"}`}
        name="contactPerson"
        type="text"
        value={props.inputs?.contactPerson || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Contact Person"
        error={props.errors?.contactPerson && props.errors?.contactPerson?.msg}
      />
      <Input
        classes={`floating-label ${props.errors?.contactPhone && "invalid"}`}
        name="contactPhone"
        type="text"
        value={props.inputs?.contactPhone || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Phone number"
        error={props.errors?.contactPhone && props.errors?.contactPhone?.msg}
      />
      <Input
        classes={`floating-label ${props.errors?.contactEmail && "invalid"}`}
        name="contactEmail"
        type="text"
        value={props.inputs?.contactEmail || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="E-mail address"
        error={props.errors?.contactEmail && props.errors?.contactEmail?.msg}
      />


      <div className="formSubmit form-next">
        <button
          type="button"
          className="next-step"
          onClick={props.handleNextStep}
        >
          Next step &gt;
        </button>
      </div>

    </div>
  );
}

const europeanCountries = JSON.parse(JSON.stringify(euCountries.counties));
const allCountries = JSON.parse(JSON.stringify(countries.counties));
// Add Countries to country select box
const countrySelect = [];
Object.keys(allCountries).forEach(function (key) {
  countrySelect.push({
    value: allCountries[key].country.toString(),
    label: allCountries[key].country.toString(),
  });
});

export default RegisterStepOne;
