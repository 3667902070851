import { SET_DRAWER, SET_UNREAD, SET_UNREADQUOTATIONS } from '../../actionTypes';

const initialState = {
  unread: null,
  drawer: false,
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UNREAD:
      if (state.unread === action.payload) return;
      return {
        ...state,
        unread: action.payload,
      };
    case SET_DRAWER:
      return {
        ...state,
        drawer: action.payload,
      };
    case SET_UNREADQUOTATIONS:
      if (state.quotes === action.payload) return;
      return {
        ...state,
        quotes: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
