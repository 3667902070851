import styled, { css } from "styled-components";

export const FilterBox = styled.div`
  background: #fff;
  border: 1px solid #9aabc2;
  border-radius: 4px;
  padding: 0.75rem 3rem 0.75rem 0.75rem;
  position: relative;
  width: 170px;
  cursor: pointer;
  user-select: none;
  h3 {
    margin: 0;
    color: #686a7d;
    font-weight: 400;
    font-size: 14px;
    white-space: nowrap;
  }
  img {
    position: absolute;
    right: 1rem;
    top: 1.1rem;
  }
`;

export const FilterOptions = styled.div`
  z-index: 3;
  position: absolute;
  border: 1px solid #9aabc2;
  border-top: none;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  top: 41px;
  left: 0;
  background: #fff;
  width: 100%;
  display: flex;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: 0.25s all ease;
  user-select: none;
  ul {
    width: 100%;
    margin: 0;
    padding: 0;
    li {
        list-style-type: none;
        padding: 0.5rem 0.75rem;
        font-size: 14px;
        color: #686a7d;
        line-height: 1.6;
        cursor: pointer;
        }
    }
  }
`;

export const FilterWrapper = styled.div`
  position: relative;
  ${(props) =>
    props.show &&
    css`
      ${FilterBox} {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      ${FilterOptions} {
        transition: 0.25s all ease;
        max-height: 500px;
        opacity: 1;
        overflow: visible;
      }
    `}

  @media(min-width: 992px) {
    position: relative;
    top: unset;
    right: unset;
  }
`;
