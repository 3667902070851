import React, {memo, useEffect, useState} from "react";
import {ButtonBack, Content} from "./Styles";
import {ArrowPrevIcon, FlagIcon, InfoIcon, PlaceIcon, SwapIcon, TimeIcon} from "../icons";

const TransportInfo = memo(({setFieldValue, activeRoute = {}, setFieldTouched, tab}) => {

 const [isInfo, setIsInfo] = useState(false);
 const closeInfo = () => setIsInfo(false);
 const onSelectInfo = (from, to) => () => {
  setFieldValue('from', from || '');
  setFieldValue('to', to || '');
  setTimeout(() => {
   setFieldTouched('from', true);
   setFieldTouched('to', true);
  })
 };


 const renderInfo = () => {
  if (isInfo && activeRoute.infoRoute) {
   const {address, departure, arrival, extra_info} = activeRoute.infoRoute;
    return (
      <div className="route-item">
       <div className="heading">
        <h3>Information</h3>
        <ButtonBack onClick={closeInfo}><ArrowPrevIcon className="icon"/>Back</ButtonBack>
       </div>
       {address && (
         <div className="route-item__info">
          <PlaceIcon className="icon"/>
          {address}
         </div>
       )}
       {departure && (
         <div className="route-item__info">
          <TimeIcon className="icon"/>
          {departure}
         </div>
       )}
       {arrival && (
         <div className="route-item__info">
          <FlagIcon className="icon"/>
          {arrival}
         </div>
       )}
       { extra_info && (
         <div className="route-item__info--about">
          <h5>About the route</h5>
          <div className="about-route" dangerouslySetInnerHTML={{__html: extra_info}}/>
         </div>
       ) }
      </div>
    )
  }

  return (
    <div className="route-item">
     <div className="heading">
      <h3>Popular {tab == 'bridges' ? 'bridge' : tab == 'tunnels' ? 'tunnel' : tab} destinations</h3>
     </div>
     <ul>
      {activeRoute.popularRoutes && activeRoute.popularRoutes.map((item, i) => (
        <li key={i} tabIndex={0} onClick={onSelectInfo(item.endpoint1, item.endpoint2)}>
        <span className="item-wrap">
         {item.endpoint1} <SwapIcon className="icon"/> {item.endpoint2}

         {activeRoute.routesInfo?.[item.endpoint1 + '_' + item.endpoint2] && <InfoIcon className="icon icon--info" data-tip="Click for more information about this route"/>}

        </span>
        </li>
      ))}
     </ul>
    </div>
  )
 }

 useEffect(() => {
  if (!!activeRoute.infoRoute) {
   setIsInfo(true);
  }
 }, [activeRoute.infoRoute])

 return (
   <Content className="transport-info">
    <div>
     {renderInfo()}
    </div>
   </Content>
 )
});

export default TransportInfo;
