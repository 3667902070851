import React from 'react';
import { withRouter } from 'react-router-dom';

import Table from '../../NewTable/Table';
import TableHeader from '../../NewTable/TableHeader';
import { TableHeaderItem } from '../../NewTable/Styles';
import TableContent from '../../NewTable/TableContent';
import TableRow from '../../NewTable/TableRow';
import TableRowItem from '../../NewTable/TableRowItem';
import formatLocationName from '../../../helpers/LocationName';

const EmptyTruckOffers = (props) => {
  const handleRedirect = (url, state) => {
    props.history.push(url, state);
  };
  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem flex='0.6'>From</TableHeaderItem>
          <TableHeaderItem flex='0.6'>To</TableHeaderItem>
          <TableHeaderItem flex='0.4'>Truck type</TableHeaderItem>
        </TableHeader>
        <TableContent>
          {props.emptytrucks.map((truck) => {
            const loading = truck.loading.locations[0];
            const unloading = truck.unloading.locations[0];
            return (
              <TableRow
                key={truck._id}
                onClick={() =>
                  handleRedirect('/trucks', {
                    sheet: truck._id,
                  })
                }
              >
                <TableRowItem
                  rowName='From'
                  image={{
                    url: loading.flagUrl,
                    alt: loading.country,
                  }}
                  title={{
                    name: formatLocationName(loading.locationName),
                  }}
                  flex='0.6'
                />
                <TableRowItem
                  rowName='To'
                  image={{
                    url: unloading.flagUrl,
                    alt: unloading.country,
                  }}
                  title={{
                    name: formatLocationName(unloading.locationName),
                  }}
                  flex='0.6'
                />
                <TableRowItem
                  rowName='Truck type'
                  title={{
                    name:
                      truck.truckType[0]?.value === 'Refrigerator' &&
                      truck.temperature
                        ? `${truck.truckType[0]?.label} (${truck.temperature}°C)`
                        : truck.truckType[0]?.label,
                  }}
                  flex='0.4'
                >
                  <span>
                    {`${truck.typeOfLoad}`}{' '}
                    {truck.freight.weight && ` · ${truck.freight.weight}t`}{' '}
                    {truck.freight.loadingMeters &&
                      ` · LDM: ${truck.freight.loadingMeters}m`}
                  </span>
                </TableRowItem>
              </TableRow>
            );
          })}
        </TableContent>
      </Table>
    </>
  );
};

export default withRouter(EmptyTruckOffers);
