import { SET_EMPTYTRUCKS } from "../../actionTypes";
import axios from "axios";
import { API_URL } from "../../../constants";
import { getToken } from "../../../helpers/getToken";

export const getEmptyTrucks = () => {
  return (dispatch) => {
    const token = getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios.get(`${API_URL}/emptyTruck`, config).then((res) => {
      dispatch({ type: SET_EMPTYTRUCKS, payload: res.data?.data });
    });
  };
};

export const getSingleEmptyTruck = (id) => {
  return () => {
    const token = getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return axios.get(`${API_URL}/emptyTruck/${id}`, config);
  };
};

export const getEmptyTruckFilters = (params) => {
  return () => {
    const token = getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return axios.get(`${API_URL}/emptyTruck`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      params: params,
    });
  };
};

export const createEmptyTruck = (method, data) => {
  return () => {
    const token = getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return axios({
      method: method,
      url: `${API_URL}/emptyTruck`,
      data: data,
      ...config,
    });
  };
};

export const validateEmptyTruck = (data) => {
  return () => {
    const token = getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return axios.post(`${API_URL}/emptytruckValidate`, data, config);
  };
};
