import styled from "styled-components";

export const Content = styled.div`
  position: relative;
  margin-top: 6rem;
  margin-bottom: 2rem;
  @media (min-width: 1200px) {
    display: inline-flex;
    margin: 6rem 0;
    width: 100%;
  }
`;
export const TextContent = styled.div`
  margin-bottom: 2rem;
  h1 {
    color: #262a68;
    line-height: 1.4;
    margin: 1rem 0 2rem 0;
    span {
      color: #ef4900;
    }
  }
  p {
    color: #262a68;
    line-height: 1.5;
    margin: 1rem 0 2rem 0;
  }
  @media (min-width: 1200px) {
    max-width: 50%;
    margin-right: 2rem;
    p {
      margin-bottom: 3.5rem;
      font-size: 18px;
      line-height: 1.7;
    }
    h1 {
      font-size: 36px;
    }
  }
  @media (min-width: 1440px) {
    h1 {
      max-width: 90%;
    }
    margin-right: 3.5rem;
  }
`;
export const HeroImage = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
  }
`;
