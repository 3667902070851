import React, {useEffect, useMemo, useState} from "react";
import uuid from "uuid";
import Navbar from "../Dashboard/Navbar";
import Screen from "../Dashboard/Screen/Screen";
import {isBrowser} from "react-device-detect";
import Minimessages from "../Dashboard/Messages/Minimessages";
import LeftDrawer from "../Dashboard/Drawers/LeftDrawer";
import ScreenBlock from "../Dashboard/Screen/ScreenBlock";
import subscription from '../../assets/icons/subscription.svg';
import Table from "../Dashboard/UI/Table";
import {CreateToast} from "../../helpers/CreateToast";
import axios from "axios";
import {getToken} from "../../helpers/getToken";
import {API_URL, TRANSPORTER_TYPES} from "../../constants";
import moment from "moment";
import Loader from "../Dashboard/UI/Loader";

const ReservationDashboard = () => {
 const [loading, setLoading] = useState(false);
 const [items, setItems] = useState([]);

 const fetchData = async () => {
  try {
   setLoading(true);
   const {data} = await axios.get(API_URL + '/bookings', {
    headers: {
     Authorization: `Bearer ${getToken()}`,
    },
   })
   setItems(data);
  } catch (error) {
   CreateToast({
    message: error?.response?.message || "Something went wrong. Please try again later.",
    type: "error",
   });
  } finally {
   setLoading(false);
  }
 }

 useEffect(() => {
  fetchData();
 }, [])

 const tableItems = useMemo(() => [{
  key: uuid(),
  items: items.map(({_id, email, phone, bookingType, transportType, from, to, date}) => ({
   key: _id,
   id: _id,
   item: [
    {
     mobileTitle: "Email",
     name: email,
    },
    {
     mobileTitle: "Phone",
     name: phone,
    },
    {
     mobileTitle: "Type",
     name: bookingType,
    },
    {
     mobileTitle: "Transport Type",
     name: transportType ? TRANSPORTER_TYPES[transportType]?.label : '-',
    },
    {
     mobileTitle: "From",
     name: from,
    },
    {
     mobileTitle: "To",
     name: to,
    },
    {
     mobileTitle: "Date",
     name: date ? moment(date).format('DD.MM.YYY') : null
    },
   ]
  }))
 }], [items])
 return (
   <Screen centered>
    <Navbar/>
    <ScreenBlock title="Ferry bookings" img={subscription}>
     {loading ? <Loader/> : (
       <Table
         head={[
          {

           key: uuid(),
           items: [
            {name: "Email"},
            {name: "Phone"},
            {name: "Type"},
            {name: "Transport Type"},
            {name: "From"},
            {name: "To"},
            {name: "Date"},
           ],
          },
         ]}
         content={tableItems}
       />
     )
     }
    </ScreenBlock>
    {isBrowser && <Minimessages/>}
    <LeftDrawer small/>
   </Screen>
 );
}

ReservationDashboard.propTypes = {};

export default ReservationDashboard;
