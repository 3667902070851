import React from "react";
import Header from "../components/FrontPage/Header/Header";
import Pricing from "../components/Pricing/";
import LeftDrawer from "../components/Dashboard/Drawers/LeftDrawer";
import { isBrowser } from "react-device-detect";
import Minimessages from "../components/Dashboard/Messages/Minimessages";

const PricingPage = ({ USER }) => {
  return (
    <div>
      <Header />
      <Pricing />
      {/*       <Pricing
        monthlyPremPrice={card[0].types[0].price}
        monthlyPremSubId={card[0].types[0].id}
        monthlyPremTotalPrice={card[0].types[0].total_price}
        monthlyProPrice={card[1].types[0].price}
        monthlyProTotalPrice={card[1].types[0].total_price}
        monthlyProSubId={card[1].types[0].id}
        sixMonthPremPrice={card[0].types[1].price}
        sixMonthPremSubId={card[0].types[1].id}
        sixMonthPremTotalPrice={card[0].types[1].total_price}
        sixMonthProPrice={card[1].types[1].price}
        sixMonthProSubId={card[1].types[1].id}
        sixMonthProTotalPrice={card[1].types[1].total_price}
        annualPremPrice={card[0].types[2].price}
        annualPremSubId={card[0].types[2].id}
        annualPremTotalPrice={card[0].types[2].total_price}
        annualProPrice={card[1].types[2].price}
        annualProSubId={card[1].types[2].id}
        annualProTotalPrice={card[1].types[2].total_price}
        currentMemberhsipId={currId}
        gatewaySubscriptionId={card.gatewaySubscriptionId}
      /> */}
      {USER && isBrowser && <Minimessages />}
      {USER && <LeftDrawer small />}
    </div>
  );
};

export default PricingPage;
