import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

import { isMobileOnly } from "react-device-detect";

import cargowell from "../../../images/testimonials/cargowell.svg";
import transway from "../../../images/testimonials/transway.png";
import seifert from "../../../images/testimonials/seifert.png";
import bws from "../../../images/testimonials/bws.svg";
import quehenberger from "../../../images/testimonials/quehenberger.svg";
import Container from "../../UI/Container";
import {
  Block,
  Content,
  Wrapper,
} from "../../../components/FrontPage/Accompaniments2/Styles";
import Card from "../../Dashboard/UI/Card";

const Slider = styled.div`
  margin: 0 1rem;
  display: flex;
  gap: 2rem;
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  @media (min-width: 1200px) {
    margin-bottom: 4rem;
    margin-inline: 0;
  }
  div.Card {
    display: flex;
    background: #e8ecf2;
    scroll-snap-align: center;
    flex: 0 0 auto;
    margin: 0 1rem;
    width: 100%;
    height: auto;
    p {
      color: #000;
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .Card-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 1rem;
      width: 100%;
      height: auto;
    }
    @media (min-width: 768px) {
      width: 50%;
      margin: 0;
    }
    @media (min-width: 992px) {
      width: 33.3333%;
      margin: 0;
    }
    @media (min-width: 1200px) {
      width: 30.5rem;
      height: auto;
      margin: 0;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  img {
    max-width: 100px;
    max-height: 50px;
  }
`;

const DotWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

const Dot = styled.button`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${(props) => (props.active ? "#000" : "#ccc")};
  border: none;
  padding: 0;
  margin: 0 3px;
`;

const Testimonials = () => {
  const testimonials = [
    {
      image: cargowell,
      name: "Karl Gumpold",
      company: "Cargowell",
      text: "Your system is great. We really found somebody and where able to find new contacts – one of them already started to work with us and with two others we are talking about a cooperation.",
    },
    {
      image: transway,
      name: "Marko Laur",
      company: "Transway",
      text: "It is pretty easy to create tenders and roundtrips. Easy to connect with hauliers. We see lots of offers between Scandinavia and Baltic countries which is important for us.",
    },
    {
      image: seifert,
      name: "Axel Kretzschmar",
      company: "Seifert",
      text: "The platform is a very good idea to explicitly contact subcontractors looking for a long term cooperation.",
    },
    {
      image: bws,
      name: "Anders Bro Maibom",
      company: "BWS",
      text: "TransConnector is a good tool to get in touch with new suppliers. I have had many suppliers contacing me and we are in dialog regarding a future corboration on diffenrent jobs. You can easily matchup prices in the current market.",
    },
    {
      image: quehenberger,
      name: "Quehenberger",
      company: "Quehenberger",
      text: "The ease of posting and responding to tenders on TransConnector is unmatched. It's streamlined our operations and opened up new avenues for business in key regions.",
    },
  ];

  return (
    <>
      <Container gutter="medium">
        <Wrapper>
          <Block centered>
            <Content accompaniment>
              <h3>What our clients say</h3>
            </Content>
          </Block>
        </Wrapper>
      </Container>
      <CardSlider>
        {testimonials.map((testimonial, index) => (
          <Card key={index} className={"testimonials"}>
            <p>{testimonial.text}</p>
            <Footer>
              <b>{testimonial.name}</b>
              <img
                src={testimonial.image}
                alt={`${testimonial.company} logo`}
                width="100px"
                height="auto"
              />
            </Footer>
          </Card>
        ))}
      </CardSlider>
    </>
  );
};

const CardSlider = ({ children }) => {
  const [currentCard, setCurrentCard] = useState(0);
  const sliderRef = useRef();
  const scrollRight = useRef(true);

  const handleScroll = () => {
    const slider = sliderRef.current;
    setCurrentCard(Math.round(slider.scrollLeft / slider.clientWidth));
  };

  useEffect(() => {
    const slider = sliderRef.current;
    const scrollAmount = window.innerWidth <= 768 ? slider.clientWidth : 400;
    const scrollInterval = setInterval(() => {
      if (slider) {
        if (slider.scrollWidth - slider.scrollLeft - slider.clientWidth <= 0) {
          slider.scrollLeft = 0;
        } else {
          slider.scrollLeft += scrollAmount;
        }
      }
    }, 5000);

    return () => clearInterval(scrollInterval);
  }, []);

  useEffect(() => {
    const slider = sliderRef.current;
    slider.scrollLeft = currentCard * slider.clientWidth;
  }, [currentCard]);

  return (
    <>
      <Slider ref={sliderRef} onScroll={handleScroll}>
        {children}
      </Slider>
      {isMobileOnly && (
        <DotWrapper>
          {React.Children.map(children, (_, index) => (
            <Dot
              key={index}
              active={index === currentCard}
              onClick={() => setCurrentCard(index)}
            />
          ))}
        </DotWrapper>
      )}
    </>
  );
      };

export default Testimonials;
