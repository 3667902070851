import React from "react";
import styled from "styled-components";

import {
    Wrapper,
    Block,
    Content,
    PartnerImage,
    PublishImage,
    MainImage,
    OffsetImage,
    ContentWrapper,
    CardsWrapper,
    CardRow,
    CardFooter,
    Offset,
    Gap,
  } from "../Accompaniments2/Styles";
  import LinkButton from "../../Buttons/NewButton/LinkButton";

  const LinkWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4rem;
    margin-top: 2rem;
    @media (min-width: 1200px) {
      flex-direction: row;
      justify-content: flex-start;
      gap: 2rem;
    }
  `;

const GetStarted = () => {
  return (
    <Wrapper>
      <Block centered>
          <Content>
            <h3 className="getStarted">Get Started with<br />
TransConnector today</h3>
            <LinkWrapper>
                <LinkButton landingPage to="/sign-up" centered="all">
                    Join now
                </LinkButton>
                <LinkButton href={"mailto:support@transconnector.eu"} landingPage background={"#fff"}>
                Get in Contact
                </LinkButton>
            </LinkWrapper>
          </Content>
      </Block>
      <Gap height="4rem" />
    </Wrapper>
  );
};

export default GetStarted;