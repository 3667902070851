import React from "react";
import {
  Static,
  Background,
  AboutList,
  AboutListItem,
  AboutListTitle,
  AboutListContent,
  CTA,
} from "./Styles";
import Container from "../../components/UI/Container";
import LinkButton from "../../components/Buttons/NewButton/LinkButton";

const About = () => {
  return (
    <Static>
      <Container gutter="medium">
        <h1>
          TransConnector is a platform to Connect Hauliers, Spedition Companies
          and Cargo Owners to solve their Transport needs.
        </h1>
        <h5>
          It's a platform where you can publish your Round-trips, Tenders and
          Spot freights. Get an overview of current prices on the market and
          contact the hauliers from all around the Europe.
        </h5>
        <h5>
          It is the easiest, cheapest and most reliable way to grow your fleet.{" "}
        </h5>
      </Container>
      <Background background="#FBFDFF" padding="2rem 0">
        <Container gutter="medium">
          <AboutList>
            <h2>TransConnector has 3 main features: </h2>
            <AboutListItem>
              <AboutListTitle>Roundtrip</AboutListTitle>
              <AboutListContent>
                <p>
                  The “Roundtrip Solution” is a unique feature for the logistics
                  and transportation sector, aimed to improve operational
                  efficiency between freight forwarders and hauliers. It allows
                  forwarders to book hauliers for dedicated routes, ensuring a
                  reliable and continuous flow of transportation. With these
                  long-term partnerships, hauliers benefit from stable
                  engagements and fewer unused vehicles.
                </p>
                <p>
                  You can easily find suitable routes, compare prices, add them
                  to your favourites list and contact the carrier right from the
                  offer.
                </p>
                <p>
                  TransConnector gives you the opportunity to manage your
                  logistics more efficiently and with lower cost.
                </p>
              </AboutListContent>
            </AboutListItem>
            <AboutListItem>
              <AboutListTitle>Tender</AboutListTitle>
              <AboutListContent>
                <p>
                  TransConnector gives you an overview of active tender offers.
                  You can also create your own offers You can easily compare different
                  tenders, filter them, add them to your favourites list and
                  contact the supplier all from the same screen.
                </p>
                <p>
                  On Tenders you You can send quotes directly to tender offers.
                  From the “Quotations” page you can have an overview of all
                  quotations you have sent or received. You can also edit your
                  sent quotations from there. For example when you want to make
                  a better offer you can update your quote and it will be sent
                  to the company who posted the original tender.
                </p>
                <p>
                  Forwarders who have published tenders on TransConnector can
                  get an overview of offers received to the tender. Offers can
                  be accepted or rejected and a notification will be sent out to
                  the offer author.
                </p>
              </AboutListContent>
            </AboutListItem>
            <AboutListItem>
              <AboutListTitle>Load Exchange</AboutListTitle>
              <AboutListContent>
                <p>
                  Load Exchange offers the possibility to sell/find spot loads
                  instantly.You can publish your freight and get offers
                  instantly from thousands of hauliers all over Europe.
                </p>
                <p>
                  On Load Exchange you can find suitable loads for your trucks.
                  You are able to publish your own freights instantly and you
                  will get notifications when somebody is interested in your
                  offer.
                </p>
                <p>
                  You can have a full overview from your phone and you are able
                  to reply your messages and chat with your partners directly
                  from your phone even when you are on the move. That way you’ll
                  never lose contact with your partner. TransConnector is your
                  online logistics platform.
                </p>
              </AboutListContent>
            </AboutListItem>
          </AboutList>
        </Container>
      </Background>
      <Container gutter="medium">
        <CTA>
          <h4>You can easily find plan that matches your business needs.</h4>
          <h3>Prices start at 49,90€ per month.</h3>
          <LinkButton to="/sign-up">Join now</LinkButton>
        </CTA>
      </Container>
    </Static>
  );
};

export default About;
