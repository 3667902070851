import React, { useState } from "react";
import { API_URL } from "../../../constants";
import Cookies from "universal-cookie";
import Modal from "../../Modals/Modal";
import styled from "styled-components";
import {spinner} from "../../Dashboard/UI/Spinner";

const FieldMappingWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
`;

const MapFieldsHeading = styled.p`
  font-size: 17px;
  font-weight: 600;
`;

const ColumnWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  line-height: 3rem;
  gap: 20px;
`;

const MappingColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MappingRow = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const MappingLabel = styled.label`
  width: 200px;
`;

const MappingSelect = styled.select`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 200px;
`;

const RandomiseAddressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const RandomiseAddressLabel = styled.label`
  width: 120px;
`;

const RandomiseAddressSelect = styled.select`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: fit-content;
`;

const CreatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const CreatorLabel = styled.label`
  width: 120px;
`;

const CreatorInput = styled.input`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 200px;
`;

const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  margin: 1.5rem 0 0;
`;

const PreviewButton = styled.button`
  color: #fff;
  background: #00b27e;
  border: none;
  border-radius: 4px;
  padding: 0.5rem;
  transition: 0.25s all ease;
  &:hover {
    transition: 0.25s all ease;
    background: #07ce94;
    border-color: #07ce94;
  }
`;

const CancelButton = styled.button`
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  transition: 0.25s all ease;
  &:hover {
    transition: 0.25s all ease;
    background: #ccc;
    border-color: #ccc;
  }
`;

const Heading = styled.h2`
  font-size: 1.25rem;
  color: #262a68;
  line-height: 1.6;
  margin: 0 0 1rem;
  font-weight: 600;
`;

const FieldMapping = ({ headers, config, csvId, onClose, onData, USER }) => {
  let adressParts;

  if (headers.includes("loadingPostalCode")) {
    adressParts = true;
  } else {
    adressParts = false;
  }

  const fieldNames = {
    creator: "Creator",
    ...(!adressParts && { loading: "Loading address" }),
    ...(!adressParts && { unloading: "Unloading address" }),
    ...(adressParts && { loadingCountry: "Loading country" }),
    ...(adressParts && { loadingCity: "Loading city" }),
    ...(adressParts && { loadingPostalCode: "Loading postal code" }),
    ...(adressParts && { loadingStreetAddress: "Loading street address" }),
    ...(adressParts && { unloadingCountry: "Unloading country" }),
    ...(adressParts && { unloadingCity: "Unloading city" }),
    ...(adressParts && { unloadingPostalCode: "Unloading postal code" }),
    ...(adressParts && { unloadingStreetAddress: "Unloading street address" }),
    loadingDateStart: "Loading start date",
    loadingDateEnd: "Loading end date",
    unloadingDateStart: "Unloading start date",
    unloadingDateEnd: "Unloading end date",
    truckType: "Truck type",
    targetPrice: "Target price",
    typeOfLoad: "Type of load",
    typeOfCargo: "Type of cargo",
    weight: "Weight",
    loadingMeters: "Loading meters",
    length: "Length",
    width: "Width",
    height: "Height",
    ADR: "ADR",
    euroPalletExchange: "Euro pallet exchange",
    TIRCable: "TIR cable",
    liveTracking: "Live tracking",
    nonStackable: "Non stackable",
    wasteLoad: "Waste load",
    extraInfo: "Extra info",
  };

  const requiredFields = adressParts
    ? [
        "Loading country",
        "Loading postal code",
        "Unloading country",
        "Unloading postal code",
        "Loading start date",
        "Unloading start date",
        "Truck type",
        "Type of load",
      ]
    : [
        "Loading address",
        "Unloading address",
        "Loading start date",
        "Unloading start date",
        "Truck type",
        "Type of load",
      ];

  const fieldNamesReverse = Object.keys(fieldNames).reduce(
    (acc, fieldName) => ({
      ...acc,
      [fieldNames[fieldName]]: fieldName,
    }),
    {}
  );

  const initialMapping = Object.keys(fieldNames).reduce(
    (acc, fieldName) => ({
      ...acc,
      [fieldName]: headers.includes(fieldName) ? fieldName : "",
    }),
    {}
  );

  const [mapping, setMapping] = useState(initialMapping);

  const [randomiseAddress, setRandomiseAddress] = useState("true");

  const [loading, setLoading] = useState(false);

  const handleRandomiseAddressChange = (e) => {
    setRandomiseAddress(e.target.value);
  };

  const handleMappingChange = (fieldName, header) => {
    setMapping({
      ...mapping,
      [fieldNamesReverse[fieldName]]: header,
    });
  };

  const handlePreview = (e) => {
    e.preventDefault();
    const id = csvId;
    const payload = { id, mapping, randomiseAddress, delimiter: config.delimiter };
    const cookies = new Cookies();

    setLoading(true);

    fetch(`${API_URL}/spotloads/upload/preview`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("tc_token")}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        onData({ data, config: { randomiseAddress } });
        // onClose();
        console.log("Success:", data);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  };
  // const handlePreviewTest = () => {
  //   onData(testData);
  // };

  const testData = {
    errors: {
      "[0].loading": {
        value: "xdxdxdxd",
        msg: "Valid loading location must be specified",
        param: "[0].loading",
        location: "body",
      },
    },
    loads: [
      {
        loading: "Tartu",
        unloading: "Pargi 6, Vasula alevik 60535",
        loadingDateStart: "2023-01-01",
        loadingDateEnd: "2023-01-02",
        unloadingDateStart: "2023-01-05",
        unloadingDateEnd: "2023-01-06",
        truckType: ["Jumbo"],
        targetPrice: "100.50",
        typeOfLoad: "FTL",
        typeOfCargo: "General goods",
        weight: "5000",
        loadingMeters: "3",
        length: "10",
        width: "2",
        height: "2",
        ADR: "true",
        euroPalletexchange: "true",
        TIRCable: "false",
        liveTracking: "true",
        nonStackable: "false",
        wasteLoad: "true",
        extraInfo: "Some extra information",
      },
      {
        loading: "Pargi 6, Vasula alevik 60535",
        unloading: "Tartu",
        loadingDateStart: "2023-02-01",
        loadingDateEnd: "2023-02-02",
        unloadingDateStart: "2023-02-05",
        unloadingDateEnd: "2023-02-06",
        truckType: ["Mega_truck"],
        targetPrice: "150.75",
        typeOfLoad: "LTL",
        typeOfCargo: "Wood",
        weight: "3000",
        loadingMeters: "2",
        length: "8.5",
        width: "2.5",
        height: "10",
        ADR: "false",
        euroPalletexchange: "true",
        TIRCable: "false",
        liveTracking: "true",
        nonStackable: "false",
        wasteLoad: "true",
        extraInfo: "Some more information",
      },
    ],
  };

  return (
    <Modal>
      <FieldMappingWrapper>
        <Heading>Modify data</Heading>
        <RandomiseAddressWrapper>
          <RandomiseAddressLabel htmlFor="randomiseAddress">
            Randomise addresses
          </RandomiseAddressLabel>
          <RandomiseAddressSelect
            id="randomiseAddress"
            value={randomiseAddress}
            onChange={handleRandomiseAddressChange}
          >
            <option value="false">False</option>
            <option value="true">True</option>
          </RandomiseAddressSelect>
        </RandomiseAddressWrapper>
        <MapFieldsHeading>Map fields</MapFieldsHeading>
        <form id="fieldmapping" onSubmit={(e) => handlePreview(e)}>
          <ColumnWrapper>
            <MappingColumn>
              {Object.values(fieldNames)
                .slice(0, Object.values(fieldNames).length / 2)
                .map((fieldName) => (
                  <MappingRow key={fieldName}>
                    <MappingLabel htmlFor={fieldName}>
                      {fieldName}: {requiredFields.includes(fieldName) && "*"}
                    </MappingLabel>
                    <MappingSelect
                      id={fieldName}
                      value={mapping[fieldNamesReverse[fieldName]]}
                      onChange={(e) =>
                        handleMappingChange(fieldName, e.target.value)
                      }
                      required={requiredFields.includes(fieldName)}
                    >
                      <option value="">Not defined</option>
                      {mapping[fieldNamesReverse[fieldName]] && (
                        <option value={mapping[fieldNamesReverse[fieldName]]}>
                          {" "}
                          {mapping[fieldNamesReverse[fieldName]]}{" "}
                        </option>
                      )}
                      {headers.map((header) => (
                        <option key={header} value={header}>
                          {header}
                        </option>
                      ))}
                    </MappingSelect>
                  </MappingRow>
                ))}
            </MappingColumn>

            <MappingColumn>
              {Object.values(fieldNames)
                .slice(Object.values(fieldNames).length / 2)
                .map((fieldName) => (
                  <MappingRow key={fieldName}>
                    <MappingLabel htmlFor={fieldName}>
                      {fieldName}: {requiredFields.includes(fieldName) && "*"}
                    </MappingLabel>
                    <MappingSelect
                      id={fieldName}
                      value={mapping[fieldNamesReverse[fieldName]]}
                      onChange={(e) =>
                        handleMappingChange(fieldName, e.target.value)
                      }
                      required={requiredFields.includes(fieldName)}
                    >
                      <option value={mapping[fieldNamesReverse[fieldName]]}>
                        {mapping[fieldNamesReverse[fieldName]]
                          ? mapping[fieldNamesReverse[fieldName]]
                          : "Select a header"}
                      </option>
                      {headers.map((header) => (
                        <option key={header} value={header}>
                          {header}
                        </option>
                      ))}
                    </MappingSelect>
                  </MappingRow>
                ))}
            </MappingColumn>
          </ColumnWrapper>

          <ButtonContainer>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <PreviewButton type="submit" form="fieldmapping">
              {loading ? spinner : "Preview"}
            </PreviewButton>
            {/* <PreviewButton onClick={handlePreviewTest}>Preview</PreviewButton> */}
          </ButtonContainer>
        </form>
      </FieldMappingWrapper>
    </Modal>
  );
};

export default FieldMapping;
