import React from "react";
import Header from "../components/FrontPage/Header/Header";
import Footer from "../components/Footer/Footer";
import Reservation from "../components/Reservation/Reservation";
import ReactTooltip from "react-tooltip";
import "../styles/Reservation/Reservation.css";
import LeftDrawer from "../components/Dashboard/Drawers/LeftDrawer";

const ReservationPage = ({USER}) => {
 return (
   <div>
    <Header />
    <Reservation/>
    <Footer/>
    <ReactTooltip className="tooltip"/>
    {USER && <LeftDrawer small />}
   </div>
 );
}

export default ReservationPage;
