var PageTitleNotification = {
  Vars: {
    OriginalTitle: document.title,
    Interval: null,
    OnFocus: () => PageTitleNotification.destroy(),
    OnBlur: null,
  },
  on: function (notification, intervalSpeed) {
    var _this = this;
    _this.destroy();
    _this.Vars.Interval = setInterval(
      function () {
        document.title =
          _this.Vars.OriginalTitle === document.title
            ? notification
            : _this.Vars.OriginalTitle;
      },
      intervalSpeed ? intervalSpeed : 1000
    );
    const onBlur = () => {
      PageTitleNotification.on(notification);
    };
    _this.Vars.OnBlur = onBlur;
    window.addEventListener('focus', _this.Vars.OnFocus);
    window.addEventListener('blur', _this.Vars.OnBlur);
  },
  /*   off: function () {
    clearInterval(this.Vars.Interval);
    document.title = this.Vars.OriginalTitle;
  }, */
  destroy: function () {
    clearInterval(this.Vars.Interval);
    document.title = this.Vars.OriginalTitle;
    window.removeEventListener('focus', this.Vars.OnFocus);
    window.removeEventListener('blur', this.Vars.OnBlur);
  },
};

export default PageTitleNotification;
