import styled, { css } from "styled-components";

export const Content = styled.div`
  margin: 4rem 0;
  h3 {
    color: ${(props) => (props.accompaniment ? "#31347D" : "#040730")};
    font-size: 1.5rem;
    margin-block-end: 1rem;
    svg {
      height: 30px;
      width: 30px;
      margin-right: 1rem;
    }
  }
  h3.getStarted {
    color: #040730;
    text-align: center;
  }
  p {
    color: #31347d;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  span {
    color: #dd5626;
  }
  ul {
    padding-left: calc(30px + 1rem);
    li {
      color: #262a68;
      font-size: 14px;
      list-style-type: none;
      padding: 0.5rem 0;
    }
  }
  @media (min-width: 992px) {
    h3 {
      font-size: 2rem;
    }
    ul li {
      font-size: 1rem;
    }
  }
  @media (min-width: 1200px) {
    margin: 0;
    h3 {
      svg {
        margin: none;
        width: unset;
        height: unset;
        display: none;
      }
    }
    h3 {
      font-size: 48px;
    }
    ul {
      margin: 1.5rem 0;
      padding: 0;
      li {
        font-size: 18px;
      }
    }
  }
`;

// props: centered
export const Block = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin: 4rem 0;
  @media (min-width: 1200px) {
    position: relative;
    margin: 4rem 0;
    display: inline-flex;
    flex-direction: ${(props) => (props.column ? "column !important" : "row")};
    width: 100%;
    align-items: ${(props) => (props.column ? "flex-start" : "center")};
    img.contentImage {
      width: 60%;
      // max-width: 400px;
    }
  }
  // @media (min-width: 1440px) {
  //   margin: 10rem 0;
  // }
  }
  ${(props) =>
    props.centered &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      }
    `}
    ${Content} {
      ${(props) =>
        props.centered &&
        css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          }
        `}
    }
  }
    `;
export const OffsetImage = styled.div`
  position: absolute;
  z-index: -1;
  opacity: 0.2;
`;

export const Offset = styled.div`
  align-self: center;
  &.clipped {
    video {
      width: 100%;
    }
  }
  @media (min-width: 1200px) {
    align-self: unset;
    height: ${(props) => props.height ?? "100%"};
    margin: ${(props) => (props.margin ? props.margin : "0")};
    transform: translate3d(
      ${(props) => props.x ?? 0},
      ${(props) => props.y ?? 0},
      0
    );
    &.clipped {
      img {
        width: 70%;
        -webkit-clip-path: url(#clip);
      }
      video {
        width: 80%;
        -webkit-clip-path: url(#clip);
      }
    }
  }
`;

export const Wrapper = styled.div`
  @media (min-width: 1200px) {
    ${Block}:nth-child(2) {
      ${Content} h3 {
        width: 28.6875rem;
      }
    }
    ${Offset} > ${Block} {
      flex-direction: row-reverse;
      h3 {
        width: 42.8125rem;
      }
      // flex-direction: row;
      justify-content: flex-end;
      // ${Content} {
      //   margin-left: 10rem;
      // }
      ${OffsetImage} {
        top: 0;
        right: -20%;
      }
    }
  }
`;
export const MainImage = styled.div``;
export const PartnerImage = styled.div`
  display: none;
  @media (min-width: 1200px) {
    transform: translate(14%, 35%);
    display: block;
    position: relative;
    ${OffsetImage} {
      transform: translate(-90px, -25px);
    }
  }
  @media (min-width: 1440px) {
    transform: translate(20%, 35%);
  }
`;
export const PublishImage = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
    position: relative;
  }
`;

export const Gap = styled.div`
  height: ${(props) => props.height ?? "2rem"};
`;

// second card is offset by 25% above using transform: translateY(25%)
export const CardRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  @media (min-width: 1200px) {
    flex-direction: row;
    gap: 2rem;
    transform: ${(props) => props.offset && "translatex(96px)"};
  }
  ${(props) =>
    props.offsetBlue &&
    css`
      @media (min-width: 1200px) {
        margin: unset;
        margin-top: 8rem;
        div.Card:nth-child(2) {
          transform: translateY(-40%);
        }
      }
    `}
`;

// CardsWrapper wraps over CardRow
export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
  .Card-header h3 {
    font-size: 1.25rem;
    padding-right: 0;
    min-width: 10rem;
  }
  .Card-content {
    color: #040730;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  @media (min-width: 1200px) {
    flex-direction: column;
  }
  // img {
  //   width: 25%;
  // }
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2rem;
  gap: 2rem;
  font-size: medium;
  a {
    font-size: 16px;
    padding: 0.5rem 1.5rem;
  }
  img {
    width: 30%;
    max-width: 100px;
  }
  ${(props) =>
    props.justifyEnd &&
    css`
      justify-content: flex-end;
    `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  ${(props) =>
    props.background &&
    css`
      background: ${props.background};
      box-shadow: 0 0 0 100vmax ${props.background};
      clip-path: inset(0 -100vmax);
    `}
  @media (min-width: 1200px) {
    flex-direction: row;
    gap: 4rem;
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 1rem;
  font-size: xx-large;
  a {
    color: #2f4265;
    text-decoration: none;
  }
`;

export const ArrowLinkWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: 0;
  font-size: xx-large;
  a {
    color: #2f4265;
    text-decoration: none;
  }
`;