import React, { useState } from "react";

import { Wrapper, Inner, SelectionGroup, Selection, List } from "./Styles";

const Benefits = () => {
  const [tab, setTab] = useState(1);
  return (
    <Wrapper>
      <Inner>
        <h3>Benefits for</h3>
        <SelectionGroup>
          <Selection
            type="button"
            onClick={() => setTab(1)}
            active={tab === 1 ? 1 : null}
          >
            Haulier
          </Selection>
          <Selection
            type="button"
            onClick={() => setTab(2)}
            active={tab === 2 ? 1 : null}
          >
            Forwarder
          </Selection>
        </SelectionGroup>
        <List>
          {tab === 1 ? (
            <>
              <li>
                Access the best <strong>roundtrip/tender/spot freight</strong>{" "}
                offers on market
              </li>
              <li>
                Direct contact with top <strong>freight forwarders</strong>
              </li>
              <li>
                Earn up to{" "}
                <strong>10% more on long-term transport co-operations</strong>
              </li>
              <li>
                Stay up-to-date with <strong>current prices</strong> on the
                market
              </li>
            </>
          ) : (
            <>
              <li>
                Publish your <strong>roundtrips/tenders/spot freights</strong>
              </li>
              <li>
                Create new <strong>long-term partnerships</strong>
              </li>
              <li>
                Stay up-to-date with <strong>current prices</strong> on the
                market
              </li>
              <li>
                See the <strong>ratings/feedback/background</strong> of
                potential partners
              </li>
            </>
          )}
        </List>
      </Inner>
    </Wrapper>
  );
};

export default Benefits;
