import { SET_OFFERS, UPDATE_OFFER, REMOVE_OFFER } from "../../actionTypes";

const initialState = {
  data: null,
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OFFERS:
      return {
        ...state,
        data: action.payload,
      };
    case UPDATE_OFFER:
      const offerArray = state.data.find((x) => x[action.array]);
      const newOfferIndex = offerArray[action.array].findIndex(
        (offer) => offer._id === action.payload._id
      );
      const newOffers = offerArray[action.array].map((offer, index) => {
        if (index === newOfferIndex) {
          return action.payload;
        }
        return offer;
      });
      const allNewOffers = state.data.map((offer) => {
        if (offer[action.array]) {
          return { [action.array]: newOffers };
        }
        return offer;
      });
      return {
        ...state,
        data: allNewOffers,
      };
    case REMOVE_OFFER:
      const filterOfferArray = state.data.find((x) => x[action.array]);
      const filteredArrayOffers = filterOfferArray[action.array].filter(
        (offer) => offer._id !== action.id
      );
      const filteredNewOffers = state.data.map((offer) => {
        if (offer[action.array]) {
          return { [action.array]: filteredArrayOffers };
        }
        return offer;
      });
      return {
        ...state,
        data: filteredNewOffers,
      };
    default:
      return state;
  }
};

export default reducer;
