import React from "react";
import TrucktypeSelect from "../../CreateForm/TrucktypeSelect";
import Input from "../../CreateForm/Input";
import { Wrapper, InputWrapper } from "../../CreateForm/Styles";
import CheckboxGroup from "../../CreateForm/CheckboxGroup";
import Checkbox from "../../CreateForm/Checkbox";
import CountrySelect from "../../CreateForm/CountrySelect";
import StandardQuill from "../../UI/StandardQuill/";
import LanguageSelect from "../../CreateForm/LanguageSelect.js";

//TODO Clean up dependencies

const FindCreatorStepOne = (props) => {
  return (
    <Wrapper>
      <InputWrapper>
        <CountrySelect
          label="Countries"
          type="countries"
          placeholder="Select countries"
          inputs={props.inputs}
          onSelectChange={props.onSelectChange}
          error={props.errors?.countries}
        />
        <TrucktypeSelect
          label="Truck types"
          name="truckTypes"
          onSelectChange={(value, name) =>
            props.onSelectChange(value, name, "trucks")
          }
          onInputChange={(value, name) =>
            props.onInputChange(value, name, "trucks")
          }
          inputs={props.inputs?.trucks}
          errors={props.errors}
        />
        <StandardQuill
          label="Truck info"
          name="additionalInfo"
          placeholder="Additional info for trucks"
          value={props.inputs?.trucks?.additionalInfo}
          onInputChange={(value, name) =>
            props.onInputChange(value, name, "trucks")
          }
          error={props.errors?.trucks?.additionalInfo}
        />
         <CheckboxGroup
        label="Capabilities"
        error={props.errors?.requirements}
      >
        <Checkbox
          label="ADR"
          value="ADR"
          name="requirements"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="requirements"
        />
        <Checkbox
          label="Euro pallet exchange"
          value="euroPallet"
          name="requirements"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="requirements"
        />
        <Checkbox
          label="TIR Cable"
          value="TIRCable"
          name="requirements"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="requirements"
        />
        <Checkbox
          label="Live tracking"
          value="liveTracking"
          name="requirements"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="requirements"
        />
        <Checkbox
          label="A-shield"
          value="aShield"
          name="requirements"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
          descendant="requirements"
        />
      </CheckboxGroup>
        <LanguageSelect
          label="Languages of communication"
          name="languages"
          placeholder="Select languages"
          isMulti
          onSelectChange={props.onSelectChange}
          onInputChange={props.onInputChange}
          inputs={props.inputs}
          error={props.errors?.languages}
        />
        <StandardQuill
          label="Additional information"
          name="additionalInformation"
          placeholder="Enter any additional information about roundtrip"
          value={props.inputs?.additionalInformation}
          onInputChange={props.onInputChange}
          error={props.errors?.additionalInformation}
        />
      </InputWrapper>
    </Wrapper>
  );
};

export default FindCreatorStepOne;
