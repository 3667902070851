import React from "react";
import Container from "../../components/UI/Container";
import {
  FormContainer,
  FormWrapperInner,
  InputContainer,
  ButtonDiv,
  VATbutton,
  SuccessToast,
  InsuranceLogo,
  InsuranceMobileLogo,
  MainPara,
} from "./Styles";
import axios from "axios";
import insuranceLogo from "../../images/TCIA.png";
import { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";

const CargoInsurance = () => {
  const [from] = useState("Cargo insurance");
  const [companyName, setCompanyName] = useState();
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [phone, setPhone] =useState('')
  const [lang, setLang] =useState('')

  const reRef = useRef(<ReCAPTCHA />);

  const postAdminData = async (e) => {
    e.preventDefault();
    const token = await reRef.current.executeAsync();
    reRef.current.reset();
    axios
      .post(
        "https://api.transconnector.eu/insurance",
        {
          data: {
            firstName: firstName,
            lastName: lastName,
            company_name: companyName,
            email: email,
            data_origin_form: from,
            phone: '+'+phone,
            lang: lang,
            token,
          },
        },
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setSuccessMessage(
            <ButtonDiv>
              <SuccessToast>
                <h3>
                  We will contact You shortly to collect the information that is
                  necessary to provide a quote.
                </h3>
              </SuccessToast>
            </ButtonDiv>
          );
        }
      })
      .catch((err) => {
        alert(
          "Something went wrong, please check your connectivity or try again later."
        );
      });
  };
  return (
    <Container gutter="medium">
      <div>
        <div style={{ marginTop: "4rem", display: "flex" }}>
          <div>
            <h1> Cargo insurance – what is it about?</h1>
            <InsuranceMobileLogo>
              <img src={insuranceLogo} alt="" />
            </InsuranceMobileLogo>
            <MainPara style={{ lineHeight: "1.5rem" }}>
              Cargo insurance is mainly in the cargo owner's interest and will
              help if the transported cargo is damaged, destroyed or lost.
              Liability insurance obtained by cargo carrier or freight forwarder
              will only cover the damage according to international convention
              limit and the cargo owner may sustain notable loss because of the
              CMR compensation limits. Cargo insurance helps reduce those
              financial losses. In other words, in case of cargo damage you will
              have additional financing through cargo insurance.
            </MainPara>
          </div>
          <div>
            <InsuranceLogo>
              <img src={insuranceLogo} alt="" />
            </InsuranceLogo>
          </div>
        </div>
        <div style={{ paddingLeft: "3rem", marginTop: "3rem" }}>
          <p>
            {" "}
            <b>—&gt; Large sum insured per event</b>
          </p>
          <MainPara>
            Our sum insured can amount to EUR 5 million or even more for each
            insured event.
          </MainPara>
          <p>
            <b>—&gt; No limit for the policy period</b>
          </p>
          <MainPara>
            There will be no policy aggregate, i.e., number of the insured
            incidents per policy period shall not be limited.
          </MainPara>
          <p>
            <b>—&gt; Worldwide protection</b>
          </p>
          <MainPara>
            We cover cargo transported worldwide, except for war disturbance
            areas.
          </MainPara>
          <p>
            <b>—&gt; Cover for extra expenses</b>
          </p>
          <MainPara>
            We can insure extra expenses (loss of profit, freight, insurance
            costs, etc.) related to cargo damage.
          </MainPara>{" "}
        </div>
      </div>
      <FormContainer>
        <FormWrapperInner>
          <form onSubmit={postAdminData}>
            <InputContainer style={{ width: "100%" }}>
              <h3 style={{ margin: "2rem auto" }}>
                Ask for quote, it doesn't cost you anything!
              </h3>
            </InputContainer>
            <InputContainer>
              <label htmlFor="first_name">First Name: </label>
              <input
                type="text"
                value={firstName}
                required
                placeholder="First Name"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </InputContainer>
            <br />
            <InputContainer>
              <label htmlFor="last_name">Last Name: </label>
              <input
                type="text"
                value={lastName}
                required
                placeholder="Last Name"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </InputContainer>
            <br />
            <InputContainer>
              <label htmlFor="company_name">Company Name: </label>
              <input
                type="text"
                value={companyName}
                required
                placeholder="Company Name"
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
              />
            </InputContainer>
            <br />
            <InputContainer>
              <label htmlFor="lang">Preffered language: </label>
              <input
                type="lang"
                value={lang}
                placeholder="Language"
                required
                onChange={(e) => setLang(e.target.value)}
              />
            </InputContainer>
            <br />

            <InputContainer>
              <label htmlFor="email">E-mail: </label>
              <input
                type="email"
                value={email}
                required
                placeholder="email@email.com"
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputContainer>
            <br />
            <InputContainer>
              <label style={{width: '100%', display: 'block'}}htmlFor="phone">Phone: </label>

              <PhoneInput
              
                value={phone}
                inputProps={{ name: "phone" }}
                country={"de"}
                onChange={(e, a) => 
                  {
                    setPhone(e)
                  }}

              />
            </InputContainer>
            <ButtonDiv>
              <ReCAPTCHA
                sitekey="6LenyGkiAAAAAHIokUL5B2k6qb3y4fGmPitExsFg"
                size="invisible"
                ref={reRef}
              />
            </ButtonDiv>

            <div className="message">
              {successMessage ? (
                <p>{successMessage}</p>
              ) : (
                <ButtonDiv style={{ marginTop: "4rem" }}>
                  <VATbutton type="submit">Register</VATbutton>
                </ButtonDiv>
              )}
            </div>
          </form>
        </FormWrapperInner>
      </FormContainer>
    </Container>
  );
};
export default CargoInsurance;
