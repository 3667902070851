import React from "react";
import RadioGroup from "../../CreateForm/RadioGroup";
import RadioInput from "../../CreateForm/RadioInput";
import NumberGroup from "../../CreateForm/NumberGroup";
import NumberInput from "../../CreateForm/NumberInput";
import AdditionalRequirements from "../../CreateForm/AdditionalRequirements";
import CheckboxGroup from "../../CreateForm/CheckboxGroup";
import Checkbox from "../../CreateForm/Checkbox";
import CargoTypeSelect from "../../CreateForm/CargoTypeSelect";
import Textarea from "../../CreateForm/Textarea";
import { Wrapper, InlineWrapper } from "../../CreateForm/Styles";

const TenderCreatorStepTwo = (props) => {
  return (
    <Wrapper>
      <InlineWrapper>
        <RadioGroup label="Type of load" error={props.errors?.typeOfLoad}>
          <InlineWrapper>
            <RadioInput
              name="typeOfLoad"
              value="FTL"
              onInputChange={props.onInputChange}
              inputs={props.inputs}
            />
            <RadioInput
              name="typeOfLoad"
              value="LTL"
              onInputChange={props.onInputChange}
              inputs={props.inputs}
            />
          </InlineWrapper>
        </RadioGroup>
        <NumberGroup>
          <InlineWrapper>
            <NumberInput
              label="Weight"
              name="weight"
              placeholder="Kg"
              descendant="freight"
              min={0}
              inputs={props.inputs}
              value={props.inputs?.freight?.weight || ""}
              onInputChange={props.onInputChange}
              error={props.errors?.weight}
            />
            <NumberInput
              label="Loading meters"
              name="loadingMeters"
              placeholder="Meter"
              descendant="freight"
              min={0}
              inputs={props.inputs}
              value={props.inputs?.freight?.loadingMeters || ""}
              onInputChange={props.onInputChange}
              error={props.errors?.loadingMeters}
            />
          </InlineWrapper>
          <AdditionalRequirements
            onInputChange={props.onInputChange}
            errors={props.errors}
            inputs={props.inputs}
            showVolume
          />
        </NumberGroup>
      </InlineWrapper>
      <CargoTypeSelect
        label="Cargo type"
        name="cargoType"
        inputs={props.inputs}
        onSelectChange={props.onSelectChange}
        error={props.errors?.cargoType}
      />
      <CheckboxGroup
        label="Special requests"
        error={props.errors?.specialRequests}
      >
        <Checkbox
          label="ADR"
          name="specialRequests"
          descendant="specialRequests"
          value="ADR"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
        />
        <Checkbox
          label="Euro pallet exchange"
          name="specialRequests"
          descendant="specialRequests"
          value="euroPalletExchange"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
        />
        <Checkbox
          label="TIR Cable"
          name="specialRequests"
          descendant="specialRequests"
          value="TIRCable"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
        />
        <Checkbox
          label="Live tracking"
          name="specialRequests"
          descendant="specialRequests"
          value="liveTracking"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
        />
        <Checkbox
          label="Non stackable"
          descendant="specialRequests"
          name="specialRequests"
          value="nonStackable"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
        />
        <Checkbox
          label="Waste load"
          name="specialRequests"
          descendant="specialRequests"
          value="wasteLoad"
          inputs={props.inputs}
          onInputChange={props.onInputChange}
        />
      </CheckboxGroup>
      <Textarea
        label="Other requirements"
        name="otherReq"
        placeholder="Other requirements"
        inputs={props.inputs}
        onInputChange={props.onInputChange}
        error={props.errors?.extraInfo}
      />
    </Wrapper>
  );
};

export default TenderCreatorStepTwo;
