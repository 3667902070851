import React from "react";

const Archive = () => {
  return (
    <div className="Screen--inner">
      <div className="Screen--block">
        <div className="Screen--block--inner">
          <div className="Screen--block-title">
            <h2>Archived Quotations</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Archive;
