import React, {useMemo, useState} from 'react';
import {SingleDatePicker} from 'react-dates'
import moment from "moment";
import {isMobile} from "react-device-detect";
import {CalendarIcon} from "../icons";
import {useField} from "formik";
import {DataPicker} from "./Styles";

const FormikDatePicker = ({
                           name,
                           placeholder
                          }) => {
 const [field, meta, helpers] = useField(name);

 const isError = useMemo(() => !!(meta.touched && meta.error), [meta.error, meta.touched]);
 const [focused, setFocused] = useState(false);
 const classes = useMemo(() => [focused && 'focused', isError && 'error'].filter(Boolean).join(' '), [focused, isError]);
 const value = useMemo(() => {
  if (!field.value) {
   return null
  }
  return moment(field.value)
 }, [field.value])
 return (
  <DataPicker className={classes}>
   <SingleDatePicker
     date={value}
     onDateChange={(date) => {
      helpers.setValue(date ? moment(date).format('YYYY-MM-DD') : date)
     }
     }
     focused={focused}
     displayFormat="DD.MM.YYYY"
     placeholder={placeholder}
     numberOfMonths={1}
     customInputIcon={<CalendarIcon className="icon"/>}
     onFocusChange={({ focused }) => setFocused(focused)}
     firstDayOfWeek={1}
     withPortal={isMobile}
   />
   {isError && (<div className="error-message">{meta.error}</div>)}
  </DataPicker>
 );
}

export default FormikDatePicker;
