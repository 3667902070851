import React from "react";
import Container from "../../components/UI/Container";
import {
  FormContainer,
  FormWrapperInner,
  InputContainer,
  ButtonDiv,
  VATbutton,
  SuccessToast,
  InsuranceLogo,
  InsuranceMobileLogo,
  MainPara,
} from "./Styles";
import axios from "axios";
import insuranceLogo from "../../images/TCIA.png";
import { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";
import { SelectContainer } from "./Styles";
import { selectStyles } from "./Styles";
import PhoneInput from "react-phone-input-2";
const NUMBERARRAY = [...new Array(101)].map((each, index) => ({
  label: index,
  value: index,
}));



const CTI = () => {
  const [from] = useState("Travel insurance");
  const [companyName, setCompanyName] = useState();
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [employeeNumber, setEmployeeNumber] = useState();
  const [phone, setPhone] =useState('')
  const [lang, setLang] =useState('')

  const reRef = useRef(<ReCAPTCHA />);

  const err = () => {
    alert(
      "Please select the number of employees to be insured before submitting the form"
    );
  };

  const postAdminData = async (e) => {
    e.preventDefault();
    const token = await reRef.current.executeAsync();
    reRef.current.reset();
    axios
      .post(
        "https://api.transconnector.eu/insurance",
        {
          data: {
            firstName: firstName,
            lastName: lastName,
            company_name: companyName,
            email: email,
            employeeNumber: employeeNumber,
            data_origin_form: from,
            phone: '+'+phone,
            lang: lang,
            token,
          },
        },
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setSuccessMessage(
            <ButtonDiv>
              <SuccessToast>
                <h3>
                  We will contact You shortly to collect the information that is
                  necessary to provide a quote.
                </h3>
              </SuccessToast>
            </ButtonDiv>
          );
        }
      })
      .catch((err) => {
        console.log(err);
        alert(
          "Something went wrong, please check your connectivity or try again later."
        );
      });
  };
  return (
    <Container gutter="medium">
      <div>
        <div style={{ marginTop: "4rem", display: "flex" }}>
          <div>
            <h1>Travel Insurance – what is it about?</h1>
            <InsuranceMobileLogo>
              <img src={insuranceLogo} alt="" />
            </InsuranceMobileLogo>
            <MainPara>
              With travel insurance you will have good coverage for unexpected
              illnesses and injuries which can come in very helpful if your
              worker is having health related problems on a working trip. Since
              employers have to pay for the losses which workers may have during
              business trips, it is good to have this specially designed health
              insurance for employers to reduce risks and financial losses and
              to help your workers.
            </MainPara>
          </div>
          <div>
            <InsuranceLogo>
              <img src={insuranceLogo} alt="" />
            </InsuranceLogo>
          </div>
        </div>
        <div style={{ paddingLeft: "3rem", marginTop: "3rem" }}>
          <p>
            <b>—&gt; Pandemic and epidemic</b>
          </p>
          <MainPara>
            A disease for which a pandemic or epidemic has been declared shall
            be considered an insured event.
          </MainPara>
          <p>
            <b>—&gt; 24/7 assistance in your language</b>
          </p>
          <MainPara>
            In case of an accident, the assistance company will provide 24/7
            assistance in Lithuanian, Latvian, Estonian, English, Russian and
            German languages.
          </MainPara>
          <p>
            <b>—&gt; No deductible</b>
          </p>
          <MainPara>
            In most cases, we will not apply deductible not only to medical
            expenses, but also to travel expenses, luggage, and Third Party
            liability insurance.
          </MainPara>
          <p>
            <b>—&gt; We will cover theft of computer and travel documents</b>
          </p>
          <br />
          <p>
            <h3>We will not apply sub-limits to:</h3>
          </p>
          <p>—&gt; Liability for non-pecuniary damage and court costs</p>
          <p>—&gt; Baggage items or groups of items</p>
          <p>
            —&gt; If you lose your travel connection – for acquiring a new
            ticket
          </p>
          <p>—&gt; Insurance in emergency situations</p>
          <p>
            War and terrorism cover shall also include a passive war and
            terrorism while being abroad.
          </p>{" "}
        </div>
      </div>
      <FormContainer style={{ width: "100%" }}>
        <FormWrapperInner>
          <form onSubmit={postAdminData}>
            <InputContainer style={{ width: "100%" }}>
              <h3 style={{ margin: "2rem auto" }}>Ask for a quote!</h3>
            </InputContainer>

            <InputContainer>
              <label htmlFor="first_name">First Name: </label>
              <input
                type="text"
                value={firstName}
                required
                placeholder="First Name"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </InputContainer>
            <br />
            <InputContainer>
              <label htmlFor="last_name">Last Name: </label>
              <input
                type="text"
                value={lastName}
                required
                placeholder="Last Name"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </InputContainer>
            <br />
            <InputContainer>
              <label htmlFor="company_name">Company Name: </label>
              <input
                type="text"
                value={companyName}
                required
                placeholder="Company Name"
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
              />
            </InputContainer>
            <br />

            <InputContainer>
              <label htmlFor="country">Employees to insure: </label>
              <SelectContainer>
                <Select
                  name="Employees"
                  options={NUMBERARRAY}
                  styles={selectStyles}
                  required
                  placeholder="0 - 100"
                  onSubmit={employeeNumber === "" ? err : ""}
                  onChange={(choice) => {
                    setEmployeeNumber(choice.value);
                  }}
                />
              </SelectContainer>
            </InputContainer>
            <br />
            <InputContainer>
              <label htmlFor="lang">Preffered language: </label>
              <input
                type="lang"
                value={lang}
                placeholder="Language"
                required
                onChange={(e) => setLang(e.target.value)}
              />
            </InputContainer>
            <br />
            <InputContainer>
              <label htmlFor="email">E-mail: </label>
              <input
                type="email"
                value={email}
                required
                placeholder="email@email.com"
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputContainer>
            <br />
            <InputContainer>
              <label style={{width: '100%', display: 'block'}}htmlFor="phone">Phone: </label>

              <PhoneInput
              
                value={phone}
                inputProps={{ name: "phone" }}
                country={"de"}
                onChange={(e, a) => 
                  {
                    setPhone(e)
                  }}

              />
            </InputContainer>
            <ButtonDiv>
              <ReCAPTCHA
                sitekey="6LenyGkiAAAAAHIokUL5B2k6qb3y4fGmPitExsFg"
                size="invisible"
                ref={reRef}
              />
            </ButtonDiv>

            <div className="message">
              {successMessage ? (
                <p>{successMessage}</p>
              ) : (
                <ButtonDiv style={{ marginTop: "4rem" }}>
                  <VATbutton type="submit">Register</VATbutton>
                </ButtonDiv>
              )}
            </div>
          </form>
        </FormWrapperInner>
      </FormContainer>
    </Container>
  );
};
export default CTI;
