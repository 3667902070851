import React from "react";
import { CTA } from "../CreateForm/Styles";
import { Link } from "react-router-dom";

const LargeButton = (props) => {
  return (
    <CTA secondary={props.secondary} centered={props.centered}>
      {!props.to ? (
        <button
          type={props.type || "button"}
          value={props.value}
          onClick={props.onClick}
          disabled={props.disabled}
        >
          {props.label && props.label}
        </button>
      ) : (
        <Link to={props.to}>{props.label}</Link>
      )}
    </CTA>
  );
};

export default LargeButton;
