import React, { Fragment, useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import uuid from "uuid";

import Form from "../UI/Form";
import Input from "../UI/Input";
import iOptions from "../../../assets/icons/options-horizontal.svg";
import iClose from "../../../assets/icons/drawer-close-mark.svg";
import welcomeWarning from "../../../assets/welcome/warning.svg";
import "../../../styles/Dashboard/UI/Table.css";

const Table = (props) => {
  const [clickedId, setClickedId] = useState();
  const [currentConfirmationModal, setCurrentConfirmationModal] = useState();
  const [currentEditModal, setCurrentEditModal] = useState();
  const [redirect, setRedirect] = useState();
  const [visible, setVisible] = useState(false);
  // Attach a click listener on the document.
  //
  const useOutsideClick = () => {
    const handleClick = (e) => {
      if (
        !e.target.getAttribute("data-key") &&
        e.target.getAttribute("class") !== "options true"
      ) {
        setVisible(false);
        setClickedId();
      }
    };
    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => {
        document.removeEventListener("click", handleClick);
      };
    }, []);
  };
  useOutsideClick();

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <div className="Table">
        <div className="Table--inner">
          <div className="Table-head only-desktop">
            {props.head &&
              props.head.map((item) => {
                return (
                  <Fragment key={item.key}>
                    {item.items.map((title) => {
                      return <h4 key={title.name}>{title.name}</h4>;
                    })}
                  </Fragment>
                );
              })}
          </div>
          <div className="Table-content">
            {props.content &&
              props.content.map((content) => {
                return (
                  <Fragment key={content.key}>
                    {content.items.map((row) => {
                      return (
                        <div className="Table-row" key={uuid()}>
                          {row.item.map((item) => {
                            const id = uuid();
                            return (
                              <Fragment key={id}>
                                <div className="item">
                                  {item.mobileTitle ? (
                                    <h6 className="only-mobile">
                                      {item.mobileTitle}
                                    </h6>
                                  ) : null}
                                  {item.component ? (
                                    item.component()
                                  ) : null}
                                  {item.name ? (
                                    item.isLink ? (
                                      <a
                                        href={item.linkTo}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {item.name}
                                      </a>
                                    ) : item.isRouterLink ? (
                                      <Link
                                        to={{
                                          pathname: item.linkTo,
                                          state: item.state,
                                        }}
                                      >
                                        {item.name}
                                      </Link>
                                    ) : (
                                      <h4>{item.name}</h4>
                                    )
                                  ) : null}
                                  {item.subtitle ? (
                                    <h5>{item.subtitle}</h5>
                                  ) : null}
                                  {item.toggler ? (
                                    <div className="toggler">
                                      <input
                                        type="checkbox"
                                        name={id}
                                        defaultChecked={item.checked}
                                        onChange={(e) => item.onChange(e)}
                                      />
                                      <div className="toggler-button"></div>
                                      {item.checked ? "Active" : "Inactive"}
                                    </div>
                                  ) : null}
                                  {item.togglerStatus ? (
                                    <div
                                      className={`toggler-status ${
                                        item.togglerStatus === "Active" ||
                                        item.togglerStatus === true
                                          ? "active"
                                          : "inactive"
                                      }`}
                                    >
                                      {item.togglerStatus}
                                    </div>
                                  ) : null}
                                  {item.status ? (
                                    <div
                                      className={`toggler-status ${
                                        item.status === "In review" 
                                          ? "in-review"
                                          : item.status === "Verified"
                                          ? "verified"
                                          : item.status === "Rejected"
                                          ? "rejected"
                                          : item.status === "Not uploaded"
                                          ? "not-uploaded"
                                          : ""
                                      }`}
                                    >
                                      {item.status}
                                    </div>
                                  ) : null}
                                  {item.options &&
                                    item.options.map((optionItem) => {
                                      return (
                                        <Fragment key={optionItem.key}>
                                          <div
                                            className={`options ${
                                              clickedId !== optionItem.key &&
                                              visible === false
                                                ? "false"
                                                : "true"
                                            }`}
                                            data-key={optionItem.key}
                                            onClick={(e) =>
                                              clickedId !== e.target.dataset.key
                                                ? setClickedId(
                                                    e.target.dataset.key
                                                  )
                                                : setClickedId()
                                            }
                                          >
                                            <img src={iOptions} alt="Options" />
                                            <div className="options-dropdown">
                                              <ul>
                                                {optionItem.items &&
                                                  optionItem.items.map(
                                                    (option) => {
                                                      return option.isLink ? (
                                                        <a
                                                          href={option.to}
                                                          key={option.name}
                                                          onClick={
                                                            option.onClick
                                                          }
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                        >
                                                          {option.name}
                                                        </a>
                                                      ) : (
                                                        <li
                                                          key={option.name}
                                                          onClick={() => {
                                                            // Set confirmation modal
                                                            if (
                                                              option.confirmationModal &&
                                                              currentConfirmationModal !==
                                                                optionItem.key
                                                            ) {
                                                              setCurrentConfirmationModal(
                                                                optionItem.key
                                                              );
                                                            } else {
                                                              setCurrentConfirmationModal();
                                                            }
                                                            // Set redirect
                                                            if (option.redirect)
                                                              setRedirect(
                                                                option.to
                                                              );
                                                            if (
                                                              option.windowRedirect
                                                            ) {
                                                              window.location.href =
                                                                option.to;
                                                            }
                                                            // Set edit modal
                                                            if (
                                                              option.editable &&
                                                              currentEditModal !==
                                                                optionItem.key
                                                            ) {
                                                              setCurrentEditModal(
                                                                optionItem.key
                                                              );
                                                            } else {
                                                              setCurrentEditModal();
                                                            }
                                                          }}
                                                        >
                                                          {option.name}
                                                        </li>
                                                      );
                                                    }
                                                  )}
                                              </ul>
                                            </div>
                                          </div>
                                        </Fragment>
                                      );
                                    })}
                                </div>
                              </Fragment>
                            );
                          })}
                        </div>
                      );
                    })}
                  </Fragment>
                );
              })}
          </div>
        </div>
      </div>
      {/* Generate modals */}
      {props.content &&
        props.content.map((content) => {
          return (
            <Fragment key={content.key}>
              {content.items.map((row) => {
                return row.item.map((item) => {
                  return (
                    item.options &&
                    item.options.map((optionItem) =>
                      optionItem.items.map((itemOption) => {
                        /* Generate a confirmation modal for every option that has confirmationModal: true */

                        if (
                          itemOption.confirmationModal &&
                          currentConfirmationModal &&
                          currentConfirmationModal === optionItem.key
                        ) {
                          return (
                            <Fragment key={uuid()}>
                              <div className="Table_Modal" key={optionItem.key}>
                                <div className="Table_Modal--inner">
                                  <div className="Table_Modal--header">
                                    <h2>
                                      {itemOption.name} - {row.item[0].name}
                                    </h2>
                                    <div
                                      className="Table_Modal--inner-close"
                                      onClick={() =>
                                        setCurrentConfirmationModal()
                                      }
                                    >
                                      <img src={iClose} alt="Close" />
                                    </div>
                                  </div>
                                  <div className="Table_Modal--body">
                                    <img src={welcomeWarning} alt="" />
                                    <h3>
                                      Are you sure you want to perform this
                                      action?
                                    </h3>
                                    <h4>This action is permanent.</h4>
                                  </div>
                                  <div className="Table_Modal--footer">
                                    <div
                                      className="Table_Modal--footer--buttons"
                                      onClick={() =>
                                        setCurrentConfirmationModal()
                                      }
                                    >
                                      <button type="button">Cancel</button>
                                      <button
                                        type="button"
                                        onClick={itemOption.onConfirm}
                                      >
                                        Confirm
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="Table_Modal--close"
                                onClick={() => setCurrentConfirmationModal()}
                              ></div>
                            </Fragment>
                          );
                        }

                        /* Generate a edit modal for every option that has editable: true */

                        if (
                          itemOption.editable &&
                          currentEditModal &&
                          currentEditModal === optionItem.key
                        ) {
                          return (
                            <Fragment key={uuid()}>
                              <div className="Table_Modal" key={optionItem.key}>
                                <div className="Table_Modal--inner">
                                  <div className="Table_Modal--header">
                                    <h2>Editing: {row.item[0].name}</h2>
                                    <div
                                      className="Table_Modal--inner-close"
                                      onClick={() => setCurrentEditModal()}
                                    >
                                      <img src={iClose} alt="Close" />
                                    </div>
                                  </div>
                                  <div className="Table_Modal--body">
                                    <Form
                                      onSubmit={(e) => itemOption.onEdit(e)}
                                    >
                                      {row.item.map((element) => {
                                        if (element.isEditable) {
                                          return (
                                            <Fragment key={uuid()}>
                                              <h5>{element.mobileTitle}</h5>
                                              {element.inputs &&
                                                element.inputs.map((input) => {
                                                  return (
                                                    <Fragment key={uuid()}>
                                                      <Input
                                                        InputType={
                                                          input.InputType
                                                        }
                                                        type={input.type}
                                                        htmlFor={input.htmlFor}
                                                        name={input.name}
                                                        value={input.value}
                                                        defaultValue={
                                                          input.defaultValue
                                                        }
                                                        placeholder={
                                                          input.placeholder
                                                        }
                                                        defaultChecked={
                                                          input.defaultChecked
                                                        }
                                                        min={input.min}
                                                        max={input.max}
                                                        maxLength={
                                                          input.maxLength
                                                        }
                                                        required={
                                                          input.required
                                                        }
                                                        minLength={
                                                          input.minLength
                                                        }
                                                        onChange={
                                                          input.onChange
                                                            ? (e) =>
                                                                input.onChange(
                                                                  e
                                                                )
                                                            : null
                                                        }
                                                      />
                                                    </Fragment>
                                                  );
                                                })}
                                            </Fragment>
                                          );
                                        } else {
                                          return null;
                                        }
                                      })}
                                      <input type="submit" value="Save" />
                                    </Form>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="Table_Modal--close"
                                onClick={() => setCurrentEditModal()}
                              ></div>
                            </Fragment>
                          );
                        } else {
                          return null;
                        }
                      })
                    )
                  );
                });
              })}
            </Fragment>
          );
        })}
    </>
  );
};

export default Table;
