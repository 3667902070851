import React, { useState, useRef } from "react";
import allCountries from "../../data/allCountries.json";
import Select from "react-select";
import Container from "../../components/UI/Container";

import {
  Refund,
  ListSection,
  EurowagLogo,
  VATbutton,
  ButtonDiv,
  ArrowDiv,
  ArrowDiv2,
  BackgroundDiv,
  Bullet,
  EurowagMobileLogo,
  TitleContent,
  FormContainer,
  FormWrapperInner,
  InputContainer,
  SelectContainer,
  selectStyles,
} from "./Styles";
import Eurowag from "../../assets/icons/Eurowag_logo.svg";
import StyledArrow from "../../assets/icons/StyleArrow.svg";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const countries = allCountries.countries;

const VatRefund = () => {
  const [country, setCountry] = useState("");
  const [from] = useState("VATRefund page");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [regNumber, setRegNumber] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [message, setMessage] = useState("");

  const reRef = useRef(<ReCAPTCHA />);

  let handleSubmit = async (e) => {
    e.preventDefault();

    const token = await reRef.current.executeAsync();
    reRef.current.reset();
    axios
      .post(
        "https://api.transconnector.eu/fuelFormData",
        {
          data: {
            country: country,
            company_name: companyName,
            email: email,
            mobile_number: mobileNumber,
            company_registration_no: regNumber,
            company_vat_no: vatNumber,
            data_origin_form: from,
            token,
          },
        },
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .then((res) => {
       
      })
      .catch((err) => console.log(err));

    try {
      let res = await fetch(
        "https://hooks.zapier.com/hooks/catch/2612367/bqy7c9x/",
        {
          method: "POST",
          body: JSON.stringify({
            country: country,
            company_name: companyName,
            email: email,
            mobile_number: mobileNumber,
            company_registration_no: regNumber,
            company_vat_no: vatNumber,
            data_origin_form: from,
          }),
        }
      );

      let resJson = await res.json();

      if (res.status === 200) {
        setCompanyName("");
        setEmail("");
        setMobileNumber("");
        setMessage("Your data has been successfully transmitted");
      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <BackgroundDiv />
      <Container gutter="large">
        <Refund>
          <TitleContent>
            <h1>
              <span>TAX</span> Refund
            </h1>
            <p>
              Hassle-free VAT and Excise duty refunds with support in your
              language. Get fast tax refund solutions for all your invoices.
              Improve your cash flow in a simple and stress-free process.
              <EurowagLogo>
                <a href="https://www.eurowag.com/en" target="blank">
                  <img src={Eurowag} alt="Eurowag logo" />
                </a>
              </EurowagLogo>
            </p>
          </TitleContent>

          <div>
            <EurowagMobileLogo>
              <a href="https://www.eurowag.com/en" target="blank">
                <img src={Eurowag} alt="Eurowag logo" />
              </a>
            </EurowagMobileLogo>

            <ArrowDiv>
              <img src={StyledArrow} alt="arrowimage" />
              <img src={StyledArrow} alt="arrowimage" />
              <img src={StyledArrow} alt="arrowimage" />
              <img src={StyledArrow} alt="arrowimage" />
              <img src={StyledArrow} alt="arrowimage" />
            </ArrowDiv>
            <ListSection>
              <ul>
                <li>
                  <Bullet>• </Bullet>{" "}
                  <b>
                    {" "}
                    VAT refunds on fuel, tolls, repairs, accommodation and much
                    more.{" "}
                  </b>
                  <br /> All in an easy, fast and unbureaucratic way.
                </li>
                <li>
                  <Bullet>• </Bullet>
                  <b>
                    Excise duty refund = Save money on fuel abroad and optimize
                    your cash flow.
                  </b>{" "}
                  <br /> A fully automated process to maximize your company's
                  profitability.
                </li>
                <li>
                  <Bullet>• </Bullet>
                  <b>
                    Easy process = The Eurowag team does everything for you.
                  </b>{" "}
                  <br /> A team of more than 30 people who speak all languages
                  to ensure tax refunds in all European tax offices. No stress
                  or worry for you.
                </li>
                <li>
                  <Bullet>• </Bullet>
                  <b>
                    {" "}
                    Visibility and Tracking = Keep track of the status of your
                    applications in your client area.
                  </b>{" "}
                  <br /> Easily see the important dates and the status of each
                  application. <br /> If you require any further assistance, you
                  can contact our team of specialists directly.
                </li>
              </ul>
            </ListSection>
          </div>
        </Refund>
        <FormContainer
          style={{
            "@media (max-width: 768px)": {
              marginBottom: "6rem",
            },
            marginBottom: "10rem",
          }}
        >
          <FormWrapperInner>
            <form onSubmit={handleSubmit}>
              <InputContainer>
                <label htmlFor="country">Country: </label>
                <SelectContainer>
                  <Select
                    name="country"
                    options={countries}
                    styles={selectStyles}
                    required
                    onChange={(choice) => {
                      setCountry(choice.shorthand);
                    }}
                  />
                </SelectContainer>
              </InputContainer>
              <br />
              <InputContainer>
                <label htmlFor="company_name">Company Name: </label>
                <input
                  type="text"
                  value={companyName}
                  placeholder="Company Name"
                  required
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                />
              </InputContainer>
              <br />
              <InputContainer>
                <label htmlFor="company_registration_number">
                  Company registration number:{" "}
                </label>
                <input
                  type="text"
                  value={regNumber}
                  placeholder="Company Registration number"
                  required
                  onChange={(e) => setRegNumber(e.target.value)}
                />
              </InputContainer>
              <br />
              <InputContainer>
                <label htmlFor="company_vat_number">Company VAT number: </label>
                <input
                  type="text"
                  value={vatNumber}
                  required
                  placeholder="Company VAT number"
                  onChange={(e) => setVatNumber(e.target.value)}
                />
              </InputContainer>
              <br />
              <InputContainer>
                <label htmlFor="phone">Phone number: </label>
                <input
                  type="number"
                  min="10000"
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "Please enter a valid phone number"
                    )
                  }
                  value={mobileNumber}
                  required
                  placeholder="Phone number"
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </InputContainer>
              <br />
              <InputContainer>
                <label htmlFor="email">E-mail: </label>
                <input
                  type="email"
                  value={email}
                  required
                  placeholder="email@email.com"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputContainer>
              <ButtonDiv style={{ marginTop: "2rem" }}>
                <ReCAPTCHA
                  sitekey="6LenyGkiAAAAAHIokUL5B2k6qb3y4fGmPitExsFg"
                  size="invisible"
                  ref={reRef}
                />
              </ButtonDiv>
              <div className="message">{message ? <p>{message}</p> : null}</div>

              <ButtonDiv className="buttondiv" style={{ marginTop: "4rem" }}>
                <VATbutton type="submit">Register</VATbutton>
              </ButtonDiv>
            </form>
          </FormWrapperInner>
        </FormContainer>
      </Container>
      <ArrowDiv2>
        <img src={StyledArrow} alt="arrowimage" />
        <img src={StyledArrow} alt="arrowimage" />
      </ArrowDiv2>
    </>
  );
};

export default VatRefund;
