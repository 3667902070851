import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  ListWrapper,
  PageTitle,
  LinkWrapper,
  ListItems,
  ListHeaderItem,
} from "../../PageWithList/List/Styles";
import Loader from "../../../components/Dashboard/UI/Loader";
import ListHeader from "../../PageWithList/List/ListHeader";
import CreateButton from "../../Dashboard/UI/CreateButton";
import LoginGateway from "../../Modals/AuthGatewayModals/LoginGateway";
import SubscribeGateway from "../../Modals/AuthGatewayModals/SubscribeGateway";
import ProGateway from "../../Modals/AuthGatewayModals/ProGateway";
import VerificationNotice from "../../Modals/AuthGatewayModals/VerificationNotice";

import ActionButton from "../../PageWithList/Sheet/ActionButton";
import ActionButtons from "../../PageWithList/Sheet/ActionButtons";
import { NoticeWrapper } from "../../UI/SignUpNotice/styles";
import usersAdd from "../../../assets/icons/userAdd.svg";

const RoundtripListHeader = (props) => {
  const { USER, COMPANY } = props;
  const [isExpired, setIsExpired] = useState(false);
  const [isRegular, setIsRegular] = useState(false);
  const [loggedModalOpen, setLoggedModalOpen] = useState(false);
  const [expiredModalOpen, setExpiredModalOpen] = useState(false);
  const [proModalOpen, setProModalOpen] = useState(false);
  const [nonVerifiedModalOpen, setNonVerifiedModalOpen] = useState(false);

  let USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.activeSubscription
  );
  const SUBSCRIPTION_NAME = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.subscriptionName
  );
  const EXPIRATION_DATE = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.expirationDate
  );
  const SUBSCRIPTION_STATUS = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.status
  );
  const urlParams = new URLSearchParams(window.location.search);
  let code = urlParams.get("create");

  useEffect(() => {
    setIsExpired(true);
    if (SUBSCRIPTION_NAME === "Roundtrip Pro") {
      if (SUBSCRIPTION_STATUS === "Active") {
        if (moment(EXPIRATION_DATE).isSameOrAfter()) {
          setIsExpired(false);
        }
      }
    }
  }, [SUBSCRIPTION_NAME, EXPIRATION_DATE, SUBSCRIPTION_STATUS]);
  useEffect(() => {
    if (code && code === "true") {
      setProModalOpen(true);
    }
  }, [code]);

  useEffect(() => {
    if (
      (SUBSCRIPTION_NAME !== "undefined" &&
        SUBSCRIPTION_NAME !== "Roundtrip Pro") ||
      (typeof USER_PLAN !== "undefined" && !USER_PLAN)
    ) {
      setIsRegular(true);
    } else {
      setIsRegular(false);
    }
  }, [USER_PLAN, SUBSCRIPTION_NAME]);

  if (USER && !COMPANY) {
    return <Loader />;
  }

  return (
    <>
      <ListWrapper>
        {!USER && (
          <a href="https://transconnector.eu/sign-up">
            <NoticeWrapper>
              <img src={usersAdd} />
              <h3>Sign up for free and get unlimited access to Roundtrips</h3>
              <div className="action">
                <button
                  style={{
                    fontWeight: "700",
                    background: "#00b27e",
                    border: "2px solid #00b27e",
                    padding: "0.5rem 1.15rem",
                    borderRadius: "6px",
                    color: "#fff",
                  }}
                  onClick={() => {
                    window.location.href = "https://transconnector.eu/sign-up";
                  }}
                >
                  Sign up now
                </button>
              </div>
            </NoticeWrapper>
          </a>
        )}
        <PageTitle>
          <h1>All roundtrips</h1>
          <LinkWrapper>
            {USER && !isExpired && !isRegular && COMPANY?.verified ? (
              <CreateButton
                title="Create Roundtrip"
                to="/dashboard/roundtrip/create"
              />
            ) : USER && isRegular && COMPANY?.verified ? (
              <CreateButton
                title="Create Roundtrip"
                isButton
                onClick={() => setProModalOpen(true)}
              />
            ) : USER && isExpired && COMPANY?.verified ? (
              <CreateButton
                title="Create Roundtrip"
                isButton
                onClick={() => setExpiredModalOpen(true)}
              />
            ) : USER && !COMPANY?.verified ? (
              <CreateButton
                title="Create Roundtrip"
                isButton
                onClick={() => setNonVerifiedModalOpen(true)}
              />
            ) : (
              <CreateButton
                title="Create Roundtrip"
                isButton
                onClick={() => setLoggedModalOpen(true)}
              />
            )}
          </LinkWrapper>
        </PageTitle>
        <ListHeader>
          <ListHeaderItem flex="0.6">Roundtrip</ListHeaderItem>
          <ListHeaderItem flex="0.3">Km / per month</ListHeaderItem>
          <ListHeaderItem flex="0.4">Truck type</ListHeaderItem>
          <ListHeaderItem flex="0.2">Price</ListHeaderItem>
          <ListHeaderItem flex="0.3">Published/Edited</ListHeaderItem>
          <ListHeaderItem flex="0.3">Publisher</ListHeaderItem>
        </ListHeader>
        <ListItems>{props.children}</ListItems>
      </ListWrapper>
      {loggedModalOpen && (
        <LoginGateway onClose={() => setLoggedModalOpen(false)} />
      )}
      {expiredModalOpen && (
        <SubscribeGateway onClose={() => setExpiredModalOpen(false)} />
      )}
      {proModalOpen && <ProGateway onClose={() => setProModalOpen(false)} />}
      {nonVerifiedModalOpen && (
        <VerificationNotice onClose={() => setNonVerifiedModalOpen(false)} />
      )}
    </>
  );
};

export default RoundtripListHeader;
