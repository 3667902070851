import React from "react";
import Container from "../../../components/UI/Container";
import { DesktopTableContainer } from "../Styles";
import Checkmark from "../../../assets/icons/checkmarkicon.svg";
const DesktopTable = () => {
  return (
    <Container gutter="medium">
      <DesktopTableContainer>
        <table cellSpacing={0} cellPadding={0}>
          <thead>
            <tr>
              <td style={{ border: "none" }}></td>
              <th>ONCE card</th>
              <th>EASY card</th>
              <th>VECTOR card</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Designed for</th>
              <td>Transport Company (CRT)</td>
              <td>Transport Company (CRT)</td>
              <td>Freight Forwarders and their vendors</td>
            </tr>
            <tr>
              <th>Payment conditions</th>
              <td>Post paid</td>
              <td>Pre paid</td>
              <td>Post paid</td>
            </tr>
            <tr>
              <th>
                Invoice <br />
                (tax document)
              </th>
              <td>
                <img src={Checkmark} alt="Checkmark icon" />
              </td>
              <td>
                <img src={Checkmark} alt="Checkmark icon" />
              </td>
              <td>
                <img src={Checkmark} alt="Checkmark icon" />
              </td>
            </tr>
            <tr>
              <th>Fuel station coverage</th>
              <td>15 000+ filling stations Across Europe</td>
              <td>15 000+ filling stations Across Europe</td>
              <td>15 000+ filling stations Across Europe</td>
            </tr>
            <tr>
              <th>Toll Coverage</th>
              <td>26 countries</td>
              <td>26 countries</td>
              <td>26 countries</td>
            </tr>
            <tr>
              <th>VAT Refund</th>
              <td>
                <img src={Checkmark} alt="Checkmark icon" />
              </td>
              <td>
                <img src={Checkmark} alt="Checkmark icon" />
              </td>
              <td>
                <img src={Checkmark} alt="Checkmark icon" />
              </td>
            </tr>
            <tr>
              <th>Excise duty refund</th>
              <td>
                <img src={Checkmark} alt="Checkmark icon" />
              </td>
              <td>
                <img src={Checkmark} alt="Checkmark icon" />
              </td>
              <td>
                <img src={Checkmark} alt="Checkmark icon" />
              </td>
            </tr>
            <tr>
              <th>Net Invoicing</th>
              <td>
                <img src={Checkmark} alt="Checkmark icon" />
              </td>
              <td>
                <img src={Checkmark} alt="Checkmark icon" />
              </td>
              <td>
                <img src={Checkmark} alt="Checkmark icon" />
              </td>
            </tr>
          </tbody>
        </table>
      </DesktopTableContainer>
    </Container>
  );
};

export default DesktopTable;
