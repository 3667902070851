import styled, { css } from "styled-components";

export const TableWrapper = styled.div``;
export const TableHeaderWrapper = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: inline-flex;
    width: 100%;
    color: #686a7d;
    font-family: "Inter";
    font-weight: 500;
    padding: 1rem;
    font-size: 13px;
    border-bottom: 1px solid #e0e6ed;
  }
`;
export const TableHeaderItem = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
  ${(props) =>
    props.sortable &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;
export const TableContentWrapper = styled.div``;
export const TableRowWrapper = styled.div`
  padding: 1rem 0.5rem;
  position: relative;
  &:not(:last-child) {
    border-bottom: 1px solid #889dbd;
  }
  @media (min-width: 1200px) {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 1.5rem 1rem;
    &:not(:last-child) {
      border-bottom: none;
    }
  }
  ${(props) =>
    props.clickable &&
    css`
      &:hover {
        cursor: pointer;
        background: #f0f3f6;
      }
    `}
`;
export const TableRowItemInline = styled.div`
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  strong,
  em,
  span,
  a {
    margin: 0;
    text-overflow: ellipsis;
    max-width: calc(100% - 2rem);
    overflow: hidden;
    padding: 0;
  }
 
  p {
    padding: 0.5rem;
  }
  ${(props) =>
    props.alignItems &&
    css`
    padding: 0.5rem !important;
  }`
  }
  ${(props) =>
    props.transform &&
    css`
    svg {
      transform: translateY(8px);
    }
  }`

  }
`;
export const TableRowItemWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-bottom: 1.5rem;
  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }
  svg {
    width: 17.5px;
    height: 17.5px;
    margin-right: 0.5rem;
  }
  img {
    margin-right: 0.5rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  strong,
  em,
  span, a {
    margin: 0;
    width: 100%;
    color: #050720;
    font-family: "Inter";
    font-weight: 500;
    font-size: 15px;
    padding: 0;
  }
  span {
    font-size: 14px;
  }
  h2 {
    color: #686a7d;
    padding-bottom: 0.5rem;
    font-size: 13px;
  }
  strong {
    font-weight: 600;
  }
  @media (min-width: 1200px) {
    white-space: nowrap;
    max-width: calc(100% - 4rem);
    overflow: hidden;
    padding-bottom: 0;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    strong,
    em,
    span, a {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    h2 {
      display: none;
    }
  }
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
  ${(props) =>
    props.sameLine &&
    css`
      @media (max-width: 1200px) {
        display: inline-flex;
        width: 100%;
        align-items: flex-start;
        flex-wrap: nowrap;
        h2 {
          max-width: 150px;
          padding-left: calc(20px + 0.5rem);
        }
      }
      @media (max-width: 350px) {
        h2 {
          padding-left: 0;
        }
      }
    `}
    ${(props) =>
    props.onlyMobile &&
    css`
        @media (min-width: 1200px) {
          display: none;
        }
      `}
    ${(props) =>
    props.onlyDesktop &&
    css`
        @media (max-width: 1200px) {
          display: none;
        }
      `}
      ${(props) =>
    props.wrap &&
    css`
          white-space: normal;
          @media (min-width: 1200px) {
            white-space: normal;
          }
        `}
        ${(props) =>
    props.overflow &&
    css`
            overflow: visible;
            @media (min-width: 1200px) {
              overflow: visible;
            }
          `}
`;
export const TableRowItemName = styled.div`
  width: 100%;
  height: 100%;
  ${(props) =>
    props.color &&
    css`
      h4 {
        color: ${props.color};
      }
    `}
`;

export const HTML = styled.div`
${(props) =>
    props.padding &&
    css`
    p{
      padding: 0.5rem;
      margin-top: 2px;
    }`
  }
}`;

export const StatusWrapper = styled.div`
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`;

export const StatusBubble = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #b2b2b2;
  transition: 0.25s all ease;
  transform: translate(0);
`;

export const StatusInner = styled.div`
  position: relative;
  width: 80px;
  height: 25px;
  background: #bcbcbc;
  border-radius: 20px;
  span {
    width: max-content;
    position: absolute;
    top: 50%;
    font-size: 10px;
    text-transform: uppercase;
    transform: translate(27.5px, -50%);
    transition: 0.25s all ease;
    color: #fff;
  }
  ${(props) =>
    props.active &&
    css`
      background: #2bbf8d;
      span {
        transform: translate(8px, -50%);
        transition: 0.25s all ease;
      }
      ${StatusBubble} {
        transition: 0.25s all ease;
        transform: translateX(225%);
      }
    `}
  ${({ inReview }) =>
    inReview &&
    css`
      span {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      ${StatusBubble} {
        display: none;
      }
    `}
`;

export const OptionsIcon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s all ease;
  circle {
    transition: 0.25s all ease;
  }
`;
export const OptionsWrapper = styled.div`
  position: relative;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  user-select: none;
  margin-left: auto;
  svg {
    margin: 0;
  }
  @media (max-width: 1200px) {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
  &:hover {
    cursor: pointer;
    ${OptionsIcon} {
      background: #e7edf4;
      border-radius: 2px;
      transition: 0.25s all ease;
      circle {
        transition: 0.25s all ease;
        fill: #3f749c;
      }
    }
  }
  &:focus {
    outline: none;
  }
`;
export const OptionsDropdown = styled.div`
  position: absolute;
  right: 0;
  top: 35px;
  width: 125px;
  height: auto;
  border: 1px solid #eaeaea;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.16);
  background: #fff;
  z-index: 1;
  display: none;
  pointer-events: all;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      padding: 1rem;
      font-size: 14px;
      color: #000;
      &:hover {
        background: #fafafa;
      }
    }
    a {
      padding: 1rem;
      font-size: 14px;
      color: #000;
      text-decoration: none;
    }
  }
  ${(props) =>
    props.open &&
    css`
      display: block;
    `}
`;


export const DeclineWrapper = styled.div`
    width: 100%;
    padding: 0.5rem;
    h3 {
      text-align: left !important;
      padding: 0;
      margin-top: 20px !important;
    }
`