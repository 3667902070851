import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import SpotloadList from "./SpotloadList/SpotloadList";
import Filter from "./SpotloadFilter/Filter";
import SpotloadSheet from "./SpotloadSheet/SpotloadSheet";
import Modal from "../Modals/Modal";
import ModalHeader from "../Modals/Header";
import { withRouter } from "react-router-dom";
import Container from "../UI/Container";
import DashboardFooter from "../Dashboard/DashboardFooter";
import ListContainer from "../UI/ListContainer";
import SpotloadUploader from "./SpotloadUpload/SpotloadUploader";
import VerificationNotice from "../Modals/AuthGatewayModals/VerificationNotice";
import Loader from "../Dashboard/UI/Loader";
import ErrorClosure from "../Dashboard/UI/ErrorClosure";
import { getCompany } from "../../store/actions/dashboard/company";

const Spotload = (props) => {
  const dispatch = useDispatch();
  const { loading, error, authFailed } = useSelector(
    (state) => state.dashboard.company
  );

  const [filters, setFilters] = useState({});
  const [filterFixed, setFilterFixed] = useState(false);
  const [sheetID, setSheetID] = useState(
    props.location?.state?.sheet || props.match?.params?.id || null
  );
  const [allSpotloads, setAllSpotloads] = useState([]);
  const [showRouteModal, setShowRouteModal] = useState(false);
  const [showVerificationNotice, setShowVerificationNotice] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const USER = useSelector((state) => state.dashboard.user?.data);
  const COMPANY = useSelector((state) => state.dashboard.company?.data);


  // If user has requested a spotload via route, if it doesn't exist, display a modal
  useEffect(() => {
    const routeSheet = props.location?.state?.sheet || props.match?.params?.id;
    if (routeSheet && allSpotloads?.length > 0) {
      if (!allSpotloads.find((e) => e._id === routeSheet)) {
        setShowRouteModal(true);
      }
    }
  }, [props.location, props.match, allSpotloads]);

  const handleSheetChange = useCallback((id) => {
    if (!error) {
      // if user is not logged in, allow to view the sheet
      if (!USER && !COMPANY) {
        setSheetID(id);
      }
      // if user is logged in, check if the company is verified
      else if (USER && COMPANY) {
        if (COMPANY.verified) {
          setSheetID(id);
        } else {
          setShowVerificationNotice(true);
        }
      }
    }
  }, []);

  const handleModalClose = () => {
    setShowRouteModal(false);
    handleSheetChange(null);
  };

  useEffect(() => {
    if (window.innerWidth >= 992) {
      const scrollEvt = document.addEventListener("scroll", function () {
        window.pageYOffset >= 134
          ? setFilterFixed(true)
          : setFilterFixed(false);
      });
      return () => document.removeEventListener("scroll", scrollEvt);
    }
  }, []);

  return (
      <>
        <Filter fixed={filterFixed} filters={filters} setFilters={setFilters} />
        <ListContainer offset={USER ? 1 : null}>
          <SpotloadList
            filters={filters}
            sheetID={sheetID}
            setSheetID={handleSheetChange}
            setAllSpotloads={setAllSpotloads}
            clearFilters={() => setFilters({})}
            USER={USER}
            COMPANY={COMPANY}
            setShowUploadModal={setShowUploadModal}
          />
          {allSpotloads?.length > 0 && <DashboardFooter />}
        </ListContainer>
        {allSpotloads && sheetID && (
          <SpotloadSheet
            spotloads={allSpotloads}
            id={sheetID}
            setSheetID={handleSheetChange}
            USER={USER}
          />
        )}
        {showRouteModal && (
          <Modal onClose={handleModalClose} small>
            <ModalHeader
              title="Spot freight not found"
              onClose={handleModalClose}
            />
            <h4>The spot freight you requested was not found.</h4>
            <h5>Here are some reasons to why:</h5>
            <ul>
              <li>
                The spot freight you requested was removed or is currently
                inactive.
              </li>
              <li>The link you accessed was broken or missing.</li>
            </ul>
          </Modal>
        )}
        {showUploadModal && (
          <SpotloadUploader
            USER={USER}
            onClose={() => setShowUploadModal(false)}
            // setSheetID={handleSheetChange}
          />
        )}
        {showVerificationNotice && (
          <VerificationNotice
            onClose={() => setShowVerificationNotice(false)}
            onConfirm={() => setShowVerificationNotice(false)}
          />
        )}
      </>
  );
};

export default withRouter(Spotload);
