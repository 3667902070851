import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RoundtripListHeader from './RoundtripListHeader';
import RoundtripListItem from './RoundtripListItem';
import Loader from '../../Dashboard/UI/Loader';
import UserNotice from '../../UserNotice/UserNotice';
import welcomeNoResults from '../../../assets/welcome/noResults.svg';
import { filterTruckTypes } from '../../../helpers/ListFiltering';
import { getFilterRoundtrip } from '../../../store/actions/app/roundtrip';
import ReviewModal from '../../Modals/ReviewModal/ReviewModal';
import AddReview from '../../Modals/ReviewModal/AddReview';
import { TextButton } from "../../Buttons/NewButton/Styles";

const RoundtripListWrapper = React.memo((props) => {
  const { USER, COMPANY } = props;
  const [roundtrips, setRoundtrips] = useState([]);
  const [hasSetAllRoundtrips, setHasSetAllRoundtrips] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [reviewModal, setReviewModal] = useState(null);
  const [addReviewModal, setAddReviewModal] = useState(null);
  const [loginGateway, setLoginGateway] = useState(false);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.activeSubscription
  );
  const SUBSCRIPTION_NAME = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.subscriptionName
  );


  useEffect(() => {
    if (!USER && props.id) setLoginGateway(true);
  }, [USER]);

  const dispatch = useDispatch();

  // Spotload list filtering
  const { truckType, country } = props.filters;
  const { setAllRoundtrips, filters } = props;

  const fetchRoundtrips = (newSkip = skip, reset = false) => {
      // Did cancel is used for canceling a request when the user requests another payload while the previous one is still loading
      let didCancel = false;
      const TruckTypes = filterTruckTypes(truckType);
      const CountryTypes = filterTruckTypes(country);
      dispatch(
        getFilterRoundtrip({
          truckType: TruckTypes,
          country: CountryTypes,
          limit: limit,
          skip: reset ? 0 : newSkip,
        })
      )
        .then((res) => {
          if (!didCancel) {
            const result = res.data.roundtrips;
            if (reset) {
              setRoundtrips(result);
            } else {
              setRoundtrips((prev) => [...prev, ...result]);
            }
            setHasMore(result.length === limit);
            const hasNoMore = result.length < limit;
            if (!hasSetAllRoundtrips && !hasNoMore) {
              setAllRoundtrips([...roundtrips, ...result]);
            }
            if (hasNoMore) {
              setAllRoundtrips([...roundtrips, ...result]);
              setHasSetAllRoundtrips(true);
            }
  
          }
        })
        .catch((err) => console.log(err));
      return () => (didCancel = true);
    };

  useEffect(() => {
    fetchRoundtrips(0, true);
    setSkip(0);
  }, [filters]);

  const loadMore = () => {
    const newSkip = skip + limit;
    setSkip(newSkip);
    fetchRoundtrips(newSkip);
  };

  // Set subscription barrier
  useEffect(() => {
    if (
      (typeof SUBSCRIPTION_NAME !== 'undefined' &&
        SUBSCRIPTION_NAME === 'Free trial') ||
      (typeof USER_PLAN !== 'undefined' && !USER_PLAN)
    )
      setIsExpired(true);
  }, [USER_PLAN, SUBSCRIPTION_NAME]);

  // Add Sheet ID to localstorage to display viewed spotloads
  useEffect(() => {
    if (props.sheetID && USER && !isExpired) {
      const viewedRoundtrips = localStorage.hasOwnProperty('viewedRoundtrips')
        ? JSON.parse(localStorage.getItem('viewedRoundtrips'))
        : [];
      if (!viewedRoundtrips.includes(props.sheetID)) {
        viewedRoundtrips.push(props.sheetID);
        localStorage.setItem(
          'viewedRoundtrips',
          JSON.stringify(viewedRoundtrips)
        );
      }
    }
  }, [props.sheetID, USER, isExpired]);


  return (
    <RoundtripListHeader USER={USER} COMPANY={COMPANY}>
      {roundtrips?.length > 0
        ? roundtrips?.map((roundtrip) => {
          return (
            <RoundtripListItem
              USER={USER}
              COMPANY={COMPANY}
              isExpired={loginGateway}
              key={roundtrip._id}
              roundtrip={roundtrip}
              sheetID={props.sheetID === roundtrip._id ? props.sheetID : null}
              setSheetID={props.setSheetID}
              setReviewModal={setReviewModal}
              viewed={
                localStorage.hasOwnProperty('viewedRoundtrips') &&
                localStorage
                  .getItem('viewedRoundtrips')
                  .includes(roundtrip._id)
              }
            />
          );
        })
        : roundtrips && <Loader />}
      {!roundtrips && (
        <UserNotice
          image={welcomeNoResults}
          title="Unfortunately no roundtrips were found. Try again with different filters or clear all filters with the button below."
          buttons={[
            {
              name: 'Clear filters',
              isButton: true,
              onClick: props.clearFilters,
            },
          ]}
        />
      )}
      {hasMore && (
        <div style={{ textAlign: "center", marginTop: "1rem", width: "100%" }}>
        <TextButton onClick={loadMore}>Load more</TextButton>
        </div>
      )}
      {reviewModal && (
        <ReviewModal
          showHeader
          fetchReviews
          id={reviewModal}
          onClose={() => setReviewModal(null)}
          onClick={() => {
            setAddReviewModal(reviewModal);
            setReviewModal(null);
          }}
        />
      )}
      {!reviewModal && addReviewModal && (
        <AddReview
          name={reviewModal?.company?.companyName}
          id={addReviewModal}
          onClose={() => setAddReviewModal(false)}
        />
      )}
    </RoundtripListHeader>
  );
});

export default RoundtripListWrapper;
