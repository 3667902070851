import styled, { css } from 'styled-components';
import { CTA } from '../CreateForm/Styles';

export const ModalInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  max-width: 1140px;
  background: #fff;
  overflow-y: auto;
  max-height: 90vh;
  pointer-events: all;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.16);
  padding: 1rem;
  z-index: 22;
  border-radius: 6px;
  h4,
  h5,
  li {
    font-family: 'Inter';
    margin: 0;
    padding: 0.5rem;
    font-size: 1rem;
  }
  h5 {
    font-weight: 500;
  }
  h3 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    line-height: 1.5;
  }
`;

export const ModalInnerWrapper = styled.div`
  @media (min-width: 992px) {
    padding: 1rem;
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 1rem;
  pointer-events: none;
  z-index: 9999;
  ${(props) =>
    props.customPadding &&
    css`
    ${ModalInner} {
      padding: ${props.customPadding};
    }
    `}
  ${(props) =>
    props.small &&
    css`
      ${ModalInner} {
        max-width: 600px;
      }
    `}
  ${(props) =>
    props.medium &&
    css`
      ${ModalInner} {
        max-width: 900px;
      }
    `}
  ${(props) =>
    props.fullscreenMobile &&
    css`
      @media (max-width: 1200px) {
        width: 100vw;
        height: 100vh;
        padding: 0;
        margin: 0;
        ${ModalInner} {
          height: 100%;
          max-width: 100vw;
          max-height: 100vh;
          border-radius: 0;
        }
      }
    `}
`;

export const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 1rem 0;
  border-bottom: 1px solid #dedede;
  margin-bottom: 1rem;
  h2 {
    font-size: 25px;
    color: #262a68;
    margin: 0;
    padding-right: 2.5rem;
  }
  @media (max-width: 374px) {
    h2 {
      font-size: 22px;
    }
  }
  @media (min-width: 992px) {
    padding: 1rem;
  }
  ${(props) =>
    props.noBorder &&
    css`
      border-bottom: none !important;
    `}
  ${(props) =>
    !props.hasTitle &&
    css`
      border-bottom: none;
    `}
`;
export const HeaderClose = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0.85rem;
  right: 0;
  width: 35px;
  height: 35px;
  &:hover {
    cursor: pointer;
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transition: 0.25s all ease;
    transform: translate(-50%, -50%) scale(0);
    width: 35px;
    height: 35px;
    border-radius: calc(35px / 2);
    transition: 0.25s all ease;
    background: #f4f4f4;
    z-index: -1;
  }
  &:hover {
    &:before {
      transition: 0.25s all ease;
      transform: translate(-50%, -50%) scale(1);
    }
  }
  @media (min-width: 992px) {
    right: 1rem;
  }
`;

export const HeaderInnerClose = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FooterWrapper = styled.div`
  display: flex;
  margin: 1.5rem auto;
  ${(props) =>
    props.centered &&
    css`
      width: 100%;
      justify-content: center;
    `}/*   ${({ fixed }) =>
    fixed === 'mobile' &&
    css`
      @media (max-width: 1200px) {
        position: fixed;
        bottom: 1rem;
        width: 84.5%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        ${CTA} {
          justify-content: center;
        }
      }
    `} */
`;

export const StickyWrapper = styled.div`
  position: relative;
  ${FooterWrapper} {
    position: sticky;
    bottom: -16px;
    left: 0;
    margin: 0;
    ${CTA} {
      position: sticky;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      margin: 0;
      background: #fff;
    }
  }
`;

export const MessageBoxWrapper = styled.div`
  .quill {
    min-width: 245px;
    max-width: 600px;
    .ql-container {
      min-height: 130px;
      max-height: 400px;
      overflow-y: auto;
      &::-webkit-scrollbar-track {
        background-color: #fbfbfc;
      }
      &::-webkit-scrollbar {
        background-color: #eaeaea;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c5c6d3;
        border: 4px solid transparent;
        border-radius: 9px;
        background-clip: content-box;
      }
      &.ql-snow {
        border: 1px solid #9aabc2;
        border-top: none;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
    .ql-toolbar.ql-snow {
      border: 1px solid #9aabc2;
      font-family: 'Inter';
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    .ql-tooltip {
      left: 0 !important;
      font-family: 'Inter';
    }
    .ql-editor {
      font-family: 'Inter';
      min-height: 130px;
      font-size: 16px;
      &:before {
        font-size: 14px;
      }
    }
    &.invalid {
      .ql-container {
        &.ql-snow {
          border: 1px solid #e13505;
        }
      }
      .ql-toolbar.ql-snow {
        border: 1px solid #e13505;
        border-bottom: none;
      }
    }
  }
`;

export const ModalClose = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  cursor: pointer;
`;

export const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;
