import React from "react";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";

import SubMenu from "../Menu/SubMenu";
import MyAccount from "./MyAccount";
import CompanyProfile from "./CompanyProfile";
import Documents from "./Documents";
import Notifications from "./Notifications";
import LeftDrawer from "../Drawers/LeftDrawer";
import Screen from "../Screen/Screen";
import Navbar from "../Navbar";
import { isBrowser } from "react-device-detect";
import Minimessages from "../Messages/Minimessages";
import DashboardFooter from "../DashboardFooter";
import VerificationDocuments from "./VerificationDocuments";

const MyAccountWrapper = (props) => {
  const { USER, COMPANY } = props;
  return (
    <Screen>
      <Navbar />
      <Router>
        <SubMenu>
          <NavLink activeClassName="active" exact to="/dashboard/my-account">
            My Account
          </NavLink>
          {USER?.privileges === "Admin" && (
            <>
              <NavLink
                activeClassName="active"
                to="/dashboard/my-account/company-profile"
              >
                Company Profile
              </NavLink>
              <NavLink
                activeClassName="active"
                to="/dashboard/my-account/documents"
              >
                Documents
              </NavLink>
              <NavLink
                activeClassName="active"
                to="/dashboard/my-account/verification"
              >
                Verification
              </NavLink>
            </>
          )}
          <NavLink
            activeClassName="active"
            to="/dashboard/my-account/notifications"
          >
            Notifications
          </NavLink>
        </SubMenu>
        <Route
          exact
          path="/dashboard/my-account/"
          render={() => <MyAccount USER={USER} />}
        />
        <Route
          path="/dashboard/my-account/notifications"
          render={() => <Notifications USER={USER} />}
        />
        {USER?.privileges === "Admin" && (
          <>
            <Route
              path="/dashboard/my-account/company-profile"
              render={() => <CompanyProfile COMPANY={COMPANY} />}
            />
            <Route
              path="/dashboard/my-account/documents"
              render={() => <Documents COMPANY={COMPANY} />}
            />
                        <Route
              path="/dashboard/my-account/verification"
              render={() => <VerificationDocuments USER={USER} COMPANY={COMPANY} />}
            />
          </>
        )}
      </Router>
      {isBrowser && <Minimessages />}
      <LeftDrawer />
      <DashboardFooter />
    </Screen>
  );
};

export default MyAccountWrapper;
