import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { Static, Background, CTA } from "./Styles";
import Container from "../../components/UI/Container";
import dripLanding from "../../../src/images/dripLanding.png";
import arvuti from "../../../src/images/arvuti.png";
import { API_URL } from "../../constants";


const Wrapper = styled.div`
// display: flex;
  p {
    color: #000;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    width: 100%
  }
  h1 {
    color: #040730;
    font-family: Inter;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  @media (min-width: 1200px) {
    h1 {
      color: #040730;
      font-family: Inter;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      width: max-content;
      max-width: 100%;
    }
  }
  h1.red {
    color: #e5004b;
  }
  h3 {
    color: #040730;
    font-family: Inter;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 100%;
  }
  a.more_info {
    color: #e5004b;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
    max-width: 100%;
    margin-top: 1rem;
  }
  ul {
    padding-inline-start: 25px;
  }
`;

// SectionOne is two columns, text on left, images on right
const SectionOne = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 4rem;
  
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 0;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: fit-content;
  @media (min-width: 1200px) {
    width: max-content;
  }
  }
  .images-desktop {
    display: none;
  }
  
  .images-mobile {
    display: flex;
    width: 100%;
    margin-block: 4rem;
    justify-content: center;
  }
  img.drip {
    width: 70%;
  }
  
  @media (min-width: 1200px) {
    .images-desktop {
      display: block;
      align-self: start;
      width: fit-content;
      max-width: 100%;
      transform: translate(66px, 66px);
    }
  
    .images-mobile {
      display: none;
    }
    img.drip {
      width: 100%;
      scale: 0.9;
      margin-bottom: 16rem;
    }
    img.arvuti {
      scale: 1.6;
      transform: translate(0px, -55px);
    }
  }
  }
`;

// SectionTwo is form centered on page
const SectionTwo = styled.div`
position: relative;
display: flex;
flex-direction: column;
align-items: center;
// background: linear-gradient(to top, #F4F8FF 50%, transparent 50%);
::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -10%);
width: 100vw;
  height: 100%;
  background: #F4F8FF;
  z-index: -1;
}
@media (max-width: 968px) {
h3 {
  font-size: 1rem;
}
}
`

const StyledForm = styled.form`
border-radius: 0.625rem;
background: #FFF;
box-shadow: 0px 4px 30px 0px rgba(66, 90, 126, 0.25);

display: flex;
flex-direction: column;
gap: 2rem;
border: 1px solid #ccc;
padding: 1.5rem;
width: -webkit-fill-available;
@media (min-width: 1200px) {
  width: 33.6rem;
  margin-top: 1rem;
}
`;

const FormGroup = styled.div`
  // margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
font-size: 1rem;
font-weight: 700;
`;

const Input = styled.input`
  width: 100%;
  padding: 1rem 0.625rem;
  font-size: 16px;
  border: 1px solid ${(props) => (props.hasError ? '#ff0000' : '#9DAAC0')};
  border-radius: 0.3125rem;
  `;

const ErrorMessage = styled.div`
  color: #ff0000;
  font-size: 12px;
  margin-top: 4px;
`;

const Button = styled.button`
  width: 100%;

  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  cursor: pointer;
  background: #4FAF82;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.25rem;
  transition: 0.25s all ease;
  &:hover {
    transition: 0.25s all ease;
    background: #5ECB98;
    border-color: #07ce94;
  }
`;

const Form = (props) => {
  const [formData, setFormData] = useState({
    country: '',
    company: '',
    regnum: '',
    vat: '',
    phone: '',
    email: '',
  });

  const [errors, setErrors] = useState({
    country: '',
    company: '',
    regnum: '',
    vat: '',
    phone: '',
    email: '',
  });

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    // Validate each field
    for (const field in formData) {
      if (formData[field].trim() === '') {
        newErrors[field] = 'This field is required';
        isValid = false;
      } else {
        newErrors[field] = '';
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      props.onSubmit(formData);
    } else {
      console.log('Form has errors. Please fix them.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };



  return (
    <StyledForm onSubmit={handleSubmit}>
      <FormGroup>
        <Label htmlFor="country">Country</Label>
        <Input
          type="text"
          id="country"
          name="country"
          value={formData.country}
          onChange={handleInputChange}
          hasError={errors.country !== ''}
        />
        <ErrorMessage>{errors.country}</ErrorMessage>
      </FormGroup>
  
      <FormGroup>
        <Label htmlFor="company">Company Name</Label>
        <Input
          type="text"
          id="company"
          name="company"
          value={formData.company}
          onChange={handleInputChange}
          hasError={errors.company !== ''}
        />
        <ErrorMessage>{errors.company}</ErrorMessage>
      </FormGroup>
  
      <FormGroup>
        <Label htmlFor="regnum">Company Registration Number</Label>
        <Input
          type="text"
          id="regnum"
          name="regnum"
          value={formData.regnum}
          onChange={handleInputChange}
          hasError={errors.regnum !== ''}
        />
        <ErrorMessage>{errors.regnum}</ErrorMessage>
      </FormGroup>
  
      <FormGroup>
        <Label htmlFor="vat">Company VAT Number</Label>
        <Input
          type="text"
          id="vat"
          name="vat"
          value={formData.vat}
          onChange={handleInputChange}
          hasError={errors.vat !== ''}
        />
        <ErrorMessage>{errors.vat}</ErrorMessage>
      </FormGroup>
  
      <FormGroup>
        <Label htmlFor="phone">Phone Number</Label>
        <Input
          type="text"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          hasError={errors.phone !== ''}
        />
        <ErrorMessage>{errors.phone}</ErrorMessage>
      </FormGroup>
  
      <FormGroup>
        <Label htmlFor="email">Email</Label>
        <Input
          type="text"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          hasError={errors.email !== ''}
        />
        <ErrorMessage>{errors.email}</ErrorMessage>
      </FormGroup>
  
      <Button type="submit">Register</Button>

      {props.children}
    </StyledForm>
  );
  
};


const DRIP = (props) => {

  const [success, setSuccess] = useState(false);
  const [unsuccessful, setUnsuccesful] = useState(false);

  const handleSubmit = (formData) => {
    // Send the form data to the server
    fetch(`${API_URL}/drip-registration`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setSuccess(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        setUnsuccesful(true);
      });
  };

  return (
    <Wrapper>
      <Static>
        <Container gutter="medium">
          <SectionOne>
            <div className="content">
              <div>
                <h1>Real Time Visibility with DRIP</h1>
                <div className="images-mobile">
                  <a href="https://sl.drip-log.com/tc-drip">
                  <img src={dripLanding} className="drip" />
                  </a>
                </div>
                <p>
                  DRIP simplifies your day-to-day business <br /> (only 1 portalfor all
                  telematics systems including sub-carriers) and <br /> allows you to
                  concentrate fully on efficient scheduling <br /> and reliable
                  handling of transport processes.
                </p>
                <p>
                  <b>Your advantage with DRIP:</b> up to 25% savings <br /> inpersonnel costs,
                  10% fewer empty kilometers and <br /> 30% lower costs for idle times
                  and penalties.
                </p>
                <p>
                  DRIP provides carriers with its numerous benefits <br /> <b>
                    and all
                    functions free of charge
                  </b> in the basic version.
                </p>
              </div>
              <div>
                <h1>Core competence:</h1>
                <h1 className="red">Real - Time Visibility</h1>
                <p>
                  <ul>
                    <li>Full Integration of all Telematic Systems</li>
                    <li>Real - Time Monitor for Trucks & Trailers</li>
                    <li>TMS-Integration with Drip Connect</li>
                    <li>Ad Hoc Onboarding of Carriers</li>
                    <li>Estimated Time of Arrival (ETA)</li>
                    <li>Project Freight Match</li>
                    <li>Project “ETA 2.0”</li>
                  </ul>
                </p>
                <a href="https://sl.drip-log.com/tc-rtv" className="more_info">
                  More about Real Time Visibility with DRIP {">"}
                </a>
                <div className="images-mobile">
                  <img src={arvuti} className="arvuti" />
                </div>
              </div>
              <div>
                <h1>Core competence:</h1>
                <h1 className="red">Mobile Communication</h1>
                <ul>
                  <li>Mobile Driver & Dispatcher App</li>
                  <li>Chat-Feature</li>
                  <li>Document Upload</li>
                  <li>Multi Message</li>
                  <li>Geo-based Messages</li>
                  <li>Integration of Customer Apps</li>
                  <li>Documentation of Claims</li>
                  <li>Language Translation Tool</li>
                  <li>Task Management with TMS</li>
                  <li>Mobile Equipment Check</li>
                  <li>Event Management</li>
                  <li>Form Builder</li>
                </ul>
                <a href="https://sl.drip-log.com/tc-mobilecommunication" className="more_info">
                  More about Real Time Visibility with DRIP {">"}
                </a>
              </div>
            </div>
            <div className="images-desktop">
              <a href="https://sl.drip-log.com/tc-drip">
              <img src={dripLanding} className="drip" />
              </a>
              <img src={arvuti} className="arvuti" />
            </div>
          </SectionOne>
          <SectionTwo>
            <h3>Asking for an offer is free of charge!</h3>
          <Form onSubmit={handleSubmit}>
          {success && <p>Thank you for your request. We will contact you soon.</p>}
          {unsuccessful && <p>Something went wrong. Please try again later.</p>}
          </Form>
          </SectionTwo>
        </Container>
      </Static>
    </Wrapper>
  );
};

export default DRIP;
