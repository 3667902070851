import React, { useState, useEffect } from "react";

import ScreenBlock from "../Screen/ScreenBlock";
import Loader from "../UI/Loader";
import Filter from "./Filter";
import TendersTable from "./TendersTable";

const Tenders = (props) => {
  const [filter, setFilter] = useState(0);
  const [active, setActive] = useState(0);
  const [inActive, setInactive] = useState(0);
  const [tenders, setTenders] = useState([]);

  const { OFFERS } = props;
  // Handle setting tender state
  useEffect(() => {
    if (OFFERS && OFFERS[0].tenders && OFFERS[0].tenders?.length > 0) {
      setTenders(OFFERS[0].tenders);
    }
  }, [OFFERS]);

  // Reset filter when there are no tenders to show
  useEffect(() => {
    if (tenders?.length <= 0 && filter > 0) {
      setFilter(0);
      setTenders(OFFERS[0].tenders);
    }
  }, [tenders, filter, OFFERS]);

  // Handle tender filtering
  useEffect(() => {
    if (filter === 0 && OFFERS) {
      setTenders(OFFERS[0].tenders);
    } else if (filter === 1) {
      setTenders(OFFERS[0].tenders.filter((e) => e.status));
    } else if (filter === 2) {
      setTenders(OFFERS[0].tenders.filter((e) => !e.status));
    }
  }, [filter, OFFERS]);

  // Get count of active and inactive tenders
  useEffect(() => {
    if (OFFERS && OFFERS[0].tenders) {
      setActive(0);
      setInactive(0);
      OFFERS[0].tenders.forEach((e) =>
        e.status
          ? setActive((prevCount) => prevCount + 1)
          : setInactive((prevCount) => prevCount + 1)
      );
    }
  }, [OFFERS]);

  // Display welcome mat when no tenders to show

  if (OFFERS && OFFERS[1].tenders && OFFERS[1].tenders?.length <= 0) {
    return null;
  }

  return (
    <ScreenBlock title="My Tenders">
      {tenders?.length > 0 && (
        <Filter
          all={OFFERS && OFFERS[0]?.tenders?.length}
          active={active}
          inActive={inActive}
          filter={filter}
          setFilter={setFilter}
          type="Tender"
          to="/dashboard/tender/create"
          isExpired={props.isExpired}
          setExpiredModal={props.setExpiredModal}
        />
      )}
      {tenders?.length > 0 ? (
        <TendersTable
          tenders={tenders}
          handleStatusChange={props.handleStatusChange}
          handleOfferRemove={props.handleOfferRemove}
        />
      ) : (
        <Loader />
      )}
    </ScreenBlock>
  );
};

export default Tenders;
