import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SET_OFFERS } from '../../store/actionTypes';
import { FormatSpotloadEditData } from '../../helpers/FormatSpotloadEditData';

import Form from '../CreateForm/Form';
import FormBlock from '../CreateForm/FormBlock';
import SpotloadCreatorStepOne from './SpotloadCreatorStepOne';
import SpotloadCreatorStepTwo from './SpotloadCreatorStepTwo';
import LargeButton from '../CreateForm/LargeButton';
import { ButtonWrapper } from '../CreateForm/Styles';
import Loader from '../Dashboard/UI/Loader';
import {
  getSingleSpotload,
  createSpotload,
  validateSpotload,
  validateEditSpotloadCSVRow,
} from '../../store/actions/app/spotload';
import Screen from '../Dashboard/Screen/Screen';
import LeftDrawer from '../Dashboard/Drawers/LeftDrawer';
import Navbar from '../Dashboard/Navbar';
import Minimessages from '../Dashboard/Messages/Minimessages';
import { isBrowser } from 'react-device-detect';

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

const SpotloadForm = (props) => {
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState(props.showAllBlocks ? 0 : 1);
  const USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.status
  );
  const dispatch = useDispatch();

  // Set subscription barrier
  /*   useEffect(() => {
    if (USER_PLAN && (USER_PLAN === 'Expired' || USER_PLAN === 'Cancelled'))
      props.history.push('/spotload');
  }, [USER_PLAN, props.history]); */

  // Get data when user requests edit page
  const { editMode, duplicateMode } = props;
  const { id: spotloadId } = props.match?.params || {};
  useEffect(() => {
    if ((editMode || duplicateMode) && spotloadId && !props.fromUploader) {
      dispatch(getSingleSpotload(spotloadId))
        .then((res) => {
          setInputs(FormatSpotloadEditData(res.data));
          setLoaded(true);
        })
        .catch(() => props.history.push('/spotload'));
        // if editMode && props.fromUploader && props.imputs,
        // we come from CSV row edit at CSV upload modal
      } else if (editMode && props.fromUploader && props.inputs) {
        setInputs(FormatSpotloadEditData(props.inputs));
        setLoaded(true);
    } else {
      setLoaded(true);
    }
  }, [editMode, duplicateMode, spotloadId, props.history, props.fromUploader, props.inputs]);

  const handleSubmit = (e) => {
    if (!props.onEdit) 
{    e.preventDefault();
    setSubmitting(true);
    dispatch(
      createSpotload(!editMode ? 'POST' : 'PUT', {
        ...inputs,
        id: editMode ? spotloadId : null,
      })
    )
      .then((res) => {
        dispatch({ type: SET_OFFERS, payload: res.data });
        props.history.push('/dashboard/my-offers/spotloads');
      })
      .catch((err) => {
        const errors = err.response.data.errors;
        setErrors(errors);
        setSubmitting(false);
        scrollToTop();
      });}
      else {
        e.preventDefault();
        dispatch(validateEditSpotloadCSVRow(inputs))
        .then(() => {
          props.onClose();
        props.onEdit(inputs);
        })
        .catch((err) => {
          const errors = err.response?.data?.errors;
          setErrors(errors);
          scrollToTop();
        });
      }
  };

  const handleInputChange = (value, type, descendant) => {
    if (!descendant) {
      setInputs((prevState) => ({ ...prevState, [type]: value }));
    } else {
      setInputs((prevState) => ({
        ...prevState,
        [descendant]: { ...prevState[descendant], [type]: value },
      }));
    }
    // Clear error of interacted input
    setErrors((prevState) => ({ ...prevState, [type]: null }));
  };

  const handleSelectChange = (value, type) => {
    setInputs({ ...inputs, [type]: value });
    // Clear error of interacted input
    if (errors?.[type])
      setErrors((prevState) => ({ ...prevState, [type]: null }));
  };

  const handleInputDelete = (type) => {
    const copy = inputs;
    Object.keys(copy).find((obj) => {
      if (obj === type) {
        delete copy[type];
        return true;
      } else {
        return false;
      }
    });
    setInputs(copy);
  };

  const changeStep = (step) => setCurrentStep(step);

  const handleNextClick = () => {
    dispatch(validateSpotload(inputs))
      .then(() => {
        changeStep(2);
        scrollToTop();
      })
      .catch((err) => {
        const errors = err.response.data.errors;
        setErrors(errors);
        scrollToTop();
      });
  };

  if (!loaded) return <Loader />;
  if (editMode && !inputs) return <Loader />;

  return (
    <Form
      title={!props.editMode ? "Add load" : "Edit load"}
      icon={icon}
      onSubmit={handleSubmit}
    >
      <FormBlock
        title="1. Route"
        step={!props.showAllBlocks ? 1 : 0}
        currentStep={currentStep}
      >
        <SpotloadCreatorStepOne
          handleInputDelete={handleInputDelete}
          onSelectChange={handleSelectChange}
          onInputChange={handleInputChange}
          inputs={inputs}
          errors={errors}
        />
        {currentStep !== 0 && (
          <ButtonWrapper>
            {props.editMode && props.fromUploader && (
              <LargeButton
                secondary
                label="Back"
                onClick={() => {
                  props.onClose();
                }}
              />
            )}
            <LargeButton label="Next" onClick={() => handleNextClick()} />
          </ButtonWrapper>
        )}
      </FormBlock>
      <FormBlock
        title="2. Freight"
        step={!props.showAllBlocks ? 2 : 0}
        currentStep={currentStep}
      >
        <SpotloadCreatorStepTwo
          onSelectChange={handleSelectChange}
          onInputChange={handleInputChange}
          inputs={inputs}
          errors={errors}
        />
        {!submitting ? (
          <ButtonWrapper>
            {!props.showAllBlocks && (
              <LargeButton
                secondary
                label="Back"
                onClick={() => {
                  changeStep(1);
                  scrollToTop();
                }}
              />
            )}
            {props.showAllBlocks && props.editMode && props.fromUploader && (
              <LargeButton
                secondary
                label="Back"
                onClick={() => {
                  props.onClose();
                }}
              />
            )}
            <LargeButton
              type="submit"
              label={!props.editMode ? "Publish" : "Edit"}
            />
          </ButtonWrapper>
        ) : (
          <Loader />
        )}
      </FormBlock>
    </Form>
  );
}

const SpotloadCreator = (props) => {

  return (
  !props.fromUploader ? (
  <Screen centered>
      <Navbar />
      <SpotloadForm {...props} />
      {isBrowser && <Minimessages />}
      <LeftDrawer small />
    </Screen>)
  : (
    <SpotloadForm {...props} />
  )
  );
};


const icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32.014"
    height="23.061"
    viewBox="0 0 32.014 23.061"
  >
    <g transform="translate(-1.306 -4.718)">
      <path
        d="M20.895,7.61H2.752a1.446,1.446,0,1,1,0-2.892H20.895a1.446,1.446,0,1,1,0,2.892Z"
        transform="translate(0 0)"
        fill="#e13505"
      />
      <path
        d="M20.895,11.582H2.752a1.446,1.446,0,1,1,0-2.892H20.895a1.446,1.446,0,1,1,0,2.892Z"
        transform="translate(0 2.772)"
        fill="#e13505"
      />
      <path
        d="M14.467,15.554H2.752a1.446,1.446,0,1,1,0-2.892H14.467a1.446,1.446,0,1,1,0,2.892Z"
        transform="translate(0 5.544)"
        fill="#e13505"
      />
      <path
        d="M26.69,15.915H14.372a1.446,1.446,0,1,1,0-2.892H26.69a1.446,1.446,0,1,1,0,2.892Z"
        transform="translate(5.184 5.705)"
        fill="#e13505"
      />
      <path
        d="M18.632,23.974a1.445,1.445,0,0,1-1.446-1.446V10.21a1.446,1.446,0,0,1,2.892,0V22.528A1.446,1.446,0,0,1,18.632,23.974Z"
        transform="translate(7.084 3.805)"
        fill="#e13505"
      />
    </g>
  </svg>
);

export default withRouter(SpotloadCreator);
