import React, { useEffect, useState, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addFavourite,
  removeFavourite,
} from '../../../store/actions/dashboard/favourites';

import Sheet from '../../PageWithList/Sheet/Sheet';
import TenderSheetHeader from './TenderSheetHeader';
import SheetInner from './SheetInner';
import { SheetClose } from '../../PageWithList/Sheet/Styles';
import LoginGateway from '../../Modals/AuthGatewayModals/LoginGateway';
import SubscribeGateway from '../../Modals/AuthGatewayModals/SubscribeGateway';
import VerificationNotice from "../../Modals/AuthGatewayModals/VerificationNotice";


import { sendUserMessage } from '../../../store/actions/misc';

const reducer = (state, action) => {
  if (action.type === 'setTender') {
    return action.payload;
  } else {
    return state;
  }
};

const TenderSheet = (props) => {
  const { USER, setSheetID, COMPANY } = props;
  const [tender, dispatch] = useReducer(reducer, props.tender || null);
  const [isFavourited, setIsFavourited] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [loginGateway, setLoginGateway] = useState(false);
  const [expiredGateway, setExpiredGateway] = useState(false);
  const [nonVerifiedStatus, setNonVerifiedStatus] = useState(false);
  const USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.status
  );
  const reduxDispatch = useDispatch();

  // Set subscription gateway when user is expired or cancelled
  useEffect(() => {
    if (USER_PLAN && (USER_PLAN === 'Expired' || USER_PLAN === 'Cancelled')) {
      setIsExpired(true);
    } else {
      setIsExpired(false);
    }
  }, [USER_PLAN]);

  // Set login and subscription gateway modals
  useEffect(() => {
    if (!USER && props.id) setLoginGateway(true);
    if (USER && !COMPANY?.verified && props.id) setNonVerifiedStatus(true);
    if (USER && isExpired && props.id) setExpiredGateway(true);
  }, [props.id, loginGateway, expiredGateway, nonVerifiedStatus, isExpired, USER, COMPANY]);

  // Get the requested tender
  useEffect(() => {
    if (props.tenders) {
      dispatch({
        type: 'setTender',
        payload: props.tenders.find((tender) => tender._id === props.id),
      });
    }
    return () => dispatch({ type: 'setTender', payload: null });
  }, [dispatch, props.tenders, props.tender, props.id]);

  // Check if the requested tender is in favourites
  useEffect(() => {
    if (USER?.favourites) {
      USER.favourites.some((favourite) =>
        favourite.favouriteId === props.id
          ? setIsFavourited(true)
          : setIsFavourited(false)
      );
    }
  }, [USER, props.id]);

  // Handle adding to favourites
  const addToFavourites = () => {
    reduxDispatch(addFavourite(props.id, tender.name, 'Tender'));
  };

  // Handle removing from favourites
  const removeFromFavourites = () => {
    reduxDispatch(removeFavourite(props.id));
  };

  const handleMessageSubmit = (value) => {
    if (value?.length > 0) {
      reduxDispatch(
        sendUserMessage(
          tender.author._id,
          tender.name,
          value,
          'Tender',
          tender._id
        )
      )
        .then((res) => setMessageSent(true))
        .catch((err) => console.log('err', err));
    }
  };
  /*
    if (loginGateway)
      return (
        <LoginGateway
          onClose={() => {
            setSheetID(null);
            setLoginGateway(false);
          }}
          offerGateway
        />
      );
  
    if (expiredGateway)
      return (
        <SubscribeGateway
          onClose={() => {
            setSheetID(null);
            setExpiredGateway(false);
          }}
        />
      );
      */
  if (/*USER && !isExpired &&*/ tender) {
    return (
      <>
        <Sheet size={props.size} denyActions={props.denyActions}>
          <TenderSheetHeader
            setSheetID={(id) => props.setSheetID(id)}
            company={tender.company}
            isFavourited={isFavourited}
            addToFavourites={addToFavourites}
            removeFromFavourites={removeFromFavourites}
            denyActions={props.denyActions}
            size={props.size}
            loginGateway={loginGateway}
            nonVerifiedStatus={nonVerifiedStatus}
          />
          {tender && (
            <SheetInner
              handleMessageSubmit={handleMessageSubmit}
              messageSent={messageSent}
              setMessageSent={setMessageSent}
              tender={tender}
              denyActions={props.denyActions}
              loginGateway={loginGateway}
              nonVerifiedStatus={nonVerifiedStatus}
            />
          )}
        </Sheet>
        {!props.denyActions && (
          <SheetClose onClick={() => props.setSheetID(null)} />
        )}
      </>
    );
  } else {
    return null;
  }
};

export default TenderSheet;
