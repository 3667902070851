import React, { useEffect } from "react";
import Select from "react-select";
import Input from "../Register/RegisterInput";
import euCountries from "../../data/EUcountries.json";
import countries from "../../data/countries.json";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import axios from "axios";
import { isMobile } from "react-device-detect";
import { API_URL } from "../../constants";

const RegisterStepTwo = (props) => {
  const COMPANY = useSelector((state) => state.dashboard.company?.data);
  const { debtorVatNumber } = props.inputs;
  useEffect(() => {
    if (debtorVatNumber && debtorVatNumber?.length >= 2) {
      if (debtorVatNumber.substr(0, 2).match(/[0-9]/i)) {
        props.setErrors((prevState) => ({
          ...prevState,
          debtorVatNumber: {
            msg: "Enter a valid VAT number",
          },
        }));
      } else {
        props.setErrors((prevState) => ({
          ...prevState,
          debtorVatNumber: null,
        }));
      }
      let countryCode = debtorVatNumber.substr(0, 2).toUpperCase();
      if (debtorVatNumber?.length > 7) {
        for (var i = 0; i < europeanCountries?.length; i++) {
          if (europeanCountries[i].code === countryCode) {
            //Wait until VAT code valid minimum lenght then run api
            if (debtorVatNumber?.length > 9) {
              axios({
                method: "GET",
                url: `${API_URL}/vatCheck`,
                params: {
                  vatid: debtorVatNumber.toString().replace(/\s/g, ""),
                },
              }).then((res) => {
                if (res.data.validation.valid) {
                  if (res.data.validation.company_name !== "---") {
                    props.setInputs((prevState) => ({
                      ...prevState,
                      debtorCompanyName: res.data.validation.company_name,
                    }));
                    props.setErrors((prevState) => ({
                      ...prevState,
                      debtorVatNumber: null,
                    }));
                  }
                  for (var i = 0; i < europeanCountries?.length; i++) {
                    if (europeanCountries[i].code === res.data.country_code) {
                      const newCountry = europeanCountries[i].country;
                      props.setInputs((prevState) => ({
                        ...prevState,
                        debtorCompanyName:
                          res.data.validation.company_name !== "---"
                            ? res.data.validation.company_name
                            : prevState.debtorCompanyName,
                        country: newCountry,
                      }));
                    }
                  }
                } else {
                  props.setErrors((prevState) => ({
                    ...prevState,
                    debtorVatNumber: {
                      msg: "Enter a valid VAT number",
                    },
                  }));
                }
              });
            }
          }
        }
      }
    }
  }, [debtorVatNumber]);

  // handle select changes

  const handleSelectChange = (e, name) => {
    props.setInputs({ ...props.inputs, [name]: e.value });
    props.setErrors({ ...props.errors, [name]: null });
  };

  return (
    <div
      className={`Registration__part--PartSecond ${
        props.pagination === 2 && "active"
      }`}
    >
      <h3>Step 2. Company details</h3>
      {isMobile && <p>Fill form with your company info</p>}
      <Input
        classes={`floating-label ${props.errors?.debtorCompanyName && "invalid"}`}
        name="debtorCompanyName"
        type="text"
        value={props.inputs?.debtorCompanyName || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Company Name"
        error={props.errors?.debtorCompanyName && props.errors?.debtorCompanyName?.msg}
      />
      <Input
        classes={`floating-label ${props.errors?.debtorVatNumber && "invalid"}`}
        name="debtorVatNumber"
        type="text"
        value={props.inputs?.debtorVatNumber || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="VAT number (for example: DE123456789)"
        error={props.errors?.debtorVatNumber && props.errors?.debtorVatNumber?.msg}
      />
      <Input
        classes={`floating-label ${props.errors?.debtorAddress && "invalid"}`}
        name="debtorAddress"
        type="text"
        value={props.inputs?.debtorAddress || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Address"
        error={props.errors?.debtorAddress && props.errors.debtorAddress.msg}
      />
            <Input
        classes={`floating-label ${props.errors?.debtorPostalCode && "invalid"}`}
        name="debtorPostalCode"
        type="text"
        value={props.inputs?.debtorPostalCode || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Postal Code"
        error={props.errors?.debtorPostalCode && props.errors.debtorPostalCode.msg}
      />
        <div>
          <Input
            classes={`floating-label ${props.errors?.debtorCity && "invalid"}`}
            name="debtorCity"
            type="text"
            value={props.inputs?.debtorCity || ""}
            onChange={(e) => props.handleInputChange(e)}
            label="City"
            error={props.errors?.debtorCity && props.errors.debtorCity.msg}
          />
                <div className="dropdown-wrapper">
          <Select
            classNamePrefix="react-select"
            className={`dropdown ${props?.errors?.debtorCountry && "invalid"}`}
            placeholder="Country"
            options={countrySelect}
            name="debtorCountry"
            value={
              props.inputs?.debtorCountry && {
                value: props.inputs?.debtorCountry,
                label: props.inputs?.debtorCountry,
              }
            }
            onChange={(e) => handleSelectChange(e, "debtorCountry")}
          />
          {props.errors?.country ? (
            <div className="error-message">{props.errors?.debtorCountry?.msg}</div>
          ) : null}
                </div>
        </div>
        <Input
        classes={`floating-label ${props.errors?.debtorContactPerson && "invalid"}`}
        name="debtorContactPerson"
        type="text"
        value={props.inputs?.debtorContactPerson || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Contact Person"
        error={props.errors?.debtorContactPerson && props.errors?.debtorContactPerson?.msg}
      />
      <Input
        classes={`floating-label ${props.errors?.debtorContactPhone && "invalid"}`}
        name="debtorContactPhone"
        type="text"
        value={props.inputs?.debtorContactPhone || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Phone number"
        error={props.errors?.debtorContactPhone && props.errors?.debtorContactPhone?.msg}
      />
      <Input
        classes={`floating-label ${props.errors?.debtorContactEmail && "invalid"}`}
        name="debtorContactEmail"
        type="text"
        value={props.inputs?.debtorContactEmail || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="E-mail address"
        error={props.errors?.debtorContactEmail && props.errors?.debtorContactEmail?.msg}
      />
      <div className="formSubmit">
        <button
          type="button"
          className="next-step back"
          onClick={() => props.setPagination(1)}
        >
          Back
        </button>
        <div className="Submit__Wrapper">
          <button
            type="button"
            className="next-step"
            onClick={() => props.setPagination(3)}
          >
            Next step &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

const companyTypes = [
  { value: "Haulier", label: "Haulier" },
  { value: "Forwarder", label: "Forwarder" },
  { value: "Producer", label: "Producer" },
];
const employeeSelect = [
  { value: "1-10", label: "1-10" },
  { value: "10-25", label: "10-25" },
  { value: "25-50", label: "25-50" },
  { value: "50-100", label: "50-100" },
  { value: "100+", label: "100+" },
];

const europeanCountries = JSON.parse(JSON.stringify(euCountries.counties));
const allCountries = JSON.parse(JSON.stringify(countries.counties));
// Add Countries to country select box
const countrySelect = [];
Object.keys(allCountries).forEach(function (key) {
  countrySelect.push({
    value: allCountries[key].country.toString(),
    label: allCountries[key].country.toString(),
  });
});

export default RegisterStepTwo;
