import {SET_COMPANY_VERIFICATION_DOCUMENTS} from "../../actionTypes";
import axios from "axios";
import { API_URL } from "../../../constants";
import { getToken } from "../../../helpers/getToken";

export const getCompanyVerificationDocuments = () => {
  const token = getToken();
  return (dispatch) => {
    axios
      .get(`${API_URL}/companyVerificationDocuments`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const { authFailed } = res.data;
        if (authFailed) return;
        dispatch({
          type: SET_COMPANY_VERIFICATION_DOCUMENTS,
          payload: res.data,
        });
      })
      .catch((err) => {
        //dispatch(userFetchFailure());
      });
  };
};

export const uploadCompanyVerificationDocuments = (data, documentType, fileName) => {
  const token = getToken();
  return (dispatch) => {
    axios
      // .post(`${API_URL}/companyVerificationDocuments`, data, {
      .post(`${API_URL}/companyVerificationDocuments?documentType=${documentType}&fileName=${fileName}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      })
      .then((res) => {
        const { authFailed } = res.data;
        if (authFailed) return;
        dispatch(getCompanyVerificationDocuments());
      })
      .catch((err) => {
        //dispatch(userFetchFailure());
      });
  };
};