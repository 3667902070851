import React from "react";
import axios from "axios";
import Container from "../../components/UI/Container";
import allCountries from "../../data/allCountries.json";
import Select from "react-select";
import {
  VATbutton,
  ButtonDiv,
  EurowagPage,
  TitleContent,
  TitleBlock,
  EurowagLogo,
  ListContainer,
  CardContainer,
  EurowagMobileLogo,
  MobileCardContainer,
  ArrowContainer,
  InputContainer,
  Img,
  FormContainer,
  FormWrapperInner,
  SelectContainer,
  selectStyles,
} from "./Styles";
import Eurowaglogo from "../../assets/icons/Eurowag_logo.svg";
import StyledArrow from "../../assets/icons/StyleArrow.svg";
import CardImage from "../../assets/icons/CardImage.svg";
import DesktopTable from "./Components/EurowagDesktopTable";
import MobileTable from "./Components/EurowagMobileTable";
import { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const countries = allCountries.countries;

const Eurowag = () => {
  const [country, setCountry] = useState("");
  const [from] = useState("Eurowag Fuel page");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [regNumber, setRegNumber] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [message, setMessage] = useState("");

  //captcha stuff
  const reRef = useRef(<ReCAPTCHA />);

  let handleSubmit = async (e) => {
    e.preventDefault();
    const token = await reRef.current.executeAsync();
    reRef.current.reset();
    axios
      .post(
        "https://api.transconnector.eu/fuelFormData",
        {
          data: {
            country: country,
            company_name: companyName,
            email: email,
            mobile_number: mobileNumber,
            company_registration_no: regNumber,
            company_vat_no: vatNumber,
            data_origin_form: from,
            token,
          },
        },
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .then((res) => {
        if (!res) {
        } else {
          // console.log(res, "this is the res");
        }
      })
      .catch((err) => console.log(err));

    try {
      let res = await fetch(
        "https://hooks.zapier.com/hooks/catch/2612367/bqy7c9x/",
        {
          method: "POST",
          body: JSON.stringify({
            country: country,
            company_name: companyName,
            email: email,
            mobile_number: mobileNumber,
            company_registration_no: regNumber,
            company_vat_no: vatNumber,
            data_origin_form: from,
          }),
        }
      );

      let resJson = await res.json();
      if (res.status === 200) {
        setCompanyName("");
        setEmail("");
        setMobileNumber("");
        setMessage("Your data has been successfully transmitted");
      } else {
        setMessage("Some error occured, please try again later");
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const err = () => {
    alert("please select country before submitting");
  };

  const phone = document.getElementsByName("phone");
  return (
    <EurowagPage>
      <Container gutter="large">
        <TitleContent>
          <h1>
            <span>Eurowag </span>Fuel Cards
          </h1>
          <TitleBlock>
            <p style={{ marginBottom: "1rem" }}>
              Fuel cards are an ideal way to control the costs associated with
              refuelling. They allow you to easily pay for fuel and use other
              services without cash. Both large and smaller fleets, even those
              with only one vehicle, can use the fuel cards. Whether you have
              trucks or vans in your fleet, Eurowag fuel cards are suitable for
              everyone.
            </p>
            <ArrowContainer
              style={{
                height: "70px",
                width: "130px",
                left: "100px",
                position: "static",
              }}
            >
              <img src={StyledArrow} alt="" />
              <img src={StyledArrow} alt="" />
            </ArrowContainer>
            <EurowagLogo style={{ margin: "3rem 0" }}>
              <a href="https://www.eurowag.com/en" target="blank">
                <img src={Eurowaglogo} alt="Eurowag logo" />
              </a>
            </EurowagLogo>
          </TitleBlock>

          <EurowagMobileLogo>
            <a href="https://www.eurowag.com/en" target="blank">
              <img src={Eurowaglogo} alt="Eurowag logo" />
            </a>
          </EurowagMobileLogo>
          <CardContainer>
            <ArrowContainer
              style={{
                height: "50px",
                width: "250px",
                zIndex: "0",
                top: "100px",
              }}
            >
              <img src={StyledArrow} alt="" />
              <img src={StyledArrow} alt="" />
              <img src={StyledArrow} alt="" />
              <img src={StyledArrow} alt="" />
              <img src={StyledArrow} alt="" />
            </ArrowContainer>
            <img src={CardImage} alt="Image of Eurowag bank card" />
          </CardContainer>
        </TitleContent>

        <ListContainer>
          <ul>
            <li>Competitive fuel prices across Europe</li>
            <li>Wide acceptance network</li>
            <li>Safer cashless payments (SMS lock or Fuel guard)</li>
            <li>Support available 24/7 via Client portal</li>
            <li>
              Check fuel prices and plan your fuel stops easily via
              state-of-the-art Eurowag application.
            </li>
            <li>
              Possibility to use toll, financial and tax services and other
              partner services.
            </li>
          </ul>
        </ListContainer>
        <MobileCardContainer>
          <img src={CardImage} alt="Image of Eurowag bank card" />
        </MobileCardContainer>
      </Container>
      <DesktopTable />
      <MobileTable />

      <Container gutter="large">
        <ArrowContainer style={{ height: "180px", left: "100px" }}>
          <Img src={StyledArrow} alt="" />
          <Img src={StyledArrow} alt="" />
          <Img src={StyledArrow} alt="" />
          <Img src={StyledArrow} alt="" />
          <Img src={StyledArrow} alt="" />
        </ArrowContainer>
        <div style={{ width: "100%", textAlign: "center", marginTop: "2rem" }}>
          <span>SMS Locking and unlocking system on all our cards</span>
        </div>
        <FormContainer>
          <FormWrapperInner>
            <form onSubmit={handleSubmit}>
              <InputContainer>
                <label htmlFor="country">Country: </label>
                <SelectContainer>
                  <Select
                    name="country"
                    options={countries}
                    styles={selectStyles}
                    required
                    onSubmit={country === "" ? err : ""}
                    onChange={(choice) => {
                      setCountry(choice.shorthand);
                    }}
                  />
                </SelectContainer>
              </InputContainer>
              <br />
              <InputContainer>
                <label htmlFor="company_name">Company Name: </label>
                <input
                  type="text"
                  value={companyName}
                  required
                  placeholder="Company Name"
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                />
              </InputContainer>
              <br />
              <InputContainer>
                <label htmlFor="company_registration_number">
                  Company registration number:{" "}
                </label>
                <input
                  type="text"
                  value={regNumber}
                  required
                  placeholder="Company Registration number"
                  onChange={(e) => setRegNumber(e.target.value)}
                />
              </InputContainer>
              <br />
              <InputContainer>
                <label htmlFor="company_vat_number">Company VAT number: </label>
                <input
                  type="text"
                  value={vatNumber}
                  required
                  placeholder="Company VAT number"
                  onChange={(e) => setVatNumber(e.target.value)}
                />
              </InputContainer>
              <br />
              <InputContainer>
                <label htmlFor="phone">Phone number: </label>
                <input
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "Please enter a valid phone number"
                    )
                  }
                  min="10000"
                  name="phone"
                  type="number"
                  value={mobileNumber}
                  required
                  placeholder="Phone number"
                  onChange={(e) => {
                    setMobileNumber(e.target.value);
                  }}
                />
              </InputContainer>
              <br />
              <InputContainer>
                <label htmlFor="email">E-mail: </label>
                <input
                  type="email"
                  value={email}
                  required
                  placeholder="email@email.com"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputContainer>
              <ButtonDiv style={{ marginTop: "2rem" }}>
                <ReCAPTCHA
                  sitekey="6LenyGkiAAAAAHIokUL5B2k6qb3y4fGmPitExsFg"
                  size="invisible"
                  ref={reRef}
                />
              </ButtonDiv>

              <div className="message">{message ? <p>{message}</p> : null}</div>

              <ButtonDiv className="buttondiv" style={{ marginTop: "4rem" }}>
                <VATbutton type="submit">Register</VATbutton>
              </ButtonDiv>
            </form>
          </FormWrapperInner>
        </FormContainer>
      </Container>
    </EurowagPage>
  );
};

export default Eurowag;
