import styled from "styled-components";

export const ControlField = styled.div`
  input {
    display: none;
  }
  input ~ label:hover,
  input:checked ~ label {
    color: #262A68;
    border-color: #262A68;
  }

  input ~ label:focus {
    border-color: #0f1540;
    color: #0f1540;
  }
`;
export const Label = styled.label`
  border: 1px solid #ECECEC;
  cursor: pointer;
  border-radius: 4px;
  height: 100%;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #9A9A9D;
  
  .icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 578px) {
    font-size: 12px;
    padding: 5px 10px;
  }
`;
export const RadioGroupWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 24px;
  flex-direction: column;
  
  .label {
    color: #0F1540;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
  }
`;

export const DataPicker = styled.div`
  margin-bottom: 24px;
  position: relative;
  .error-message {
    top: -10px;
  }
  .icon {
    color: #9A9A9D;
  }
  .SingleDatePicker,
  .SingleDatePickerInput {
    width: 100%;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid #262A68;
  }
  .DateInput_input__focused {
    border-color: #262A68;
  }
  
  .SingleDatePickerInput {
    border-color: #DEDEDE;
    border-radius: 4px;
    padding: 4px 0 5px;
  }
  
  &.focused .SingleDatePickerInput {
    box-shadow: 0 0 0 2px #a0d1fa;
  }
  &.focused.error .SingleDatePickerInput {
    box-shadow: 0 0 0 2px #d40000;
  }
  &.error .SingleDatePickerInput {
    border-color: #d40000;
  }
`;

export const PrivacyField = styled.div`
  position: relative;
  margin-bottom: 30px;
  
  label {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
  }
  input[type="checkbox"] {
    width: 18px;
    height: 18px;
    flex: 0 0 18px;
    position: relative;
    margin-right: 10px;
    cursor: pointer;
  }
  a {
    color: #313131;
    text-decoration: underline;
  }
  .error-message {
    left: 0;
    top: auto;
    bottom: -24px;
  }

  @media (min-width: 578px) {
    input[type="checkbox"] {
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
    }
  }
`;

