import styled from "styled-components";

export const multiSelectStyles = {
  container: (base) => ({
    ...base,
    "&.invalid": {
      ".styled__control": {
        border: "1px solid red",
      },
    },
  }),
  control: (base, state) => ({
    ...base,
    border: "1px solid #9aabc2",
    borderRadius: 6,
    boxShadow: state.isFocused ? "0 0 0 2px #a0d1fa" : "none",
  }),
  input: (base) => ({
    ...base,
    width: "100%",
  }),
  placeholder: (base) => ({
    ...base,
    color: "#686a7d",
  }),

  valueContainer: (base) => ({
    ...base,
  }),

  dropdownIndicator: (base) => ({
    ...base,

    svg: {
      fill: "#686a7d",
    },
  }),

  singleValue: (base) => ({
    ...base,
    color: "#0C0C0C",
    fontSize: "16px",
    fontFamily: "Inter",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 10 }),
};

export const MultiSelectContainer = styled.div`
  width: 100%;
  padding: 0rem 1rem;
  display: flex;
  row-gap: 0.5rem;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 768px) {
    width: 100%;
    font-size: 16px;
    margin: 0 auto;
    padding: 0rem 3rem;
  }

  input {
    border: 1px solid #9aabc2;
    border-radius: 5px;
    padding-left: 10px;
    font-size: 16;
    ::placeholder {
      opacity: 0.7;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  label {
    @media (max-width: 769px) {
      font-size: 16px;
    }
    padding-right: 20px;
  }
`;

export const MultiSelectInput = styled.div`
  width: 100%;
`;

export const PhoneInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;

  @media (min-width: 768px) {
    padding: 0 3rem;
  }

  input {
    max-width: 240px;
    height: 35px;

    @media (min-width: 460px) {
      max-width: none;
    }
  }
`;

export const CMRFormWrapperInner = styled.div`
  background: #fff;
  max-width: 600px;
  margin: 0 auto;
  @media (min-width: 768px) {
    padding-bottom: 0;
    border-radius: 5px;
    border: 1px solid #eee;
    padding: 2rem;
    padding-top: 4rem;
    box-shadow: 0px 3px 27px 0px rgba(151, 180, 203, 0.16);
  }
  form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 2.5rem;
  }
`;
