import React from "react";
import { Link } from "react-router-dom";

import { LinkWrapper } from "./Styles";

const LinkButton = (props) => {
  return (
    <LinkWrapper centered={props.centered} background={props.background} hoverBackground={props.hoverBackground} {...props}>
      {props.href
        ? <a href={props.href}>{props.children}</a>
        : <Link to={props.to}>{props.children}</Link>}
    </LinkWrapper>
  );
};

export default LinkButton;
