import React from "react";
import { withRouter } from "react-router-dom";
import Register from "../components/Register/Register";

const LoginPage = (props) => {
  const { USER, history } = props;
  return (
    <>
      {USER && history.push("/")}
      <Register />
    </>
  );
};

export default withRouter(LoginPage);
