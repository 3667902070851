import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/material.css";

import React, { useMemo } from "react";
import { useField } from "formik";

const PhoneInputField = ({ name, placeholder, label, type = "text" }) => {
  const [field, meta, helpers] = useField(name);
  const isError = useMemo(
    () => !!(meta.touched && meta.error),
    [meta.error, meta.touched]
  );
  return (
    <>
    <PhoneInput
      value={field.value}
      inputProps={{ name: name }}
      country={"de"}
      onChange={(value) => {
        helpers.setValue(value);
      }}
      error={isError && meta.error}
      onBlur={field.onBlur}
    />
     {meta.error ? (
       <div style={{top: '-12px'}} className="error-message">{meta.error}</div>
     ) : null}
    </>
    
    
  );
};

export default PhoneInputField;
