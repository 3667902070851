import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";

import "../../../styles/Header/Header.css";
import { withRouter, Link } from "react-router-dom";
import LinkButton from "../../Buttons/NewButton/LinkButton";
import logo from "../../../images/logo.svg";
import close from "../../../images/close.svg";
import user from "../../../images/user.svg";
import email from "../../../images/email.svg";
import { getUnread } from "../../../store/actions/misc";
import Container from "../../UI/Container";
import {
  SET_USER,
  SET_COMPANY,
  SET_USER_SUBSCRIPTION,
  SET_SPOTLOADS,
  SET_TENDERS,
  SET_EMPTYTRUCKS,
  SET_ROUNDTRIPS,
  SET_OFFERS,
  SET_QUOTATIONS,
} from "../../../store/actionTypes";

const cookies = new Cookies(),
  token = cookies.get("tc_token");

const Header = (props) => {
  const { history } = props;
  const USER = useSelector((state) => state.dashboard.user?.data);
  const messageCount = useSelector((state) => state.app?.misc?.unread);
  const dispatch = useDispatch();
  const [active, setActive] = useState("off");
  const [RoundtripDropdownVisible, setRoundtripDropdownVisible] = useState(false);
  const [FeaturesDropdownVisible, setFeaturesDropdownVisible] = useState(false);

  // Get data for message count
  useEffect(() => {
    if (USER) {
      dispatch(getUnread(token));
    }
  }, [USER, dispatch]);

  const handleLogOut = () => {
    const cookies = new Cookies();
    cookies.remove("tc_token", { path: "/" });
    dispatch({
      type: SET_USER,
      payload: null,
    });
    dispatch({
      type: SET_COMPANY,
      payload: null,
    });
    dispatch({
      type: SET_USER_SUBSCRIPTION,
      payload: null,
    });
    dispatch({
      type: SET_SPOTLOADS,
      payload: null,
    });
    dispatch({
      type: SET_TENDERS,
      payload: null,
    });
    dispatch({
      type: SET_ROUNDTRIPS,
      payload: null,
    });
    dispatch({
      type: SET_EMPTYTRUCKS,
      payload: null,
    });
    dispatch({
      type: SET_OFFERS,
      payload: null,
    });
    dispatch({
      type: SET_QUOTATIONS,
      payload: null,
    });
    history.push("/");
  };

  const toggle = (e) => {
    if (e.target.parentElement.classList.contains("link")) return;
    setActive(active === "off" ? "on" : "off");
  };

  return (
    <>
      <div className="navbar-desktop">
        <Container gutter={"medium"}>
          <header>
            {(!USER || props.landingPage) && (
              <Link to="/" className="logo">
                <img src={logo} alt="TransConnector" />
              </Link>
            )}
            <div className="nav__items">
              {/* <Link to="/financing">Financing</Link> */}
              <Link to="/spotload">Load Exchange</Link>
              <div className="info">
                <Link to="/roundtrip">
                  Roundtrips{" "}
                  <svg
                    width="10"
                    height="7"
                    viewBox="0 0 10 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1.5L5 5.5L9 1.5"
                      stroke="#0E114A"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Link>
                <div className="info--dropdown">
                  <ul>
                    <li>
                      <Link to="/roundtrip">Roundtrip</Link>
                    </li>
                    <li>
                      <Link to="/find-roundtrip">Roundtrip Request</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <Link to="/tender">Tender</Link>
              <Link to="/trucks">Empty Trucks</Link>
              <div className="info">
                <Link to="#!">
                  Features{" "}
                  <svg
                    width="10"
                    height="7"
                    viewBox="0 0 10 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1.5L5 5.5L9 1.5"
                      stroke="#0E114A"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Link>
                <div className="info--dropdown">
                  <ul>
                    <li>
                      {/* /private-exchange or something else?*/}
                      {/* <Link to="/private-exchange">Private Exchange</Link> */}
                      <Link to="/#!">Private Exchange</Link>
                    </li>
                    <li>
                      <Link to="/eurowag-fuel">Fuel cards</Link>
                    </li>
                    <li>
                      <Link to="/drip">GPS - real time visibility</Link>
                    </li>
                    <li>
                        <Link to="/debt-collect">Transport Debt Collection Solution</Link>
                      </li>
                    <li>
                      <Link to="/cmr">CMR Insurance</Link>
                    </li>
                    <li>
                      <Link to="/ferry-bookings">Ferry bookings</Link>
                    </li>
                    <li>
                      <Link to="/instant-payment">Factoring</Link>
                    </li>
                    <li>
                      <Link to="/vat-refund">VAT refund</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="nav__actions">
              {USER ? (
                <div className="nav__logged_actions">
                  <div className="email">
                    <Link to="/dashboard/messages">
                      <img src={email} alt="" />
                      {messageCount >= 1 && (
                        <div className="email--count">
                          <h6>{messageCount}</h6>
                        </div>
                      )}
                    </Link>
                  </div>
                  <div className="user">
                    <Link to="/dashboard/">
                      <img src={user} alt="" />
                    </Link>
                    <div className="user--dropdown">
                      <ul>
                        <li>
                          <Link to="/dashboard/my-company">Profile</Link>
                        </li>
                        <li>
                          <Link to="/dashboard/my-offers">My offers</Link>
                        </li>
                        {USER.privileges === "Admin" && (
                          <li>
                            <Link to="/dashboard/subscription">
                              Subscription
                            </Link>
                          </li>
                        )}
                        <li onClick={handleLogOut}>Log out</li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="nav__logged_out_actions">
                  <Link id="log_in" to="/log-in">
                    Log in
                  </Link>
                  <LinkButton id="sign_up_btn" landingPage to="/sign-up">
                    Sign up!
                  </LinkButton>{" "}
                </div>
              )}
            </div>
          </header>
        </Container>
      </div>
      <div className="navbar-mobile">
        <Container>
          <header>
            <Link to="/dashboard" className="logo">
              <img src={logo} alt="TransConnector" />
            </Link>
            <div className="burger" onClick={toggle}>
              <div className="burger-inner"></div>
            </div>
            <div className={`nav__items ${active}`} onClick={toggle}>
              <div className="close" onClick={toggle}>
                <img src={close} alt="Close" />
              </div>
              <Link to="/spotload">Load Exchange</Link>
              <div
                className="info"
                onClick={() =>
                  setRoundtripDropdownVisible(!RoundtripDropdownVisible)
                }
              >
                <Link
                  className="link"
                  to="/roundtrip"
                  onClick={(e) => e.preventDefault()}
                >
                  <div>
                    Roundtrips{" "}
                    <svg
                      width="10"
                      height="7"
                      viewBox="0 0 10 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1.5L5 5.5L9 1.5"
                        stroke="#0E114A"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </Link>
                {RoundtripDropdownVisible && (
                  <div className="info--dropdown">
                    <ul>
                      <li>
                        <Link to="/roundtrip">Roundtrip</Link>
                      </li>
                      <li>
                        <Link to="/find-roundtrip">Roundtrip Request</Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <Link to="/tender">Tender</Link>
              <Link to="/trucks">Empty Trucks</Link>
              <div
                className="info"
                onClick={(e) =>
                  setFeaturesDropdownVisible(!FeaturesDropdownVisible)
                }
              >
                <Link
                  className="link"
                  to="#!"
                  onClick={(e) => e.preventDefault()}
                >
                  <div>
                    Features{" "}
                    <svg
                      width="10"
                      height="7"
                      viewBox="0 0 10 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1.5L5 5.5L9 1.5"
                        stroke="#0E114A"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </Link>
                {FeaturesDropdownVisible && (
                  <div className="info--dropdown">
                    <ul>
                      <li>
                        {/* /private-exchange or something else?*/}
                        {/* <Link to="/private-exchange">Private Exchange</Link> */}
                        <Link to="/#!">Private Exchange</Link>
                      </li>
                      <li>
                        <Link to="/eurowag-fuel">Fuel cards</Link>
                      </li>
                      <li>
                        <Link to="/drip">GPS - real time visibility</Link>
                      </li>
                      <li>
                        <Link to="/debt-collect">Transport Debt Collection Solution</Link>
                      </li>
                      <li>
                        <Link to="/cmr">CMR Insurance</Link>
                      </li>
                      <li>
                        <Link to="/ferry-bookings">Ferry bookings</Link>
                      </li>
                      <li>
                        <Link to="/instant-payment">Factoring</Link>
                      </li>
                      <li>
                        <Link to="/vat-refund">VAT refund</Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>

              <div>
                {USER ? (
                  <div className="nav__logged_actions">
                    <Link to="/dashboard/messages">Messages</Link>
                    <Link to="/dashboard/my-account">My account</Link>
                    <Link to="/" onClick={handleLogOut}>
                      Log out
                    </Link>
                  </div>
                ) : (
                  <div className="nav__logged_out_actions">
                    <Link to="/log-in">Log in</Link>
                    <LinkButton
                      id="sign-up-btn-mbl"
                      landingPage
                      to="/sign-up"
                      centered="mobile"
                    >
                      Sign up!
                    </LinkButton>{" "}
                  </div>
                )}
              </div>
            </div>
          </header>
        </Container>
      </div>
    </>
  );
};

export default withRouter(Header);
