import React from "react";
import Header from "../components/FrontPage/Header/Header";
import Footer from "../components/Footer/Footer";
import LeftDrawer from "../components/Dashboard/Drawers/LeftDrawer";
import FAQ from "./static/FAQ";
import ScrollToTopOnMount from "../helpers/ScrollToTop";

const FAQPage = ({ USER }) => {
  return (
    <>
      <ScrollToTopOnMount />
      <Header />
      <FAQ />
      <Footer />
      {USER && <LeftDrawer small />}
    </>
  );
};

export default FAQPage;
