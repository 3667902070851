import React from "react";
import Container from "../../components/UI/Container";
import {
  FormContainer,
  InputContainer,
  ButtonDiv,
  VATbutton,
  SuccessToast,
  InsuranceLogo,
  InsuranceMobileLogo,
  MainPara,
  SelectContainer,
  selectStyles,
} from "./Styles";
import {
  multiSelectStyles,
  MultiSelectContainer,
  MultiSelectInput,
  PhoneInputContainer,
  CMRFormWrapperInner,
} from "./CMRStyles";
import { useState, useRef } from "react";
import axios from "axios";
import insuranceLogo from "../../images/TCIA.png";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import allCountries from "../../data/allCountries.json";
import allLanguages from "../../data/allLanguages.json";
import typesOfGoods from "../../data/typesOfGoods.json";
import MultiSelectCheckbox from "../../components/CreateForm/MultiSelectCheckbox";

const availableCountries = allCountries.countries;
const languages = allLanguages.languages;

const CMR = (props) => {
  const [from] = useState("CMR insurance");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [lang, setLang] = useState("");
  const [ownTrucks, setOwnTrucs] = useState(false);
  const [broke, setBroke] = useState("");
  const [goods, setGoods] = useState("");
  const [turnover, setTurnover] = useState("");
  const [noOfTrucks, setNoOfTrucks] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const err = () => {
    alert("please select language before submitting");
  };

  const handleGoods = (selected) => {
    let selectedGoods = selected.map((i) => " " + i.value);
    setGoods(selectedGoods);
  };

  const [successMessage, setSuccessMessage] = useState();
  const reRef = useRef(<ReCAPTCHA />);

  const postAdminData = async (e) => {
    e.preventDefault();
    const token = await reRef.current.executeAsync();
    reRef.current.reset();
    setIsLoading(true);

    axios
      .post(
        "https://api.transconnector.eu/insurance",
        // "http://localhost:3000/insurance",
        {
          data: {
            firstName: firstName,
            lastName: lastName,
            company_name: companyName,
            email: email,
            phone: "+" + phone,
            lang: lang,
            ownTrucks: ownTrucks,
            noOfTrucks: noOfTrucks,
            broke: broke,
            goods: goods,
            turnover: turnover,
            countries: countries,
            data_origin_form: from,
            token,
          },
        },
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setSuccessMessage(
            <ButtonDiv>
              <SuccessToast>
                <h3>
                  We will contact You shortly to collect the information that is
                  necessary to provide a quote.
                </h3>
              </SuccessToast>
            </ButtonDiv>
          );
        }
      })
      .catch((err) => {
        alert(
          "Something went wrong, please check your connectivity or try again later."
        );
      });
  };
  return (
    <Container gutter="medium">
      <div>
        <div style={{ marginTop: "4rem", display: "flex" }}>
          <div>
            <h1>Freight Services Liability insurance – what is it about?</h1>
            <InsuranceMobileLogo>
              <img src={insuranceLogo} alt="" />
            </InsuranceMobileLogo>
            <MainPara>
              Even the most experienced cargo carriers or freight forwarders are
              not immune to mistakes that could result in damage, destruction or
              loss of the cargo entrusted to them. If the carrier or freight
              forwarder is held liable for such loss, its interests will be
              protected, and incurred losses will be indemnified by the Freight
              Services Liability (FSL) insurance policy. Road Hauler's liability
              is regulated by the Convention on the Contract for the
              International Carriage of Goods by Road (CMR).
            </MainPara>
          </div>
          <div style={{ marginLeft: "2rem" }}>
            <InsuranceLogo>
              <img src={insuranceLogo} alt="" />
            </InsuranceLogo>
          </div>
        </div>
        <div style={{ paddingLeft: "3rem", marginTop: "3rem" }}>
          <p>
            <b>
              {" "}
              —&gt; Broader insurance coverage than required by CMR Convention
            </b>
          </p>

          <MainPara>
            {" "}
            We insure carriers' and freight forwarders' liability, arising under
            all applicable laws, international conventions and even, subject to
            prior agreement, contracts or agreements with customers.
          </MainPara>
          <p>
            <b>—&gt; Total cargo value</b>
          </p>

          <MainPara>
            We can extend insurance coverage to include liability for the entire
            cargo value, not restricted to the CMR convention provisions.
          </MainPara>

          <p>
            <b>—&gt; Insurance of the entire turnover of transported cargo </b>
          </p>

          <MainPara>
            We automatically insure the entire cargo turnover, so no need to
            inform us if you buy or sell a vehicle.
          </MainPara>
        </div>
      </div>
      <FormContainer>
        <CMRFormWrapperInner>
          <form onSubmit={postAdminData}>
            <InputContainer style={{ width: "100%" }}>
              <h3 style={{ margin: "2rem auto" }}>
                Asking for an offer is free of charge!
              </h3>
            </InputContainer>
            <InputContainer>
              <label htmlFor="first_name">First Name: </label>
              <input
                type="text"
                value={firstName}
                required
                placeholder="First Name"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </InputContainer>

            <InputContainer>
              <label htmlFor="last_name">Last Name: </label>
              <input
                type="text"
                value={lastName}
                required
                placeholder="Last Name"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </InputContainer>

            <InputContainer>
              <label htmlFor="email">Contact e-email: </label>
              <input
                type="email"
                value={email}
                required
                placeholder="email@email.com"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </InputContainer>

            <InputContainer>
              <label htmlFor="company_name">Company Name: </label>
              <input
                type="text"
                value={companyName}
                required
                placeholder="Company Name"
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
              />
            </InputContainer>

            <InputContainer>
              <label htmlFor="preferredLanguage">Preffered language: </label>
              <SelectContainer>
                <Select
                  name="preferredLanguage"
                  options={languages}
                  styles={selectStyles}
                  required
                  onSubmit={lang === "" ? err : ""}
                  onChange={(choice) => {
                    setLang(choice.shorthand);
                  }}
                />
              </SelectContainer>
            </InputContainer>

            {/*siit*/}
            <InputContainer>
              <label htmlFor="own_trucks">Do you have own vehicles: </label>
              <SelectContainer>
                <Select
                  name="own_trucks"
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  styles={selectStyles}
                  required
                  onSubmit={ownTrucks === "" ? err : ""}
                  onChange={(choice) => {
                    setOwnTrucs(choice.value);
                  }}
                />
              </SelectContainer>
            </InputContainer>

            {ownTrucks && (
              <>
                <InputContainer>
                  <label htmlFor="no_of_trucks">Number of trucks: </label>
                  <input
                    name="no_of_trucks"
                    type="number"
                    value={noOfTrucks}
                    required={ownTrucks}
                    onChange={(e) => setNoOfTrucks(e.target.value)}
                  />
                </InputContainer>
              </>
            )}
            <InputContainer>
              <label htmlFor="f_broke">Do you do freight brokerage: </label>
              <SelectContainer>
                <Select
                  name="f_broke"
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  styles={selectStyles}
                  required
                  onSubmit={broke === "" ? err : ""}
                  onChange={(choice) => {
                    setBroke(choice.value);
                  }}
                />
              </SelectContainer>
            </InputContainer>

            <InputContainer>
              <label htmlFor="turnover">
                What is your yearly turnover for transport / freight brokerage:{" "}
              </label>
              <input
                name="turnover"
                type="number"
                value={turnover}
                required
                placeholder=""
                onChange={(e) => setTurnover(e.target.value)}
              />
            </InputContainer>
            <MultiSelectContainer>
              <label htmlFor="goods">
                What kind of goods do you transport:
              </label>
              <MultiSelectInput>
                <Select
                  name="goods"
                  options={typesOfGoods}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{ MultiSelectCheckbox }}
                  styles={multiSelectStyles}
                  required
                  isMulti
                  allowSelectAll={true}
                  onSubmit={goods === "" ? err : ""}
                  onChange={handleGoods}
                />
              </MultiSelectInput>
            </MultiSelectContainer>
            <MultiSelectContainer>
              <label htmlFor="countries">
                Which countries are you operating in:
              </label>
              <MultiSelectInput>
                <Select
                  name="countries"
                  options={availableCountries}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  styles={multiSelectStyles}
                  menuPortalTarget={document.body}
                  // value={countries.value}
                  required
                  isMulti
                  allowSelectAll={true}
                  onChange={(choice) => {
                    setCountries(
                      choice
                        ? choice.map(
                            (countryOption) => " " + countryOption.value
                          )
                        : []
                    );
                  }}
                />
              </MultiSelectInput>
            </MultiSelectContainer>
            <PhoneInputContainer>
              <label
                style={{ width: "100%", display: "block" }}
                htmlFor="phone"
              >
                Phone:{" "}
              </label>
              <PhoneInput
                value={phone}
                inputProps={{ name: "phone" }}
                specialLabel={""}
                country={"de"}
                onChange={(e, a) => {
                  setPhone(e);
                }}
              />
            </PhoneInputContainer>

            <ButtonDiv>
              <ReCAPTCHA
                sitekey="6LenyGkiAAAAAHIokUL5B2k6qb3y4fGmPitExsFg"
                size="invisible"
                ref={reRef}
              />
            </ButtonDiv>

            <div className="message">
              {successMessage ? (
                <p>{successMessage}</p>
              ) : (
                <ButtonDiv style={{ marginTop: "4rem" }}>
                  <VATbutton disabled={isLoading} type="submit">
                    Register
                  </VATbutton>
                </ButtonDiv>
              )}
            </div>
          </form>
        </CMRFormWrapperInner>
      </FormContainer>
    </Container>
  );
};
export default CMR;
