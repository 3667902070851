import React from "react";
import { ScreenBlockWrapper, ScreenBlockTitle } from "./Styles";

const ScreenBlock = (props) => {
  return (
    <ScreenBlockWrapper>
      {props.title && (
        <ScreenBlockTitle>
          <h2>
            {props.img && <img src={props.img} alt="" />} {props.title}
          </h2>
        </ScreenBlockTitle>
      )}
      {props.children}
    </ScreenBlockWrapper>
  );
};

export default ScreenBlock;
