import React, { useEffect } from "react";
import Select from "react-select";
import Input from "../Register/RegisterInput";
import euCountries from "../../data/EUcountries.json";
import countries from "../../data/countries.json";
import { Link } from "react-router-dom";

import axios from "axios";
import { isMobile } from "react-device-detect";
import { API_URL } from "../../constants";
import Checkbox from "../CreateForm/Checkbox";

const RegisterStepThree = (props) => {
  // handle select changes

  const handleSelectChange = (e, name) => {
    props.setInputs({ ...props.inputs, [name]: e.value });
    props.setErrors({ ...props.errors, [name]: null });
  };

  const handleCheckboxChange = (e, name) => {
    props.setInputs({ ...props.inputs, [name]: e.target.checked });
    props.setErrors({ ...props.errors, [name]: null });
  };

  return (
    <div
      className={`Registration__part--PartSecond ${
        props.pagination === 3 && "active"
      }`}
    >
      <h3>Step 3. Debt</h3>
      <Input
        classes={`floating-label ${props.errors?.amount && "invalid"}`}
        name="amount"
        type="text"
        value={props.inputs?.amount || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Gross amount of the debt"
        error={props.errors?.companyName && props.errors?.amount?.msg}
      />
      <Input
        classes={`floating-label ${props.errors?.dueDate && "invalid"}`}
        name="dueDate"
        type="date"
        value={props.inputs?.dueDate || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Date of agreed payment term"
        error={props.errors?.dueDate && props.errors?.dueDate?.msg}
      />
      <p>Additional useful information:</p>
      <Input
        classes={`floating-label ${props.errors?.lastContact && "invalid"}`}
        name="lastContact"
        type="date"
        value={props.inputs?.lastContact || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Last contact with the debtor"
        error={props.errors?.vatNumber && props.errors?.lastContact?.msg}
      />
    
      <Input
        classes={`floating-label ${props.errors?.email && "invalid"}`}
        name="email"
        type="text"
        value={props.inputs?.email || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="E-mail"
        error={props.errors?.email && props.errors.email.msg}
      />

      <Input
        classes={`floating-label ${props.errors?.phone && "invalid"}`}
        name="phone"
        type="text"
        value={props.inputs?.phone || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Phone number"
        error={props.errors?.phone && props.errors.phone.msg}
      />
      <Input
        classes={`floating-label ${props.errors?.otherContacts && "invalid"}`}
        name="otherContacts"
        type="text"
        value={props.inputs?.otherContacts || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Other Contacts at Debtors Office"
        error={props.errors?.otherContacts && props.errors.otherContacts.msg}
      />
      <div className="inline-label termslinks">
        <label htmlFor="acceptTC">
          <input
            type="checkbox"
            id="acceptTC"
            name="acceptTC"
            onChange={(e) => handleCheckboxChange(e, "acceptTC")}
          />
          Accept{" "}
          <span>
            <Link to="/debtcollect-terms" target="_blank">
            general conditions
            </Link>
          </span>{" "}
          for the provision of debt collections
        </label>
        </div>
      <div className="inline-label termslinks">
        <label htmlFor="acceptPP">
          <input
            type="checkbox"
            id="acceptPP"
            name="acceptPP"
            onChange={(e) => handleCheckboxChange(e, "acceptPP")}
          />
          TdC Solutions processes the data only in connection with
completing the order, in accordance with{" "}
          <span>
            <Link to="/debtcollect-privacy" target="_blank">
            privacy policy
            </Link>
          </span>
        </label>
        </div>
        {props.errors?.acceptTC && (
          <p className="terms-error-message">{props.errors?.acceptTC?.msg}</p>
        )}
        {props.errors?.acceptPP && (
          <p className="terms-error-message">{props.errors?.acceptPP?.msg}</p>
        )}
      <div className="formSubmit">
        <button
          type="button"
          className="next-step back"
          onClick={() => props.setPagination(2)}
        >
          Back
        </button>
        <div className="Submit__Wrapper">
          <button type="submit" className="next-step">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterStepThree;
