import React from "react";
import { OptionsWrapper, ClearFilters } from "./Styles";

const FilterOptions = (props) => {
  return (
    <OptionsWrapper fixed={props.fixed} open={props.open}>
      <ClearFilters onClick={props.clearFilters}>Clear Filters</ClearFilters>
      {props.children}
    </OptionsWrapper>
  );
};

export default FilterOptions;
