import React from 'react';
import QuillEditor, { Quill } from 'react-quill';
import { MessageBoxWrapper } from './Styles';
import 'react-quill/dist/quill.snow.css';
import ImageUploader from 'quill-image-uploader';
import { API_URL } from '../../constants';
Quill.register('modules/imageUploader', ImageUploader);

const MessageBox = (props) => {
  return (
    <MessageBoxWrapper>
      <QuillEditor
        theme="snow"
        value={props.value || ''}
        modules={{
          toolbar: [
            ['bold', 'italic'],
            !props.disableImageUpload ? ['link', 'image'] : ['link'],
          ],
          ...extraModules,
        }}
        formats={formats}
        className={props.error && 'invalid'}
        onChange={(e) => props.onInputChange(e)}
        placeholder="Type your message..."
      />
    </MessageBoxWrapper>
  );
};

const extraModules = {
  imageUploader: {
    upload: (file) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file);

        fetch(`${API_URL}/uploadImage`, {
          method: 'POST',
          body: formData,
        })
          .then((response) => response.json())
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject('Upload failed');
            console.error('Error:', error);
          });
      });
    },
  },
};

const formats = ['header', 'bold', 'italic', 'link', 'image'];

export default MessageBox;
