import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import ScreenBlock from "../Screen/ScreenBlock";

import Table from "../../NewTable/Table";
import TableHeader from "../../NewTable/TableHeader";
import { TableHeaderItem } from "../../NewTable/Styles";
import TableContent from "../../NewTable/TableContent";
import TableRow from "../../NewTable/TableRow";
import TableRowItem from "../../NewTable/TableRowItem";

import moment from "moment";
import QuotationModal from "../../Modals/QuotationModal/QuotationModal";
import DashboardFooter from "../DashboardFooter";

const Recieved = (props) => {
  const [quotationModalId, setQuotationModalId] = useState(null);

  return (
    <>
      <ScreenBlock title="Recieved Quotations">
        <Table>
          <TableHeader>
            <TableHeaderItem flex="0.5">Loading</TableHeaderItem>
            <TableHeaderItem flex="0.7">Unloading</TableHeaderItem>
            <TableHeaderItem flex="0.2">Deadline</TableHeaderItem>
            <TableHeaderItem flex="0.1">Offers</TableHeaderItem>
            <TableHeaderItem flex="0.1"></TableHeaderItem>
          </TableHeader>
          <TableContent>
            {props.CURRENT_QUOTATIONS?.recievedQuotations.map((quotation) => {
              const { _id, tender } = quotation;
              const loading = tender.loading[0];
              const unloading = tender.unloading[0];
              return (
                <Fragment key={_id}>
                  <TableRow
                    onClick={() =>
                      quotation?.offers?.length > 0
                        ? setQuotationModalId(_id)
                        : null
                    }
                  >
                    <TableRowItem
                      rowName="Loading"
                      image={{
                        url: loading.flagUrl,
                        alt: loading.country,
                      }}
                      title={{
                        name: loading.locationName,
                      }}
                      flex="0.5"
                    />
                    <TableRowItem
                      rowName="Unloading"
                      image={{
                        url: unloading.flagUrl,
                        alt: unloading.country,
                      }}
                      title={{
                        name: unloading.locationName,
                      }}
                      flex="0.7"
                    />
                    <TableRowItem
                      rowName="Deadline"
                      title={{
                        name: moment(tender.offerIsActive).format("DD.MM.YY"),
                      }}
                      flex="0.2"
                      mobileSameLine
                    />
                    <TableRowItem
                      rowName="Offers"
                      title={{
                        name: quotation.count,
                      }}
                      flex="0.1"
                      mobileSameLine
                    />
                    <TableRowItem icon={dropdownArrow} flex="0.1" onlyDesktop />
                  </TableRow>
                  {quotationModalId && (
                    <QuotationModal
                      id={quotationModalId}
                      handleAction={props.handleAction}
                      onClose={() => setQuotationModalId(null)}
                    />
                  )}
                </Fragment>
              );
            })}
          </TableContent>
        </Table>
      </ScreenBlock>
      <DashboardFooter />
    </>
  );
};

const dropdownArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
  >
    <g transform="translate(-1398 -502)">
      <g>
        <rect
          width="23"
          height="23"
          rx="11.5"
          transform="translate(1398 502)"
          fill="#e7edf4"
        />
      </g>
      <path
        d="M9661.037-15187.137l4.813,4.813,4.813-4.813"
        transform="translate(-8255.822 15697.871)"
        fill="none"
        stroke="#262a68"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export const mapStateToProps = (state) => {
  return {
    CURRENT_QUOTATIONS: state.dashboard.quotations.data,
  };
};

export default connect(mapStateToProps)(Recieved);
