import React from "react";
import Header from "../components/FrontPage/Header/Header";
import Tender from "../components/NewTender/Tender";
import LeftDrawer from "../components/Dashboard/Drawers/LeftDrawer";
import { isBrowser } from "react-device-detect";
import Minimessages from "../components/Dashboard/Messages/Minimessages";

const TenderPage = ({ USER }) => {
  return (
    <>
      <Header />
      <Tender />
      {USER && isBrowser && <Minimessages />}
      {USER && <LeftDrawer small />}
    </>
  );
};

export default TenderPage;
