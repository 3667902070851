import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import Header from "../../FrontPage/Header/Header";

import { Wrapper, Inner, Info, InfoDropdown } from "./Styles";
import { getUnread } from "../../../store/actions/misc";

const Navbar = () => {
  const USER = useSelector((state) => state.dashboard?.user?.data);
  const dispatch = useDispatch();

  // Get data for message count
  useEffect(() => {
    const cookies = new Cookies(),
      token = cookies.get("tc_token");
    if (USER) {
      dispatch(getUnread(token));
    }
  }, [USER, dispatch]);
  return (
    <Header />
  );
};

export default Navbar;
