import React, {useMemo, memo} from 'react';
import {ControlField, InputGroup, Label, RadioGroupWrap} from "./Styles";
import {Field} from "formik";
import {TRANSPORTER_TYPES} from "../../../constants";



const RadioSelect = memo(() => {
 const renderFields = useMemo(() =>  Object.keys(TRANSPORTER_TYPES).map(key => {
  const Icon = TRANSPORTER_TYPES[key].Icon;
  const id = `transport-type-${key}`;
  return (
    <ControlField className="flex-fill"  key={key}>
     <Field id={id} type="radio" name="transportType" value={key} />
     <Label htmlFor={id}>
      <Icon className="icon"/>
      <span>
          {TRANSPORTER_TYPES[key].label}
         </span>
     </Label>
    </ControlField>
  )
 }), [])
 return (
   <RadioGroupWrap>
    <div className="label">Freight transport type:</div>
    <InputGroup className="flex-fill">
     {renderFields}
    </InputGroup>
   </RadioGroupWrap>
 );
});

RadioSelect.propTypes = {};

export default RadioSelect;
