import styled, { css } from 'styled-components';

export const ButtonsWrapper = styled.div`
  display: flex;
  button {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: none;
    background: none;
    font-weight: 600;
    font-size: 13px;
    height: 40px;
    padding: 0.5rem 1.5rem;
    font-family: 'Inter';
    white-space: nowrap;
    transition: 0.25s all ease;
    cursor: pointer;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #a0d1fa;
    }
    svg {
      margin-right: 1rem;
    }
    &:nth-child(2) {
      border: 1px solid #262a68;
      color: #262a68;
    }
  }
  ${(props) =>
    props.maxWidth &&
    css`
      width: 100%;
    `}
  ${(props) =>
    props.right &&
    css`
      margin-left: auto;
    `}

    ${(props) =>
    props.desktopOnly &&
    css`
      display: none;
      @media (min-width: 1200px) {
        display: flex;
        padding: 0.5rem 1rem;
      }
    `}
`;

export const ButtonWrapper = styled.div`
  margin-top: 0.75rem;
  button {
    font-family: 'Inter';
  }
  ${(props) =>
    props.type === 'primary' &&
    css`
      button {
        margin-right: 1rem;
        border: 1px solid #00b27e;
        color: #fff;
        background: #00b27e;
      }
    `}
  ${(props) =>
    props.type === 'secondary' &&
    css`
      button {
        margin-right: 1rem;
        border: 1px solid #262a68;
        color: #262a68;
        background: #fff;
      }
    `}

  ${(props) =>
    props.type === 'alert' &&
    css`
      button {
        border: 1px solid #ce1e1e;
        color: #ce1e1e;
        background: #fff;
      }
    `}
    ${(props) =>
    props.type === 'danger' &&
    css`
      button {
        border: 1px solid #ce1e1e;
        color: #fff;
        background: #ce1e1e;
      }
    `}

    ${(props) =>
    props.small &&
    css`
      button {
        width: 88px;
      }
    `}

      ${(props) =>
    props.fileExport &&
    css`
      display: flex;
      align-items: center;
      margin-right: 50px !important;
      button {
        border: none;
        padding: 0.5rem 0;
        &:nth-child(2) {
          border: none;
        }
      }
    `}
        
  ${(props) =>
    props.spacing &&
    css`
      margin: 1rem;
    `}
`;

export const ButtonsInnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${(props) =>
    props.centered &&
    css`
      margin: auto;
      ${ButtonWrapper} {
        button {
          margin-right: 0;
        }
      }
    `}
  ${(props) =>
    props.column &&
    css`
      flex-direction: column;
      ${ButtonWrapper} {
        button {
          padding: 0.5rem 4rem;
        }
      }
      @media (min-width: 1200px) {
        flex-direction: row;
        margin: 0;
        ${ButtonWrapper} {
          margin: 0 0.5rem;
          button {
            padding: 0.5rem 1.5rem;
          }
        }
      }
    `}
`;

const Button = styled.button`
  color: #fff;
  background: #00b27e;
  font-family: "Inter var", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  align-self: center;
  border: none;
  border-radius: 4px;
  width: fit-content;
  padding: 0.5rem 1rem;
  margin: 1.5rem 0 0;
  transition: 0.25s all ease;
  &:hover {
    transition: 0.25s all ease;
    background: #07ce94;
    border-color: #07ce94;
  }
`;