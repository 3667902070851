import styled, { css } from "styled-components";

export const ModalInnerHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 1rem;
  flex-direction: column;
  @media (min-width: 992px) {
    padding: 0 1rem;
  }
`;

export const AddReview = styled.div``;

export const CompanyTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const InlineWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const CompanyLetters = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  background: #05e19f;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
  font-weight: 600;
  color: #fff;
  @media (min-width: 992px) {
    margin-right: 1.2rem;
    width: 50px;
    min-width: 50px;
    height: 50px;
    margin-right: 0.75rem;
    font-size: 1.1rem;
    text-transform: uppercase;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 2rem;
  p {
    margin-right: 1rem;
  }
`;

export const SingleReview = styled.div`
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
  line-height: 1.5;
`;

export const ReviewWrapper = styled.div`
  max-height: 500px;
  margin-top: 1.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar {
    background-color: #eaeaea;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c5c6d3;
    border: 4px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }
  ${SingleReview}:first-child {
    margin-top: 0;
  }
  ${SingleReview}:not(:last-child) {
    border-bottom: 1px solid #707070;
  }
  @media (min-width: 992px) {
    padding: 0 1rem;
  }
`;

export const SingleReviewHeader = styled.div`
  display: flex;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  flex-direction: column;
  h4,
  h5 {
    padding-right: 2rem;
    padding-left: 0;
    display: flex;
    align-items: center;
    margin: 0;
  }
  h5 {
    font-weight: 500;
    font-size: 12px;
  }
  svg {
    margin-left: 0.5rem;
  }
  a {
    text-decoration: none;
    color: #000000;
  }
  @media (min-width: 768px) {
    display: inline-flex;
    flex-direction: row;
  }
`;

export const SingleReviewHeaderInline = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: calc(35px + 0.5rem);
  @media (min-width: 768px) {
    padding-left: 0;
    width: max-content;
    justify-content: flex-start;
  }
`;

export const ReviewLettersWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const ReviewLetters = styled.div`
  width: 35px;
  min-width: 35px;
  height: 35px;
  display: flex;
  margin-right: 0.5rem;
  justify-content: center;
  align-items: center;
  background: #1b418a;
  border-radius: 50%;
  color: #fff;
  font-weight: 600;
  @media (min-width: 992px) {
    margin-right: 0.75rem;
    font-size: 0.9rem;
    text-transform: uppercase;
  }
`;

export const ReviewTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const ReviewDate = styled.div``;

export const ReviewRating = styled.div``;

export const ReviewButtons = styled.div`
  display: inline-flex;
  width: 100%;
`;

export const ReviewButton = styled.button`
  background: #f5fafb;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  ${({ mr }) =>
    mr &&
    css`
      margin-right: ${mr};
    `}
  ${({ active }) =>
    active &&
    css`
      background: #e6f3f8;
      border: 1px solid #3fbc61;
    `}
`;

export const LargeButtonSpacing = styled.div`
  @media (min-width: 992px) {
    margin-left: 135px;
  }
`;
