import React from "react";
import Header from "../components/FrontPage/Header/Header";
import Footer from "../components/Footer/Footer";
import LeftDrawer from "../components/Dashboard/Drawers/LeftDrawer";
import LoadUpload from "./static/LoadUpload";
import ScrollToTopOnMount from "../helpers/ScrollToTop";

const LoadUploadPage = ({ USER }) => {
  return (
    <>
      <ScrollToTopOnMount />
      <Header />
      <LoadUpload />
      <Footer />
      {USER && <LeftDrawer small />}
    </>
  );
};

export default LoadUploadPage;
