import React from "react";
import Header from "../components/FrontPage/Header/Header";
import Footer from "../components/Footer/Footer";
import LeftDrawer from "../components/Dashboard/Drawers/LeftDrawer";
import CargoInsurance from "./static/CargoInsurance";
import ScrollToTopOnMount from "../helpers/ScrollToTop";

const CargoInsurancePage = ({ USER }) => {
  return (
    <>
      <ScrollToTopOnMount />
      <Header />
      <CargoInsurance />
      <Footer />
      {USER && <LeftDrawer small />}
    </>
  );
};

export default CargoInsurancePage;
