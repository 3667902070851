import React from "react";
import { useDispatch } from "react-redux";

import iFavourites from "../../../assets/icons/favourites.svg";
import welcomeFavourites from "../../../assets/welcome/favourites.svg";

import uuid from "uuid";
import Table from "../UI/Table";
import ScrollToTopOnMount from "../../../helpers/ScrollToTop";
import UserNotice from "../../UserNotice/UserNotice";
import LeftDrawer from "../Drawers/LeftDrawer";
import { removeFavourite } from "../../../store/actions/dashboard/favourites";
import Screen from "../Screen/Screen";
import ScreenBlock from "../Screen/ScreenBlock";
import Navbar from "../Navbar";
import { isBrowser } from "react-device-detect";
import Minimessages from "../Messages/Minimessages";
import DashboardFooter from "../DashboardFooter";

const Favourites = (props) => {
  const { USER } = props;
  const dispatch = useDispatch();

  // Handler for removing favourites from list
  const favouriteRemoveHandler = (id) => dispatch(removeFavourite(id));

  return (
    <Screen>
      <Navbar />
      <ScrollToTopOnMount />
      <ScreenBlock title="Favourites" img={iFavourites}>
        {/* RENDER TENDER FAVOURITE TABLE */}
        {USER?.favourites.filter((e) => e.type === "Tender")?.length > 0 && (
          <Table
            head={[
              {
                key: uuid(),
                items: [{ name: "Tender" }, { name: "Options" }],
              },
            ]}
            content={[
              {
                key: uuid(),
                items: USER.favourites
                  .filter((e) => e.type === "Tender")
                  .map((favourite) => {
                    return {
                      key: favourite._id,
                      id: favourite.favouriteId,
                      item: [
                        {
                          mobileTitle: "Tender",
                          name: favourite.name,
                          isRouterLink: true,
                          linkTo: "/tender",
                          state: {
                            sheet: favourite.favouriteId,
                          },
                        },
                        {
                          options: [
                            {
                              key: uuid(),
                              items: [
                                {
                                  name: "Remove",
                                  confirmationModal: true,
                                  onConfirm: () =>
                                    favouriteRemoveHandler(
                                      favourite.favouriteId
                                    ),
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    };
                  }),
              },
            ]}
          />
        )}
        {/* RENDER LINE BREAK IF BOTH TENDER AND ROUNDTRIP EXIST AS FAVOURITES */}
        {USER?.favourites.filter((e) => e.type === "Roundtrip")?.length > 0 &&
          USER?.favourites.filter((e) => e.type === "Tender")?.length > 0 && (
            <hr />
          )}
        {/* RENDER ROUNDTRIP FAVOURITE TABLE */}
        {USER?.favourites.filter((e) => e.type === "Roundtrip")?.length > 0 && (
          <Table
            head={[
              {
                key: uuid(),
                items: [{ name: "Roundtrip" }, { name: "Options" }],
              },
            ]}
            content={[
              {
                key: uuid(),
                items: USER.favourites
                  .filter((e) => e.type === "Roundtrip")
                  .map((favourite) => {
                    return {
                      key: favourite.favouriteId,
                      id: favourite.favouriteId,
                      item: [
                        {
                          mobileTitle: "Roundtrip",
                          name: favourite.name,
                          isRouterLink: true,
                          linkTo: "/roundtrip",
                          state: {
                            sheet: favourite.favouriteId,
                          },
                        },
                        {
                          options: [
                            {
                              key: uuid(),
                              items: [
                                {
                                  name: "Remove",
                                  confirmationModal: true,
                                  onConfirm: () =>
                                    favouriteRemoveHandler(
                                      favourite.favouriteId
                                    ),
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    };
                  }),
              },
            ]}
          />
        )}
        {/* RENDER LINE BREAK IF TENDER OR ROUNDTRIP EXIST AS FAVOURITES */}
        {USER?.favourites.filter((e) => e.type === "Roundtrip")?.length > 0 ||
          (USER?.favourites.filter((e) => e.type === "Tender")?.length > 0 && (
            <hr />
          ))}
        {/* RENDER SPOTLOAD FAVOURITE TABLE */}
        {USER?.favourites.filter((e) => e.type === "Spotload")?.length > 0 && (
          <Table
            head={[
              {
                key: uuid(),
                items: [{ name: "Spot freight" }, { name: "Options" }],
              },
            ]}
            content={[
              {
                key: uuid(),
                items: USER.favourites
                  .filter((e) => e.type === "Spotload")
                  .map((favourite) => {
                    return {
                      key: favourite._id,
                      id: favourite.favouriteId,
                      item: [
                        {
                          mobileTitle: "Spot freight",
                          name: favourite.name,
                          isRouterLink: true,
                          linkTo: "/spotload",
                          state: {
                            sheet: favourite.favouriteId,
                          },
                        },
                        {
                          options: [
                            {
                              key: uuid(),
                              items: [
                                {
                                  name: "Remove",
                                  confirmationModal: true,
                                  onConfirm: () =>
                                    favouriteRemoveHandler(
                                      favourite.favouriteId
                                    ),
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    };
                  }),
              },
            ]}
          />
        )}
        {/* RENDER EMPTY TRUCK FAVOURITE TABLE */}
        {USER?.favourites.filter((e) => e.type === "Truck")?.length > 0 && (
          <Table
            head={[
              {
                key: uuid(),
                items: [{ name: "Empty truck" }, { name: "Options" }],
              },
            ]}
            content={[
              {
                key: uuid(),
                items: USER.favourites
                  .filter((e) => e.type === "Truck")
                  .map((favourite) => {
                    return {
                      key: favourite._id,
                      id: favourite.favouriteId,
                      item: [
                        {
                          mobileTitle: "Empty Truck",
                          name: favourite.name,
                          isRouterLink: true,
                          linkTo: "/trucks",
                          state: {
                            sheet: favourite.favouriteId,
                          },
                        },
                        {
                          options: [
                            {
                              key: uuid(),
                              items: [
                                {
                                  name: "Remove",
                                  confirmationModal: true,
                                  onConfirm: () =>
                                    favouriteRemoveHandler(
                                      favourite.favouriteId
                                    ),
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    };
                  }),
              },
            ]}
          />
        )}
        {USER?.favourites?.length <= 0 && (
          <UserNotice
            image={welcomeFavourites}
            title="Add tenders, spot freights or roundtrips to your favourites to quickly
                      find them here"
            buttons={[
              {
                name: "Browse tenders",
                isRouter: true,
                to: "/tender",
              },
              {
                name: "Browse spot freights",
                isRouter: true,
                to: "/spotload",
              },
              {
                name: "Browse roundtrips",
                isRouter: true,
                to: "/roundtrip",
              },
            ]}
          />
        )}
      </ScreenBlock>
      {isBrowser && <Minimessages />}
      <LeftDrawer />
      <DashboardFooter />
    </Screen>
  );
};

/* const mapDispatchToProps = (dispatch) => {
  return {
    onFetchFavourites: () => dispatch(fetchFavourites()),
    onRemoveFavourite: (id) => dispatch(removeFavourite(id)),
  };
}; */

export default Favourites;
