import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  SHOW_MESSAGE_WINDOW,
  SET_LAST_MESSAGE_WINDOW,
  SET_CURRENT_MESSAGE_WINDOW,
} from "../../../store/actionTypes";
import { connect } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import "../../../styles/Dashboard/Messages/MessagesList.css";
import { isBrowser } from "react-device-detect";
import iArrowDown from "../../../assets/icons/arrow-down.svg";
import iSearch from "../../../assets/icons/search-icon.svg";
import welcomeMessages from "../../../assets/welcome/empty-message.svg";
import Loader from "../UI/Loader";
import moment from "moment-timezone";
import UserNotice from "../../UserNotice/UserNotice";
import { API_URL } from "../../../constants";
import SubscriptionGateway from "../../Modals/AuthGatewayModals/SubscribeGateway";
import io from "socket.io-client";


const socket = io(`${API_URL}`, {
  transports: ["websocket"],
  secure: true,
});
const MessageList = (props) => {
  const USER = useSelector((state) => state.dashboard.user?.data);

  const [isRegular, setIsRegular] = useState(false);
  const [regularModal, setRegularModal] = useState(false);
  const [messagesList, setMessagesList] = useState(false);
  const [originalMessagesList, setOriginalMessagesList] = useState();
  const [availableFilterOptions, setAvailableFilterOptions] = useState([]);
  const ACTIVE_SUBSCRIPTION = useSelector(
    (state) => state.dashboard.company?.data?.activeSubscription
  );
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (USER?.id) {
      socket.on(USER?.id, (res) => {
        getMessages()
      })
    }
  }, [])

  useEffect(() => {
    if (ACTIVE_SUBSCRIPTION !== "undefined") {
      if (!ACTIVE_SUBSCRIPTION && !checked) {
        setIsRegular(true);
        setChecked(true);
      } else if (ACTIVE_SUBSCRIPTION) {
        setIsRegular(false);
        setChecked(true);
      }
    }
  }, [ACTIVE_SUBSCRIPTION, isRegular, checked]);

  const getMessages = () => {
    const cookies = new Cookies(),
      token = cookies.get("tc_token");
    axios
      .get(`${API_URL}/conversations`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data) {
          // Set all available messages
          setMessagesList(res.data);
          setOriginalMessagesList(res.data);
          // Set available filters
          const options = ["All messages"];
          if (res.data?.length > 0) {
            res.data.forEach((e) => {
              if (!options.includes(e.type)) options.push(e.type);
            });
            const offerTitles = ["All offers"];
            res.data.forEach((e) => {
              if (!offerTitles.includes(e.offer.title))
                offerTitles.push(e.offer.title);
            });
          }
          setAvailableFilterOptions(options);
        }
      });
  }
  useEffect(() => {
    getMessages()
  }, []);

  const [currentOptionFilter, setCurrentOptionFilter] = useState(
    "All messages"
  );
  const [currentOfferFilter] = useState("All offers");
  const [filterOptions, setFilterOptions] = useState(false);
  const [messagesEmptyText, setMessagesEmptyText] = useState(
    "You currently have no active messages."
  );

  const { CURRENT_MESSAGE_WINDOW, onShowMessageWindow } = props;
  useEffect(() => {
    if (CURRENT_MESSAGE_WINDOW) {
      // show message window
      onShowMessageWindow(true);
    }
  }, [CURRENT_MESSAGE_WINDOW, onShowMessageWindow]);

  // Filtering messages
  useEffect(() => {
    currentOptionFilter !== "All messages"
      ? setMessagesList(
        originalMessagesList.filter((e) => e.type === currentOptionFilter)
      )
      : setMessagesList(originalMessagesList);
  }, [currentOptionFilter, currentOfferFilter]);

  //SEARCH by company name
  function searchConversation(e) {
    if (e.target?.value?.length > 1) {
      let searchWord = e.target.value.toLowerCase();
      const findbyCompany = originalMessagesList.filter((e) =>
        e.recieverCompanyName.toLowerCase().includes(searchWord)
      );
      const findbyTitle = originalMessagesList.filter((e) =>
        e.offer.title.toLowerCase().includes(searchWord)
      );
      const searcresutls = findbyCompany.concat(
        findbyTitle.filter((_id) => findbyCompany.indexOf(_id) < 0)
      );
      setMessagesList(searcresutls);
      if (findbyCompany?.length === 0) {
        setMessagesEmptyText("No results");
      }
    } else {
      setMessagesList(originalMessagesList);
    }
  }

  if (!messagesList) {
    return (
      <div className="Messages_List">
        <div className="Messages_List--inner">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={`Messages_List ${messagesList?.length <= 0 ? "empty-list" : ""
          }`}
      >
        <div className="Messages_List--inner">
          <div className="Messages_Search">
            <div className="input-collection">
              <div className="input-wrapper">
                <div className="input">
                  <img src={iSearch} alt="" />
                  <input
                    type="text"
                    onChange={searchConversation}
                    placeholder="Search by company/title"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="Messages">
            <div className="Messages--header">
              <h2>Messages</h2>
              {availableFilterOptions && (
                <div className="Filter_Select">
                  <div
                    className={`Filter_Select--box ${filterOptions ? "show" : ""
                      }`}
                    onClick={() => setFilterOptions(!filterOptions)}
                  >
                    <h3>{currentOptionFilter}</h3>
                    <img src={iArrowDown} alt="" />
                  </div>
                  <div
                    className="Filter_Select_Options"
                    onClick={() => setFilterOptions(!filterOptions)}
                  >
                    <ul>
                      {availableFilterOptions.map((filter) => {
                        return (
                          <li
                            key={filter}
                            onClick={() => setCurrentOptionFilter(filter)}
                          >
                            {filter}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <div className="All_Messages">
              {messagesList?.length > 0 ? (
                messagesList.map((message) => {
                  let messageTime = message.lastMessageTime;
                  if (
                    message.lastMessageTime.match(/[0-9][0-9][:][0-6][0-59]/)
                  ) {
                    const utcTime = moment(
                      moment().format("YYYY-MM-DD") +
                      "T" +
                      message.lastMessageTime
                    );
                    const userTimezone = moment.tz.guess();
                    moment.tz.setDefault("UTC");
                    messageTime = utcTime.tz(userTimezone).format("HH:mm");
                  }
                  return (
                    <div
                      className={`message-wrapper ${
                        /* currID === message._id ? "active" : "" */
                        props.CURRENT_MESSAGE_WINDOW === message._id
                          ? "active"
                          : ""
                        }`}
                      key={message._id}
                      data-id={message._id}
                      onClick={
                        message.type === "Roundtrip" &&
                          isRegular &&
                          !regularModal
                          ? () => setRegularModal(true)
                          : props.CURRENT_MESSAGE_WINDOW !== message._id
                            ? function () {
                              props.onSetCurrentMessageWindow(message._id);
                              message.unread = 0;
                            }
                            : null
                      }
                    >
                      <div
                        className={"message-color-code " + message.type}
                      ></div>
                      <h4>{message.offer.title}</h4>
                      <h5
                        style={{
                          filter:
                            message.type === "Roundtrip" &&
                            isRegular &&
                            "blur(3px)",
                        }}
                      >
                        {message.type !== "Roundtrip"
                          ? message.recieverCompanyName
                          : !isRegular
                            ? message.recieverCompanyName
                            : "xxxxxxxxxxxx"}
                      </h5>
                      <h5
                        style={{
                          filter:
                            message.type === "Roundtrip" &&
                            isRegular &&
                            "blur(3px)",
                        }}
                      >
                        {message.type !== "Roundtrip"
                          ? message.lastMessage.replace(/<[^>]+>/g, "")
                          : !isRegular
                            ? message.lastMessage.replace(/<[^>]+>/g, "")
                            : "xxxxxxxxxxxxxxxxx"}
                      </h5>
                      {message?.unread > 0 ? (
                        <div className="unread-messages-count">
                          {message.unread}
                        </div>
                      ) : null}
                      <div className="last-message-sent-date">
                        {messageTime}
                      </div>
                    </div>
                  );
                })
              ) : (
                <h4>{messagesEmptyText}</h4>
              )}
            </div>
          </div>
        </div>
      </div>
      {messagesList?.length <= 0 ||
        (!props.CURRENT_MESSAGE_WINDOW && isBrowser && (
          <UserNotice
            image={welcomeMessages}
            title="This is where action begins"
            fullscreen
          />
        ))}
      {regularModal && (
        <SubscriptionGateway onClose={() => setRegularModal(false)} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    CURRENT_MESSAGE_WINDOW: state.dashboard.message.currentMessageWindow,
    USER_DATA: state.dashboard.user.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessageWindow: (payload) =>
      dispatch({ type: SHOW_MESSAGE_WINDOW, payload: payload }),
    onSetCurrentMessageWindow: (payload) =>
      dispatch({ type: SET_CURRENT_MESSAGE_WINDOW, payload: payload }),
    onSetLastMessageWindow: (payload) =>
      dispatch({ type: SET_LAST_MESSAGE_WINDOW, payload: payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageList);
