import React from "react";
import AsyncLocationInput from "../../CreateForm/AsyncLocationInput";
import DateSelect from "../../CreateForm/DateSelect";
import SingleDateSelect from "../../CreateForm/SingleDateSelect";
import TrucktypeSelect from "../../CreateForm/TrucktypeSelect";
import NumberInput from "../../CreateForm/NumberInput";
import PeriodSelect from "../../CreateForm/PeriodSelect";
import { InlineWrapper, Wrapper } from "../../CreateForm/Styles";
import { InputInlineWrapper } from "../../Modals/SendQuoteModal/Styles";
import moment from "moment";

const TenderCreatorStepOne = (props) => {
  return (
    <Wrapper>
      <InlineWrapper>
        <AsyncLocationInput
          label="Loading"
          type="loading"
          onSelectChange={props.onSelectChange}
          handleInputDelete={props.handleInputDelete}
          errors={props.errors}
          inputs={props.inputs}
          maxInputCount={10}
        />
        <AsyncLocationInput
          label="Unloading"
          type="unloading"
          onSelectChange={props.onSelectChange}
          handleInputDelete={props.handleInputDelete}
          errors={props.errors}
          inputs={props.inputs}
          maxInputCount={10}
        />
      </InlineWrapper>
      <DateSelect
        label="Period of transports"
        type="periodOfTransports"
        onInputChange={props.onInputChange}
        error={props.errors?.periodOfTransports}
        inputs={props.inputs}
      />
      <InputInlineWrapper>
        <NumberInput
          label="Loads"
          name="noOfTrucks"
          placeholder="Loads"
          min={0}
          inputs={props.inputs}
          value={props.inputs?.noOfTrucks || ""}
          onInputChange={props.onInputChange}
          error={props.errors?.noOfTrucks}
        />
        <span>per</span>
        <PeriodSelect
          placeholder="Period"
          name="period"
          inputs={props.inputs}
          onSelectChange={props.onInputChange}
          error={props.errors?.period}
        />
      </InputInlineWrapper>
      <NumberInput
        label="Target price (per shipment)"
        name="targetPrice"
        placeholder="Price €"
        min={0}
        inputs={props.inputs}
        value={props.inputs?.targetPrice || ""}
        onInputChange={props.onInputChange}
        error={props.errors?.targetPrice}
      />
      <TrucktypeSelect
        label="Truck type"
        name="truckType"
        onSelectChange={props.onSelectChange}
        onInputChange={props.onInputChange}
        inputs={props.inputs}
        errors={props.errors}
      />
      <SingleDateSelect
        label="Deadline to recieve offers"
        type="offerIsActive"
        id="offer_is_active"
        placeholder="Until"
        onInputChange={props.onInputChange}
        error={props.errors?.offerIsActive}
        inputs={props.inputs}
        disabled={!props.inputs?.periodOfTransports?.endDate}
        isOutsideRange={(d) =>
          !d.isBetween(
            moment(moment().subtract("1", "days")),
            props.inputs?.periodOfTransports?.endDate + 1
          )
        }
      />
    </Wrapper>
  );
};

export default TenderCreatorStepOne;
