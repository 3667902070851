import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SET_OFFERS } from '../../../store/actionTypes';
import moment from 'moment';
import Form from '../../CreateForm/Form';
import FormBlock from '../../CreateForm/FormBlock';
import RoundtripCreatorStepOne from './RoundtripCreatorStepOne';
import RoundtripCreatorStepTwo from './RoundtripCreatorStepTwo';
import RoundtripCreatorStepThree from './RoundtripCreatorStepThree';
import LargeButton from '../../CreateForm/LargeButton';
import { ButtonWrapper } from '../../CreateForm/Styles';
import Loader from '../../Dashboard/UI/Loader';
import { API_URL } from '../../../constants';
import {
  getSingleRoundtrip,
  createRoundtrip,
  validateRoundtrip,
} from '../../../store/actions/app/roundtrip';
import Screen from '../../Dashboard/Screen/Screen';
import Navbar from '../../Dashboard/Navbar';
import LeftDrawer from '../../Dashboard/Drawers/LeftDrawer';
import { isBrowser } from 'react-device-detect';
import Minimessages from '../../Dashboard/Messages/Minimessages';

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

const RoundtripCreator = (props) => {
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState(props.showAllBlocks ? 0 : 1);
  const USER_PLAN = useSelector(
    (state) => state.dashboard.company?.data?.activeSubscription
  );
  const SUBSCRIPTION_NAME = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.subscriptionName
  );
  const EXPIRATION_DATE = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.expirationDate
  );
  const SUBSCRIPTION_STATUS = useSelector(
    (state) => state.dashboard.company?.data?.subscriptionPlan?.status
  );
  const dispatch = useDispatch();

  // Set subscription barrier
  const { history } = props;
  useEffect(() => {
    if (
      (SUBSCRIPTION_NAME !== 'undefined' &&
        SUBSCRIPTION_NAME !== 'Roundtrip Pro') ||
      (typeof USER_PLAN !== 'undefined' && !USER_PLAN)
    )
      history.push('/roundtrip');
  }, [USER_PLAN, EXPIRATION_DATE, SUBSCRIPTION_NAME, history]);


  useEffect(() => {
    let canCreate = false
    if(SUBSCRIPTION_NAME === 'Roundtrip Pro') {
      if(SUBSCRIPTION_STATUS === 'Active'){
        if(moment(EXPIRATION_DATE).isSameOrAfter()){
          canCreate = true
        }
      }
    }

    if(!canCreate) {
      history.push('/roundtrip?create=true');

    }
  }, [SUBSCRIPTION_NAME, EXPIRATION_DATE, SUBSCRIPTION_STATUS]);

  // Get data when user requests edit page
  const { editMode, duplicateMode } = props;
  const { id: roundtripId } = props.match?.params || {};

  useEffect(() => {
    if ((editMode || duplicateMode) && roundtripId) {
      dispatch(getSingleRoundtrip(roundtripId))
        .then((res) => {
          const { roundtrip } = res.data;
          setInputs(roundtrip);
          setLoaded(true);
        })
        .catch(() => history.push('/roundtrip'));
    } else {
      setLoaded(true);
    }
  }, [editMode, duplicateMode, roundtripId, history]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    dispatch(
      createRoundtrip(
        !editMode ? 'POST' : 'PUT',
        `${API_URL}/roundtrip`,
        inputs
      )
    )
      .then((res) => {
        dispatch({ type: SET_OFFERS, payload: res.data });
        props.history.push('/dashboard/my-offers/roundtrips');
      })
      .catch((err) => {
        const { errors } = err.response.data;
        setErrors(errors);
        setSubmitting(false);
        scrollToTop();
      });
  };

  const handleInputChange = (value, type, descendant) => {
    if (!descendant) {
      setInputs((prevState) => ({ ...prevState, [type]: value }));
    } else {
      setInputs((prevState) => ({
        ...prevState,
        [descendant]: { ...prevState[descendant], [type]: value },
      }));
    }
    // Clear error of interacted input
    setErrors((prevState) => ({ ...prevState, [type]: null }));
  };

  const handleSelectChange = (value, type, descendant) => {
    if (!descendant) {
      setInputs((prevState) => ({ ...prevState, [type]: value }));
    } else {
      setInputs((prevState) => ({
        ...prevState,
        [descendant]: { ...prevState[descendant], [type]: value },
      }));
    }
    // Clear error of interacted input
    if (errors?.[type])
      setErrors((prevState) => ({ ...prevState, [type]: null }));
  };

  const changeStep = (step) => setCurrentStep(step);

  const handleNextClick = () => {
    if (currentStep === 1) {
      dispatch(validateRoundtrip(`${API_URL}/roundtripValidate`, inputs))
        .then((res) => {
          changeStep(2);
          scrollToTop();
        })
        .catch((err) => {
          const { errors } = err.response.data;
          setErrors(errors);
          scrollToTop();
        });
    }
    if (currentStep === 2) {
      dispatch(validateRoundtrip(`${API_URL}/roundtripValidateTwo`, inputs))
        .then((res) => {
          changeStep(3);
          scrollToTop();
        })
        .catch((err) => {
          const { errors } = err.response.data;
          setErrors(errors);
          scrollToTop();
        });
    }
  };

  if (!loaded) return <Loader />;
  if (editMode && !inputs) return <Loader />;
  return (
    <Screen centered>
      <Navbar />
      <Form
        title={!props.editMode ? 'Add roundtrip' : 'Edit roundtrip'}
        icon={icon}
        onSubmit={handleSubmit}
        noValidate
      >
        <FormBlock
          title="1. Route"
          step={!props.showAllBlocks ? 1 : 0}
          currentStep={currentStep}
        >
          <RoundtripCreatorStepOne
            onSelectChange={handleSelectChange}
            onInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
          />
          {currentStep !== 0 && (
            <ButtonWrapper>
              <LargeButton label="Next" onClick={() => handleNextClick()} />
            </ButtonWrapper>
          )}
        </FormBlock>
        <FormBlock
          title="2. Freight"
          step={!props.showAllBlocks ? 2 : 0}
          currentStep={currentStep}
        >
          <RoundtripCreatorStepTwo
            onSelectChange={handleSelectChange}
            onInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
          />
          {currentStep !== 0 && (
            <ButtonWrapper>
              <LargeButton
                secondary
                label="Back"
                onClick={() => {
                  changeStep(1);
                  scrollToTop();
                }}
              />
              <LargeButton label="Next" onClick={() => handleNextClick()} />
            </ButtonWrapper>
          )}
        </FormBlock>
        <FormBlock
          title="3. Additional information"
          step={!props.showAllBlocks ? 3 : 0}
          currentStep={currentStep}
        >
          <RoundtripCreatorStepThree
            onSelectChange={handleSelectChange}
            onInputChange={handleInputChange}
            inputs={inputs}
            errors={errors}
          />
          {!submitting ? (
            <ButtonWrapper>
              {!props.showAllBlocks && (
                <LargeButton
                  secondary
                  label="Back"
                  onClick={() => {
                    changeStep(2);
                    scrollToTop();
                  }}
                />
              )}
              <LargeButton type="submit" label="Publish" />
            </ButtonWrapper>
          ) : (
            <Loader />
          )}
        </FormBlock>
      </Form>
      {isBrowser && <Minimessages />}
      <LeftDrawer small />
    </Screen>
  );
};

const icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32.014"
    height="23.061"
    viewBox="0 0 32.014 23.061"
  >
    <g transform="translate(-1.306 -4.718)">
      <path
        d="M20.895,7.61H2.752a1.446,1.446,0,1,1,0-2.892H20.895a1.446,1.446,0,1,1,0,2.892Z"
        transform="translate(0 0)"
        fill="#e13505"
      />
      <path
        d="M20.895,11.582H2.752a1.446,1.446,0,1,1,0-2.892H20.895a1.446,1.446,0,1,1,0,2.892Z"
        transform="translate(0 2.772)"
        fill="#e13505"
      />
      <path
        d="M14.467,15.554H2.752a1.446,1.446,0,1,1,0-2.892H14.467a1.446,1.446,0,1,1,0,2.892Z"
        transform="translate(0 5.544)"
        fill="#e13505"
      />
      <path
        d="M26.69,15.915H14.372a1.446,1.446,0,1,1,0-2.892H26.69a1.446,1.446,0,1,1,0,2.892Z"
        transform="translate(5.184 5.705)"
        fill="#e13505"
      />
      <path
        d="M18.632,23.974a1.445,1.445,0,0,1-1.446-1.446V10.21a1.446,1.446,0,0,1,2.892,0V22.528A1.446,1.446,0,0,1,18.632,23.974Z"
        transform="translate(7.084 3.805)"
        fill="#e13505"
      />
    </g>
  </svg>
);

export default withRouter(RoundtripCreator);
