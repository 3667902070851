import styled, { css } from 'styled-components';

export const UserNoticeWrapper = styled.div`
  padding: 3rem 0;
  width: 100%;
  ${(props) =>
    props.fullscreen &&
    css`
      width: 100%;
      height: 100%;
      margin: auto;
    `}
`;

export const UserNoticeInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const UserNoticeImage = styled.div``;

export const UserNoticeContent = styled.div`
  margin: 2rem 0;
  max-width: 500px;
  h4 {
    text-align: center;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
    padding: 0 0.5rem;
  }
`;

export const UserNoticeButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: column;

  @media (min-width: 992px) {
    justify-content: center;
    flex-direction: row;
  }
`;

export const UserNoticeButton = styled.div`
  display: inline-flex;
  flex-direction: row;
  a,
  button {
    width: 100%;
    display: flex;
    margin: 1rem;
    padding: 1rem 2rem;
    color: #262a68;
    transition: 0.25s all ease;
    border-radius: 10px;
    justify-content: center;
    border: 2px solid #262a68;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    font-size: 1rem;
    &:hover {
      background: #262a68;
      transition: 0.25s all ease;
      color: #fff;
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #a0d1fa;
    }
  }
  button {
    background: none;
    cursor: pointer;
    font-family: 'Inter';
  }
  @media (min-width: 992px) {
    a,
    button {
      margin: 0 1rem;
    }
  }
  ${(props) =>
    props.primary &&
    css`
      a,
      button {
        background: #00b27e;
        border-color: #00b27e;
        color: #fff;
        &:hover {
          background: #07ce94;
          border-color: #07ce94;
        }
      }
    `}
`;
