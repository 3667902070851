import React from "react";
import Header from "../components/FrontPage/Header/Header";
import Roundtrip from "../components/Roundtrip/Roundtrip";
import LeftDrawer from "../components/Dashboard/Drawers/LeftDrawer";
import Minimessages from "../components/Dashboard/Messages/Minimessages";
import { isBrowser } from "react-device-detect";

const RoundtripPage = ({ USER }) => {
  return (
    <>
      <Header />
      <Roundtrip />
      {USER && isBrowser && <Minimessages />}
      {USER && <LeftDrawer small />}
    </>
  );
};

export default RoundtripPage;
