import React, { useMemo } from "react";
import { Content, Grid } from "./Styles";
import Container from "../UI/Container";
import ReservationFormFields from "./Form/ReservationFormFields";
import TransportInfo from "./Info/TransportInfo";
import { Form, withFormik } from "formik";
import ReservationTabs from "./ReservationTabs";
import reservationRoutes from "./mock.json";
import validationSchema from "./schema";
import axios from "axios";
import { API_URL } from "../../constants";
import { useState } from "react";

const initialValues = {
  email: "",
  transportType: undefined,
  phone: "",
  date: undefined,
  privacy: false,
  bookingType: "ferries",
  from: "",
  to: "",
};

const Reservation = ({
  setFieldValue,
  values,
  isSubmitting,
  setFieldTouched,
  status,
}) => {
  const activeRoute = useMemo(() => {
    const route = reservationRoutes[values.bookingType];
    if (!route) {
      return {};
    }
    const infoRoute = route.routes_info[values.from + "_" + values.to];

    return {
      infoRoute,
      routesInfo: route.routes_info,
      popularRoutes: route.popular_routes,
      routesAutocomplete: route.routes_autocomplete,
    };
  }, [values]);

  return (
    <Content>
      <Form>
        <h1 style={{ maxWidth: "80%" }}>
          We will help to obtain the freight transport tickets for{" "}
          <span>ferries</span>, <span>bridges</span> and <span>tunnels</span> by
          the <span>best terms</span>
        </h1>
        <Container>
          <Grid>
            <div className="transport-select">
              <ReservationTabs
                setFieldValue={setFieldValue}
                activeTab={values.bookingType}
                setFieldTouched={setFieldTouched}
              />

              <ReservationFormFields
                routeFrom={values.from}
                isSubmitting={isSubmitting}
                routeTo={values.to}
                setFieldValue={setFieldValue}
                routesAutocomplete={activeRoute.routesAutocomplete}
                sent={status}
              />
            </div>

            <TransportInfo
              activeRoute={activeRoute}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              tab={values.bookingType}
            />
          </Grid>
          <p>
            Your desired relation is not listed? Please contact: <a href="tel:+37062631667">+37062631667</a> or <a href="mailto:cargo@ferrybookings.lt">cargo@ferrybookings.lt</a>
          </p>
        </Container>
      </Form>
    </Content>
  );
};

export default withFormik({
  mapPropsToValues: () => initialValues,
  validationSchema,
  handleSubmit: async (values, { resetForm, setFieldError, setStatus }) => {
    try {
      await axios.post(`${API_URL}/bookings`, values);
      resetForm();
      setStatus(true);
    } catch (error) {
      console.log(error)
      const { data } = error.response || {};
      (data?.errors || []).forEach(({ param, msg }) => {
        setFieldError(param, msg);
      });
    }
  },
})(Reservation);
