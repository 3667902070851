import React from "react";
import { withRouter } from "react-router-dom";
import VerificationDocuments from "../components/Dashboard/MyAccount/VerificationDocuments";

const VerificationDocumentsPage = (props) => {
  const { USER, COMPANY, history } = props;
  return <>{USER && <VerificationDocuments COMPANY={COMPANY} />}</>;
};

export default withRouter(VerificationDocumentsPage);
