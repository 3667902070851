import styled from 'styled-components';

export const Wrapper = styled.div``;

export const MobileNav = styled.div`
  display: inline-flex;
  height: 64px;
  box-shadow: 0px 3px 6px 0px rgba(117, 141, 187, 0.16);
  width: 100%;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  align-items: center;
  z-index: 11;
  position: fixed;
  background: #fff;
  top: 0;
  a {
    text-decoration: none;
  }
  img {
    width: 90px;
  }
  button {
    border: none;
    padding: 0;
    background: none;
    width: 35px;
    display: flex;
    height: 35px;
    justify-content: center;
    align-items: center;
    div {
      width: 22px;
      height: 14px;
    }
    &:focus {
      outline: none;
    }
  }
  @media (min-width: 992px) {
    display: none;
  }
`;
export const Letters = styled.div`
  background: #e13505;
  color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.9rem;
`;

export const Inner = styled.div``;
//export const Screen = styled.div``;
