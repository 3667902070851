import React, { useState, useEffect } from "react";
import Checkout from "../components/Checkout/Checkout";
import { withRouter, Redirect } from "react-router-dom";

const CheckoutPage = (props) => {
  const [pathName, setPathName] = useState("/pricing");

  useEffect(() => {
    if (props.location.pathname === "/checkout") {
      setPathName("/pricing");
    }
  }, [props.location]);

  if (props.location.state) {
    return (
      <Checkout
        plan={props.location.state.plan}
        billing={props.location.state.billing}
        price={props.location.state.price}
        subscriptionId={props.location.state.subscriptionId}
        currentSubscriptionId={props.location.state.currentMembershipId}
        gatewaySubscriptionId={props.location.state.gatewaySubscriptionId}
      />
    );
  } else if (props.location.pathname === "/checkout/success") {
    return <Checkout
      plan={new URLSearchParams(props.location.search).get("plan")}
      billing={new URLSearchParams(props.location.search).get("duration")}
      price={new URLSearchParams(props.location.search).get("price")}
      subscriptionId={new URLSearchParams(props.location.search).get("subscriptionId")}
      currentSubscriptionId={new URLSearchParams(props.location.search).get("currentMembershipId")}
      gatewaySubscriptionId={new URLSearchParams(props.location.search).get("gatewaySubscriptionId")}
      />
  }
  else {
    return <Redirect to={pathName} />;
  }
};

export default withRouter(CheckoutPage);
