import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  UserNoticeWrapper,
  UserNoticeInner,
  UserNoticeImage,
  UserNoticeContent,
  UserNoticeButtons,
  UserNoticeButton,
} from './Styles';
import SubscribeGateway from '../Modals/AuthGatewayModals/SubscribeGateway';
import ProGateway from '../Modals/AuthGatewayModals/ProGateway';

const UserNotice = (props) => {
  const [expiredModal, setExpiredModal] = useState(false);
  const [proModal, setProModal] = useState(false);
  return (
    <>
      <UserNoticeWrapper fullscreen={props.fullscreen}>
        <UserNoticeInner>
          {props.image && (
            <UserNoticeImage>
              <img src={props.image} alt={props.alt || ''} />
            </UserNoticeImage>
          )}
          {props.title && (
            <UserNoticeContent>
              <h4>{props.title}</h4>
            </UserNoticeContent>
          )}
          {props.buttons && props.buttons?.length > 0 && (
            <UserNoticeButtons>
              {props.buttons.map((button) => {
                return (
                  <Fragment key={button.name}>
                    {button.isRouter &&
                    !button.isButton &&
                    !props.isExpired &&
                    !button.isRegular ? (
                      <UserNoticeButton primary={button.primary}>
                        <Link to={button.to}>{button.name}</Link>
                      </UserNoticeButton>
                    ) : (
                      !button.isButton &&
                      !props.isExpired &&
                      !button.isRegular && (
                        <UserNoticeButton primary={button.primary}>
                          <a href={button.to}>{button.name}</a>
                        </UserNoticeButton>
                      )
                    )}
                    {button.isButton && !props.isExpired && !button.isRegular && (
                      <UserNoticeButton primary={button.primary}>
                        <button type="button" onClick={button.onClick}>
                          {button.name}
                        </button>
                      </UserNoticeButton>
                    )}
                    {props.isExpired && !button.isRegular && (
                      <UserNoticeButton primary={button.primary}>
                        <button
                          type="button"
                          onClick={() => setExpiredModal(true)}
                        >
                          {button.name}
                        </button>
                      </UserNoticeButton>
                    )}
                    {button.isRegular && (
                      <UserNoticeButton primary={button.primary}>
                        <button type="button" onClick={() => setProModal(true)}>
                          {button.name}
                        </button>
                      </UserNoticeButton>
                    )}
                  </Fragment>
                );
              })}
            </UserNoticeButtons>
          )}
        </UserNoticeInner>
      </UserNoticeWrapper>
      {expiredModal && (
        <SubscribeGateway onClose={() => setExpiredModal(false)} />
      )}
      {proModal && <ProGateway onClose={() => setProModal(false)} />}
    </>
  );
};

export default UserNotice;
